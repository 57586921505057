import React, { useState, useEffect } from "react";
import { Box, Typography, Button, CircularProgress, NoSsr, ThemeProvider } from "@mui/material";
import { ToastProps } from "../../Types/common";
import { AddReports } from "./AddReports";
import { RemoveReports } from "./RemoveReports";
import { theme } from '../../Types/common';
import OrangeStartIconButton from '../OrangeStartIconButton';
import OrangeEndIconButton from '../OrangeEndIconButton';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
const days = {
    1: "Sun", 
    2: "Mon",
    3: "Tue",
    4: "Wed", 
    5: "Thu", 
    6: "Fri", 
    7: "Sat",
};
const reportConfigs = [
    { id: 1, label: "Pending Orders" },
    { id: 2, label: "In-Process Orders" },
    { id: 4, label: "All Orders by Status" },
    { id: 5, label: "Orders Waiting on Paperwork" }
];
interface ScheduledReport {
    ReportId: number;
    DayOfWeekIds: number[];
}
interface ReportDetailsProps {
    setToast: React.Dispatch<React.SetStateAction<ToastProps>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setReportTitle: React.Dispatch<React.SetStateAction<string>>;
    setReportPage: React.Dispatch<React.SetStateAction<number>>;
    reportPage: number;
}
const ReportDetails: React.FC<ReportDetailsProps> = ({ setToast, setOpen, setReportTitle, setReportPage, reportPage }) => {
    const [scheduledReports, setScheduledReports] = useState<ScheduledReport[]>([]);
    
    const fetchScheduledReports = async () => {
        try {
            const response = await fetch(window.location.origin + `/Reports/GetScheduledList`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            setScheduledReports(data.result);
            setReportTitle("Scheduled Reports")
        } catch (error) {
            setToast({
                show: true,
                message: "Failed to fetch scheduled reports",
                severity: "error",
            });
        }
    };

    useEffect(() => {
        fetchScheduledReports();
    }, []);

    const formatScheduledDays = (daysArray: number[]) => {
        if (daysArray.length === 7 && daysArray.every(day => [1, 2, 3, 4, 5, 6, 7].includes(day))) {
            return "Every day";
        }
        if (daysArray.length === 5 && daysArray.every(day => [2, 3, 4, 5, 6].includes(day))) {
            return "Every week day";
        }
        if (daysArray.length === 2 && daysArray.every(day => [1, 7].includes(day))) {
            return "Weekends only";
        }
        return daysArray.length > 0 ? daysArray.map((day: number) => days[day]).join(", ") : "No scheduled report";

    };
    const allReportsEmpty = () => {
        return [1, 2, 4, 5].every(reportId =>
            formatScheduledDays(scheduledReports.find(r => r.ReportId === reportId)?.DayOfWeekIds || []) === "No scheduled report"
        );
    };

    if (!scheduledReports) {
        return <CircularProgress sx={{ color:'#ED7300'}} />;
    }

    return (
        <ThemeProvider theme={theme}>
        <NoSsr>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    minHeight: "200px",
                }}
            >
            {reportPage === 0 && (
                <div>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        {reportConfigs.map(({ id, label }) => (
                            <Typography key={id} fontWeight="700" sx={{ fontSize: '12px', color: "#333E48" }}>
                                {label}:&nbsp;
                                <span style={{ fontWeight: 'normal' }}>
                                    {formatScheduledDays(scheduledReports.find(r => r.ReportId === id)?.DayOfWeekIds || [])}
                                </span>
                            </Typography>
                        ))}
                    </Box>
                    <Box sx={{ display: "flex", gap: '20px', marginTop: '30px' }}>
                        <OrangeStartIconButton
                            label="Add Report"
                            onClick={() => setReportPage(1)}
                            variant="contained"
                            disabled={false}
                            startIcon={<EventAvailableIcon />}
                            sx={{ fontFamily: '"Noto Sans", Arial, sans-serif', width: '170px' }}
                        />
                        <OrangeEndIconButton
                            label="Remove Report"
                            onClick={() => setReportPage(2)}
                            variant="contained"
                            endIcon={<EventBusyIcon />}
                            disabled={allReportsEmpty()}
                            sx={{ fontFamily: '"Noto Sans", Arial, sans-serif', width: '170px' }}
                        />
                    </Box>
                </div>
            )}
            {reportPage === 1 && (
                <AddReports setToast={setToast} setReportTitle={setReportTitle} refreshReports={fetchScheduledReports}
                    setReportPage={setReportPage} />
            )}
            {reportPage === 2 && (
                <RemoveReports setToast={setToast} setReportTitle={setReportTitle} refreshReports={fetchScheduledReports}
                    scheduledReports={scheduledReports} setScheduledReports={setScheduledReports} setReportPage={setReportPage} />
            )}
            </Box>
        </NoSsr>
        </ThemeProvider>
    );
};

export default ReportDetails;
