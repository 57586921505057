import React, { useState, useEffect, Dispatch, SetStateAction, useRef } from "react";
import { NoSsr, Box, Typography, Grid, FormControlLabel, Autocomplete, CircularProgress, TextField } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { CurrencyInput, DatePicker, FileUpload, NumberInput, PhoneInput, SSNInput, TextInput } from '../Components/Input';
import CircleCheckboxAcertus from '../Components/CircleCheckboxAcertus';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import OrangeLoadingStartIconButton from "../Components/OrangeLoadingStartIconButton";
import BackButton from "../Components/BackButton";
import { State, ToastProps, theme } from '../Types/common';
import ShipAddress from './ShipAddress';
import GeneralAddress from './GeneralAddress';
import { LoadingDialog } from '../Components/Dialog';
import { RequirementAddress, LookupOptions, Selection, UpdateGrid } from './Common';
interface UpdateRequirementProps {
    invoiceNumber: number;
    transactionRequirementId: number;
    hasLookupValue: boolean;
    currentRequirementValue: string;
    setReqDialogOpen: Dispatch<SetStateAction<boolean>>;
    setInvoiceDialogOpen: Dispatch<SetStateAction<boolean>>;
    handleApplyFilters: () => void;
    setToast: Dispatch<SetStateAction<ToastProps>>;
}

export const UpdateRequirement: React.FC<UpdateRequirementProps> = ({ invoiceNumber, transactionRequirementId, hasLookupValue, currentRequirementValue, setReqDialogOpen, setInvoiceDialogOpen, handleApplyFilters, setToast }) => {
    const [reqAddress, setReqAddress] = React.useState<RequirementAddress | null>(null);
    const [loading, setLoading] = useState(false);
    const [requirementName, setRequirementName] = React.useState<string | null>(null);
    const [requirementData, setRequirementData] = React.useState<string | null>(null);
    const [externalDescription, setExternalDescription] = React.useState<string | null>();
    const [fieldTypeId, setFieldTypeId] = React.useState<number | null>();
    const [isDocument, setIsDocument] = useState(false);
    const [saving, setSaving] = useState(false);
    const [lookupOptions, setLookupOptions] = React.useState<LookupOptions[] | null>(null);
    const [checkboxStates, setCheckboxStates] = useState({});
    const [file, setFile] = useState<File | null>(null);
    const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [document, setDocument] = useState<FormData | null>(null);
    const [attentionTo, setAttentionTo] = useState<string | null>(null);
    const [address1, setAddress1] = useState<string | null>(null);
    const [address2, setAddress2] = useState<string | null>(null);
    const [city, setCity] = useState<string | null>(null);
    const [states, setStates] = useState<State[]>([]);
    const [zipCode, setZipCode] = useState<string | null>(null);
    const [countyId, setCountyId] = useState<number | null>(null);
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
    const [selection, setSelection] = useState<Selection>({ selectedState: states[1], });
    const [addressError, setAddressError] = React.useState<boolean>(false);
    const [showVerifyDialog, setShowVerifyDialog] = React.useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        if (transactionRequirementId) {
            getRequirementInfo(transactionRequirementId, hasLookupValue);
        }
    }, []);

    useEffect(() => {
        if (states) {
            const updatedReqAddress: RequirementAddress = {
                Attention: attentionTo,
                Phone: phoneNumber,
                StreetAddress: address1,
                AddressLine2: address2,
                City: city,
                CountyId: countyId,
                StateId: selection.selectedState?.StateId,
                StateName: selection.selectedState?.StateName,
                StateAbrv: selection.selectedState?.StateAbbreviation,
                ZipCode: zipCode,
            };
            setReqAddress(updatedReqAddress);
        }
    }, [attentionTo, address1, address2, city, zipCode, countyId, phoneNumber, states]);

    const handleInvoiceOpen = async () => {
        setReqDialogOpen(false);
        setInvoiceDialogOpen(true);
    }

    const getRequirementInfo = async (transactionReqId: number, hasLookupValue: boolean) => {
        const requestOptions = {
            method: 'GET',
        };

        const url = `${window.location.origin}/PendingOrders/GetRequirementInfo?tranReqId=${transactionReqId}&hasLookupValue=${hasLookupValue}`;

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                console.error("getMacroQuestions response failure:", response.status, response.statusText);
                console.error("Request URL:", response.url);

                response.headers.forEach((value, key) => {
                    console.error(`${key}: ${value}`);
                });

                const errorDetails = await response.text();
                console.error("Response Body:", errorDetails);

                return false;
            } else {
                const data = await response.json();
                if (data.success) {
                    if (data.lookupValues) {
                        setLookupOptions(data.lookupValues);
                    }
                    setFieldTypeId(data.requirementInfo.FieldTypeId);
                    setRequirementName(data.requirementInfo.RequirementName);
                    setExternalDescription(data.requirementInfo.ExternalDescription);
                    setIsDocument(data.requirementInfo.IsDocument);
                    setLoading(false);
                }
                
            }
        } catch (error: any) {
            console.error('An error occurred during getMacroQuestions', error);
            console.error('Error message:', error.message);
            console.error('Stack trace:', error.stack);
            return false;
        }
    };

    const handleCheckboxChange = async (tranReqId: number, selection: string, checked: any) => {

        setCheckboxStates(prevState => ({
            ...prevState,
            [tranReqId]: {
                YES: selection === 'YES' ? checked : false,
                NO: selection === 'NO' ? checked : false,
            }
        }));

        await handleInputChange(tranReqId, selection, null, document);
    };

    const handleInputChange = async (tranReqId: number, data: string, address: RequirementAddress[] | null, document: any) => {

        if (document == null) {
            setDocument(null);
        }
        if (address === null) {
            setReqAddress(null);
        }
        setRequirementData(data);
    };

    const handleSave = async () => {

        setSaving(true);

        const showErrorAndExit = async (errorMsg: string) => {
            setSaving(false);
            await handleError(errorMsg);
        };

        if (requirementData == null) {
            showErrorAndExit("Please add a value before Saving");
            return;
        }

        if (document == null && isDocument && requirementData == "YES" ) {
            showErrorAndExit("Please upload a document");
            return;
        }

        if (document != null && requirementData !== "YES") {
            showErrorAndExit("Requirement must be set to YES if a document is uploaded");
            return;
        }

        const addressFieldTypeIds = [7, 8, 9, 10, 11, 12, 13];
        if (fieldTypeId != null && addressFieldTypeIds.includes(fieldTypeId)) {
            if (!address1 || !city || !states || !zipCode) {
                showErrorAndExit("Please provide the full address");
                return;
            }
            else {
                const verifySuccess = await verifyAddress();

                if (!verifySuccess) {
                    showErrorAndExit("Unable to verify address. Please check spelling and try again.");
                    return;
                }
            }
        }

        await saveRequirement(transactionRequirementId, requirementData, document, reqAddress);
    }

    const handleError = async (error: string) => {
        setToast({
            show: true,
            message: error,
            severity: 'error'
        });
    };

    const handleFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>, index) => {
        const selectedFile = e.target.files && e.target.files[0];
        if (selectedFile) {
            const allowedExtensions = ["pdf", "jpg", "jpeg", "png", "tiff"];
            const fileExtension = selectedFile.name.split('.').pop();
            if (fileExtension && allowedExtensions.includes(fileExtension)) {
                setFile(selectedFile);
                await handleFile(selectedFile, selectedFile.name);
                if (fileInputRefs.current[index]) {
                    fileInputRefs.current[index]!.value = '';
                }
            }
            else {
                console.log("error in handleFileInputChange ")
                setFile(null);
                const errorMessage = "File type not supported.";
                setError(errorMessage);
                handleError(errorMessage);

            }
        }
    };

    const handleFile = async (file, fileName) => {
        console.log("handleFile")
        const data = new FormData();
        if (file instanceof File) {
            data.append("document", file, file.name);
        }
        setDocument(data);
    };

    const verifyAddress = async () => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                address1: address1,
                city: city,
                state: selection.selectedState?.StateAbbreviation,
                zipCode: zipCode
            }),
        };
        const url = `${window.location.origin}/CreateOrders/VerifyAddress`;

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                console.error("Verify address response failure:", response.status, response.statusText);
                console.error("Request URL:", response.url);
                console.error("Response Headers:", response.headers);
            } else {
                const data = await response.json();
                if (data.changesMade) {
                    setAddress1(data.address1);
                    setCity(data.city);
                    setSelection({
                        ...selection,
                        selectedState: {
                            StateId: data.stateId,
                            StateName: data.stateName,
                            StateAbbreviation: data.stateAbbreviation,
                            CountryId: null
                        },
                    });
                    setCity(data.city);
                    setZipCode(data.zipCode);
                    setCountyId(data.county);
                    await setShowVerifyDialog(false);
                    return true;
                }
                else {
                    if (data.verifyFailed) {
                        console.error("verifyFailed");
                        //const errorMsg = "Unable to verify address. Please check spelling and try again.";
                        //await handleError(errorMsg);
                        setShowVerifyDialog(false);
                        return false;
                    }
                    else {
                        await setCountyId(data.county);
                        await setShowVerifyDialog(false);
                        return true;
                    }
                }
                await setShowVerifyDialog(false);
            }
        } catch (error: any) {
            console.error('An error occurred during address verification tsx:', error);
            console.error('Error message:', error.message);
            console.error('Stack trace:', error.stack);
            return false;
        }
    };

    const saveRequirement = async (tranReqId: number, requirementData: string, document: File | FormData | null, reqAddress: any) => {
       
        const formData = new FormData();
        if (document instanceof File) {
            formData.append("document", document);
        } else if (document instanceof FormData) {
            const file = document.get("document") as File | null;
            if (file) {
                formData.append("document", file);
            } else {
                console.error("Error: document is not a File type");
            }
        } 
        formData.append("tranReqId", tranReqId.toString());
        formData.append("requirementData", requirementData);
        formData.append("fieldTypeId", fieldTypeId?.toString() || "");
        formData.append("isDocument", isDocument?.toString() || "");
        formData.append("attention", reqAddress?.Attention || "");
        formData.append("address1", reqAddress?.StreetAddress || "");
        formData.append("address2", reqAddress?.AddressLine2 || "");
        formData.append("city", reqAddress?.City || "");
        formData.append("stateId", reqAddress?.StateId?.toString() || "");
        formData.append("zipCode", reqAddress?.ZipCode || "");
        formData.append("phone", reqAddress?.Phone || "");
        formData.append("countyId", reqAddress?.CountyId?.toString() || "");

        const requestOptions: RequestInit = {
            method: "PUT",
            body: formData,
        };

        const url = `${window.location.origin}/PendingOrders/UpdateRequirement`;

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                console.error("Response status:", response.status);
                console.error("Response URL: ", url, "Response Request Options: ", requestOptions)
                console.error("Response text:", await response.text());
                throw new Error(`Failed to update requirement: HTTP ${response.status}`);
            }
            const responseData = await response.json();
            console.log("responseData", responseData);
            if (responseData.success) {
                setToast({
                    show: true,
                    message: "Requirement updated!",
                    severity: 'success'
                })
                handleApplyFilters();
            } else {
                console.error("Backend error:", responseData.message);
                setToast({
                    show: true,
                    message: "Requirement failed to update!",
                    severity: "error",
                });
            }
        } catch (error) {
            if (error instanceof Error) {
                console.error("Failed to update requirement. Error message:", error.message);
                console.error("Stack trace:", error.stack);
            }
            console.error("Failed to update req bottom error:", error);
            setToast({
                show: true,
                message: "Requirement failed to update! Please try again.",
                severity: 'error'
            })
        }
        finally {
            setRequirementData(null);
            setDocument(null);
            setFile(null);
            setReqAddress(null);
            setSaving(false)
            setReqDialogOpen(false)
        }
    };

    const renderInputComponent = () => {
        const renderFileUpload = (
            <>
                <FileUpload onChange={(e) => handleFileInputChange(e, transactionRequirementId)} sx={{ marginTop: 2, fontSize: '12px' }} />
                <Typography color="#5D6771" sx={{ fontSize: '11px' }}>
                    Valid File Types: jpg | jpeg | png | pdf | tiff 
                </Typography>
            </>
        );
        if (currentRequirementValue) {
            return (
                <>
                    <Typography color="#5D6771" sx={{ fontSize: '12px' }} > {currentRequirementValue}</Typography>
                </>
            );
        }
        if (lookupOptions && lookupOptions.length > 0) {
            return (
                <Autocomplete
                    options={lookupOptions || []}
                    getOptionLabel={(option: LookupOptions) => option.Value}
                    onChange={(e, value) => {
                        const selectedValue = value ? value.Value : '';
                        handleInputChange(transactionRequirementId, selectedValue, null, null);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} sx={{ width: '500px', fontSize: '12px' }} />
                    )}
                />
            );
        }

        switch (fieldTypeId) {
            case 0: // Alphanumeric
                return (
                    <>
                        <TextInput value={null} onChange={(e) => handleInputChange(transactionRequirementId, e.target.value, null, document)} sx={{ width: '500px', fontSize: '12px' }} />
                        {isDocument && renderFileUpload}
                    </>
                );
            case 1: // Numeric
                return <NumberInput value={null} onChange={(e) => handleInputChange(transactionRequirementId, e.target.value, null, null)} sx={{ width: '500px', fontSize: '12px' }} />;
            case 2: // Currency
                return <CurrencyInput value={null} onChange={(e) => handleInputChange(transactionRequirementId, e.target.value, null, null)} sx={{ width: '500px', fontSize: '12px' }} />;
            case 3: // DateText
                return (
                    <>
                        <DatePicker value={null} onChange={(e) => handleInputChange(transactionRequirementId, e.target.value, null, document)} sx={{ width: '500px', fontSize: '12px' }} />
                        {isDocument && renderFileUpload}
                    </>
                );
            case 5: // YesNo
                return (
                    <>
                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <CircleCheckboxAcertus
                                            checked={checkboxStates[transactionRequirementId]?.YES || false}
                                            onChange={(e) =>
                                                handleCheckboxChange(transactionRequirementId, 'YES', e.target.checked)
                                            }
                                        />
                                    }
                                    label="Yes"
                                    sx={{ fontSize: '12px' }}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <CircleCheckboxAcertus
                                            checked={checkboxStates[transactionRequirementId]?.NO || false}
                                            onChange={(e) =>
                                                handleCheckboxChange(transactionRequirementId, 'NO', e.target.checked)
                                            }
                                        />
                                    }
                                    label="No"
                                    sx={{ fontSize: '12px' }}
                                />
                            </Grid>
                        </Grid>
                        {isDocument && renderFileUpload}
                    </>
                );
            case 7: // GeneralAddress
                return <GeneralAddress
                    setRequirementData={setRequirementData}
                    setAddress1={setAddress1}
                    setAddress2={setAddress2}
                    setCity={setCity}
                    setStates={setStates}
                    setZipCode={setZipCode}
                    requirementData={requirementData}
                    address1={address1}
                    address2={address2}
                    city={city}
                    states={states}
                    zipCode={zipCode}
                    selection={selection}
                    setSelection={setSelection}
                />;
            case 8: // LesseeAddress, 8 - 13: Similar Address Types
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
                return <ShipAddress
                    setRequirementData={setRequirementData}
                    setAddress1={setAddress1}
                    setAddress2={setAddress2}
                    setCity={setCity}
                    setStates={setStates}
                    setZipCode={setZipCode}
                    setPhoneNumber={setPhoneNumber}
                    setAttentionTo={setAttentionTo}
                    requirementData={requirementData}
                    address1={address1}
                    address2={address2}
                    city={city}
                    states={states}
                    zipCode={zipCode}
                    phoneNumber={phoneNumber}
                    attentionTo={attentionTo}
                    selection={selection}
                    setSelection={setSelection}
                />;
            case 14: // SocialSecurityNumber
                return <SSNInput value={null} onChange={(e) => handleInputChange(transactionRequirementId, e.target.value, null, null)} sx={{ width: '500px', fontSize: '12px' }} />;
            case 16: // USPhoneNumber
                return <PhoneInput value={null} onChange={(e) => handleInputChange(transactionRequirementId, e.target.value, null, null)} sx={{ width: '500px', fontSize: '12px' }} />;
            case 17: // OriginalDocument, 18: CopyDocument
            case 18:
                return (
                    <>
                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <CircleCheckboxAcertus
                                            checked={checkboxStates[transactionRequirementId]?.YES || false}
                                            onChange={(e) =>
                                                handleCheckboxChange(transactionRequirementId, 'YES', e.target.checked)
                                            }
                                        />
                                    }
                                    label="Yes"
                                    sx={{ fontSize: '12px' }}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <CircleCheckboxAcertus
                                            checked={checkboxStates[transactionRequirementId]?.NO || false}
                                            onChange={(e) =>
                                                handleCheckboxChange(transactionRequirementId, 'NO', e.target.checked)
                                            }
                                        />
                                    }
                                    label="No"
                                    sx={{ fontSize: '12px' }}
                                />
                            </Grid>
                        </Grid>
                        {isDocument && renderFileUpload}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <NoSsr>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                    padding: 2, 
                }}>
                    {loading || fieldTypeId == undefined ? (
                        <CircularProgress sx={{color:'#ED7300'}} />
                    ) : (
                        <UpdateGrid>
                            <Grid sx={{ gridArea: 'title', marginBottom: '20px', display:'grid', justifyItems:'center', justifyContent:'start' }}>
                                    <Typography color="#5D6771" sx={{ fontSize: '16px' }} fontWeight="700" >{requirementName} - #{invoiceNumber}</Typography>
                            </Grid>
                            <Grid sx={{ gridArea: 'body', marginBottom: '20px', display:'grid' }}>
                            {!currentRequirementValue ? <Typography color="#5D6771" sx={{ fontSize: '12px' }} >{externalDescription}</Typography> : null}
                            {renderInputComponent()}
                            </Grid>
                            <Grid sx={{ gridArea: 'backButton' }}>
                                <BackButton
                                    label="Back"
                                    onClick={() => { setReqDialogOpen(false) }}
                                    variant="text"
                                    startIcon={<ArrowBackIcon sx={{ width: '18px', height: '18px', color: '#ED7300' }} />}
                                    disabled={null}
                                    sx={{ marginBottom: '20px' }}
                                />
                            </Grid>
                            <Grid sx={{ gridArea: 'saveButton' }}>
                                <OrangeLoadingStartIconButton
                                    label={!currentRequirementValue ? "Save" : "View Invoice"}
                                    onClick={() => { !currentRequirementValue ? handleSave() : handleInvoiceOpen() }}
                                    variant="contained"
                                    startIcon={!currentRequirementValue ? <SaveIcon sx={{ color: 'white' }} /> : null}
                                    disabled={saving}
                                    sx={{ fontFamily: '"Noto Sans", Arial, sans-serif' }}
                                    loading={saving}
                                />
                            </Grid>
                        </ UpdateGrid>
                    )}
                </Box>
                <LoadingDialog
                    open={showVerifyDialog}
                    style={{ height: '150px', width: '450px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    sx={{ fontSize: '22px', fontFamily: '"Noto Sans", Arial, sans-serif', marginLeft: '20px' }}
                    message="Verifying Address"
                />
            </NoSsr>
        </ThemeProvider>
    );
};
