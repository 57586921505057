import React, { useState, useEffect, useRef } from "react";
import { NoSsr, Box, Snackbar, Alert } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import PendingOrdersHeader from "./PendingOrdersHeader";
import PendingOrdersBody from "./PendingOrdersBody";
import ReportsAcertus from "../Components/Reports/ReportsAcertus";
import FiltersAcertus from "../Components/Filters/FiltersAcertus";
import { AutocompleteAcertusItem } from "../Components/AutocompleteAcertus";
import { ToastProps, theme } from '../Types/common';
import GetFilter from "../Components/GetFilter";

export const PendingOrdersPage: React.FC = () => {
    const [rows, setRows] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [useStoredCreateOrder, setUseStoredCreateOrder] = useState<boolean>(false);
    const [useLastStoredSearch, setUseLastStoredSearch] = useState<boolean>(false);
    const [useStoredFilter, setUseStoredFilter] = useState<boolean>(false);
    const [useDefaultCustomer, setUseDefaultCustomer] = useState<boolean>(false);
    const [showApplyButton, setShowApplyButton] = useState<boolean>(false);
    const [pendingOpen, setPendingOpen] = useState<boolean>(false);
    const [showClearAll, setShowClearAll] = useState<boolean>(false);
    const isFirstRender = useRef(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15); 
    const [totalCount, setTotalCount] = useState(0);
    const [pendingCount, setPendingCount] = useState<number>(0);
    const [waitingForPaperworkCount, setWaitingForPaperworkCount] = useState<number>(0);
    const [selectedWorkType, setSelectedWorkType] = useState<AutocompleteAcertusItem[] | undefined>(undefined);
    const [selectedCustomer, setSelectedCustomer] = useState<AutocompleteAcertusItem[] | undefined>(undefined);
    const [selectedUser, setSelectedUser] = useState<AutocompleteAcertusItem[] | undefined>(undefined);   
    const [defaultCustomer, setDefaultCustomer] = useState<AutocompleteAcertusItem | null>(null); 
    const [toast, setToast] = useState<ToastProps>({ show: false, message: "", severity: 'info' })
    const [selections, setSelections] = useState<{ label: string, choice: string | boolean, id?: number }[]>([
        { label: 'Page', choice: String(page) },
        { label: 'Page Size', choice: String(pageSize) },
    ]);
    const lastSelectionsRef = useRef(selections);
    const abortControllerRef = React.useRef<AbortController | null>(null);

    useEffect(() => {
        const fetchStoredFilter = async () => {
            try {
                return await GetFilter(true);
            } catch (error) {
                console.error("Error fetching stored filter pending:", error);
                return null;
            }
        };

        const fetchStored = async (savedFilter) => {
            try {
                const getCreateOrder = sessionStorage.getItem('pendingCreateOrderSelections');
                const getLastSearch = sessionStorage.getItem('pendingSelections');

                if (getCreateOrder && getCreateOrder.length > 0) {
                    setUseStoredCreateOrder(true);
                    await getCreateOrderSelections();
                } else if (getLastSearch && getLastSearch.length > 0) {
                    setUseLastStoredSearch(true);
                    await getLastStoredSearch();
                } else if (savedFilter && savedFilter.length > 0) {
                    setUseStoredFilter(true);
                    await handleFilterSelections(savedFilter);
                }
                else {
                    setUseDefaultCustomer(true);
                }
            } catch (error) {
                console.error("Error fetching stored pending -", error);
                setLoading(false);
            }
        };

        const execute = async () => {
            try {
                const savedFilter = await fetchStoredFilter();
                await fetchStored(savedFilter);
            } catch (error) {
                console.error("Error execute pending -", error);
                setLoading(false);
            }
        };

        execute();

    }, []);
  
    useEffect(() => {
        const filteredSelections = selections.filter(
            item => item.label !== 'Page' && item.label !== 'Page Size'
        );

        const hasSelectionsChanged = JSON.stringify(lastSelectionsRef.current) !== JSON.stringify(filteredSelections);

        if (hasSelectionsChanged && filteredSelections.length > 0) {
            if (isFirstRender.current) {
                if (useStoredCreateOrder) {
                    setUseStoredCreateOrder(false);
                    handleApplyFilters();
                }
                else if (useLastStoredSearch) {
                    setUseLastStoredSearch(false);
                }
                else if (useStoredFilter) {
                    setUseStoredFilter(false);
                    handleApplyFilters();
                }
                else if (useDefaultCustomer) {
                    setUseDefaultCustomer(false);
                    handleApplyFilters();
                }

                isFirstRender.current = false;
                return;
            }

            setShowApplyButton(true);
            lastSelectionsRef.current = filteredSelections;
        }
    }, [selections]);

    useEffect(() => {
        if (useDefaultCustomer) {
            if (defaultCustomer) {
                const customer = selections.findIndex(selection => selection.label === "Customer");
                if (customer !== -1) {
                    setSelections(prevSelections => {
                        const newSelections = [...prevSelections];
                        newSelections[customer] = { ...newSelections[customer], choice: defaultCustomer.label, id: defaultCustomer.id };
                        return newSelections;
                    });
                } else {
                    setSelections(prevSelections => [...prevSelections, { label: "Customer", choice: defaultCustomer.label, id: defaultCustomer.id }]);
                }
            }
        }
    }, [defaultCustomer]);

    const handleApplyFilters = async () => {
        setShowApplyButton(false);
        await sendSelectionsToBackend();
    };

    const handleFilterSelections = async (filter) => {
        try {
            const filterSelections = JSON.parse(filter) as Array<{
                Label: string;
                Choice: string | boolean;
                Id?: number;
            }>;

            if (filterSelections.length > 0) {
                const newSelections: { label: string, choice: string | boolean, id?: number }[] = [];

                filterSelections.forEach(selection => {
                    const { Label: label, Choice: choice, Id: id } = selection;
                    newSelections.push({ label, choice, id });
                });

                setSelections(prevSelections => [
                    ...prevSelections,
                    ...newSelections.filter(
                        item => !prevSelections.some(
                            selection => selection.label === item.label && selection.id === item.id
                        )
                    )
                ]);

            } else {
                console.error("No filter selections to process.");
            }
        } catch (error) {
            console.error("Error parsing filterSelections:", error);
        }
    };

    const sendSelectionsToBackend = async () => {

        abortControllerRef.current = new AbortController();
        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(selections),
            signal: abortControllerRef.current.signal,
        };
        setShowApplyButton(false);
        setLoading(true);

        try {
            const response = await fetch('/PendingOrders/ReceiveSelectionsAsync', requestOptions);
            if (!response.ok) {
                throw new Error("Failed to send selections pending");
            }
            const responseData = await response.json();
            const data = responseData.Results;
            setTotalCount(responseData.Total);
            setPendingCount(responseData.PendingCount);
            setWaitingForPaperworkCount(responseData.WaitingCount);
            sessionStorage.setItem('pendingPendingCount', String(responseData.PendingCount));
            sessionStorage.setItem('pendingWaitingCount', String(responseData.WaitingCount));
            sessionStorage.setItem('pendingTotalCount', String(responseData.Total));

            const dataWithIds = data.map((row: any, index: any) => ({
                ...row,
                id: index,
            }));
            setRows(dataWithIds);

            sessionStorage.setItem('pendingRows', JSON.stringify(dataWithIds));
            sessionStorage.setItem('pendingSelections', JSON.stringify(selections));

        } catch (error) {
            console.error("Failed to send selections pending", error);
            setLoading(false);
        }
        finally {
            setLoading(false);
            setShowApplyButton(false); 
            sessionStorage.removeItem('pendingCreateOrderSelections');
        }
    };

    const handleCancelLoading = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        setLoading(false);
    };

    const getLastStoredSearch = async () => {
        const getSearchSelections = sessionStorage.getItem('pendingSelections');
        const getSearchRows = sessionStorage.getItem('pendingRows');
        const parsedSelections = getSearchSelections ? JSON.parse(getSearchSelections) : [];
        const parsedRows = getSearchRows ? JSON.parse(getSearchRows) : [];
        const storedPageSize = parsedSelections.find((selection: { label: string; }) => selection.label === "Page Size")?.choice ?? pageSize;
        const storedPage = parsedSelections.find((selection: { label: string; }) => selection.label === "Page")?.choice ?? page;

        setSelections(parsedSelections);
        setRows(parsedRows);
        setPageSize(parseInt(storedPageSize));
        setPage(parseInt(storedPage));
        updateNumericState('pendingPendingCount', setPendingCount);
        updateNumericState('pendingWaitingCount', setWaitingForPaperworkCount);
        updateNumericState('pendingTotalCount', setTotalCount);
        setLoading(false);
    }

    const updateNumericState = (key: string, setState: React.Dispatch<React.SetStateAction<number>>) => {
        const value = sessionStorage.getItem(key);
        if (value) {
            const parsedValue = parseInt(value, 10);
            if (!isNaN(parsedValue)) {
                setState(parsedValue);
            } else {
                console.error(`Invalid number format for ${key} in sessionStorage`);
            }
        }
    };

    const getCreateOrderSelections = async () => {
        try {
            const storedSelections = JSON.parse(sessionStorage.getItem('pendingCreateOrderSelections') || '[]');

            if (storedSelections.length > 0) {
                setSelections(storedSelections);
            } else {
                console.error("No stored create order selections found");
            }
        } catch (error) {
            console.error("Error parsing stored selections:", error);
        }
    };

    const handleClearAll = async () => {
        const updatedSelections = selections.filter(
            (selection) => selection.label === "Page" || selection.label === "Page Size"
        );

        setSelections(updatedSelections);
        lastSelectionsRef.current = updatedSelections;
        setShowApplyButton(false);
        setShowClearAll(false);
        setRows([]);
        setTotalCount(0);
        setPendingCount(0);
        setWaitingForPaperworkCount(0);
    };

    return (
        <ThemeProvider theme={theme}>
            <NoSsr>
                <Snackbar open={toast.show} autoHideDuration={8000} onClose={() => setToast({ ...toast, show: false })} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert severity={toast.severity} sx={{ fontSize: '12px', width: '300px' }}>{toast.message}</Alert>
                </Snackbar>
                <PendingOrdersHeader selections={selections} setSelections={setSelections} loading={loading} useDefaultCustomer={useDefaultCustomer} handleClearAll={handleClearAll}
                    setShowApplyButton={setShowApplyButton} showApplyButton={showApplyButton} handleApplyFilters={handleApplyFilters} setDefaultCustomer={setDefaultCustomer}
                    defaultCustomer={defaultCustomer} setPendingOpen={setPendingOpen} setShowClearAll={setShowClearAll} showClearAll={showClearAll} />
                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: "wrap", justifyContent: 'space-between', alignItems: 'flex-start', minHeight: "620px", marginTop: "10px", gap: "20px" }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: "nowrap", justifyContent: 'flex-start', alignItems: 'flex-start', minHeight: "620px", width: "80vw" }}>
                        <PendingOrdersBody rows={rows} loading={loading} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} totalCount={totalCount}
                            selections={selections} setSelections={setSelections} pendingCount={pendingCount} waitingForPaperworkCount={waitingForPaperworkCount}
                            handleApplyFilters={handleApplyFilters} setToast={setToast} handleCancelLoading={handleCancelLoading} /> 
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: "nowrap", justifyContent: 'flex-end', alignItems: 'flex-start', minHeight: "620px", gap: '10px' }}>
                        <ReportsAcertus setSelections={setSelections} selections={selections} />
                        <FiltersAcertus setSelections={setSelections} selections={selections} setPendingOpen={setPendingOpen} pendingOpen={pendingOpen} />
                    </Box>
                </Box>
            </NoSsr>
        </ThemeProvider>
    );
};
