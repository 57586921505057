import React from "react";
import { Box, Typography, IconButton, Zoom, Tooltip } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

interface FilterAcertusProps {
    onClickDelete?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    headerText: string;
    bodyText: string;
    selections: { label: string, choice: string | boolean, id?: number }[];
    setSelections: React.Dispatch<React.SetStateAction<{ label: string, choice: string | boolean, id?: number }[]>>;
    setBalancesOnlyChecked?: React.Dispatch<React.SetStateAction<boolean>>;
};

const FilterBox: React.FC<FilterAcertusProps> = ({ onClickDelete, headerText, bodyText, setSelections, selections, setBalancesOnlyChecked }) => {

    const handleFilterClick = () => {
        setSelections([...selections]);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: "wrap", justifyContent: 'flex-start', alignItems: 'center', height: "77px", width: "288px", border: 1, borderColor: "#DFE1E3", borderRadius: "8px", gap: "20px" }}>
            <Tooltip TransitionComponent={Zoom} enterDelay={1000} leaveDelay={200} title={<span style={{ fontSize: '12px' }}>Delete {headerText}</span>} placement="top" arrow>
                <IconButton onClick={onClickDelete}>
                    <DeleteForeverIcon sx={{color:'#ED7300', width:'24px', height:'24px'}} />
                </IconButton>
           </Tooltip>
           <Box onClick={handleFilterClick} sx={{ display: 'flex', flexDirection: 'column', flexWrap: "nowrap", justifyContent: 'space-between', alignItems: 'flex-start', height: "45px", width: "222px", cursor:'pointer' }}>
                <Typography color="#5D6771" sx={{fontSize:'12px'}} fontWeight="700">{headerText}</Typography>
                <Typography color="#5D6771" sx={{ fontSize: '10px' }} fontWeight="300">{bodyText}</Typography>
           </Box>
        </Box>
    )
};

export default FilterBox;