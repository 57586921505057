import React, { useState, useEffect, useRef } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Tab, Typography, NoSsr, styled, IconButton, Tooltip, Button, Box, FormControl, Autocomplete, createFilterOptions, TextField, Grid, Divider, Dialog, Zoom } from '@mui/material';
import { RenewalTitle, GoBackButton, SubmitButton, PizzaGrid, UploadGrid, ButtonGrid, SubTitle, ResultText, ResetGrid, OrderGrid, SuccessGrid, PromptButton, MiddleConfirmGrid, SummaryText, CloseButton} from '../Shared/Renewals';
import { MultiplePizzaTracker } from './MultiplePizzaTracker';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export const MultipleUpload = ({ handleGoBack, formData, handleNewOrder }) => {
    const [uploadingSuccess, setUploadingSuccess] = React.useState<boolean>(false);
    const [verifyingSuccess, setVerifyingSuccess] = React.useState<boolean>(false);
    const [analyzingSuccess, setAnalyzingSuccess] = React.useState<boolean>(false);
    const [errorDialog, setErrorDialog] = React.useState<boolean>(false);
    const [showSubmitBox, setShowSubmitBox] = React.useState<boolean>(false);
    const [completed, setCompleted] = React.useState<boolean>(false);
    const [contact, setContact] = useState(formData?.upload?.contact ?? null);
    const [customerId, setCustomerId] = useState(formData?.upload?.custId ?? null);
    const [fileName, setFileName] = useState(formData?.upload?.excelData.get("file").name ?? null);
    const [incompleteList, setIncompleteList] = useState<any[]>([]);  
    const [showIncomplete, setShowIncomplete] = React.useState<boolean>(false);
    const [duplicateList, setDuplicateList] = useState<any[]>([]);
    const [showDuplicate, setShowDuplicate] = React.useState<boolean>(false);
    const [addressErrorList, setAddressErrorList] = useState<any[]>([]);
    const [showAddressError, setShowAddressError] = React.useState<boolean>(false);
    const [available, setAvailable] = useState(0);
    const [incomplete, setIncomplete] = useState(0);
    const [duplicate, setDuplicate] = useState(0);
    const [addressError, setAddressError] = useState(0);
    const [total, setTotal] = useState(0);
    const [workTypeId, setWorkTypeId] = useState(0);
    const [error, setError] = useState<string | null>();
    const [tempOrderId, setTempOrderId] = useState<string | null>();
    const [stateAbrv, setStateAbrv] = useState<string | null>();
    const [projectDescription, setProjectDescription] = useState<string | null>();
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [showLoading, setShowLoading] = React.useState<boolean>(false);
    
    const handleClick = async () => {
        setShowLoading(true);
        await insertOrders();
    }
    const handleIncomplete = async () => {
        setShowIncomplete(true);
    }
    const handleDuplicate = async () => {
        setShowDuplicate(true);
    }
    const handleAddressError = async () => {
        setShowAddressError(true);
    }
    const handleError = () => {
        setErrorDialog(true);
    }
    const handleErrorAck = () => {
        setErrorDialog(false);
        handleNewOrder();
    }
    const handleInformationAck = () => {
        setShowIncomplete(false);
        setShowDuplicate(false);
        setShowAddressError(false);
    }
    const handleViewInvoiceClick = async () => {
        window.location.href = `/Portal/PendingWork?SelectedCustomerId=${customerId}&SelectedWorkTypeId=20&ContactId=${contact}`;
    }

    //todo add replace file functionality 

    const uploading = async () => {
        setTimeout(() => {
            setUploadingSuccess(true);
        }, 3000);
        const requestOptions: RequestInit = {
            method: 'PUT',
            body: formData?.upload.excelData
        };

        const url = `${window.location.origin}/Portal/UploadExcel?contactId=${contact}`;
        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                const errorMessage = `Failed to upload order. Status: ${response.status} ${response.statusText}`;
                const responseBody = await response.text();
                console.error(errorMessage, responseBody);
                console.error("Request URL:", url);
                console.error("Request Options:", requestOptions);
            } else {
                const data = await response.json();
                if (data.error != undefined) {
                    setError(data.error);
                    await handleError();
                }
                setAvailable(data.available);
                setDuplicate(data.duplicates);
                setIncomplete(data.incomplete);
                setAddressError(data.criticalAddressError);
                setTotal(data.total);
                setError(data.error);
                setTempOrderId(data.verify.Data.tempOrderId);
                setProjectDescription(data.projectDescription);
                setWorkTypeId(data.workTypeId);
                setStateAbrv(data.stateAbrv);
                setAnalyzingSuccess(true);
                setIncompleteList(data.incompleteList);
                setDuplicateList(data.duplicateList);
                setAddressErrorList(data.addressErrorList);
            }
        } catch (error) {
            console.error('An error occurred during uploadingOrders tsx:', error);
            return false;
        }
    };
    const insertOrders = async () => {
        const requestOptions = {
            method: 'PUT',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ tempOrderId: tempOrderId, project: projectDescription, workType: workTypeId, customer: customerId, state: stateAbrv }),
        };
        const url = `${window.location.origin}/Portal/InsertOrders`;

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                const errorMessage = `Failed to insert order. Status: ${response.status} ${response.statusText}`;
                const responseBody = await response.text();
                console.error(errorMessage, responseBody);
                console.error("Request URL:", url);
                console.error("Request Options:", requestOptions);
            } else {
                const data = await response.json();
                if (data.error != undefined) {
                    setError(data.error);
                    await handleError();
                }
                setShowLoading(false);
                setShowSubmitBox(true);
            }
        } catch (error) {
            console.error('An error occurred during insertOrders:', error);
            return false;
        }
    };
    const verifying = async () => {
        if (customerId != null && tempOrderId != null && projectDescription != null && workTypeId != 0 && stateAbrv != null) {
            setTimeout(() => {
                setVerifyingSuccess(true);
            }, 2000);
            setTimeout(() => {
                 setCompleted(true);
            }, 2000);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                await uploading();
            } catch (error) {
                console.error("An error occurred during useeffect uploading:", error);
            }
        };

        fetchData();
        return () => { };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await verifying();
            } catch (error) {
                console.error("An error occurred during useeffect verifying:", error);
            }
        };

        fetchData();
        return () => { };
    }, [tempOrderId, stateAbrv]);

    return (
        <NoSsr>
            <div className="container height-450">
                <div>
                    <Grid container style={{ height: '100%', justifyContent: 'center' }}>
                        <UploadGrid item xs={12}>
                            <Grid sx={{ gridArea: 'title', margin: '30px 0px' }} >
                                <RenewalTitle>Multiple Orders</RenewalTitle>
                            </Grid>
                            <Zoom in={completed}>
                                <Grid sx={{ gridArea: 'success', margin: '0px 0px' }} >
                                    <SubTitle sx={{ color: '#ED7300' }}>Successfully Uploaded!</SubTitle>
                                </Grid>
                            </Zoom>
                            <Grid sx={{ gridArea: 'fileName', margin: '30px 0px' }} >
                                <SubTitle>File Name: <span style={{ fontFamily: 'InterstateLight' }}>{fileName}</span></SubTitle>
                            </Grid>
                        </UploadGrid>
                        {!completed && (
                            <PizzaGrid item xs={12}>
                                <Grid sx={{ gridArea: 'pizza', margin: '30px 0px 24px', fontSize: '13px', fontFamily: 'Interstate', display:'grid', justifyContent:'center' }} >
                                    <MultiplePizzaTracker uploadingSuccess={uploadingSuccess} verifyingSuccess={verifyingSuccess} analyzingSuccess={analyzingSuccess} />
                                </Grid>
                            </PizzaGrid>
                        )} 
                        {completed && (
                                <MiddleConfirmGrid item xs={12}>
                                    <Grid sx={{ gridArea: 'invoices', margin: '30px 0px' }} >
                                        <SummaryText>Invoices</SummaryText>
                                        <ResultText sx={{ fontFamily: 'InterstateLight' }}>{available}</ResultText>
                                    </Grid>
                                    <Grid sx={{ gridArea: 'duplicates', margin: '30px 0px' }} >
                                    <SummaryText>Duplicates <Tooltip TransitionComponent={Zoom} enterDelay={200} title={<span style={{ fontSize: '12px' }}>VINs are listed multiple times.</span>} placement="top"><img src="../Content/assets/img/icons/Information.png" onClick={handleDuplicate} /></Tooltip></SummaryText>
                                        <ResultText sx={{ fontFamily: 'InterstateLight' }}>{duplicate}</ResultText>
                                    </Grid>
                                    <Grid sx={{ gridArea: 'incomplete', margin: '30px 0px' }} >
                                    <SummaryText>Incomplete <Tooltip TransitionComponent={Zoom} enterDelay={200} title={<span style={{ fontSize: '12px' }}>Required information needed to create the invoice was missing.</span>} placement="top"><img src="../Content/assets/img/icons/Information.png" onClick={handleIncomplete} /></Tooltip></SummaryText>
                                        <ResultText sx={{ fontFamily: 'InterstateLight' }}>{incomplete}</ResultText>
                                    </Grid>
                                    <Grid sx={{ gridArea: 'addressError', margin: '30px 0px' }} >
                                    <SummaryText>Address Error <Tooltip TransitionComponent={Zoom} enterDelay={200} title={<span style={{ fontSize: '12px' }}>An address created an error causing the invoice to be skipped.</span>} placement="top"><img src="../Content/assets/img/icons/Information.png" onClick={handleAddressError} /></Tooltip></SummaryText>
                                        <ResultText sx={{ fontFamily: 'InterstateLight' }}>{addressError}</ResultText>
                                    </Grid>
                                    <Grid sx={{ gridArea: 'total', margin: '30px 0px' }} >
                                        <SummaryText sx={{fontWeight:'bold'}}>Total Uploaded</SummaryText>
                                        <ResultText sx={{ fontFamily: 'Interstate', fontWeight:'bold'}}>{total}</ResultText>
                                    </Grid>
                                </MiddleConfirmGrid>
                        )}
                        <ButtonGrid item xs={12}>
                            <Zoom in={completed}>
                                <Grid sx={{ gridArea: 'backButton', marginTop: '30px' }}>
                                    <GoBackButton onClick={handleGoBack} variant="text"><ArrowBackIcon sx={{ color: '#ED7300' }} />Start Over</GoBackButton>
                                </Grid>
                            </Zoom>
                            <Grid sx={{ gridArea: 'nextButton', display: 'grid', justifyContent: 'end', marginTop: '30px' }}>
                                <SubmitButton sx={{ width: '162px' }} type="submit" disabled={!completed} onClick={handleClick}>Submit Renewals</SubmitButton>
                            </Grid>
                        </ButtonGrid>
                    </Grid>
                    <Dialog open={errorDialog}>
                        <Zoom in={errorDialog}>
                            <div style={{ height: '150px', width: '600px', display: 'grid', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}>
                                <Grid container style={{ height: '100%', justifyContent: 'center' }}>
                                    <ResetGrid>
                                        <Grid sx={{ gridArea: 'question', marginBottom: '20px' }}>
                                            <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}>{error}</Typography>
                                        </Grid>
                                        <Grid sx={{ gridArea: 'yesButton' }}>
                                            <SubmitButton onClick={handleErrorAck}>Ok</SubmitButton>
                                        </Grid>
                                    </ResetGrid>
                                </Grid>
                            </div>
                        </Zoom>
                    </Dialog>
                    <Dialog open={showIncomplete}>
                        <Zoom in={showIncomplete}>
                            <div style={{ minHeight: '200px', width: '450px', height: '100%', display: 'grid', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}>
                                <Grid container style={{ height: '100%', justifyContent: 'center' }}>
                                    <ResetGrid>
                                        <Grid sx={{ gridArea: 'question', marginBottom: '20px' }}>
                                            <Typography sx={{ fontSize: '20px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}>Incomplete</Typography>
                                            <Typography sx={{ fontSize: '16px', fontFamily: 'InterstateLight', margin: '20px 0px 0px 20px' }}>
                                                {incompleteList.map((item, index) => (
                                                    <div key={index} dangerouslySetInnerHTML={{ __html: item }} />
                                                ))} 
                                            </Typography>
                                        </Grid>
                                        <Zoom in={showIncomplete} style={{ transitionDelay: showIncomplete ? '500ms' : '0ms' }}>
                                            <Grid sx={{ gridArea: 'yesButton' }}>
                                                <CloseButton onClick={handleInformationAck}>Close</CloseButton>
                                            </Grid>
                                        </Zoom>
                                    </ResetGrid>
                                </Grid>
                            </div>
                        </Zoom>
                    </Dialog>
                    <Dialog open={showDuplicate}>
                        <Zoom in={showDuplicate}>
                            <div style={{ minHeight: '200px', width: '450px', height:'100%', display: 'grid', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}>
                                <Grid container style={{ height: '100%', justifyContent: 'center' }}>
                                    <ResetGrid>
                                        <Grid sx={{ gridArea: 'question', marginBottom: '20px' }}>
                                            <Typography sx={{ fontSize: '20px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}>Duplicate</Typography>
                                            <Typography sx={{ fontSize: '16px', fontFamily: 'InterstateLight', margin: '20px 0px 0px 20px' }}>
                                                {duplicateList.map((item, index) => (
                                                    <div key={index} dangerouslySetInnerHTML={{ __html: item }} />
                                                ))}
                                            </Typography>
                                        </Grid>
                                        <Zoom in={showDuplicate} style={{ transitionDelay: showDuplicate ? '500ms' : '0ms' }}>
                                            <Grid sx={{ gridArea: 'yesButton' }}>
                                                <CloseButton onClick={handleInformationAck}>Close</CloseButton>
                                            </Grid>
                                        </Zoom>
                                    </ResetGrid>
                                </Grid>
                            </div>
                        </Zoom>
                    </Dialog>
                    <Dialog open={showAddressError}>
                        <Zoom in={showAddressError}>
                            <div style={{ minHeight: '200px', width: '450px', height: '100%', display: 'grid', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}>
                                <Grid container style={{ height: '100%', justifyContent: 'center' }}>
                                    <ResetGrid>
                                        <Grid sx={{ gridArea: 'question', marginBottom: '20px' }}>
                                            <Typography sx={{ fontSize: '20px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}>Address Error</Typography>
                                            <Typography sx={{ fontSize: '16px', fontFamily: 'InterstateLight', margin: '20px 0px 0px 20px' }}>
                                                {addressErrorList.map((item, index) => (
                                                    <div key={index} dangerouslySetInnerHTML={{ __html: item }} />
                                                ))}
                                            </Typography>
                                        </Grid>
                                        <Zoom in={showAddressError} style={{ transitionDelay: showAddressError ? '500ms' : '0ms' }}>
                                            <Grid sx={{ gridArea: 'yesButton' }}>
                                                <CloseButton onClick={handleInformationAck}>Close</CloseButton>
                                            </Grid>
                                        </Zoom>
                                    </ResetGrid>
                                </Grid>
                            </div>
                        </Zoom>
                    </Dialog>
                    <Dialog open={showLoading || showSubmitBox}>
                        <Grid container style={{ height: '100%', justifyContent: 'center', width: '600px' }}>
                            <OrderGrid item xs={10} style={{ fontSize: '22px', fontFamily: 'Interstate' }}>
                                <Grid sx={{ gridArea: 'logo', display: 'grid', justifyItems: 'center', margin: '30px 0px' }}>
                                    <img src="/Content/assets/img/metro/logo/tnr4C.png" width="400px" alt="Logo" />
                                </Grid>
                            </OrderGrid>
                            <SuccessGrid item xs={10} style={{ fontSize: '22px', fontFamily: 'Interstate' }}>
                                {!showSubmitBox && (
                                    <Zoom in={showLoading} style={{ transitionDelay: showLoading ? '500ms' : '0ms' }}>
                                        <Grid sx={{ gridArea: 'success', display: 'grid', justifyItems: 'center', margin: '0px 0px 40px 0px' }}>
                                            <Typography sx={{ fontSize: '1em', fontFamily: 'Interstate' }}>Thank you for your submission!</Typography>
                                        </Grid>
                                    </Zoom>
                                )}
                                {showSubmitBox && !errorDialog && (
                                    <>
                                        <Zoom in={showSubmitBox}>
                                            <Grid sx={{ gridArea: 'success', display: 'grid', justifyItems: 'center', margin: '20px 0px 49px' }}>
                                                <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate' }}>Order Received Successfully</Typography>
                                                <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', textAlign:'center' }}>Invoices are being created and will be available shortly.</Typography>
                                                <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate' }}>Total Invoices: {total}</Typography>
                                            </Grid>
                                        </Zoom>
                                        <Zoom in={showSubmitBox} style={{ transitionDelay: showSubmitBox ? '500ms' : '0ms' }}>
                                            <Grid sx={{ gridArea: 'newOrder', marginBottom: '20px' }}>
                                                <Tooltip TransitionComponent={Zoom} enterDelay={1000} leaveDelay={200} title={<span style={{ fontSize: '12px' }}>Click here to submit a new order</span>} placement="top">
                                                    <PromptButton startIcon={<NavigateBeforeIcon sx={{ color: 'white' }} />} onClick={handleNewOrder}>New Order</PromptButton>
                                                </Tooltip>
                                            </Grid>
                                        </Zoom>
                                        <Zoom in={showSubmitBox} style={{ transitionDelay: showSubmitBox ? '500ms' : '0ms' }}>
                                            <Grid sx={{ gridArea: 'viewOrder', marginBottom: '20px' }}>
                                                <Tooltip TransitionComponent={Zoom} enterDelay={1000} leaveDelay={200} title={<span style={{ fontSize: '12px' }}>Click here to view your invoices that are pending</span>} placement="top">
                                                    <PromptButton endIcon={<NavigateNextIcon sx={{ color: 'white' }} />} onClick={handleViewInvoiceClick}>View Invoices</PromptButton>
                                                </Tooltip>
                                            </Grid>
                                        </Zoom>
                                    </>
                                )}
                            </SuccessGrid>
                        </Grid>
                    </Dialog>
                </div>
            </div>
        </NoSsr>
    );
}
