import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, Box, Typography, IconButton, Slide } from "@mui/material";
import CloseConfirmationModalAcertus from "./CloseConfirmationModalAcertus"; // Import the reusable CloseConfirmationModal

const CloseIconAcertus = () => (
    <img src="../../React/svgs/CloseIconAcertus.svg" alt="Close Icon" />
);

// Slide Transition Component
const Transition = React.forwardRef(function Transition(props: any, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

interface ModalAcertusProps {
    open: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
    paperSx?: object;
}

const ModalAcertus: React.FC<ModalAcertusProps> = ({ open, onClose, title, children, paperSx }) => {
    const [confirmOpen, setConfirmOpen] = useState(false); // State to manage confirmation modal

    const handleClose = () => {
        //setConfirmOpen(true); // Open confirmation modal
        onClose();
    };

    const handleCancel = () => {
        setConfirmOpen(false); // Close confirmation modal, keep main modal open
    };

    const handleQuit = () => {
        setConfirmOpen(false); // Close confirmation modal
        onClose(); // Close the main modal
    };

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                //onClose={handleClose} // Trigger close confirmation instead of closing directly // No need for that, it keeps data on memory
                PaperProps={{
                    sx: {
                        width: 407,
                        maxHeight: 593,
                        ...paperSx
                    },
                }}
                sx={{
                    '& .MuiDialog-container': {
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                }}
            >
                {/* Custom Dialog Title */}
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h4" fontWeight="400"> {title}</Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIconAcertus />
                        </IconButton>
                    </Box>
                </DialogTitle>
                {/* Dialog Content */}
                <DialogContent>
                    {children}
                </DialogContent>
            </Dialog>

            {/* Reusable Close Confirmation Modal */}
            <CloseConfirmationModalAcertus
                open={confirmOpen}
                onCancel={handleCancel}
                onQuit={handleQuit}
            />
        </>
    );
};

export default ModalAcertus;
