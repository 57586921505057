const GetFilter = async (pending) => {
    try {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        const url = `${window.location.origin}/SavedSearch/GetFilter?pending=${pending}`;

        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            console.error(`Response Error GetFilter: ${response.statusText}`);
        }

        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error("Error get filter:", error);
    }
};

export default GetFilter;
