import React, { useEffect, useState,useRef } from 'react';
import { NoSsr, Grid, FormControlLabel, Tooltip, Zoom} from '@mui/material';
import { MainBody, Title, SubTitle, FileGrid } from '../Themes/OneAcertus';
import CheckboxAcertus from '../Components/CheckboxAcertus';
import OrangeStartIconButton from '../Components/OrangeStartIconButton';
import GreyCard from '../Components/GreyCard';
import { getStateInfo } from './StateTooltipInfo';
import StateMap from './StateMap';
import DownloadIcon from '@mui/icons-material/Download';
interface File {
    Name: string;
}

export const ResourcesPage = ({ }) => {
    const [files, setFiles] = useState<File[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
    const [tooltip, setTooltip] = useState<{ visible: boolean, data: any, x: number, y: number }>({ visible: false, data: {}, x: 0, y: 0 });
    const tooltipRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                await getFiles();
            } catch (error) {
                console.error("An error occurred during get files:", error);
            }
        };
        fetchFiles();
    }, []);

    useEffect(() => {
        if (tooltip.visible && tooltipRef.current) {
            const tooltipElement = tooltipRef.current;
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;
            const tooltipWidth = tooltipElement.offsetWidth;
            const tooltipHeight = tooltipElement.offsetHeight;

            let newX = tooltip.x;
            let newY = tooltip.y;

            if (newX + tooltipWidth > viewportWidth) {
                newX = viewportWidth - tooltipWidth;
            }
            if (newY + tooltipHeight > viewportHeight) {
                newY = viewportHeight - tooltipHeight;
            }

            setTooltip(prev => ({
                ...prev,
                x: newX,
                y: newY
            }));
        }
    }, [tooltip.visible, tooltip.x, tooltip.y]);
    const getFiles = async () => {
        const url = window.location.origin + "/Resources/GetResourceFiles";
        const requestOptions = {
            method: "GET",
            headers: { 'Content-Type': 'application/json' }
        };

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                console.error("fetch files error:", response.status, response.statusText);
                console.error("Request URL:", response.url);
                console.error("Response Headers:", response.headers);
            }
            const data: File[] = await response.json();
            setFiles(data);
        } catch (error) {
            console.error("Error fetching resource files:", error);
        }
    }

    const handleFileSelection = (fileName: string) => {
        setSelectedFiles((prevSelectedFiles) => {
            if (prevSelectedFiles.includes(fileName)) {
                return prevSelectedFiles.filter(file => file !== fileName);
            } else {
                return [...prevSelectedFiles, fileName];
            }
        });
    };

    const handleDownload = async () => {
        for (const fileName of selectedFiles) {
            // Trigger file download for each selected file
            window.open(`${window.location.origin}/Resources/Download?fileName=${fileName}`, '_blank');
        }
    };

    const updateTooltip = (stateInfo, x, y) => {
        setTooltip({ visible: true, data: stateInfo, x, y });
    };

    const hideTooltip = () => {
        setTooltip({ visible: false, data: {}, x: 0, y: 0 });
    };

    const handleStateClick = (event) => {
        event.preventDefault();  
        const stateElement = event.target.closest('.state');
        const stateId = stateElement?.getAttribute('data-id');
        if (stateId) {
            const stateInfo = getStateInfo(stateId);
            updateTooltip(stateInfo, event.pageX, event.pageY);
        }
    };
    const handlePillClick = (event) => {
        event.preventDefault();  
        const stateElement = event.target.closest('.state-pill');
        const stateId = stateElement?.getAttribute('data-id');

        if (stateId) {
            const stateInfo = getStateInfo(stateId); 
            updateTooltip(stateInfo, event.pageX, event.pageY); 
        }
    };
    return (
        <NoSsr>
            <link rel="stylesheet" href="../../Content/assets/css/StateMap.css" />
            <MainBody>
                <GreyCard>
                    <Title>Resources</Title>
                    <FileGrid item xs={8}>
                        <Grid sx={{gridArea:'document'}}>
                            <SubTitle>Documents:</SubTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'files', display:'flex', alignItems:'center', margin:'5px 30px 0px 30px' }}>
                            {files.map((file, index) => (
                                <Grid key={index}>
                                    <FormControlLabel
                                        control={
                                            <CheckboxAcertus
                                                isChecked={selectedFiles.includes(file.Name)}
                                                onChange={() => handleFileSelection(file.Name)}
                                            />
                                        }
                                        label={file.Name}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Grid sx={{ gridArea: 'button' }}>
                            <OrangeStartIconButton
                                label="Download"
                                onClick={handleDownload}
                                variant="contained"
                                startIcon={<DownloadIcon sx={{ width: '18px', height: '18px' }} />}
                                disabled={selectedFiles.length === 0}
                                sx={{}}
                            />
                        </Grid>
                    </FileGrid>
                </GreyCard>
                <SubTitle sx={{ margin: '30px 0px 0px 38px' }}>State Information:</SubTitle>
                <div style={{ display: 'grid', justifyItems: 'center', marginRight:'200px' }}>
                    <div id="tooltip" ref={tooltipRef} style={{ display: !tooltip.visible ? 'none' : 'block', position: 'absolute', top: tooltip.y, left: tooltip.x }}>
                        <span className="close" onClick={hideTooltip}>&times;</span>
                        <h3>{tooltip.data.name}</h3>
                        {tooltip.data.turnTime && (
                            <p><strong>Title Turn Time (in weeks):</strong> <span className="turnTime">{tooltip.data.turnTime}</span></p>
                        )}
                        {tooltip.data.titleOwner && (
                            <p><strong>Title To Owner Or Lienholder:</strong> <span className="titleOwner">{tooltip.data.titleOwner}</span></p>
                        )}
                        {tooltip.data.inspection && (
                            <p><strong>Inspection Type #1 (Emissions, VIN, Safety):</strong><br /><span className="inspection">{tooltip.data.inspection}</span></p>
                        )}
                        {tooltip.data.details && (
                            <p><strong>When Required:</strong> <span className="details">{tooltip.data.details}</span></p>
                        )}
                        {tooltip.data.inspectionTwo && (
                            <p><strong>Inspection Type #2 (Emissions, VIN, Safety):</strong><br /><span className="inspectionTwo">{tooltip.data.inspectionTwo}</span></p>
                        )}
                        {tooltip.data.detailsTwo && (
                            <p><strong>When Required:</strong> <span className="detailsTwo">{tooltip.data.detailsTwo}</span></p>
                        )}
                        {tooltip.data.inspectionThree && (
                            <p><strong>Inspection Type #3 (Emissions, VIN, Safety):</strong><br /><span className="inspectionThree">{tooltip.data.inspectionThree}</span></p>
                        )}
                        {tooltip.data.detailsThree && (
                            <p><strong>When Required:</strong> <span className="detailsThree">{tooltip.data.detailsThree}</span></p>
                        )}
                        {tooltip.data.inspectionFour && (
                            <p><strong>Inspection Type #4 (Emissions, VIN, Safety):</strong><br /><span className="inspectionFour">{tooltip.data.inspectionFour}</span></p>
                        )}
                        {tooltip.data.detailsFour && (
                            <p><strong>When Required:</strong> <span className="detailsFour">{tooltip.data.detailsFour}</span></p>
                        )}
                        {tooltip.data.stateLink && (
                            <p><a href={tooltip.data.stateLink} target="_blank" style={{ color: '#ED7300', fontFamily: 'Interstate' }}>{tooltip.data.stateLink}</a></p>
                        )}
                    </div>
                    <StateMap onStateClick={handleStateClick} onPillClick={handlePillClick} />
                </div>
            </MainBody>
        </NoSsr>
    );
}
