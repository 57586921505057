import React from "react";
import { Autocomplete, TextField } from "@mui/material";

export interface AutocompleteAcertusItem {
    id: number;
    label: string;
};

interface AutocompleteAcertusProps {
    items: AutocompleteAcertusItem[];
    value: AutocompleteAcertusItem[] | undefined;
    onChange: (value: AutocompleteAcertusItem[] | undefined) => void;
    label?: string;
    disabled?: boolean;
};

const AutocompleteAcertus: React.FC<AutocompleteAcertusProps> = ({ items, value, onChange, label, disabled }) => {

    return (
        <Autocomplete
            multiple
            options={items}
            renderInput={(params) =>
                <TextField
                    {...params}
                    size="small"
                    label={label}
                    sx={{
                        "& .MuiInputBase-root": {
                            width: "215px",
                            //height: "36px",
                            border: "1px",
                            borderRadius: "4px",
                            backgroundColor: "#FFFFFF",
                            "&:hover fieldset": {
                                borderColor: "#ED7300",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "#ED7300",
                            },
                        },
                        "& .MuiInputLabel-root": {
                            fontSize: "12px",
                            color: '#5D6771',
                            "&.Mui-focused": {
                                color: "#5D6771", 
                            },
                        },
                        "& .MuiInputBase-input": {
                            fontSize: "12px",
                        },
                    }}
                />
            }
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.label}
                </li>
            )}
            value={[]}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            disableListWrap
            disabled={disabled}
        />
    );
};

export default AutocompleteAcertus;