import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Tooltip, Zoom, Dialog, IconButton, DialogTitle, DialogContent } from "@mui/material";
import DataGridAcertus from "../Components/DataGridAcertus";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { InvoiceDetailPage } from "../InvoiceDetail/InvoiceDetailPage";

const columns: GridColDef[] = [
    { field: "OrderId", headerName: "Order ID", width: 100, type: "string" },
    { field: "InvoiceNumber", headerName: "Invoice #", width: 90, type: "string" },
    { field: "Customer", headerName: "Customer", width: 175, type: "string" },
    { field: "Registrant", headerName: "Registrant", width: 175, type: "string" },
    { field: "UnitNumber", headerName: "Unit #", width: 110, type: "string" },
    { field: "State", headerName: "State", width: 100, type: "string" },
    { field: "Task", headerName: "Task", width: 110, type: "string" },
    { field: "VIN", headerName: "VIN", width: 150, type: "string" },
    { field: "Status", headerName: "Status", width: 175, type: "string" },
    { field: "Date", headerName: "Date", width: 95, type: "date",
        valueGetter: (params) => {
            return params.value ? new Date(params.value) : null;
        },
        valueFormatter: (params) => {
            return params.value ? params.value.toLocaleDateString() : '';
        },
    },
    { field: "Balance", headerName: "Balance", width: 100, type: "string", valueFormatter: (params) => {
            const value = params.value;
            return value !== null ? `$${value}` : "";
        }
    },
    { field: "ETA", headerName: "ETA", width: 134, type: "date",
        valueGetter: (params) => {
            return params.value ? new Date(params.value) : null;
        },
        valueFormatter: (params) => {
            return params.value ? params.value.toLocaleDateString() : '';
        },
    },
];

interface OrdersBodyProps {
    rows: Array<any>;
    loading: boolean;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    inProcessCount: number;
    pendingCount: number;
    waitingForPaperworkCount: number;
    completeCount: number;
    cancelledCount: number;
    totalCount: number;
    handleApplyFilters: () => void;
    selections: { label: string, choice: string | boolean, id?: number }[];
    setSelections: React.Dispatch<React.SetStateAction<{ label: string, choice: string | boolean, id?: number }[]>>;
    handleCancelLoading: () => void;
}

const OrdersBody: React.FC<OrdersBodyProps> = ({ rows, loading, page, setPage, pageSize, setPageSize, totalCount, inProcessCount, pendingCount, completeCount, cancelledCount, waitingForPaperworkCount, handleApplyFilters, setSelections, selections, handleCancelLoading }) => {
    const isFirstRender = useRef(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState<number | null>(null);

    const CloseIconAcertus = () => (
        <img src="../../React/svgs/CloseIconAcertus.svg" alt="Close Icon" />
    );

    const handleRowClick = (params: GridRowParams) => {
        const invoiceNumber = params.row["InvoiceNumber"] as number;
        setSelectedInvoice(invoiceNumber);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedInvoice(null);
    };

    const pageUpdate = async (pageNumber) => {
        if (page !== pageNumber) {
            setPage(pageNumber);
            setSelections(prevSelections => {
                const newSelections = prevSelections.filter(selection => selection.label !== 'Page');
                return [...newSelections, { label: 'Page', choice: pageNumber }];
            });
        }
    };

    const pageSizeUpdate = async (pageAmount) => {
        if (pageSize !== pageAmount) {
            setPageSize(pageAmount); 
            setSelections(prevSelections => {
                const newSelections = prevSelections.filter(selection => selection.label !== 'Page Size');
                return [...newSelections, { label: 'Page Size', choice: pageAmount }];
            });
        }
    };

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false; //stops apply filters from happening when page loads
            return;
        }
        if (selections.some(selection => selection.label === 'Page' || selection.label === 'Page Size')) {
            handleApplyFilters();
        }
    }, [page, pageSize]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: "wrap", justifyContent: 'flex-start', alignItems: 'flex-start', minHeight: "620px", gap: "20px" }}>
            <Box sx={{ display: 'inline-flex', flexDirection: 'row', flexWrap: "nowrap", justifyContent: 'space-between', alignItems: 'center', width: "80vw", minHeight: "26px", gap: "20px" }}>
                <Box sx={{ display: 'inline-flex', flexDirection: 'row', flexWrap: "wrap", justifyContent: 'flex-start', alignItems: 'center', minHeight: "26px", gap: "20px", marginLeft:"30px" }}>
                    <Typography color="#333E48" fontWeight="bold" sx={{ padding: "0px", fontSize: '12px' }}>In-Process: <span style={{ fontWeight: 'normal' }}>{inProcessCount.toLocaleString()}</span></Typography>
                    <Typography color="#333E48" fontWeight="bold" sx={{ padding: "0px", fontSize: '12px' }}>Pending: <span style={{ fontWeight: 'normal' }}>{pendingCount.toLocaleString()}</span></Typography>
                    <Typography color="#333E48" fontWeight="bold" sx={{ padding: "0px", fontSize: '12px' }}>Waiting For Paperwork: <span style={{ fontWeight: 'normal' }}>{waitingForPaperworkCount.toLocaleString()}</span></Typography>
                    <Typography color="#333E48" fontWeight="bold" sx={{ padding: "0px", fontSize: '12px' }}>Complete: <span style={{ fontWeight: 'normal' }}>{completeCount.toLocaleString()}</span></Typography>
                    <Typography color="#333E48" fontWeight="bold" sx={{ padding: "0px", fontSize: '12px' }}>Cancelled: <span style={{ fontWeight: 'normal' }}>{cancelledCount.toLocaleString()}</span></Typography>
                </Box>
                <Box sx={{ display: 'inline-flex', flexDirection: 'row', flexWrap: "wrap", justifyContent: 'flex-end', alignItems: 'center', minHeight: "26px", gap: "10px" }}>
                    <Typography color="#333E48" fontWeight="bold" sx={{ padding: "0px", fontSize: '13px' }}>Total: {totalCount.toLocaleString()}</Typography>
                    {totalCount === 50000 && (
                        <Tooltip TransitionComponent={Zoom} enterDelay={200} leaveDelay={500} title={<span style={{ fontSize: '12px' }}>Search produces results over the 50,000 order limit. Please refine your search parameters.</span>} placement="top" arrow>
                            <InfoIcon sx={{ color: "#ED7300", cursor: 'pointer', fontSize: '18px' }} />
                        </Tooltip>
                    )}
                </Box>
            </Box>
            <DataGridAcertus
                width="80vw"
                maxHeight="600px"
                minHeight="300px"
                columns={columns}
                rows={rows}
                loading={loading}
                page={page}
                pageSize={pageSize}
                rowCount={totalCount}
                onPageChange={(newPage) => pageUpdate(newPage)}
                onPageSizeChange={(newPageSize) => pageSizeUpdate(newPageSize)}
                pageSizeOptions={[15, 30, 50, 100]}
                onRowClick={handleRowClick}
                handleCancelLoading={handleCancelLoading}
            />
            <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="lg" fullWidth>
                <DialogTitle>
                    <IconButton
                        onClick={handleDialogClose}
                        sx={{
                            position: 'absolute',
                            right: 30,
                            top: 8,
                        }}
                    >
                        <CloseIconAcertus />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {selectedInvoice && <InvoiceDetailPage InvoiceNumber={selectedInvoice} />}
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default OrdersBody;
