import React, { useEffect, useState } from "react";
import { StateType } from "../Types/common";
import { InvoiceDetailPageModal } from "./InvoiceDetailPage";

export interface OrderInformationState extends Object {
    invoiceNumber: number;
    customerName: string;
    stateName: string;
    workType: string;
    vin: string;
    year: string;
    make: string;
    model: string;
    clientUnit: string;
    address: string;
}

export const OrderInformation: React.FC<InvoiceDetailPageModal> = (props: InvoiceDetailPageModal) => {
    OrderInformation.displayName = "OrderInformation"

    const [state, setState] = useState<OrderInformationState>({} as OrderInformationState)

    useEffect(() => {
        getState(props)
    }, [props])

    const getState = (model: InvoiceDetailPageModal): void => {
        try {
            setState
                ({
                invoiceNumber: model.Transaction.InvoiceNumber,
                customerName: model.Customer.Name,
                stateName: model.State.StateAbbreviation,
                vin: model.Transaction.VIN,
                year: model.Transaction.VehicleYear,
                make: model.Transaction.Make,
                model: model.Transaction.Model,
                workType: model.TypeElement.Description,
                clientUnit: model.Transaction.ClientUnit,
                address: model.Transaction.Address
                    ? `${model.Transaction.Address}, ${model.Transaction.CityName}, ${StateType[model.Transaction.StateId || 0]} ${model.Transaction.ZipCode}`
                    : "NO ADDRESS PROVIDED"
            })
        } catch (e) {
            setState({} as OrderInformationState)
        }
    }

    return (
        <div className="row card">
            <div className="card-header">
                <div className="col-sm-12">
                    <h3 style={{ fontFamily: '"Noto Sans", Arial, sans-serif' }}>Primary Owner</h3>
                </div>
            </div>
            <div className="card-body">
                <div className="col-sm-12">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Unit #</th>
                                <th>Name and Address</th>
                                <th>Work State</th>
                                <th>Work Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{state.clientUnit}</td>
                                <td>{state.customerName}</td>
                                <td>{state.stateName}</td>
                                <td>{state.workType}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>{state.address}</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-sm-12">
                    <h3 style={{ fontFamily: '"Noto Sans", Arial, sans-serif' }}>Vehicle Information</h3>
                </div>
                <div className="col-sm-12">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>VIN</th>
                                <th>Year</th>
                                <th>Make</th>
                                <th>Model</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{state.vin}</td>
                                <td>{state.year}</td>
                                <td>{state.make}</td>
                                <td>{state.model}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        )
}