const DeleteSavedSearch = async (id) => {
    try {
        const response = await fetch(window.location.origin + "/SavedSearch/DeleteSavedSearch", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id }),
        });
        if (!response.ok) {
            throw new Error(`Response Error: ${response.statusText}`);
        }
        const result = await response.json();
        return result.success; 
    } catch (error) {
        console.error("Error:", error);
        console.error("error delete saved search")
    } finally {

    }
};

export default DeleteSavedSearch;
