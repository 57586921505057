import React, { useState, useEffect, useRef } from 'react';
import { Tab, Typography, NoSsr, styled, IconButton, Tooltip, Button, Box, FormControl, Autocomplete, createFilterOptions, TextField, Grid, Divider, Dialog, Zoom, Grow } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DescriptionIcon from '@mui/icons-material/Description';
import { VisuallyHiddenInput, OrangeButton, SelectFileButton, TemplateButton,ResetGrid, WhiteGoBackButton, SubmitButton, RenewalTitle, FieldLabel } from '../../Shared/Renewals';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WhiteEndIconButton from '../../Components/WhiteEndIconButton';
import OrangeEndIconButton from '../../Components/OrangeEndIconButton';
import BackButton from '../../Components/BackButton';
import PrintIcon from '@mui/icons-material/Print';
import { TextInput, CurrencyInput, SSNInput, PhoneInput, NumberInput, DatePicker, AddressButton, FileUpload, LookupValueInput } from '../../Components/Input';
import UploadIcon from '@mui/icons-material/Upload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { State, TranReq } from '../../Types/common';

export const OrderFieldTitle = styled(Typography)({
    color: '#333E48',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    fontFamily: 'InterstateLight',
});
export const DocumentName = styled(Typography)({
    color: '#333E48',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
    letterSpacing: '0.5px',
    fontFamily: 'InterstateLight',
});
export const SubGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas:"'title title title''step1 step1 print''step2 step2 step2''step3 step3 step3''bpoa ipoa .''step4 step4 step4''upload upload upload''divider divider divider''step5 step5 step5''step6 step6 step6''address address address''backButton nextButton nextButton'",
    gridTemplateColumns: '4fr 4fr .5fr',
})
export const ListGrid = styled(Grid)({
    display: 'grid',
    gridTemplateColumns: '4fr 1fr',
    gap: 2,
    margin: '10px 0px',
    alignItems: 'center'
})

export const Submissions = ({ handleNext, formData, handleGoBack, editing }) => {
    const [disableButton, setDisableButton] = React.useState<boolean>(true);
    const [vin, setVIN] = useState(formData?.vehicle?.vin ?? null);
    const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [errorDialog, setErrorDialog] = React.useState<boolean>(false);
    const [error, setError] = useState<string | null>();
    const [file, setFile] = useState<File | null>(null);
    const [enable, setEnable] = React.useState<boolean>(false);
    const [selectedRequirementId, setSelectedRequirementId] = useState<number | null>(null);
    const [requirementValues, setRequirementValues] = React.useState<TranReq[]>(formData?.sub?.requirementValues || []);

    const handleReqNext = async () => {
        const submissionData = {
            sub: { requirementValues },
        };
        handleNext(submissionData);
    }
    const handleUploadSuccess = async (requirementId, fileName) => {
        await handleInputChange(requirementId, "YES", fileName);
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                setEnable(true);
            } catch (error) {
                console.error("An error occurred during fetch data:", error);
            }
        };

        fetchData();
        return () => { };
    }, []);
    const handleSelectFileButtonClick = (requirementId, index) => {
        setSelectedRequirementId(requirementId);
        const inputRef = fileInputRefs.current[index];
        if (inputRef) {
            inputRef.click();
        }
    };
    const handleInputChange = async (requirementId, data, fileName) => {
        setRequirementValues(prevValues => ({
            ...prevValues,
            [requirementId]: {
                ...prevValues[requirementId],
                Data: data,
                DocumentName: fileName,
                DocumentUploaded: true,
                IsExternal: true,
            }
        }));
        if (formData?.sub?.requirementValues && formData.sub.requirementValues[requirementId]) {
            formData.sub.requirementValues[requirementId] = {
                ...formData.sub.requirementValues[requirementId],
                Data: data,
                DocumentName: fileName,
                DocumentUploaded: true
            };
        }
        if (formData?.reqs?.requirements) {
            const updatedRequirements = formData.reqs.requirements.map(req => {
                if (req.RequirementId === requirementId) {
                    return {
                        ...req,
                        Value: "YES", //sets requirement value to yes if it was uploaded
                    };
                }
                return req;
            });
            formData.reqs.requirements = updatedRequirements;
        }
    }; 
    const handleFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>, requirementId, index) => {
        const selectedFile = e.target.files && e.target.files[0];
        if (selectedFile) {
            const allowedExtensions = ["pdf", "jpg", "jpeg", "png", "tiff"];
            const fileExtension = selectedFile.name.split('.').pop();
            if (fileExtension && allowedExtensions.includes(fileExtension)) {
                setFile(selectedFile);
                await handleFile(selectedFile, selectedRequirementId, selectedFile.name);
                if (fileInputRefs.current[index]) {
                    fileInputRefs.current[index]!.value = '';
                }
            }
            else {
                await setError("File type not supported.");
                await handleError();
            }
        }
    };

    const handleFile = async (file, requirementId, fileName) => {
        const document = new FormData();
        if (file instanceof File) {
            document.append("document", file, file.name);
        }
        await uploadDocument(document, requirementId, fileName);
    };

    const uploadDocument = async (document, requirementId, fileName) => {
        
        const requestOptions: RequestInit = {
            method: 'PUT',
            body: document,
        };

        const url = `${window.location.origin}/CreateOrders/UploadLTOrderDocument?vin=${vin}`;

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                const errorMessage = `Failed to upload LT order document. Status: ${response.status} ${response.statusText}`;
                const responseBody = await response.text();
                console.error(errorMessage, responseBody);
                console.error("Request URL:", url);
                console.error("Request Options:", requestOptions);
            } else {
                const data = await response.json();
                if (data.error != undefined) {
                    setError(data.error);
                    await handleError();
                }
                else {
                    handleUploadSuccess(requirementId, fileName)
                }
            }
        } catch (error) {
            console.error("Error during uploadDocument:", error);
        }
    };
    const handleError = () => {
        setErrorDialog(true);
    }
    const handleErrorAck = () => {
        setErrorDialog(false);
    }
    const handleDownload = async () => {
        const requirementDocs = formData.reqs.requirements
            .filter(requirement => requirement.IsDocument && requirement.IsExternal)
            .map(requirement => ({
                Name: requirement.Name,
            }));
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
             body: JSON.stringify({
                companyName: formData?.reqBy?.custName || '',
                contactId: formData?.reqBy?.contact || '',
                isLease: formData?.reqs?.typeElement.Id == 32,
                typeTitle: formData.reqs.requirementValues[3132]?.Data || '',
                state: formData?.reg?.individualModal?.selectedState?.Abbreviation || '',
                color: formData?.reqs?.requirementValues[52]?.Data || '',
                gvw: formData?.reqs?.requirementValues[74]?.Data || '',
                vin: formData?.vehicle?.vin || '',
                tradeIn: formData?.reqs?.requirementValues[135]?.Data || '',
                lienName1: formData?.reqs?.requirementValues[43]?.Data || '',
                lienAddress1: formData?.reqs?.requirementValues[43]?.StreetAddress || '',
                lienCity: formData?.reqs?.requirementValues[43]?.City || '',
                lienStateId: formData?.reqs?.requirementValues[43]?.StateId || '',
                lienZipCode: formData?.reqs?.requirementValues[43]?.ZipCode || '',
                reqDocuments: requirementDocs,
            }),
        };
        
        const url = `${window.location.origin}/CreateOrders/DownloadForm`;

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                console.error("Failed to fetch the form:", response.status, response.statusText);
                console.error("Request URL:", response.url);
                console.error("Response Headers:", response.headers);
            } else {
                const blob = await response.blob();
                const a = document.createElement('a');
                const blobUrl = window.URL.createObjectURL(blob);
                a.href = blobUrl;
                a.download = 'Coversheet.pdf';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setTimeout(() => {
                    window.URL.revokeObjectURL(blobUrl);
                }, 5000);
            }
        } catch (error) {
            console.error("Error during download:", error);
        }
    };
    return (
        <NoSsr>
            <Zoom in={enable}>
                <div style={{ width: '502px' }}>
                    <SubGrid>
                        <Grid sx={{ gridArea: 'title', margin: '20px 0px 0px 0px' }}>
                            <RenewalTitle>Submissions</RenewalTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'step1', margin: '20px 0px 0px 0px' }}>
                            <OrderFieldTitle>Step 1: Complete Cover Sheet</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'print', margin: '0px 0px 0px 0px', display: 'grid', alignItems: 'end', justifyContent: 'center' }}>
                            <Tooltip TransitionComponent={Zoom} enterDelay={500} leaveDelay={100} title={<span style={{ fontSize: '12px' }}>Download the Cover Sheet</span>} placement="right">
                                <PrintIcon sx={{ color: '#ED7300', fontSize: '24px', cursor: 'pointer' }} onClick={handleDownload} />
                            </Tooltip>
                        </Grid>
                        <Grid sx={{ gridArea: 'step2', margin: '20px 0px 0px 0px' }}>
                            <OrderFieldTitle>Step 2: Sign Original Vehicle Title (with seller signature if sale)</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'step3', margin: '20px 0px 0px 0px' }}>
                            <OrderFieldTitle>Step 3: Complete Power of Attorney with Buyer Signature</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'bpoa', margin: '20px 0px 0px 0px' }}>
                            <Tooltip TransitionComponent={Zoom} enterDelay={500} leaveDelay={100} title={<span style={{ fontSize: '12px' }}>Download the Business Power of Attorney Document</span>} placement="left">
                                <a href="/Content/Files/Business POA.pdf" download="BusinessPOA.pdf" rel="noopener noreferrer">
                                    <WhiteEndIconButton
                                        label="Business POA"
                                        onClick={null}
                                        variant="outlined"
                                        endIcon={<DownloadIcon sx={{ color: '#ED7300' }} />}
                                        disabled={false}
                                        sx={{ width: '220px', height: '40px' }}
                                    />
                                </a>
                            </Tooltip>
                        </Grid>
                        <Grid sx={{ gridArea: 'ipoa', margin: '20px 0px 0px 0px' }}>
                            <Tooltip TransitionComponent={Zoom} enterDelay={500} leaveDelay={100} title={<span style={{ fontSize: '12px' }}>Download the Individual Power of Attorney Document</span>} placement="right">
                                <a href="/Content/Files/Individual POA.pdf" download="IndividualPOA.pdf" rel="noopener noreferrer">
                                    <WhiteEndIconButton
                                        label="Individual POA"
                                        onClick={null}
                                        variant="outlined"
                                        endIcon={<DownloadIcon sx={{ color: '#ED7300' }} />}
                                        disabled={false}
                                        sx={{ width: '220px', height: '40px' }}
                                    />
                                    </a>
                            </Tooltip>
                        </Grid>
                        <Grid sx={{ gridArea: 'step4', margin: '20px 0px 0px 0px' }}>
                            <OrderFieldTitle>Step 4: Upload State Requirements Documents</OrderFieldTitle>
                        </Grid>
                        <ListGrid sx={{ gridArea: 'upload' }}>
                            {Array.isArray(formData.reqs.requirements) && formData.reqs.requirements.filter(requirement => requirement.IsDocument && requirement.IsExternal).map((requirement, index) => (
                                <React.Fragment key={requirement.RequirementId}>
                                    <Grid item sx={{ margin: '10px 0px 0px 0px' }}>
                                        <DocumentName>{`${index + 1}. ${requirement.Name}`}</DocumentName>
                                    </Grid>
                                    <Grid item sx={{ margin: '10px 0px 0px 0px', display: 'grid', justifyContent: 'end' }}>
                                        {requirementValues[requirement.RequirementId]?.Data === "YES" && requirementValues[requirement.RequirementId]?.DocumentUploaded && requirementValues[requirement.RequirementId]?.IsExternal ? (
                                            <Tooltip TransitionComponent={Zoom} enterDelay={500} leaveDelay={100} title={<span style={{ fontSize: '12px' }}>{requirement.Name} uploaded successfully</span>} placement="right">
                                                <CheckCircleIcon sx={{ color: '#32B83D', fontSize: '24px', cursor: 'pointer' }} />
                                            </Tooltip>
                                        ) : (
                                            <>
                                                <Tooltip TransitionComponent={Zoom} enterDelay={500} leaveDelay={100} title={<span style={{ fontSize: '12px' }}>Upload {requirement.Name} here</span>} placement="right">
                                                    <UploadIcon sx={{ color: '#ED7300', fontSize: '24px', cursor: 'pointer' }} onClick={() => handleSelectFileButtonClick(requirement.RequirementId, index)} />
                                                </Tooltip>
                                                <input ref={(i) => (fileInputRefs.current[index] = i)} type="file" style={{ display: 'none' }} onChange={(e) => handleFileInputChange(e, requirement.RequirementId, index)} />
                                            </>
                                        )}
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </ListGrid>
                        <Grid sx={{ gridArea: 'divider', margin: '0px 0px 0px 0px' }}>
                            <Divider sx={{ border: '1px solid #ED7300', width: '502px' }} />
                        </Grid>
                        <Grid sx={{ gridArea: 'step5', margin: '20px 0px 0px 0px' }}>
                            <OrderFieldTitle>Step 5: Print All Documents</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'step6', margin: '20px 0px 0px 0px' }}>
                            <OrderFieldTitle>Step 6: Send all documents including original power of attorney and title</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'address', margin: '20px 0px 0px 0px' }}>
                            <OrderFieldTitle sx={{ fontWeight:'normal', fontSize:'18px', fontFamily:'Interstate' }}>ACERTUS <br /> 6301 Glenwood <br /> Overland Park, KS  66202</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'backButton', marginTop: '30px' }}>
                            <BackButton
                                label="Back"
                                onClick={handleGoBack}
                                variant="text"
                                startIcon={<ArrowBackIcon sx={{ width: '18px', height: '18px', color: '#ED7300' }} />}
                                disabled={editing}
                                sx={{ marginBottom: '20px' }}
                            />
                        </Grid>
                        <Grid sx={{ gridArea: 'nextButton', display: 'grid', justifyContent: 'end', marginTop: '30px' }}>
                            <OrangeEndIconButton
                                label={editing ? "Save" : "Summary"}
                                onClick={handleReqNext}
                                variant="contained"
                                endIcon={editing ? <SaveIcon sx={{ color: 'white' }} /> : <ArrowForwardIcon sx={{ color: 'white' }} />}
                                disabled={!disableButton}
                                sx={{ width: '168px', height: '36px', marginBottom: '20px' }}
                            />
                        </Grid>
                    </SubGrid>
                </div>
            </Zoom>
            <Dialog open={errorDialog}>
                <Zoom in={errorDialog}>
                    <div style={{ height: '150px', width: '600px', display: 'grid', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}>
                        <Grid container style={{ height: '100%', justifyContent: 'center' }}>
                            <ResetGrid>
                                <Grid sx={{ gridArea: 'question', marginBottom: '20px' }}>
                                    <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}>{error}</Typography>
                                </Grid>
                                <Grid sx={{ gridArea: 'yesButton' }}>
                                    <SubmitButton onClick={handleErrorAck}>Ok</SubmitButton>
                                </Grid>
                            </ResetGrid>
                        </Grid>
                    </div>
                </Zoom>
            </Dialog>
        </NoSsr>
    );
}