import React  from 'react';
import { Button, styled, FormControlLabel, Checkbox } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const WhiteHeaderButton = ({ label, variant, disabled, sx, onClick }) => {
    return (
        <FormControlLabel
            labelPlacement="start"
            disabled={disabled}
            control={
                <Checkbox
                    icon={<CancelIcon sx={{ color: "#ED7300" }} />}
                    checkedIcon={<CancelIcon sx={{ color: "#ED7300" }} />}
                    checked={true}
                    onClick={onClick}
                />}
            label={label}
            sx={{
                width: '135px', height: "36px", color: "#6D767F", backgroundColor: "white", borderRadius: "5px", border:'1px solid #c4c4c4', padding: "10px 20px 10px 20px", margin: "0px",
                "& .MuiFormControlLabel-label": {
                    fontSize: "12px",
                },
                '& .MuiTouchRipple-root': {
                    color: '#ED7300',
                },
            }}
        />
    );
};

export default WhiteHeaderButton;