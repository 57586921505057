import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { NoSsr, FormControl, InputLabel, OutlinedInput, IconButton, InputAdornment, styled, Link, FormControlLabel, Checkbox, Typography, Box, Grid } from "@mui/material";

export const LoginPage: React.FC = (props) => {
    const action = props["ReturnURL"] ? '/Account/Login?returnUrl=' + props["ReturnURL"] : '/Account/Login';
    const [showPassword, setShowPassword] = React.useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClick = () => {
        setIsClicked(true);
    };

    React.useEffect(() => {
        const rememberMeValue = localStorage.getItem("rememberMe"); // Check local storage 
        if (rememberMeValue === "true") {
            setRememberMe(true);
        }
    }, []);
    const handleRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rememberMe = event.target.checked ? true : false
        setRememberMe(rememberMe);
        localStorage.setItem("rememberMe", rememberMe.toString()); // Store Remember Me 
    };

    const FieldLabel = styled(InputLabel)({
        fontSize: '12px',
        fontFamily: 'Interstate',
        fontWeight: '700',
        marginBottom: '2px',
        marginLeft: '16px',
    });

    const LearnMoreButton = styled(Button)(({ theme }) => ({
        width: '115px',
        height: '36px',
        fontFamily: 'Interstate',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '20px',
        textAlign: 'center',
        borderRadius: '18px',
        border: '1px solid #ED7300',
        letterSpacing: '0.0125em',
        cursor: 'pointer',
        color: '#ED7300',
        backgroundColor: 'white',
        display: 'inline-block',
        '&:hover': {
            backgroundColor: 'white',
            border: '1px solid #ED7300',
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
            color: '#ED7300',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#FAD5B3',
            border: '1px solid #ED7300',
            color: '#ED7300',
        },
        '&:focus': {
            boxShadow: 'none',
            border: '1px solid #ED7300',
            color: '#ED7300',
            backgroundColor: '#FDF1E6',
        },
    }));
    const SignInButton = styled(Button)({
        border: '0',
        width: '90px',
        height: '36px',
        display: 'inline-block',
        alignItems: 'center',
        padding: '8px 16px',
        gap: '8px',
        textTransform: 'none',
        backgroundColor: '#ED7300',
        borderRadius: '18px',
        color: '#FFFFFF',
        cursor: 'pointer',
        fontFamily: 'Interstate',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '14px',
        float: 'right',
        lineHeight: '18px',
        '&:hover': {
            backgroundColor: '#ED7300',
            borderColor: '#0062cc',
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
            color: '#FFFFFF',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#C76000',
            borderColor: '#C76000',
            color: '#FFFFFF',
        },
        '&:focus': {
            boxShadow: 'none',
            border: '2px solid #A14E00',
            color: '#FFFFFF',
        },
    });
    const SignUpGrid = styled('div')({
        gridArea: 'signup',
        gridTemplateAreas:
            "'. . logo logo logo' '. . title title title' '. . email email email' '. . password password password' '. . remember-me  . forgot-password' '. . . . sign-in'",
        display: 'grid',
        gridTemplateColumns: '0.5fr repeat(3, 1fr) 0.5fr',
        gridTemplateRows: '0.5fr 0.5fr 1fr 1fr 0.5fr 0.5fr'
    });
    const NewHereGrid = styled('div')({
        gridArea: 'newhere',
        gridTemplateAreas:
            "'. . .' '. learnMoreSection .'",
        display: 'grid',
        gridTemplateRows: '1.5fr 3.1fr',
        gridTemplateColumns: '0.1fr 1fr',
    });

    return (
        <NoSsr defer>
            <div className="container">
                <form method="POST" action={action} >
                    <div dangerouslySetInnerHTML={{ __html: props["AntiForgeryToken"] }} />
                    <Grid container style={{ height: '100%' }}>
                        <Grid item xs={7} sx={{ display: 'grid', alignContent: 'center', justifyContent: 'center', gridTemplateAreas: "'. signup .'", gridTemplateColumns: '0.1fr 4fr 0.5fr' }} >
                            <SignUpGrid>
                                <Box component="img" src="../Content/assets/img/metro/logo/TandRLogo.svg" alt="MMT" sx={{ gridArea: 'logo', paddingBottom: '24px' }} />
                                <Typography
                                    sx={{
                                        fontFamily: 'Interstate',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '36px',
                                        lineHeight: '44px',
                                        color: '#333E48',
                                        marginBottom: '8px',
                                        gridArea: 'title',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    LOG IN TO YOUR ACCOUNT</Typography>
                                <Grid sx={{ gridArea: 'email', display: 'grid', alignContent: 'center' }}>
                                    <FieldLabel sx={{ marginLeft: '8px', color: '#333E48' }}>Email</FieldLabel>
                                    <FormControl variant="outlined" sx={{ m: 1, width: '100%', height: '48px', marginBottom: '24px', marginLeft: '0px', fontFamily: 'InterstateLight' }} >
                                        <InputLabel htmlFor="Email" shrink={isClicked} sx={{ fontFamily: 'InterstateLight', fontSize: '16px', lineHeight: '24px', color: '#8E959C', fontStyle: 'normal' }}>
                                        </InputLabel>
                                        <OutlinedInput
                                            name="Email"
                                            placeholder="Vinny@acertus.com"
                                            onClick={handleClick}
                                            sx={{ fontFamily: 'InterstateLight', fontSize: '16px', lineHeight: '24px', color: '#8E959C', fontStyle: 'normal' }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid sx={{ gridArea: 'password' }} >
                                    <FieldLabel sx={{ marginLeft: '8px', color: '#333E48' }} >Password</FieldLabel>
                                    <FormControl variant="outlined" sx={{ m: 1, width: '100%', height: '48px', marginBottom: '24px', marginLeft: '0px', fontFamily: 'InterstateLight', }} >
                                        <InputLabel htmlFor="Password" sx={{ fontFamily: 'InterstateLight', fontSize: '16px', lineHeight: '24px', color: '#8E959C', fontStyle: 'normal' }}>
                                        </InputLabel>
                                        <OutlinedInput
                                            name="Password"
                                            onClick={handleClick}
                                            placeholder="*******"
                                            sx={{ fontFamily: 'InterstateLight', fontSize: '16px', lineHeight: '24px', color: '#8E959C', fontStyle: 'normal' }}
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <img src="../Content/assets/img/icons/ClosedEye.svg" /> : <img src="../Content/assets/img/icons/Eye.svg" />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    <div style={{ fontFamily: 'InterstateLight', color: '#c50000', listStyleType: 'none' }} dangerouslySetInnerHTML={{ __html: props["ErrorMessages"] }} />

                                </Grid>
                                <Grid sx={{ gridArea: 'remember-me' }} >
                                    <FormControlLabel
                                        name="RememberMe"
                                        sx={{ margin: '0' }}
                                        control={<Checkbox
                                            sx={{
                                                color: '#ED7300',
                                                width: '18',
                                                height: '18',

                                                '&.Mui-isChecked': {
                                                    color: '#ED7300',
                                                },
                                                '& .MuiSvgIcon-root': { fontSize: 18 },
                                            }}
                                            value={rememberMe}
                                            checked={rememberMe}
                                            onChange={handleRememberMeChange}
                                        />}
                                        label={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'InterstateLight',
                                                    fontStyle: 'normal',
                                                    fontSize: '14px',
                                                    lineHeight: '20px',
                                                    textAlign: 'left',
                                                    verticalAlign: 'top',
                                                    letterSpacing: '0.25%',
                                                    float: 'left',
                                                    paddingLeft: '8px',
                                                    whiteSpace: 'nowrap',

                                                }}
                                            >
                                                Remember me
                                            </Typography>
                                        }
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid sx={{ gridArea: 'forgot-password' }}>
                                    <Link href="/Account/ForgotPassword" underline="none">
                                        <Typography
                                            sx={{
                                                fontFamily: 'InterstateLight',
                                                fontStyle: 'normal',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                letterSpacing: '0.25%',
                                                color: 'black',
                                                float: 'right',
                                                textAlign: 'right',
                                                display: 'inline',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            Forgot password?
                                        </Typography>
                                    </Link>
                                </Grid>
                                <Grid sx={{ gridArea: 'sign-in' }}>
                                    <SignInButton variant="contained" type="submit"> Sign in </SignInButton>
                                </Grid>

                            </SignUpGrid>
                        </Grid>

                        <Grid item xs={5} sx={{ backgroundImage: "url('../Content/assets/img/metro/Orangebackground.svg')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display: 'grid', alignContent: 'center', justifyContent: 'center', gridTemplateAreas: "'. newhere .'", gridTemplateColumns: '0.5fr 3fr 1fr' }}>
                            <NewHereGrid>
                                <Grid sx={{ gridArea: 'learnMoreSection', display: 'grid' }} >
                                    <div style={{
                                        gridTemplateAreas:
                                            "'title' 'description' 'button'",
                                        display: 'grid',
                                        gridTemplateRows: '0.5fr 0.5fr 0.5fr',
                                        alignItems: 'center',
                                        gridRowGap: '54px',
                                    }} >
                                        <Grid sx={{ gridArea: 'title' }}>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Interstate',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    fontSize: '36px',
                                                    lineHeight: '44px',
                                                    color: '#FFFFFF',
                                                }}
                                            >
                                                NEW HERE?</Typography>
                                        </Grid>
                                        <Grid sx={{ gridArea: 'description' }}>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Interstate',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    fontSize: '22px',
                                                    lineHeight: '32px',
                                                    color: '#FFFFFF',
                                                }}
                                            >
                                                Connect with our team today to learn about ACERTUS' title & registration processing capabilities</Typography>
                                        </Grid>
                                        <Grid sx={{ gridArea: 'button' }}>
                                            <Link href="https://acertusdelivers.com/title-registration-landing-page/" underline="none">
                                                <LearnMoreButton
                                                    className="LMbutton"
                                                    variant="contained">Learn more </LearnMoreButton>
                                            </Link>
                                        </Grid>
                                    </div>
                                </Grid>
                            </NewHereGrid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </NoSsr >
    );
}
