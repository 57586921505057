import React  from 'react';
import { Button, styled } from '@mui/material';

const AcertusButton = styled(Button)({
    color: '#ED7300',
    fontSize: '13px',
    fontFamily: 'Interstate',
});

const BackButton = ({ label, onClick, variant, startIcon, disabled, sx }) => {
    return (
        <AcertusButton
            onClick={onClick}
            variant={variant}
            startIcon={startIcon}
            disabled={disabled}
            sx={sx}
        >
            {label}
        </AcertusButton>
    );
};

export default BackButton;