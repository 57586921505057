import React, { useEffect, useRef, useState } from "react";
import { Payment, TransactionFee } from "../Types/common";

export interface OrderBalanceState {
    TransactionFees: TransactionFee[];
    Payments: Payment[];
};

export interface OrderBalanceProps {
    TransactionFees: TransactionFee[];
    Payments: Payment[];
};

export const OrderBalance: React.FC<OrderBalanceProps> = ({ TransactionFees = [], Payments = [] }) => {
    OrderBalance.displayName = "OrderBalance"
    const [transFees, setTransFees] = useState<TransactionFee[]>([])
    const [payments, setPayments] = useState<Payment[]>([])

    useEffect(() => {
        setTransFees(TransactionFees)
    }, [JSON.stringify(TransactionFees)])

    useEffect(() => {
        setPayments(Payments)
    }, [JSON.stringify(Payments)])

    const getTransactionFeeRows = (): JSX.Element[] => {
        return transFees.map(fee => {
            return (
                <tr key={"tran_fee_" + fee.TransactionFeeId}>
                    <td>{fee.FeeMetaData.Description}</td>
                    <td>{new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    }).format(fee.Fee)}</td>
                </tr>
                )
        })
    }

    const getTotalFees = (): string => {
        var total = transFees.reduce((a, b) => a + b.Fee, 0);

        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(total)
    }

    const getTotalPayment = (): string => {
        var total = payments.reduce((a, b) => a + b.CheckAmount, 0);

        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(total)
    }

    return (
        <div className="row card">
            <div className="card-header">
                <div className="col-sm-12">
                    <h3 style={{ fontFamily: '"Noto Sans", Arial, sans-serif' }}>Order Balance</h3>
                </div>
            </div>
            <div className="card-body">
                <table className="table">
                    <tbody>
                        { getTransactionFeeRows() }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>Total</td>
                            <td>{ getTotalFees() }</td>
                        </tr>
                        <tr>
                            <td>Received</td>
                            <td>{ getTotalPayment() }</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        )
}
