import React, { useState, useEffect } from "react";
import { Box, Typography, Button, CircularProgress, Alert } from "@mui/material";
import ReportItem from "./ReportItem";
import { ToastProps } from "../../Types/common";
import BackButton from '../BackButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
const days = {
    1: "S", // Sunday
    2: "M", // Monday
    3: "T", // Tuesday
    4: "W", // Wednesday
    5: "T", // Thursday
    6: "F", // Friday
    7: "S", // Saturday,
};
interface AddReportsProps {
    setToast: React.Dispatch<React.SetStateAction<ToastProps>>;
    setReportTitle: React.Dispatch<React.SetStateAction<string>>;
    refreshReports: () => void;
    setReportPage: React.Dispatch<React.SetStateAction<number>>;
 }
export const AddReports: React.FC<AddReportsProps> = ({ setToast, setReportTitle, refreshReports, setReportPage }) => {
    const [selectedDays, setSelectedDays] = useState<number[]>([]);
    const [isPendingOrdersChecked, setIsPendingOrdersChecked] = useState<boolean>(false);
    const [isInProcessOrdersChecked, setIsInProcessOrdersChecked] = useState<boolean>(false);
    const [isAllOrdersByStatusChecked, setIsAllOrdersByStatusChecked] = useState<boolean>(false);
    const [isWaitingOnPaperworkChecked, setIsWaitingOnPaperworkChecked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setReportTitle("Add Scheduled Report");
    }, []);

    const toggleDay = (dayNumber: number) => {
        if (selectedDays.includes(dayNumber)) {
            setSelectedDays(selectedDays.filter((selectedDay) => selectedDay !== dayNumber));
        } else {
            setSelectedDays([...selectedDays, dayNumber]);
        }
    };

    const sendReportDetailsToBackend = async () => {
        setIsLoading(true); 
        setToast({
            show: false,
            message: "",
            severity: 'info'
        });

        const payload = {
            selectedDays, 
            reportIds: [
                isPendingOrdersChecked && 1,
                isInProcessOrdersChecked && 2,
                isAllOrdersByStatusChecked && 4,
                isWaitingOnPaperworkChecked && 5,
            ].filter(Boolean),
        };

        try {
            const response = await fetch(window.location.origin + "/Reports/PostReportRequestAsync", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const result = await response.json();

            setToast({
                show: true,
                message: result.message,
                severity: result.severity,
            });
            refreshReports();
            setReportPage(0);
        } catch (error) {
            console.error("Error:", error);
            setToast({
                show: true,
                message: "Failed to submit report request. Please try again.",
                severity: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleBack = async () => {
        setReportTitle("Scheduled Reports")
        setReportPage(0)
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "flex-start",
                minHeight: "500px",
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", width: "100%" }}>
                <Typography fontWeight="700" sx={{ fontSize: '12px', color:"#333E48" }}>
                    Select Day(s) of Delivery
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    {Object.entries(days).map(([dayNumber, dayLabel]) => {
                        const isSelected = selectedDays.includes(Number(dayNumber));
                        return (
                            <Button
                                key={dayNumber}
                                onClick={() => toggleDay(Number(dayNumber))}
                                variant="outlined"
                                sx={{
                                    borderRadius: "50%",
                                    minWidth: "40px",
                                    minHeight: "40px",
                                    padding: 0,
                                    backgroundColor: isSelected ? "#ED7300" : "#FFFFFF",
                                    borderColor: isSelected ? "#ED7300" : "#FFFFFF",
                                    fontSize: "12px",
                                    color: isSelected ? "#FFFFFF" : "#333E48",
                                    "&:hover": {
                                        backgroundColor: isSelected ? "#ED7300" : "#FFFFFF",
                                        borderColor: "#ED7300",
                                    },
                                }}
                            >
                                {dayLabel}
                            </Button>
                        );
                    })}
                </Box>
                <Typography fontWeight="400" sx={{ fontSize: '11px' }}>
                    Reports will be emailed weekly on the morning of the selected day(s).
                </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <Typography fontWeight="700" sx={{ fontSize: '12px', color:'#333E48' }}>
                    Select Report Type
                </Typography>
                <ReportItem
                    title="Pending Orders"
                    description="Orders being held for additional items"
                    isChecked={isPendingOrdersChecked}
                    onChange={() => setIsPendingOrdersChecked(!isPendingOrdersChecked)}
                />
                <ReportItem
                    title="In-Process Orders"
                    description="Orders Sent to State for processing with ETA"
                    isChecked={isInProcessOrdersChecked}
                    onChange={() => setIsInProcessOrdersChecked(!isInProcessOrdersChecked)}
                />
                <ReportItem
                    title="All Orders by Status"
                    description="All orders, including completed, with balance"
                    isChecked={isAllOrdersByStatusChecked}
                    onChange={() => setIsAllOrdersByStatusChecked(!isAllOrdersByStatusChecked)}
                />
                <ReportItem
                    title="Waiting on Paperwork"
                    description="Orders with Acknowledge Status"
                    isChecked={isWaitingOnPaperworkChecked}
                    onChange={() => setIsWaitingOnPaperworkChecked(!isWaitingOnPaperworkChecked)}
                />
            </Box>
            <Box sx={{ display:'flex' }}>
                <BackButton startIcon={<ArrowBackIosNewIcon />} label="Back" variant="text" onClick={() => handleBack()} disabled={false} sx={{ width: '170px' }} />
                <Button
                    sx={{
                        borderRadius: "8px",
                        width: "170px",
                        backgroundColor: "#ED7300",
                        textTransform: "none",
                        "&:hover": {
                            backgroundColor: "#ED7300",
                        },
                    }}
                    variant="contained"
                    disabled={
                        isLoading ||
                        selectedDays.length === 0 ||
                        (!isPendingOrdersChecked &&
                            !isInProcessOrdersChecked &&
                            !isAllOrdersByStatusChecked &&
                            !isWaitingOnPaperworkChecked)
                    }
                    onClick={sendReportDetailsToBackend}
                >
                    {isLoading ? (
                        <CircularProgress size={20} sx={{ color: "#FFFFFF" }} />
                    ) : (
                        <Typography fontWeight="400" sx={{ color: "#FFFFFF", fontSize: '14px' }}>
                            Save
                        </Typography>
                    )}
                </Button>
            </Box>
        </Box>
    );
};

export default AddReports;
