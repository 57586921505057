import React, { useMemo } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";

interface SearchBarAcertusProps {
    inputLabel: string;
    width: string;
    disabled: boolean;
    onSearch: (value: string) => void;
    selections: { label: string, choice: string | boolean, id?: number }[];
    setSelections: React.Dispatch<React.SetStateAction<{ label: string, choice: string | boolean, id?: number }[]>>;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
};

const SearchIconAcertus = () => (
    <img src="../../React/svgs/SearchIconAcertus.svg" alt="Search Icon" />
);

const SearchBarAcertus: React.FC<SearchBarAcertusProps> = ({ inputLabel, onSearch, width, disabled, setSelections, selections, setSearchTerm }) => {
    const latestSearchSelection = useMemo(() => {
        return selections.find(selection => selection.label === 'Search')?.choice || '';
    }, [selections]);

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value);
        handleSearchSelection(value);
    };

    const handleKeyPress = async (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            onSearch(String(latestSearchSelection));
        }
    };

    const handleSearchClick = async () => {
        onSearch(String(latestSearchSelection));
    }; 

    const handleSearchSelection = async (searchTerm: string) => {
        await setSelections(prevSelections => {
            const searchSelectionIndex = prevSelections.findIndex(selection => selection.label === 'Search');

            if (searchSelectionIndex !== -1) {
                const newSelections = [...prevSelections];
                newSelections[searchSelectionIndex] = { ...newSelections[searchSelectionIndex], choice: searchTerm };
                return newSelections;
            }

            return [...prevSelections, { label: 'Search', choice: searchTerm }];
        });
    };

    return (
        <TextField
            variant="outlined"
            label={inputLabel}
            disabled={disabled}
            size="small"
            sx={{
                ".MuiOutlinedInput-root": {
                    width: width,
                    //height: "36px",
                    fontSize: "12px",
                    borderRadius: "8px",
                    backgroundColor: "#FFFFFF",
                    "&:hover fieldset": {
                        borderColor: "#ED7300",
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: "#ED7300",
                    },
                },
                "& .MuiInputLabel-root": {
                    fontSize: "12px",
                    color: '#5D6771',
                    "&.Mui-focused": {
                        color: "#5D6771",
                    },
                },
                "& .MuiInputBase-input": {
                    fontSize: "12px",
                },
            }}
            autoFocus={false}
            value={latestSearchSelection || ""}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleSearchClick} edge="end" disabled={disabled}>
                            <SearchIconAcertus />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default SearchBarAcertus;
