import React, { useState, useEffect } from "react";
import { Box, Typography, Alert, FormControlLabel, Checkbox } from "@mui/material";
import AutocompleteAcertus, { AutocompleteAcertusItem } from "../../Components/AutocompleteAcertus";
import DatePickerAcertus from "../../Components/DatePickerAcertus";
import CancelIcon from '@mui/icons-material/Cancel';
import dayjs, { Dayjs } from 'dayjs';
import WhiteHeaderButton from "../../Components/WhiteHeaderButton";
import { FormTextField, WhiteGoBackButton, SubmitButton } from '../../Shared/Renewals'

export interface ToastProps {
    show: boolean,
    message: string,
    severity: 'error' | 'warning' | 'info' | 'success'
}
interface FilterDetailsProps {
    onClickSave: () => void;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setFilterSelections: React.Dispatch<React.SetStateAction<{ label: string, choice: string | boolean, id?: number }[]>>;
    filterSelections: { label: string, choice: string | boolean, id?: number }[];
    setFilterName: React.Dispatch<React.SetStateAction<string | null>>;
    filterName: string | null;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    updateUserList: (param: number[] | null) => Promise<AutocompleteAcertusItem[]>;
    handleChange: (label: string, value: AutocompleteAcertusItem[] | undefined) => void;
    getDropdownSessionData: (requestMethod: string, setter: React.Dispatch<React.SetStateAction<AutocompleteAcertusItem[]>>, param?: number[] | null) => void;
}

const FilterDetails: React.FC<FilterDetailsProps> = ({ onClickSave, setOpen, setFilterSelections, filterSelections, setFilterName, filterName, setPageSize, updateUserList, handleChange, getDropdownSessionData }) => {
    const [alertMessage, setAlertMessage] = useState<string | null>(null); 
    const [alertSeverity, setAlertSeverity] = useState<"success" | "error" | undefined>(undefined); 
    const [customers, setCustomers] = useState<AutocompleteAcertusItem[]>([]);
    const [workTypes, setWorkTypes] = useState<AutocompleteAcertusItem[]>([]);
    const [states, setStates] = useState<AutocompleteAcertusItem[]>([]);
    const [statuses, setStatuses] = useState<AutocompleteAcertusItem[]>([]);
    const [etaRange, setETARange] = useState<AutocompleteAcertusItem[]>([]);
    const [users, setUsers] = useState<AutocompleteAcertusItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [showClearAll, setShowClearAll] = useState<boolean>(false);
    
    
    useEffect(() => {
        const dropdowns = [
            { method: "/GetCustomersDropDownAsync", setter: setCustomers },
            { method: "/GetWorkTypesDropDownAsync", setter: setWorkTypes },
            { method: "/GetStatesDropDownAsync", setter: setStates },
            { method: "/GetStatusesDropDownAsync", setter: setStatuses },
            { method: "/GetETAsDropDownAsync", setter: setETARange },
            { method: "/GetUsersDropDownAsync", setter: setUsers },
        ];

        dropdowns.forEach(drop => {
            getDropdownSessionData(drop.method, drop.setter);
        });

        const getSearchSelections = sessionStorage.getItem('selections');
        const getPageSize = sessionStorage.getItem('pageSize');
        const parsedSelections = getSearchSelections ? JSON.parse(getSearchSelections) : [];
        const pageSizeNumber = Number(getPageSize);
        const storedStartDate = parsedSelections.find(selection => selection.label === "Start Date")?.choice ?? null;
        const storedEndDate = parsedSelections.find(selection => selection.label === "End Date")?.choice ?? null;
        setPageSize(pageSizeNumber);
        setFilterSelections(parsedSelections);
        setStartDate(storedStartDate ? dayjs(storedStartDate, "MM/DD/YYYY") : null);
        setEndDate(storedEndDate ? dayjs(storedEndDate, "MM/DD/YYYY") : null);
        const filteredSelections = parsedSelections.filter(
            (selection) => selection.label !== "Page" && selection.label !== "Page Size" && selection.label !== "Search"
        );
        setShowClearAll(filteredSelections.length > 0);
    }, []);

    useEffect(() => {
        const filteredSelections = filterSelections.filter(
            (selection) => selection.label !== "Page" && selection.label !== "Page Size" && selection.label !== "Search"
        );
        setShowClearAll(filteredSelections.length > 0);

        const customerSelections = filterSelections
            .filter(selection => selection.label === 'Customer' && selection.id != null)
            .map(selection => selection.id as number);

        if (customerSelections.length > 0) {
            (async () => {
                const rawData = await updateUserList(customerSelections);
                setUsers(rawData);
            })();
        }
    }, [filterSelections]);

    const isCategorySelected = (label: string) => {
        return filterSelections.some(filterSelection => filterSelection.label === label);
    };

    const updateFilterSelection = (label: string, formattedDate: string) => {
        const dateSelectionIndex = filterSelections.findIndex(selection => selection.label === label);
        if (dateSelectionIndex !== -1) {
            setFilterSelections(prevSelections => {
                const newSelections = [...prevSelections];
                newSelections[dateSelectionIndex] = { ...newSelections[dateSelectionIndex], choice: formattedDate };
                return newSelections;
            });
        } else {
            setFilterSelections(prevSelections => [...prevSelections, { label: label, choice: formattedDate }]);
        }
    };

    useEffect(() => {
        if (startDate && endDate && startDate.isAfter(endDate)) {
            setEndDate(startDate);
            updateFilterSelection("End Date", startDate.format('MM/DD/YYYY'));
        }
    }, [startDate]);

    useEffect(() => {
        if (startDate && endDate && endDate.isBefore(startDate)) {
            setStartDate(endDate);
            updateFilterSelection("Start Date", endDate.format('MM/DD/YYYY'));
        }
    }, [endDate]);

    const handleRemove = (label: string, choice: string | boolean) => {
        setFilterSelections(prevFilterSelections => {
            return prevFilterSelections.filter(filterSelection => {
                return !(filterSelection.label === label && filterSelection.choice === choice);
            });
        });

        if (label === 'Start Date') {
            setStartDate(null);
        }
        if (label === 'End Date') {
            setEndDate(null);
        }
    };

    const handleETAChange = (label: string, value: AutocompleteAcertusItem[] | undefined) => {
        if (!value || value.length === 0) return;

        const choice = value[0].label;

        if (label === "ETA Range") {
            updateETASelection("ETA Range", choice);
        }
    };

    const updateETASelection = (label: string, choice: string | boolean) => {
        const etaIndex = filterSelections.findIndex(selection => selection.label === label);
        if (etaIndex !== -1) {
            setFilterSelections(prevSelections => {
                const newSelections = [...prevSelections];
                newSelections[etaIndex] = { ...newSelections[etaIndex], choice: choice };
                return newSelections;
            });
        } else {
            setFilterSelections(prevSelections => [...prevSelections, { label: label, choice: choice }]);
        }
    };

    const handleDateChange = (label: string, date: Dayjs | null) => {
        if (!date) return;

        const formattedDate = date.format('MM/DD/YYYY');

        if (label === "Start Date") {
            if (endDate && date.isAfter(endDate)) {
                setStartDate(endDate);
                const formattedEndDate = endDate.format('MM/DD/YYYY');
                updateFilterSelection("Start Date", formattedEndDate);
                sessionStorage.setItem("Start Date", JSON.stringify(formattedEndDate));
            } else {
                setStartDate(date);
                updateFilterSelection("Start Date", formattedDate);
                sessionStorage.setItem("Start Date", JSON.stringify(formattedDate));
            }
        }

        if (label === "End Date") {
            if (startDate && date.isBefore(startDate)) {
                setEndDate(startDate);
                const formattedStartDate = startDate.format('MM/DD/YYYY');
                updateFilterSelection("End Date", formattedStartDate);
                sessionStorage.setItem("End Date", JSON.stringify(formattedStartDate));
            } else {
                setEndDate(date);
                updateFilterSelection("End Date", formattedDate);
                sessionStorage.setItem("End Date", JSON.stringify(formattedDate));
            }
        }
    };

    const handleClearAll = async () => {
        setFilterSelections([]);
        setShowClearAll(false);
        setStartDate(null);
        setEndDate(null);
    };

    const handleSearchName = (name) => {
        setFilterName(name);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "flex-start",
                minHeight: "500px",
            }}
        >
            {alertMessage && (
                <Alert severity={alertSeverity} sx={{ width: "100%", marginBottom: "20px" }}>
                    {alertMessage}
                </Alert>
            )}
            <Typography color="#5D6771" sx={{ fontSize: '12px' }} fontWeight="700">Filter Name</Typography>
            <FormTextField value={filterName} sx={{ fontSize: '12px', width: '442px', marginBottom: '20px' }} InputProps={{ sx: { fontSize: '12px' } }} onChange={(e) => handleSearchName(e.target.value)} />
            <Typography color="#5D6771" sx={{ fontSize: '12px' }} fontWeight="700">Filters</Typography>
            <Box sx={{ display: "inline-flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start", alignItems: "center", minHeight: "40px", gap: "6px", marginBottom: '20px' }}>
                <AutocompleteAcertus items={customers} value={undefined} onChange={(value) => handleChange("Customer", value)} label="Customer" disabled={customers.length === 0 || loading} />
                <AutocompleteAcertus items={workTypes} value={undefined} onChange={(value) => handleChange("Task", value)} label="Task" disabled={workTypes.length === 0 || loading} />
                <AutocompleteAcertus items={states} value={undefined} onChange={(value) => handleChange("State", value)} label="State" disabled={states.length === 0 || loading} />
                <AutocompleteAcertus items={statuses} value={undefined} onChange={(value) => handleChange("Status", value)} label="Status" disabled={statuses.length === 0 || loading} />
                <AutocompleteAcertus items={etaRange} value={undefined} onChange={(value) => handleETAChange("ETA Range", value)} label="ETA Range" disabled={etaRange.length === 0 || loading} />
                <DatePickerAcertus label="Start Date" value={startDate} onChange={(date) => handleDateChange("Start Date", date)} disabled={loading} clearable={true} minDate={dayjs().subtract(1, 'year')} maxDate={dayjs(endDate)} />
                <DatePickerAcertus label="End Date" value={endDate} onChange={(date) => handleDateChange("End Date", date)} disabled={loading} clearable={true} minDate={dayjs(startDate)} maxDate={dayjs()} />
                {isCategorySelected("Customer") && (
                    <AutocompleteAcertus
                        items={users}
                        value={undefined}
                        onChange={(value) => handleChange("Users", value)}
                        label="Users"
                        disabled={users.length === 0 || loading}
                    />
                )}
            </Box>
            <Box sx={{ display: "inline-flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start", alignItems: "center", minHeight: "40px", gap: "10px", marginBottom: '20px' }}>
                {filterSelections
                    .filter(filterSelection => filterSelection.label !== "Balances Only" && filterSelection.label !== "Search" && filterSelection.label !== "Page" && filterSelection.label !== "Page Size")
                    .map((filterSelection, index) => (
                        <FormControlLabel
                            key={index}
                            labelPlacement="start"
                            disabled={loading}
                            control={
                                <Checkbox
                                    icon={<CancelIcon sx={{ color: "#FFFFFF" }} />}
                                    checkedIcon={<CancelIcon sx={{ color: "#FFFFFF" }} />}
                                    checked={true}
                                    onChange={() => handleRemove(filterSelection.label, filterSelection.choice)}
                                />}
                            label={`${filterSelection.label}: ${filterSelection.choice}`}
                            sx={{
                                height: "36px", color: "#FFFFFF", backgroundColor: "#6D767F", borderRadius: "5px", padding: "10px 20px 10px 20px", margin: "0px",
                                "& .MuiFormControlLabel-label": {
                                    fontSize: "12px",
                                },
                                '& .MuiTouchRipple-root': {
                                    color: '#ED7300',
                                },
                            }}
                        />
                    ))
                }
                {showClearAll && (
                    <WhiteHeaderButton
                        label="Clear All"
                        onClick={handleClearAll}
                        variant="contained"
                        disabled={null}
                        sx={{}}
                    />
                )}
            </Box>
            <Box sx={{ display: "inline-flex", justifyContent: "space-between", minHeight: "40px", gap: "10px", width: '100%', alignItems: 'flex-start' }}>
                <WhiteGoBackButton onClick={() => setOpen(false)} sx={{ marginBottom: '20px' }} variant="text">Cancel</WhiteGoBackButton>
                <SubmitButton onClick={onClickSave} disabled={!showClearAll || filterName == null} sx={{ fontFamily: '"Noto Sans", Arial, sans-serif' }}>Save</SubmitButton>
            </Box>
        </Box>
    );
};

export default FilterDetails;
