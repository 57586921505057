import React, { useEffect, useState } from "react";
import { ScannedDocument } from "../Types/common";

export interface DocumentsProps extends Object {
    ScannedDocuments: ScannedDocument[];
};

export const Documents: React.FC<DocumentsProps> = ({ ScannedDocuments = [] }) => {
    Documents.displayName = "Documents"
    const [docs, setDocs] = useState<ScannedDocument[]>([])

    useEffect(() => {
       setDocs(ScannedDocuments)
    }, [JSON.stringify(ScannedDocuments)])

    const getScannedDocuments = (): JSX.Element[] => {
        if (docs.length > 0) {
            return docs.map((doc,index) => {
                return (
                    <div className="row" key={"docs_id_" + index}>
                        <div className="col-lg-4 d-flex align-items-center">
                            { doc.Label }
                        </div>
                        <div className="col-lg-8">
                            <button type="submit" id="btnGetFile" name="btnGetFile" onClick={(e) => getScan(e, doc.AWSFilepath)} className="btn btn-link" value={doc.AWSFilepath} data-toggle="tooltip" data-placement="top" title={"Click to View File:" + doc.AWSFilepath} style={{marginTop: 0}} >
                                <a style={{ fontFamily: '"Noto Sans", Arial, sans-serif' }} href="">{ doc.AWSFilepath }</a>
                            </button>
                        </div>
                    </div>
                )
            });
        } else {
            return [
                <div className="row" key="docs_id_none">
                    <div className="col-sm-12">
                        <p style={{ fontFamily: '"Noto Sans", Arial, sans-serif' }}>Please scan requirement documents</p>
                    </div>
                </div>
            ]
        }
    };

    const getScan = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, filePath: string) => {
        e.preventDefault();
        var newWin = window.open();
        if (newWin != null) {
            newWin.location.href = window.location.origin + "/Portal/GetScan?btnGetFile=" + filePath
        }
    }

    return (
        <div>
            <div className="mmt-card row card">
                <div className="card-header">
                    <div className="col-sm-12 d-flex justify-content-between align-items-center">
                        <h3 style={{ fontFamily: '"Noto Sans", Arial, sans-serif' }}>Documents</h3>
                    </div>
                </div>
                <div className="card-body">
                    <div className="col-sm-12">
                        { getScannedDocuments() }
                    </div>
                </div>
            </div>
        </div>
        )
}