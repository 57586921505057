import { StyledComponent } from "@emotion/styled";
import { TabList, TabListProps } from "@mui/lab";
import { styled, Tab, TabProps } from "@mui/material";


export const StyledTab = styled(Tab)<TabProps>(() => ({
    color: "#000",
    fontSize: "14px",
    '&.Mui-selected': {
        'color': '#ed7800',
        'borderBottom': '2px solid #ed7800'  
    },
}));