import {LoginPage} from "./Account/LoginPage"
globalThis.LoginPage = LoginPage
import {CreateOrdersPage} from "./CreateOrders/CreateOrdersPage"
globalThis.CreateOrdersPage = CreateOrdersPage
import {InvoiceDetailPage} from "./InvoiceDetail/InvoiceDetailPage"
globalThis.InvoiceDetailPage = InvoiceDetailPage
import {OrdersPage} from "./Orders/OrdersPage"
globalThis.OrdersPage = OrdersPage
import {PendingOrdersPage} from "./PendingOrders/PendingOrdersPage"
globalThis.PendingOrdersPage = PendingOrdersPage
import {RenewalsPage} from "./Portal/RenewalsPage"
globalThis.RenewalsPage = RenewalsPage
import {ResourcesPage} from "./Resources/ResourcesPage"
globalThis.ResourcesPage = ResourcesPage
