import React  from 'react';
import { Button, styled } from '@mui/material';

const AcertusButton = styled(Button)({
    color: '#ED7300',
    backgroundColor: 'white',
    fontSize: '14px',
    fontFamily: 'Interstate',
    lineHeight: '20px',
    letterSpacing: '0.17px',
    border:'1px #ED7300 solid',
    wordWrap: 'break-word',
    width: '129px',
    height: '36px',
    textTransform: 'none',
    borderRadius: '8px',
    boxShadow:'none',
    '&:hover': {
        backgroundColor: 'white',
        border: '1px #ED7300 solid',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        color: '#ED7300',
    },
    '&.Mui-disabled': {
        backgroundColor: 'white',
        color: '#F8C799',
        border:'#F8C799 1px solid',
        cursor: 'not-allowed',
    },
    '&:focus': {
        border: '1px #ED7300 solid',
        backgroundColor:'#FDF1E6'
    },
    '&:active': {
        backgroundColor: '#FAD5B3',
    },
});

const WhiteEndIconButton = ({ label, onClick, variant, endIcon, disabled, sx }) => {
    return (
        <AcertusButton
            onClick={onClick}
            variant={variant}
            endIcon={endIcon}
            disabled={disabled}
            sx={sx}
        >
            {label}
        </AcertusButton>
    );
};

export default WhiteEndIconButton;