import React, { useState, useEffect, useRef} from 'react';
import { Autocomplete, TextField, Grid, FormControl, createFilterOptions, Typography, styled, Zoom, Divider } from "@mui/material";
import { SubmitButton, FieldLabel, RenewalTitle, SummaryGrid } from '../Shared/Renewals';
import BackButton from '../Components/BackButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingDialog, OkButtonDialog } from '../Components/Dialog';
import { State, TranReq } from '../Types/common';

export const OrderFieldTitle = styled(Typography)({
    color: '#333E48',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    fontFamily: 'InterstateLight',
});
export const EntryGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'title title title''attentionTitle attentionTitle addressTitle''attention attention addressDropdown''recTitle recTitle .''rec rec rec''regTitle regTitle regTitle2''reg reg reg2''cityTitle stateAbrvTitle zipTitle''city stateAbrv zip''phoneTitle phoneTitle .''phone phone .''divider divider divider'",
    gridTemplateColumns: '4fr 1.8fr 1.8fr',
})
export const GreyOutlineTextField = styled(TextField)({
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#70787F',
        borderWidth: '1px',
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
        fontFamily: 'InterstateLight',
        fontSize: '13px',
    },
    '& .MuiInputBase-input': {
        fontFamily: 'InterstateLight',
        fontSize: '12px',
    },
    '& .MuiSelect-select': {
        fontFamily: 'InterstateLight',
        fontSize: '12px',
    },
    '& .MuiMenuItem-root': {
        fontFamily: 'InterstateLight',
        fontSize: '12px',
    }
});
interface GetDropDowns {
    states: State[];
}
export interface Selection {
    selectedState: State | null;
}
interface ShipAddressEntryProps {
    handleGoBack: () => void;
    onSubmit: (address: {
        attentionTo: string | null;
        recipient: string | null;
        address1: string | null;
        address2: string | null;
        city: string | null;
        stateId: number | undefined;
        stateName: string | undefined;
        stateAbrv: string | undefined;
        zipCode: string | null;
        countyId: number | null;
        phoneNumber: string | null;
        requirementId: number | undefined;
    }) => void;
    RequirementId: number | undefined;
    Editing: boolean;
    InputValues: TranReq[];
    RegAddress: TranReq;
    CustomerAddress: TranReq;
}
const ShipAddressEntry = ({ handleGoBack, onSubmit, RequirementId, Editing, InputValues, RegAddress, CustomerAddress }: ShipAddressEntryProps) => {
    const [states, setStates] = useState<State[]>([]);
    const [disableSubmit, setDisableSubmit] = React.useState<boolean>(true);
    const [attentionTo, setAttentionTo] = useState<string | null>(null);
    const [recipient, setRecipient] = useState<string | null>(null);
    const [address1, setAddress1] = useState<string | null>(null);
    const [address2, setAddress2] = useState<string | null>(null);
    const [city, setCity] = useState<string | null>(null);
    const [zipCode, setZipCode] = useState<string | null>(null);
    const [countyId, setCountyId] = useState<number | null>(null);
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
    const [requirementId, setRequirementId] = useState(RequirementId);
    const [selection, setSelection] = useState<Selection>({ selectedState: states[1], });
    const [addressError, setAddressError] = React.useState<boolean>(false);
    const [showVerifyDialog, setShowVerifyDialog] = React.useState<boolean>(false);
    const [selectedAddress, setSelectedAddress] = useState<TranReq | null>(null); 
    const allAddresses = [...InputValues, RegAddress, CustomerAddress];
    const filterOptions = (options: State[], { inputValue }: { inputValue: string }) => {
        const filtered = options.filter((option) =>
            option.StateAbbreviation.toLowerCase().includes(inputValue.toLowerCase())
        );
        return filtered;
    };
    const filteredAddresses = allAddresses.filter((option, index, self) =>
        index === self.findIndex(
            (o) =>
                o.Attention === option.Attention &&
                o.Data === option.Data &&
                o.StreetAddress === option.StreetAddress
        )
    );
    const formatShippingAddress = () => {
        const state = selection.selectedState ? selection.selectedState.StateAbbreviation : '';
        if (recipient && address1 && city && state && zipCode && phoneNumber) {
            const formattedAddress = (
                <>
                    {attentionTo}
                    <br />
                    {recipient}
                    <br />
                    {address1}{address2 ? `, ${address2}` : ''}
                    <br />
                    {city}, {state} {zipCode}
                    <br />
                    {phoneNumber}
                </>
            );
            return formattedAddress;
        }
        return '';
    };

    const handleReqId = () => {
        return requirementId != null;
    }

    const handleErrorClose = async () => {
        setAddressError(false);
    }

    const handleSubmit = async () => {
        setShowVerifyDialog(true);
        await verifyAddress()
    };

    const handleEditAddress = async () => {
        if (RequirementId === undefined) {
            console.error("RequirementId is undefined. Cannot find address data.");
            return;
        }
        const address = InputValues[RequirementId];

        if (!address) {
            console.error(`No address found for RequirementId: ${RequirementId}`);
            return;
        }
        else {

            if (address) {
                setAttentionTo(address.Attention);
                setRecipient(address.Data);
                setAddress1(address.StreetAddress);
                setAddress2(address.AddressLine2);
                setCity(address.City);
                setSelection(prevSelection => ({
                    ...prevSelection,
                    selectedState: {
                        StateName: address.StateName ?? "",
                        StateId: address.StateId ?? 0,
                        StateAbbreviation: address.StateAbrv ?? "",
                        CountryId: null
                    }
                }));
                setZipCode(address.ZipCode);
                setPhoneNumber(address.Phone);
            } else {
                console.error("No address found for RequirementId:", RequirementId);
            }
        }
    };
    const handleAddressSelection = (event: any, selectedValue: TranReq | null) => {
        if (selectedValue) {
            setSelectedAddress(selectedValue);
            setAttentionTo(selectedValue.Attention);
            setRecipient(selectedValue.Data);
            setAddress1(selectedValue.StreetAddress);
            setAddress2(selectedValue.AddressLine2);
            setCity(selectedValue.City);
            setZipCode(selectedValue.ZipCode);
            setPhoneNumber(selectedValue.Phone);
            setSelection({
                selectedState: {
                    StateId: selectedValue.StateId || 0,
                    StateName: selectedValue.StateName || "",
                    StateAbbreviation: selectedValue.StateAbrv || "",
                    CountryId: null
                },
            });
        } else {
            // Reset form when no address is selected
            setSelectedAddress(null);
            setAttentionTo(null);
            setRecipient(null);
            setAddress1(null);
            setAddress2(null);
            setCity(null);
            setZipCode(null);
            setPhoneNumber(null);
        }
    };

    //populate states dropdown and check for editing
    useEffect(() => {
        const fetchData = async () => {
            try {
                const statesData = await getStates();
                setStates(statesData);
                if (Editing) {
                    await handleEditAddress();
                }
            } catch (error) {
                console.error("An error occurred during data fetching:", error);
            }
        };

        fetchData();
        return () => { };
    }, []);

    //enable submit button check
    useEffect(() => {
        if (recipient != null && address1 != null && city != null && zipCode != null && phoneNumber != null && selection.selectedState != undefined) {
            if (recipient.trim() != '' && address1.trim() != '' && city.trim() != '' && zipCode.trim() != '' && phoneNumber.trim() != '') {
                setDisableSubmit(false);
            }
            else {
                setDisableSubmit(true);
            }
        }
        else {
            setDisableSubmit(true);
        }
    }, [attentionTo, recipient, address1, city, zipCode, phoneNumber, selection.selectedState]);

    const verifyAddress = async () => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                address1: address1,
                city: city,
                state: selection.selectedState?.StateAbbreviation,
                zipCode: zipCode
            }),
        };
        const url = `${window.location.origin}/CreateOrders/VerifyAddress`;

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                console.error("Verify address response failure:", response.status, response.statusText);
                console.error("Request URL:", response.url);
                console.error("Response Headers:", response.headers);
            } else {
                const data = await response.json();
                if (data.changesMade) {
                    setAddress1(data.address1);
                    setCity(data.city);
                    setSelection({
                        ...selection,
                        selectedState: {
                            StateId: data.stateId,
                            StateName: data.stateName,
                            StateAbbreviation: data.stateAbbreviation,
                            CountryId: null
                        },
                    });
                    setZipCode(data.zipCode);
                    setCountyId(data.county);
                    onSubmit({
                        attentionTo,
                        recipient,
                        address1: data.address1,
                        address2,
                        city: data.city,
                        stateId: data.stateId,
                        stateName: data.stateName,
                        stateAbrv: data.stateAbbreviation,
                        zipCode: data.zipCode,
                        countyId: data.county,
                        phoneNumber,
                        requirementId
                    });
                    await setShowVerifyDialog(false);
                }
                else {
                    if (data.verifyFailed) {
                        console.error("verifyFailed");
                        setAddressError(true);
                        setShowVerifyDialog(false);
                    }
                    else {
                        await setCountyId(data.county);
                        onSubmit({
                            attentionTo,
                            recipient,
                            address1,
                            address2,
                            city,
                            stateId: selection.selectedState?.StateId,
                            stateName: selection.selectedState?.StateName,
                            stateAbrv: selection.selectedState?.StateAbbreviation,
                            zipCode,
                            countyId: data.county,
                            phoneNumber,
                            requirementId
                        });
                        await setShowVerifyDialog(false);                       
                    }
                }
                await setShowVerifyDialog(false);
            }
        } catch (error: any) {
            console.error('An error occurred during address verification tsx:', error);
            console.error('Error message:', error.message);
            console.error('Stack trace:', error.stack);
            return false;
        }
    };
   
    const getStates = (): Promise<State[]> => {
        const requestOptions = {
            method: "GET",
        };

        const url = window.location.origin + "/CreateOrders/GetStates";

        return fetch(url, requestOptions)
            .then((res) => res.json())
            .then((result: GetDropDowns) => result.states)
            .catch((error) => {
                console.error("error get states dropdown");
                console.error("error:", error);
                return [];
            });
    };

    return (
        <Zoom in={handleReqId()}>
            <div style={{ width: '502px', margin: '20px' }}>
                <EntryGrid>
                    <Grid sx={{ gridArea: 'title', margin: '20px 0px' }}>
                        <RenewalTitle>Enter Address Information</RenewalTitle>
                    </Grid>
                    <Grid sx={{ gridArea: 'attentionTitle', margin: '0px 0px 0px 0px' }} >
                        <OrderFieldTitle>Attention To</OrderFieldTitle>
                    </Grid>
                    <Grid sx={{ gridArea: 'attention', margin: '10px 0px 0px 0px' }}>
                        <FormControl required>
                            <GreyOutlineTextField
                                placeholder="First and Last Name"
                                value={attentionTo || ''}
                                name="name"
                                required
                                sx={{ marginRight: '23px', width: '302px' }}
                                onChange={(e) => {
                                    const newAttention = e.target.value;
                                    if (newAttention.length <= 80) { // limit attn to 80 digits
                                        setAttentionTo(newAttention);
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ gridArea: 'addressTitle', margin: '10px 0px 0px 0px' }} >
                        <OrderFieldTitle>Reuse Address</OrderFieldTitle>
                    </Grid>
                    <Grid sx={{ gridArea: 'addressDropdown', margin: '10px 0px 0px 0px' }}>
                        <FormControl required>
                            <Autocomplete
                                options={filteredAddresses.filter((option: TranReq) => option.StreetAddress)}
                                getOptionLabel={(option: TranReq) => {
                                    const attention = option.Attention ? `${option.Attention} ` : '';
                                    return `${attention}${option.Data ? `${option.Data} ` : ''}${option.StreetAddress || ''}, ${option.City || ''}, ${option.StateAbrv || ''} ${option.ZipCode || ''}`;
                                }}
                                value={selectedAddress}
                                sx={{ marginRight: '23px', width: '166px' }}
                                onChange={handleAddressSelection}
                                renderInput={(params) => <GreyOutlineTextField {...params} required />}
                            />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ gridArea: 'recTitle', margin: '10px 0px 0px 0px' }} >
                        <OrderFieldTitle>Recipient Name</OrderFieldTitle>
                    </Grid>
                    <Grid sx={{ gridArea: 'rec', margin: '10px 0px 0px 0px' }}>
                        <FormControl required>
                            <GreyOutlineTextField
                                placeholder="Company Name"
                                value={recipient || ''}
                                name="name"
                                required
                                sx={{ marginRight: '23px', width: '302px' }}
                                onChange={(e) => {
                                    const newShipName = e.target.value;
                                    if (newShipName.length <= 80) { // limit name to 80 digits
                                        setRecipient(newShipName);
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ gridArea: 'regTitle', margin: '10px 0px 0px 0px' }} >
                        <OrderFieldTitle>Recipient Address</OrderFieldTitle>
                    </Grid>
                    <Grid sx={{ gridArea: 'reg', margin: '10px 0px 0px 0px' }}>
                        <FormControl required>
                            <GreyOutlineTextField
                                placeholder="1000 Street Rd"
                                value={address1 || ''}
                                name="address"
                                required
                                sx={{ marginRight: '23px', width: '302px' }}
                                onChange={(e) => {
                                    const newAddress1 = e.target.value;
                                    if (newAddress1.length <= 80) { // limit addr1 to 80 digits
                                        setAddress1(newAddress1);
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ gridArea: 'regTitle2', margin: '10px 0px 0px 0px' }} >
                        <OrderFieldTitle>Suite (if applicable)</OrderFieldTitle>
                    </Grid>
                    <Grid sx={{ gridArea: 'reg2', margin: '10px 0px 0px 0px', display: 'grid', justifyItems: 'end' }}>
                        <FormControl required>
                            <GreyOutlineTextField
                                placeholder="Apt 10"
                                value={address2 || ''}
                                name="address2"
                                required
                                sx={{ marginRight: '23px', width: '166px' }}
                                onChange={(e) => {
                                    const newAddress2 = e.target.value;
                                    if (newAddress2.length <= 80) { // limit addr2 to 80 digits
                                        setAddress2(newAddress2);
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ gridArea: 'cityTitle', margin: '10px 0px 0px 0px' }} >
                        <OrderFieldTitle>City</OrderFieldTitle>
                    </Grid>
                    <Grid sx={{ gridArea: 'city', margin: '10px 0px 0px 0px' }}>
                        <FormControl required>
                            <GreyOutlineTextField
                                placeholder="St Town"
                                value={city || ''}
                                name="city"
                                required
                                sx={{ width: '223px' }}
                                onChange={(e) => {
                                    const newCity = e.target.value;
                                    if (newCity.length <= 30) { // limit city to 30 digits
                                        setCity(newCity);
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ gridArea: 'stateAbrvTitle', margin: '10px 0px 0px 0px' }} >
                        <OrderFieldTitle>State</OrderFieldTitle>
                    </Grid>
                    <Grid sx={{ gridArea: 'stateAbrv', margin: '10px 0px 0px 0px', display:'grid', justifyContent:'center' }}>
                        <FormControl required>
                            <Autocomplete
                                options={states}
                                id="state"
                                value={selection.selectedState || null}
                                filterOptions={filterOptions}
                                sx={{ width: "68px", cursor: 'pointer', fontFamily: 'InterstateLight' }}
                                getOptionLabel={(option: State) => option.StateAbbreviation}
                                renderInput={(params) => <GreyOutlineTextField {...params} required />}
                                isOptionEqualToValue={(option, value) => option.StateAbbreviation === value?.StateAbbreviation}
                                onChange={(e, newValue) => {
                                    if (newValue) {
                                        setSelection({ ...selection, selectedState: newValue });
                                    }
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.StateId} style={{ fontFamily: 'InterstateLight', fontSize: '10px' }} >
                                            {option.StateAbbreviation}
                                        </li>
                                    );
                                }}
                                autoComplete
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        const inputValue = (e.target as HTMLInputElement).value?.toUpperCase();
                                        const matchedOption = states.find(
                                            (option) => option.StateAbbreviation === inputValue
                                        );
                                        if (matchedOption) {
                                            setSelection({ ...selection, selectedState: matchedOption });
                                        }
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ gridArea: 'zipTitle', margin: '10px 0px 0px 0px' }} >
                        <OrderFieldTitle>Zip</OrderFieldTitle>
                    </Grid>
                    <Grid sx={{ gridArea: 'zip', margin: '10px 0px 0px 0px' }}>
                        <FormControl required>
                            <GreyOutlineTextField
                                placeholder="12345"
                                value={zipCode || ''}
                                name="zip"
                                required
                                sx={{ marginRight: '23px', width: '166px' }}
                                onChange={(e) => {
                                    const newZip = e.target.value;
                                    if (newZip.length <= 5) { // limit zip to 5 digits
                                        setZipCode(newZip);
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ gridArea: 'phoneTitle', margin: '10px 0px 0px 0px' }} >
                        <OrderFieldTitle>Phone Number</OrderFieldTitle>
                    </Grid>
                    <Grid sx={{ gridArea: 'phone', margin: '10px 0px 0px 0px' }}>
                        <FormControl required>
                            <GreyOutlineTextField
                                placeholder="913-236-9923"
                                value={phoneNumber || ''}
                                name="phone"
                                required
                                sx={{ width: '223px' }}
                                onChange={(e) => {
                                    const newPhone = e.target.value;
                                    if (newPhone.length <= 26) { // limit phone to 26 digits
                                        setPhoneNumber(newPhone);
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ gridArea: 'divider', margin: '10px 0px' }}>
                        <Divider sx={{ border: '1px solid #ED7300', width: '502px' }} />
                    </Grid>
                </EntryGrid>
                <SummaryGrid>
                    <Grid sx={{ gridArea: 'shipTo' }}>
                        <Typography sx={{ fontFamily: 'Interstate', fontSize: '13px' }} >
                            Ship To:
                        </Typography>
                    </Grid>
                    <Grid sx={{ gridArea: 'addressSummary' }}>
                        <Zoom in={!disableSubmit}>
                            <Typography sx={{ fontFamily: 'InterstateLight', fontSize: '13px' }} >
                                {formatShippingAddress()}
                            </Typography>
                        </Zoom>
                    </Grid>
                    <Grid sx={{ gridArea: 'backButton', marginTop: '30px' }}>
                        <BackButton
                            label="Back"
                            onClick={handleGoBack}
                            variant="text"
                            startIcon={<ArrowBackIcon sx={{ width: '18px', height: '18px', color: '#ED7300' }} />}
                            disabled={false}
                            sx={{ marginBottom: '20px' }}
                        />
                    </Grid>
                    <Grid sx={{ gridArea: 'nextButton', display: 'grid', justifyContent: 'end', marginTop: '30px' }}>
                        <SubmitButton sx={{ width: '191px' }} disabled={disableSubmit} onClick={handleSubmit}>Save</SubmitButton>
                    </Grid>
                </SummaryGrid>
                <LoadingDialog
                    open={showVerifyDialog}
                    style={{ height: '150px', width: '450px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    sx={{ fontSize: '22px', fontFamily: 'Interstate', marginLeft: '20px' }}
                    message="Verifying Address"
                />
                <OkButtonDialog
                    open={addressError}
                    style={{ height: '150px', width: '580px', textWrap: 'pretty', display: 'grid', margin: '10px', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}
                    sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}
                    message="Unable to verify address. Please check spelling and try again."
                    close={handleErrorClose}
                />
            </div>
        </Zoom>
    );
};

export default ShipAddressEntry;