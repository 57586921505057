import React, { useEffect, useState, useRef, SetStateAction, Dispatch } from "react";
import { Box, Typography, Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import DataGridAcertus from "../Components/DataGridAcertus";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { UpdateRequirement } from "../PendingOrders/UpdateRequirement";
import { InvoiceDetailPage } from "../InvoiceDetail/InvoiceDetailPage";
import { ToastProps } from '../Types/common';
import { RequirementAnswer } from './Common';

const columns: GridColDef[] = [
    { field: "AcertusOrderId", headerName: "Order ID", width: 134, type: "string" },
    { field: "InvoiceNumber", headerName: "Invoice #", width: 134, type: "string" },
    { field: "Name", headerName: "Customer", width: 175, type: "string" },
    { field: "ClientUnit", headerName: "Unit #", width: 134, type: "string" },
    { field: "WorkState", headerName: "State", width: 134, type: "string" },
    { field: "WorkType", headerName: "Task", width: 134, type: "string" },
    { field: "VIN", headerName: "VIN", width: 175, type: "string" },
    { field: 'RequirementName', headerName: 'Requirement Needed', width: 300, type: "string",
        renderCell: (params) => (
            <>
                {
                    params.row["RequirementDescription"] !== "" && params.row["RequirementDescription"] !== null && params.row["RequirementDescription"] !== undefined
                        ? `${params.value}-${params.row["RequirementDescription"]}`
                        : params.value
                }
            </>
        ),
    },
];

interface PendingOrdersBodyProps {
    rows: Array<any>;
    loading: boolean;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    totalCount: number;
    pendingCount: number;
    waitingForPaperworkCount: number;
    selections: { label: string, choice: string | boolean, id?: number }[];
    setSelections: React.Dispatch<React.SetStateAction<{ label: string, choice: string | boolean, id?: number }[]>>;
    handleApplyFilters: () => void;
    setToast: Dispatch<SetStateAction<ToastProps>>;
    handleCancelLoading: () => void;
}

const PendingOrdersBody: React.FC<PendingOrdersBodyProps> = ({ rows, loading, page, setPage, pageSize, setPageSize, totalCount, selections, setSelections, pendingCount, waitingForPaperworkCount, handleApplyFilters, setToast, handleCancelLoading }) => {
    const [reqDialogOpen, setReqDialogOpen] = useState(false);
    const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
    const isFirstRender = useRef(true);
    const [selectedTransaction, setSelectedTransaction] = useState<{
        invoiceNumber: number;
        transactionRequirementId: number;
        hasLookupValue: boolean;
        currentRequirementValue: string;
    } | null>(null);

    const handleDialogClose = () => {
        setReqDialogOpen(false);
        setInvoiceDialogOpen(false);
        setSelectedTransaction(null);
    };

    const CloseIconAcertus = () => (
        <img src="../../React/svgs/CloseIconAcertus.svg" alt="Close Icon" />
    );

    const handleRowClick = async (params: GridRowParams) => {
        const transactionRequirementId = params.row["TransactionRequirementId"];
        const hasLookupValue = params.row["HasLookupValue"];
        const currentRequirementValue = params.row["RequirementDescription"];
        const invoiceNumber = params.row["InvoiceNumber"];

        setSelectedTransaction({
            transactionRequirementId,
            hasLookupValue,
            currentRequirementValue,
            invoiceNumber
        });
        setReqDialogOpen(true);
    };

    const pageUpdate = async (pageNumber) => {
        if (page !== pageNumber) {
            setPage(pageNumber);
            setSelections(prevSelections => {
                const newSelections = prevSelections.filter(selection => selection.label !== 'Page');
                return [...newSelections, { label: 'Page', choice: pageNumber }];
            });
        }
    };

    const pageSizeUpdate = async (pageAmount) => {
        if (pageSize !== pageAmount) {
            setPageSize(pageAmount);
            setSelections(prevSelections => {
                const newSelections = prevSelections.filter(selection => selection.label !== 'Page Size');
                return [...newSelections, { label: 'Page Size', choice: pageAmount }];
            });
        }
    };

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false; //stops apply filters from happening when page loads
            return;
        }
        if (selections.some(selection => selection.label === 'Page' || selection.label === 'Page Size')) {
            handleApplyFilters();
        }
    }, [page, pageSize]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: "wrap", justifyContent: 'flex-start', alignItems: 'flex-start', minHeight: "620px", gap: "20px" }}>
            <Box sx={{ display: 'inline-flex', flexDirection: 'row', flexWrap: "nowrap", justifyContent: 'space-between', alignItems: 'center', width: "80vw", minHeight: "26px", gap: "20px" }}>
                <Box sx={{ display: 'inline-flex', flexDirection: 'row', flexWrap: "wrap", justifyContent: 'flex-start', alignItems: 'center', minHeight: "26px", gap: "20px", marginLeft: "30px" }}>
                    <Typography color="#333E48" fontWeight="bold" sx={{ padding: "0px", fontSize: '12px' }}>Pending: <span style={{ fontWeight: 'normal' }}>{pendingCount.toLocaleString()}</span></Typography>
                    <Typography color="#333E48" fontWeight="bold" sx={{ padding: "0px", fontSize: '12px' }}>Waiting For Paperwork: <span style={{ fontWeight: 'normal' }}>{waitingForPaperworkCount.toLocaleString()}</span></Typography>
                </Box>
                <Box sx={{ display: 'inline-flex', flexDirection: 'row', flexWrap: "wrap", justifyContent: 'flex-end', alignItems: 'center', minHeight: "26px", gap: "20px" }}>
                    <Typography color="#333E48" fontWeight="bold" sx={{ padding: "0px", fontSize: '13px' }}>Total: {totalCount.toLocaleString()}</Typography>
                </Box>
            </Box>
            <DataGridAcertus
                width="80vw"
                maxHeight="600px"
                minHeight="300px"
                columns={columns}
                rows={rows}
                loading={loading}
                page={page}
                pageSize={pageSize}
                rowCount={totalCount}
                onPageChange={(newPage) => pageUpdate(newPage)}
                onPageSizeChange={(newPageSize) => pageSizeUpdate(newPageSize)}
                pageSizeOptions={[15, 30, 50, 100]}
                onRowClick={handleRowClick}
                handleCancelLoading={handleCancelLoading}
            />
            <Dialog open={reqDialogOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <IconButton
                        onClick={handleDialogClose}
                        sx={{
                            position: 'absolute',
                            right: 30,
                            top: 8,
                        }}
                    >
                        <CloseIconAcertus />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {selectedTransaction &&
                        <UpdateRequirement setInvoiceDialogOpen={setInvoiceDialogOpen} setReqDialogOpen={setReqDialogOpen} invoiceNumber={selectedTransaction.invoiceNumber}
                            transactionRequirementId={selectedTransaction.transactionRequirementId} hasLookupValue={selectedTransaction.hasLookupValue}
                            currentRequirementValue={selectedTransaction.currentRequirementValue} handleApplyFilters={handleApplyFilters} setToast={setToast} />
                    }
                </DialogContent>
            </Dialog>
            <Dialog open={invoiceDialogOpen} onClose={handleDialogClose} maxWidth="lg" fullWidth>
                <DialogTitle>
                    <IconButton
                        onClick={handleDialogClose}
                        sx={{
                            position: 'absolute',
                            right: 30,
                            top: 8,
                        }}
                    >
                        <CloseIconAcertus />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {selectedTransaction?.invoiceNumber && <InvoiceDetailPage InvoiceNumber={selectedTransaction?.invoiceNumber} />}
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default PendingOrdersBody;
