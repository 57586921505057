import React, { useState, useEffect } from 'react';
import { Typography, NoSsr, styled, Tooltip, Grid, Divider, Dialog, Zoom, LinearProgress } from '@mui/material';
import { ResetGrid,  SubmitButton, RenewalTitle, OrderGrid, OrangeLinearProgress, SuccessGrid, PromptButton } from '../../Shared/Renewals';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import OrangeEndIconButton from '../../Components/OrangeEndIconButton';
import { GreyEdit } from '../../Shared/Renewals';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TranReq, Lookup, NameId } from '../../Types/common';

export const OrderFieldTitle = styled(Typography)({
    color: '#333E48',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    fontFamily: 'InterstateLight',
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis'
});
export const Field = styled(Typography)({
    color: '#333E48',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    fontFamily: 'InterstateLight',
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis'
});
interface Requirements extends Object {
    Name: string;
    RequirementId: number;
    FieldTypeId: number;
    FieldTypeName: string;
    WorktypeId: number;
    HasLookupValue: boolean;
    LookupValues: Lookup[];
    IsDocument: boolean;
    IsExternal: boolean;
}
export const LTSummaryGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'sumTitle sumTitle sumTitle''stateTitle typeTitle .''state workType .''divider divider divider''vinTitle vinTitle edit''vin vin .''vehicleTitle vehicleTitle .''vehicle vehicle vehicle''divider2 divider2 divider2''customerTitle contactTitle edit2''customer contact .''addressTitle addressTitle edit3''address address .''divider3 divider3 divider3''reqTitle reqTitle edit4''req req req''divider4 divider4 divider4''subTitle subTitle edit5''sub sub sub''createButton createButton createButton'",
    gridTemplateColumns: '4fr 3fr .5fr',
})
export const ListGrid = styled(Grid)({
    display: 'grid',
    gridTemplateColumns: '4fr 3.5fr',
    gap: 2,
    margin: '10px 0px 20px 0px',
    alignItems: 'center'
})
export const DocumentName = styled(Typography)({
    color: '#333E48',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
    letterSpacing: '0.5px',
    fontFamily: 'InterstateLight',
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis'
});

export const Summary = ({ handleNext, formData, handleGoBack, handleEdit, handleNewOrder, handleClose }) => {
    const [disableButton, setDisableButton] = React.useState<boolean>(true);
    const [vin, setVIN] = useState(formData?.vehicle?.vin ?? null);
    const [year, setYear] = useState(formData?.vehicle?.year ?? null);
    const [model, setModel] = useState(formData?.vehicle?.model ?? null);
    const [make, setMake] = useState(formData?.vehicle?.make ?? null);
    const [showOrderBox, setShowOrderBox] = React.useState<boolean>(false);
    const [invoiceNumber, setInvoiceNumber] = useState<number | null>(null);
    const [defaultSuccess, setDefaultSuccess] = React.useState<boolean>(false);
    const [createSuccess, setCreateSuccess] = React.useState<boolean>(false);
    const [contact, setContact] = useState(formData?.reqBy?.contact ?? null);
    const [regName, setRegName] = useState(formData?.reg?.regName ?? null);
    const [regAddress1, setRegAddress1] = useState(formData?.reg?.regAddress1 ?? null);
    const [regAddress2, setRegAddress2] = useState(formData?.reg?.regAddress2 ?? null);
    const [regCity, setRegCity] = useState(formData?.reg?.regCity ?? null);
    const [regZip, setRegZip] = useState(formData?.reg?.regZip ?? null);
    const [regState, setRegState] = useState(formData?.reg?.selection?.selectedState?.StateAbbreviation ?? null);
    const [workType, setWorkType] = useState(formData?.reqs?.workType?.Name ?? null);
    const [typeElementId, setTypeElementId] = React.useState<NameId | null>(formData?.reqs?.typeElement.Id ?? null);
    const [custName, setCustName] = useState(formData?.reqBy?.custName ?? null);
    const [customerId, setCustomerId] = useState(formData?.reqBy?.custId ?? null);
    const [contactName, setContactName] = useState(formData?.reqBy?.contactName ?? null);
    const [contactId, setContactId] = useState(formData?.reqBy?.contact ?? null);
    const [enable, setEnable] = React.useState<boolean>(false);
    const [requirements, setRequirements] = React.useState<Requirements[]>(formData?.reqs?.requirements || []);
    const [requirementValues, setRequirementValues] = React.useState<TranReq[]>(formData?.reqs?.requirementValues || []);
    const [error, setError] = useState<string | null>();
    const [errorDialog, setErrorDialog] = React.useState<boolean>(false);
    //const [selections, setSelections] = useState<{ label: string, choice: string | boolean, id?: number }[]>

    useEffect(() => {
        const fetchData = async () => {
            try {
                setEnable(true);
            } catch (error) {
                console.error("An error occurred during fetch data:", error);
            }
        };

        fetchData();
        return () => { };
    }, []);

    const handleViewInvoiceClick = (customerId, custName, typeElementId, workType, contactId, contactName) => {

        const updatedSelections = [
            { label: "Customer", choice: custName, id: customerId },
            { label: "Task", choice: workType, id: typeElementId },
            { label: "Users", choice: contactName, id: contactId }
        ];
        
        sessionStorage.setItem('pendingCreateOrderSelections', JSON.stringify(updatedSelections));
        window.location.href = '/PendingOrders';
    };
    const handleErrorAck = () => {
        setErrorDialog(false);
    }
    const handleError = () => {
        setErrorDialog(true);
    }
    const formatShippingAddress = (address) => {
        const formattedAddress = (
            <>
                {address.Attention && (
                    <>
                        {address.Attention}
                        <br />
                    </>
                )}
                {address.Data}
                {address.StreetAddress && (
                    <>
                        <br />
                        {address.StreetAddress}{address.AddressLine2 !== null && `, ${address.AddressLine2}`}
                    </>
                )}
                {(address.City || address.StateAbrv || address.ZipCode) && (
                    <>
                        <br />
                        {address.City}, {address.StateAbrv} {address.ZipCode}
                    </>
                )}
                {address.Phone && (
                    <>
                        <br />
                        {address.Phone}
                    </>
                )}
            </>
        );

        return formattedAddress;
    };

    const formatRegAddress = () => {
        const formattedAddress = (
            <>
                {regName}
                <br />
                {regAddress1}{regAddress2 !== null && `, ${regAddress2}`}
                <br />
                {regCity}, {regState} {regZip}
            </>
        );
        return formattedAddress;
    };

    const handleCreateLTOrder = async () => {
        setShowOrderBox(true);
        await createInvoice()
        //addDefaults
        //createTranHistory
    }
    const createInvoice = async () => {

        const url = `${window.location.origin}/CreateOrders/CreateLTInvoice`;
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                vin: formData.vehicle?.vin,
                vehicleYear: formData.vehicle?.year,
                vehicleMake: formData.vehicle?.make,
                vehicleModel: formData.vehicle?.model,
                contact: {
                    Id: formData.reqBy?.contact,
                    Name: formData.reqBy?.contactName
                },
                customer: {
                    Id: formData.reqBy?.custId,
                    Name: formData.reqBy?.custName
                },
                state: {
                    Id: formData.reg.selection?.selectedState?.StateId,
                    Name: formData.reg.selection?.selectedState?.StateName
                },
                workTypeId: formData.reqs?.workType?.Id,
                typeElement: {
                    Id: formData.reqs?.typeElement?.Id,
                    Name: formData.reqs?.typeElement?.Name
                },
                garagedAddress: {
                    Attention: null,
                    Name1: formData.reg?.regName,
                    Phone: null,
                    Address1: formData.reg?.regAddress1,
                    Address2: formData.reg?.regAddress2,
                    City: formData.reg?.regCity,
                    County: formData.reg?.regCountyName,
                    State: formData.reg?.selection.selectedState.StateName,
                    Zip: formData.reg?.regZip,
                    CityId: null,
                    CountyId: formData.reg?.regCounty,
                    StateId: formData.reg?.selection.selectedState.StateId,
                    CountryId: 1,
                    ShipViaId: null,
                    AirbillNumber: null
                },
                requirements: formData.reqs?.requirements,
                fees: formData.reqs?.transaction?.Fees,
                forms: formData.reqs?.transaction?.Forms,
                procedures: formData.reqs?.transaction?.Procedures,
                integrityHash: formData.reqs?.transaction?.IntegrityHash

            }),
        };
        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                const errorMessage = `Failed to create LT invoice. Status: ${response.status} ${response.statusText}`;
                const responseBody = await response.text();
                console.error(errorMessage, responseBody);
                console.error("Request URL:", url);
                console.error("Request Options:", requestOptions);
                await setError("Error occurred. Please try again.");
                await handleError();
                await setShowOrderBox(false);
            } else {
                const data = await response.json();
                if (data.success) {
                    const tranid = data.TransactionId
                    const worktype = data.Worktype
                    setInvoiceNumber(tranid);
                    setCreateSuccess(true);
                }
                else {
                    await setError("Error occurred. Please try again.");
                    await handleError();
                    await setShowOrderBox(false);
                }
            }
        } catch (error) {
            console.error('An error occurred during creating invoice:', error);
            return false;
        }
    };
    return (
        <NoSsr>
            <Zoom in={enable}>
                <div style={{ width: '502px' }}>
                    <LTSummaryGrid>
                        <Grid sx={{ gridArea: 'sumTitle', margin: '20px 0px 0px 0px' }} >
                            <RenewalTitle>Summary</RenewalTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'stateTitle', margin: '20px 0px 0px 0px' }} >
                            <OrderFieldTitle>State</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'state', margin: '10px 0px 20px 0px' }} >
                            <Field>{regState}</Field>
                        </Grid>
                        <Grid sx={{ gridArea: 'typeTitle', margin: '20px 0px 0px 0px' }} >
                            <OrderFieldTitle>WorkType</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'workType', margin: '10px 0px 20px 0px' }} >
                            <Field>{workType}</Field>
                        </Grid>
                        <Grid sx={{ gridArea: 'divider', margin: '0px 0px 0px 0px' }}>
                            <Divider sx={{ border: '1px solid #ED7300', width: '502px' }} />
                        </Grid>
                        <Grid sx={{ gridArea: 'vinTitle', margin: '20px 0px 0px 0px' }} >
                            <OrderFieldTitle>VIN</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'edit', display: 'grid', alignItems: 'center', margin: '20px 0px 0px 0px' }}>
                            <Tooltip TransitionComponent={Zoom} enterDelay={1000} leaveDelay={200} title={<span style={{ fontSize: '12px' }}>Click to edit the vehicle information</span>} placement="right">
                                <GreyEdit onClick={() => handleEdit(7)} />
                            </Tooltip>
                        </Grid>
                        <Grid sx={{ gridArea: 'vin', margin: '10px 0px 0px 0px' }} >
                            <Field>{vin}</Field>
                        </Grid>
                        <Grid sx={{ gridArea: 'vehicleTitle', margin: '20px 0px 0px 0px' }} >
                            <OrderFieldTitle>Vehicle</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'vehicle', margin: '10px 0px 20px 0px' }} >
                            <Field>{`${year} ${make} ${model}`}</Field>
                        </Grid>
                        <Grid sx={{ gridArea: 'divider2', margin: '0px 0px 0px 0px' }}>
                            <Divider sx={{ border: '1px solid #ED7300', width: '502px' }} />
                        </Grid>
                        <Grid sx={{ gridArea: 'customerTitle', margin: '20px 0px 0px 0px' }} >
                            <OrderFieldTitle>Customer</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'contactTitle', margin: '20px 0px 0px 0px' }} >
                            <OrderFieldTitle>Contact</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'edit2', display: 'grid', alignItems: 'center', margin: '20px 0px 0px 0px' }}>
                            <Tooltip TransitionComponent={Zoom} enterDelay={500} leaveDelay={100} title={<span style={{ fontSize: '12px' }}>Click to edit the contact information</span>} placement="right">
                                <GreyEdit onClick={() => handleEdit(7)} />
                            </Tooltip>
                        </Grid>
                        <Grid sx={{ gridArea: 'customer', margin: '10px 0px 0px 0px' }} >
                            <Field>{custName}</Field>
                        </Grid>
                        <Grid sx={{ gridArea: 'contact', margin: '10px 0px 0px 0px' }} >
                            <Field>{contactName}</Field>
                        </Grid>
                        <Grid sx={{ gridArea: 'addressTitle', margin: '20px 0px 0px 0px' }} >
                            <OrderFieldTitle>Registration Address</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'divider3', margin: '0px 0px 0px 0px' }}>
                            <Divider sx={{ border: '1px solid #ED7300', width: '502px' }} />
                        </Grid>
                        <Grid sx={{ gridArea: 'edit3', display: 'grid', alignItems: 'center', margin: '20px 0px 0px 0px' }}>
                            <Tooltip TransitionComponent={Zoom} enterDelay={500} leaveDelay={100} title={<span style={{ fontSize: '12px' }}>Click to edit the address</span>} placement="right">
                                <GreyEdit onClick={() => handleEdit(7)} />
                            </Tooltip>
                        </Grid>
                        <Grid sx={{ gridArea: 'address', margin: '10px 0px 20px 0px' }} >
                            <Field>{formatRegAddress()}</Field>
                        </Grid>
                        <Grid sx={{ gridArea: 'reqTitle', margin: '20px 0px 0px 0px' }} >
                            <OrderFieldTitle>Requirements</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'divider4', margin: '0px 0px 0px 0px' }}>
                            <Divider sx={{ border: '1px solid #ED7300', width: '502px' }} />
                        </Grid>
                        <Grid sx={{ gridArea: 'edit4', display: 'grid', alignItems: 'center', margin: '20px 0px 0px 0px' }}>
                            <Tooltip TransitionComponent={Zoom} enterDelay={500} leaveDelay={100} title={<span style={{ fontSize: '12px' }}>Click to edit the requirements</span>} placement="right">
                                <GreyEdit onClick={() => handleEdit(8)} />
                            </Tooltip>
                        </Grid>
                        <ListGrid sx={{ gridArea: 'req' }}>
                            {Array.isArray(requirements) && requirements.filter(requirement => !requirement.IsDocument && requirement.IsExternal).map(requirement => (
                                <React.Fragment key={requirement.RequirementId}>
                                    <Grid item sx={{ margin: '10px 0px 0px 0px' }}>
                                        <OrderFieldTitle>{requirement.Name}</OrderFieldTitle>
                                    </Grid>
                                    <Grid item sx={{ margin: '10px 0px 0px 0px' }}>
                                        {requirement.FieldTypeId >= 7 && requirement.FieldTypeId <= 13 && requirement.FieldTypeId != 5 ? (       
                                            <Field>
                                                {formatShippingAddress(formData.reqs?.requirementValues[requirement.RequirementId] || {})}
                                            </Field>
                                        ) : (
                                            <Field>
                                                {formData.reqs?.requirementValues[requirement.RequirementId]?.Data || 'N/A'}
                                            </Field>
                                        )}
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </ListGrid>
                        <Grid sx={{ gridArea: 'subTitle', margin: '20px 0px 0px 0px' }} >
                            <OrderFieldTitle>Submissions</OrderFieldTitle>  
                        </Grid>
                        <Grid sx={{ gridArea: 'edit5', display: 'grid', alignItems: 'center', margin: '20px 0px 0px 0px' }}>
                            <Tooltip TransitionComponent={Zoom} enterDelay={500} leaveDelay={100} title={<span style={{ fontSize: '12px' }}>Click to edit the submissions</span>} placement="right">
                                <GreyEdit onClick={() => handleEdit(9)} />
                            </Tooltip>
                        </Grid>
                        <ListGrid sx={{ gridArea: 'sub' }}>
                            {Array.isArray(formData.reqs?.requirements) && formData.reqs?.requirements.filter(requirement => requirement.IsDocument && requirement.IsExternal).map((requirement, index) => (
                                <React.Fragment key={requirement.RequirementId}>
                                    <Grid item sx={{ margin: '10px 0px 0px 0px' }}>
                                        <DocumentName>{`${index + 1}. ${requirement.Name}`}</DocumentName>
                                    </Grid>
                                    <Grid item sx={{ margin: '10px 0px 0px 0px', display: 'grid', justifyContent: 'end' }}>
                                        {formData.sub?.requirementValues[requirement.RequirementId]?.Data === "YES" && formData.sub?.requirementValues[requirement.RequirementId]?.DocumentUploaded && formData.sub?.requirementValues[requirement.RequirementId]?.IsExternal ? (
                                            <div style={{display:'flex', alignItems:'center'}}>
                                                <Field>{formData.sub?.requirementValues[requirement.RequirementId]?.DocumentName}</Field>
                                                <CheckCircleIcon sx={{ color: '#32B83D', fontSize: '24px', cursor: 'pointer', marginLeft:'5px' }} />
                                            </div>
                                        ) : (
                                            <Field>Will be mailed to Acertus only</Field>
                                        )}
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </ListGrid>
                        <Grid sx={{ gridArea: 'createButton', margin: '10px 0px 0px 0px' }} >
                            <Tooltip TransitionComponent={Zoom} enterDelay={500} leaveDelay={100} title={<span style={{ fontSize: '12px' }}>Click to create the order</span>} placement="right">
                                <span><OrangeEndIconButton
                                    label={"Create Order"}
                                    onClick={handleCreateLTOrder}
                                    variant="contained"
                                    endIcon={<ArrowForwardIcon sx={{ color: 'white' }} />}
                                    disabled={!disableButton}
                                    sx={{ width: '505px', marginBottom: '20px' }}
                                /></span>
                            </Tooltip>
                        </Grid>
                    </LTSummaryGrid>
                </div>
            </Zoom>
            <Dialog open={showOrderBox}>
                <Grid container style={{ height: '100%', justifyContent: 'center', width: '600px' }}>
                    <OrderGrid item xs={10} style={{ fontSize: '22px', fontFamily: 'Interstate' }}>
                        <Grid sx={{ gridArea: 'logo', display: 'grid', justifyItems: 'center', margin: '30px 0px' }}>
                            <img src="/Content/assets/img/metro/logo/tnr4C.png" width="400px" />
                        </Grid>
                        {!createSuccess && (
                            <Zoom in={showOrderBox}>
                                <Grid sx={{ gridArea: 'pizza', display: 'grid', justifyItems: 'center', margin: '0px 20px', fontSize: '13px' }}>
                                    <LinearProgress />
                                </Grid>
                            </Zoom>
                        )}
                    </OrderGrid>
                    {createSuccess ? (
                        <SuccessGrid item xs={10} style={{ fontSize: '22px', fontFamily: 'Interstate' }}>
                            <Zoom in={createSuccess} >
                                <Grid sx={{ gridArea: 'success', display: 'grid', justifyItems: 'center', margin: '20px 0px 49px' }}>
                                    <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate' }} >Order Received Successfully</Typography>
                                    <Typography sx={{ fontSize: '21px', fontFamily: 'Interstate' }}>Invoice Number: {invoiceNumber}</Typography>
                                </Grid>
                            </Zoom>
                            <Zoom in={createSuccess} style={{ transitionDelay: createSuccess ? '500ms' : '0ms' }}>
                                <Grid sx={{ gridArea: 'newOrder', marginBottom: '20px' }}>
                                    <Tooltip TransitionComponent={Zoom} enterDelay={1000} leaveDelay={200} title={<span style={{ fontSize: '12px' }}>Click here to submit a new order</span>} placement="top">
                                        <PromptButton startIcon={<NavigateBeforeIcon sx={{ color: 'white' }} />} onClick={handleNewOrder}>New Order</PromptButton>
                                    </Tooltip>
                                </Grid>
                            </Zoom>
                            <Zoom in={createSuccess} style={{ transitionDelay: createSuccess ? '500ms' : '0ms' }}>
                                <Grid sx={{ gridArea: 'viewOrder', marginBottom: '20px' }}>
                                    <Tooltip TransitionComponent={Zoom} enterDelay={1000} leaveDelay={200} title={<span style={{ fontSize: '12px' }}>Click here to view invoice details</span>} placement="top">
                                        <PromptButton endIcon={<NavigateNextIcon sx={{ color: 'white' }} />} onClick={() => handleViewInvoiceClick(customerId, custName, typeElementId, workType, contactId, contactName)}>View Invoice</PromptButton>
                                    </Tooltip>
                                </Grid>
                            </Zoom>
                        </SuccessGrid>
                    ) : (
                        <SuccessGrid item xs={10} style={{ fontSize: '22px', fontFamily: 'Interstate' }}>
                            <Grid sx={{ gridArea: 'pizza', margin: '0px 20px', fontSize: '13px' }}>
                                <OrangeLinearProgress />
                            </Grid>
                            <Grid sx={{ gridArea: 'success', display: 'grid', justifyItems: 'center', margin: '20px 0px' }}>
                                <Zoom in={!createSuccess} style={{ transitionDelay: createSuccess ? '500ms' : '0ms' }}>
                                    <Typography sx={{ fontSize: '1em', fontFamily: 'Interstate' }}>Thank you for your submission!</Typography>
                                </Zoom>
                            </Grid>
                        </SuccessGrid>
                    )}
                </Grid>
            </Dialog>
            <Dialog open={errorDialog}>
                <Zoom in={errorDialog}>
                    <div style={{ height: '150px', width: '600px', display: 'grid', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}>
                        <Grid container style={{ height: '100%', justifyContent: 'center' }}>
                            <ResetGrid>
                                <Grid sx={{ gridArea: 'question', marginBottom: '20px' }}>
                                    <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}>{error}</Typography>
                                </Grid>
                                <Grid sx={{ gridArea: 'yesButton' }}>
                                    <SubmitButton onClick={handleErrorAck}>Ok</SubmitButton>
                                </Grid>
                            </ResetGrid>
                        </Grid>
                    </div>
                </Zoom>
            </Dialog>
        </NoSsr>
    );
}