import React, { useState, useEffect, Dispatch, SetStateAction, useRef } from "react";
import { Autocomplete, TextField, Grid, FormControl, createFilterOptions, Typography, styled, Zoom, Divider } from "@mui/material";
import { SubmitButton, FieldLabel, RenewalTitle, SummaryGrid } from '../Shared/Renewals';
import BackButton from '../Components/BackButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingDialog, OkButtonDialog } from '../Components/Dialog';
import { State, TranReq } from '../Types/common';

export const OrderFieldTitle = styled(Typography)({
    color: '#333E48',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    fontFamily: 'Noto Sans, Arial, sans-serif',
});
export const EntryGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'title title title''attentionTitle attentionTitle addressTitle''attention attention addressDropdown''recTitle recTitle .''rec rec rec''regTitle regTitle regTitle2''reg reg reg2''cityTitle stateAbrvTitle zipTitle''city stateAbrv zip''phoneTitle phoneTitle .''phone phone .''divider divider divider'",
    gridTemplateColumns: '4fr 1.8fr 1.8fr',
})
export const GreyOutlineTextField = styled(TextField)({
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#70787F',
        borderWidth: '1px',
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
        fontFamily: 'Noto Sans, Arial, sans-serif',
        fontSize: '12px',
    },
    '& .MuiInputBase-input': {
        fontFamily: 'Noto Sans, Arial, sans-serif',
        fontSize: '12px',
    },
    '& .MuiSelect-select': {
        fontFamily: 'Noto Sans, Arial, sans-serif',
        fontSize: '12px',
    },
    '& .MuiMenuItem-root': {
        fontFamily: 'Noto Sans, Arial, sans-serif',
        fontSize: '12px',
    }
});
interface GetDropDowns {
    states: State[];
}
export interface Selection {
    selectedState: State | null;
}
interface AddressEntryProps {
    setRequirementData: Dispatch<SetStateAction<string | null>>;
    setAddress1: Dispatch<SetStateAction<string | null>>;
    setAddress2: Dispatch<SetStateAction<string | null>>;
    setCity: Dispatch<SetStateAction<string | null>>;
    setStates: Dispatch<SetStateAction<State[]>>;
    setZipCode: Dispatch<SetStateAction<string | null>>;
    requirementData: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    states: State[];
    zipCode: string | null;
    selection: Selection;
    setSelection: Dispatch<SetStateAction<Selection>>;
}
const ShipAddressEntry: React.FC<AddressEntryProps> = ({ setRequirementData, setAddress1, setAddress2, setCity, setStates, setZipCode, requirementData, address1, address2, city, states, zipCode, selection, setSelection }) => {
    
    const [loading, setLoading] = React.useState<boolean>(true);
    const [addressError, setAddressError] = React.useState<boolean>(false);
    const filterOptions = (options: State[], { inputValue }: { inputValue: string }) => {
        const filtered = options.filter((option) =>
            option.StateAbbreviation.toLowerCase().includes(inputValue.toLowerCase())
        );
        return filtered;
    };

    const handleErrorClose = async () => {
        setAddressError(false);
    }

    //populate states dropdown and check for editing
    useEffect(() => {
        const fetchData = async () => {
            try {
                const statesData = await getStates();
                setStates(statesData);
            } catch (error) {
                console.error("An error occurred during data fetching:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        return () => { };
    }, []);

    const getStates = (): Promise<State[]> => {
        const requestOptions = {
            method: "GET",
        };

        const url = window.location.origin + "/CreateOrders/GetStates";

        return fetch(url, requestOptions)
            .then((res) => res.json())
            .then((result: GetDropDowns) => result.states)
            .catch((error) => {
                console.error("error get states dropdown");
                console.error("error:", error);
                return [];
            });
    };

    return (
        <Zoom in={!loading}>
            <div style={{ width: '502px', margin: '20px 0px' }}>
                <EntryGrid>
                    <Grid sx={{ gridArea: 'recTitle', margin: '10px 0px 0px 0px' }} >
                        <OrderFieldTitle>Recipient Name</OrderFieldTitle>
                    </Grid>
                    <Grid sx={{ gridArea: 'rec', margin: '10px 0px 0px 0px' }}>
                        <FormControl required>
                            <GreyOutlineTextField
                                placeholder="Company Name"
                                value={requirementData || ''}
                                name="name"
                                required
                                sx={{ marginRight: '23px', width: '302px' }}
                                onChange={(e) => {
                                    const newShipName = e.target.value;
                                    if (newShipName.length <= 80) { // limit name to 80 digits
                                        setRequirementData(newShipName);
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ gridArea: 'regTitle', margin: '10px 0px 0px 0px' }} >
                        <OrderFieldTitle>Recipient Address</OrderFieldTitle>
                    </Grid>
                    <Grid sx={{ gridArea: 'reg', margin: '10px 0px 0px 0px' }}>
                        <FormControl required>
                            <GreyOutlineTextField
                                placeholder="1000 Street Rd"
                                value={address1 || ''}
                                name="address"
                                required
                                sx={{ marginRight: '23px', width: '302px' }}
                                onChange={(e) => {
                                    const newAddress1 = e.target.value;
                                    if (newAddress1.length <= 80) { // limit addr1 to 80 digits
                                        setAddress1(newAddress1);
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ gridArea: 'regTitle2', margin: '10px 0px 0px 0px' }} >
                        <OrderFieldTitle>Suite (if applicable)</OrderFieldTitle>
                    </Grid>
                    <Grid sx={{ gridArea: 'reg2', margin: '10px 0px 0px 0px', display: 'grid', justifyItems: 'end' }}>
                        <FormControl required>
                            <GreyOutlineTextField
                                placeholder="Apt 10"
                                value={address2 || ''}
                                name="address2"
                                required
                                sx={{ marginRight: '23px', width: '166px' }}
                                onChange={(e) => {
                                    const newAddress2 = e.target.value;
                                    if (newAddress2.length <= 80) { // limit addr2 to 80 digits
                                        setAddress2(newAddress2);
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ gridArea: 'cityTitle', margin: '10px 0px 0px 0px' }} >
                        <OrderFieldTitle>City</OrderFieldTitle>
                    </Grid>
                    <Grid sx={{ gridArea: 'city', margin: '10px 0px 0px 0px' }}>
                        <FormControl required>
                            <GreyOutlineTextField
                                placeholder="St Town"
                                value={city || ''}
                                name="city"
                                required
                                sx={{ width: '223px' }}
                                onChange={(e) => {
                                    const newCity = e.target.value;
                                    if (newCity.length <= 30) { // limit city to 30 digits
                                        setCity(newCity);
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ gridArea: 'stateAbrvTitle', margin: '10px 0px 0px 0px' }} >
                        <OrderFieldTitle>State</OrderFieldTitle>
                    </Grid>
                    <Grid sx={{ gridArea: 'stateAbrv', margin: '10px 0px 0px 0px', display:'grid', justifyContent:'center' }}>
                        <FormControl required>
                            <Autocomplete
                                options={states}
                                id="state"
                                value={selection.selectedState || null}
                                filterOptions={filterOptions}
                                sx={{ width: "68px", cursor: 'pointer', fontFamily: 'Noto Sans, Arial, sans-serif' }}
                                getOptionLabel={(option: State) => option.StateAbbreviation}
                                renderInput={(params) => <GreyOutlineTextField {...params} required />}
                                isOptionEqualToValue={(option, value) => option.StateAbbreviation === value?.StateAbbreviation}
                                onChange={(e, newValue) => {
                                    if (newValue) {
                                        setSelection({ ...selection, selectedState: newValue });
                                    }
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.StateId} style={{ fontFamily: 'Noto Sans, Arial, sans-serif', fontSize: '12px' }} >
                                            {option.StateAbbreviation}
                                        </li>
                                    );
                                }}
                                autoComplete
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        const inputValue = (e.target as HTMLInputElement).value?.toUpperCase();
                                        const matchedOption = states.find(
                                            (option) => option.StateAbbreviation === inputValue
                                        );
                                        if (matchedOption) {
                                            setSelection({ ...selection, selectedState: matchedOption });
                                        }
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ gridArea: 'zipTitle', margin: '10px 0px 0px 0px' }} >
                        <OrderFieldTitle>Zip</OrderFieldTitle>
                    </Grid>
                    <Grid sx={{ gridArea: 'zip', margin: '10px 0px 0px 0px' }}>
                        <FormControl required>
                            <GreyOutlineTextField
                                placeholder="12345"
                                value={zipCode || ''}
                                name="zip"
                                required
                                sx={{ marginRight: '23px', width: '166px' }}
                                onChange={(e) => {
                                    const newZip = e.target.value;
                                    if (newZip.length <= 5) { // limit zip to 5 digits
                                        setZipCode(newZip);
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                </EntryGrid>
                <OkButtonDialog
                    open={addressError}
                    style={{ height: '150px', width: '580px', textWrap: 'pretty', display: 'grid', margin: '10px', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}
                    sx={{ fontSize: '22px', fontFamily: 'Noto Sans, Arial, sans-serif', margin: '20px 0px 0px 20px' }}
                    message="Unable to verify address. Please check spelling and try again."
                    close={handleErrorClose}
                />
            </div>
        </Zoom>
    );
};

export default ShipAddressEntry;