import React, { useEffect, useState } from 'react';
import { Grid, FormControlLabel, styled } from '@mui/material';
import { AskIfAnswers, AskIfQuestions, OrderFieldTitle } from '../Common';
import CircleCheckboxAcertus from '../../Components/CircleCheckboxAcertus';

const QuestionListGrid = styled(Grid)({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr ',
    gap: 2,
    margin: '10px 0px',
    alignItems: 'center',
    '& .MuiFormControlLabel-label': {
        fontFamily: 'InterstateLight',
        fontSize: '13px',
    },
})

interface QuestionListProps {
    macroQuestions: AskIfQuestions[];
    questionAnswers: AskIfAnswers[];
    onQuestionAnswered: Function;
    onAllQuestionsAnswered: Function;
}

const QuestionList: React.FC<QuestionListProps> = ({ macroQuestions, questionAnswers, onQuestionAnswered, onAllQuestionsAnswered }) => {
    useEffect(() => {
        if (Array.isArray(macroQuestions) && macroQuestions.length > 0) {
            const allAnswered = macroQuestions.filter(shouldDisplayQuestion).every(question => questionAnswers[question.AskIfId]?.Answer !== undefined);

            if (allAnswered) {
                onAllQuestionsAnswered();
            }
        }
    }, [questionAnswers, macroQuestions]);

    const handleAnswerChange = (questionId, selectedValue, isChecked) => {
        const newAnswer = isChecked ? (selectedValue === 'YES' ? true : false) : null;
        onQuestionAnswered(questionId, newAnswer);
    };

    const shouldDisplayQuestion = (question) => {
        if (question.AskIfParentId === 0) {
            return true;
        }
        const parentAnswer = questionAnswers[question.AskIfParentId]?.Answer;
        return parentAnswer !== null && parentAnswer === question.ParentAnswerToDisplay;
    };
    return (
        <QuestionListGrid>
            {Array.isArray(macroQuestions) && macroQuestions
                .filter(shouldDisplayQuestion)
                .map((question) => (
                    <React.Fragment key={question.AskIfId}>
                        <Grid sx={{ margin: '10px 20px 0px 0px' }}>
                            <OrderFieldTitle>{question.Question}</OrderFieldTitle>
                        </Grid>
                        <Grid item sx={{ margin: '10px 0px 0px 0px' }}>
                            <Grid sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridTemplateAreas: "'yes no'" }} >
                                <Grid sx={{ gridArea: 'yes', margin: '0px 10px 0px 0px' }}>
                                    <FormControlLabel
                                        control={
                                            <CircleCheckboxAcertus
                                                checked={questionAnswers[question.AskIfId]?.Answer === true}
                                                onChange={(e) => handleAnswerChange(question.AskIfId, 'YES', e.target.checked)}
                                            />
                                        }
                                        label="Yes"
                                    />
                                </Grid>
                                <Grid sx={{ gridArea: 'no', margin: '0px 0px 0px 0px' }}>
                                    <FormControlLabel
                                        control={
                                            <CircleCheckboxAcertus
                                                checked={questionAnswers[question.AskIfId]?.Answer === false}
                                                onChange={(e) => handleAnswerChange(question.AskIfId, 'NO', e.target.checked)}
                                            />
                                        }
                                        label="No"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                ))}
        </QuestionListGrid>
    );
};

export default QuestionList;
