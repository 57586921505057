import React  from 'react';
import { Button, styled } from '@mui/material';

const AcertusButton = styled(Button)({
    color: 'white',
    backgroundColor: '#ED7300',
    fontSize: '14px',
    fontFamily: 'Interstate',
    lineHeight: '20px',
    letterSpacing: '0.17px',
    wordWrap: 'break-word',
    width: '129px',
    height: '36px',
    textTransform: 'none',
    borderRadius: '8px',
    boxShadow:'none',
    '&:hover': {
        backgroundColor: '#ED7300',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        color: '#FFFFFF',
    },
    '&.Mui-disabled': {
        backgroundColor: '#F8C799',
        color: 'white',
        cursor: 'not-allowed',
    },
    '&:focus': {
        border: '2px #A14E00 solid',
    },
    '&:active': {
        backgroundColor: '#C76000',
    },
});

const OrangeEndIconButton = ({ label, onClick, variant, endIcon, disabled, sx }) => {
    return (
        <AcertusButton
            onClick={onClick}
            variant={variant}
            endIcon={endIcon}
            disabled={disabled}
            sx={sx}
        >
            {label}
        </AcertusButton>
    );
};

export default OrangeEndIconButton;