import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { DateUtil } from "../Helpers/DateUtil";
import { TranNotes } from "../Types/common";

export interface EmailHistoryProps extends Object {
    TranNotes: TranNotes[] | [];
}

export const EmailHistory: React.FC<EmailHistoryProps> = ({ TranNotes }) => {
    EmailHistory.displayName = "EmailHistory";
    const [notes, setNotes] = useState<TranNotes[]>([]);

    useEffect(() => {
        if (TranNotes != undefined) {
            setNotes(TranNotes)
        }
    }, [TranNotes])

    const getTransNotesHTML = (): JSX.Element[] => {
        let results: JSX.Element[] = [];
        if (notes.length > 0) {
            notes.forEach(note => {
                results.push((
                    <div className="row">
                        <div className="col-12" style={{ fontFamily: '"Noto Sans", Arial, sans-serif' }}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        Date Sent: {DateUtil.convertJsonDateToShortDate(note.CreatedTime)} Click to Expand
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {note.TranNoteDescription}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                ))
            })
        } else {
            results.push(
                <div className="row" key="docs_id_none">
                    <div className="col-sm-12">
                        <p style={{ fontFamily: '"Noto Sans", Arial, sans-serif' }}>No conversation history available</p>
                    </div>
                </div>
            );
        }

        return results;
    }

        return (
            <div>
                <div className="mmt-card row card">
                    <div className="card-header">
                        <div className="col-sm-12 d-flex justify-content-between align-items-center">
                            <h3 style={{ fontFamily: '"Noto Sans", Arial, sans-serif' }}>Email History</h3>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="col-sm-12">
                            { getTransNotesHTML() }
                        </div>
                    </div>
                </div>
            </div>)
}