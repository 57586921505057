import React from 'react';
import { Dialog, Zoom, Typography, CircularProgress, styled, Grid, Divider, FormControl, FormControlLabel, Checkbox, TextField, Autocomplete } from '@mui/material';
import { SubmitButton, ShippingGrid, FieldLabel, ShipCityGrid, SummaryGrid, GoBackButton } from '../Shared/Renewals';

const CircleLoading = styled(CircularProgress)({
    color: '#ED7300',
});

export const OkButtonDialog = ({ close, open, message, style, sx }) => {
    return (
        <Dialog open={open}>
            <Zoom in={open}>
                <div style={style}>
                    <Typography sx={sx}>{message}</Typography>
                    <SubmitButton onClick={close}>OK</SubmitButton>
                </div>
            </Zoom>
        </Dialog>
    );
};

export const LoadingDialog = ({ open, message, style, sx }) => {
    return (
        <Dialog open={open}>
            <Zoom in={open}>
                <div style={style}>
                    <CircleLoading />
                    <Typography sx={sx}>{message}</Typography>
                </div>
            </Zoom>
        </Dialog>
    );
};
