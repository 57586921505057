import React from "react";
import { Box, Typography } from "@mui/material";
import CheckboxAcertus from "../CheckboxAcertus";

interface ReportItemProps {
    title: string;
    description: string;
    isChecked: boolean;
    onChange: () => void;
}

const ReportItem: React.FC<ReportItemProps> = ({ title, description, isChecked, onChange }) => (
    <Box sx={{ display: "flex", alignItems: "center" }}>
        <CheckboxAcertus isChecked={isChecked} onChange={onChange} />
        <Box>
            <Typography fontWeight="700" color="#333E48" sx={{fontSize:'12px'}}>
                {title}
            </Typography>
            <Typography color="#333E48" sx={{ fontSize: '11px' }}>
                {description}
            </Typography>
        </Box>
    </Box>
);

export default ReportItem;
