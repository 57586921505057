export const getStateInfo = (stateId: string) => {
    let stateInfo = {};
    switch (stateId) {
        case 'AL':
            stateInfo = {
                name: 'Alabama',
                turnTime: '2-4 weeks (County Dependent)',
                titleOwner: 'Lienholder',
                inspection: 'VIN (Original)',
                details: 'Title & Registration',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.revenue.alabama.gov/division/motor-vehicle/'
            };
            break;
        case 'AK':
            stateInfo = {
                name: 'Alaska',
                turnTime: '2-4 weeks',
                titleOwner: 'Owner',
                inspection: 'VIN (Original)',
                details: 'Title & Registration',
                inspectionTwo: 'WAQ (Original)',
                detailsTwo: 'Commercial vehicles',
                inspectionThree: 'Weight Slip (Original)',
                detailsThree: 'Commercial vehicles',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'http://doa.alaska.gov/dmv/'
            };
            break;
        case 'AZ':
            stateInfo = {
                name: 'Arizona',
                turnTime: '4 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Emission (Copy)',
                details: 'Title & Registration',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://azdot.gov/mvd'
            };
            break;
        case 'AR':
            stateInfo = {
                name: 'Arkansas',
                turnTime: '3 weeks',
                titleOwner: 'Lienholder',
                inspection: 'None',
                details: '',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.dfa.arkansas.gov/motor-vehicle/'
            };
            break;
        case 'CA':
            stateInfo = {
                name: 'California',
                turnTime: '3 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Weight (Original)',
                details: 'Commercial vehicles',
                inspectionTwo: 'VIN (Original)',
                detailsTwo: 'Title & Registration',
                inspectionThree: 'Smog (Copy)',
                detailsThree: 'Title & Registration',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.dmv.ca.gov/portal/'
            };
            break;
        case 'CO':
            stateInfo = {
                name: 'Colorado',
                turnTime: '3-4 weeks (County Dependent)',
                titleOwner: 'Lienholder',
                inspection: 'Emission (Copy)',
                details: 'Title & Registration',
                inspectionTwo: 'Weight Slip (Original)',
                detailsTwo: 'Commercial vehicles',
                inspectionThree: 'VIN (Original)',
                detailsThree: 'Title & Registration',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://dmv.colorado.gov/'
            };
            break;
        case 'CT':
            stateInfo = {
                name: 'Connecticut',
                turnTime: '4 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Emission (Copy)',
                details: 'Title & Registration',
                inspectionTwo: 'VIN (Original)',
                detailsTwo: 'Title & Registration',
                inspectionThree: 'Business License (Copy)',
                detailsThree: 'Business owner',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://portal.ct.gov/dmv?language=en_US'
            };
            break;
        case 'DE':
            stateInfo = {
                name: 'Delaware',
                turnTime: '3 weeks',
                titleOwner: 'Lienholder',
                inspection: 'VIN (Original)',
                details: 'Title & Registration',
                inspectionTwo: 'VIN Safety (Original)',
                detailsTwo: '',
                inspectionThree: 'Business License (Copy)',
                detailsThree: 'Business owner',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.dmv.de.gov/'
            };
            break;
        case 'DC':
            stateInfo = {
                name: 'District of Columbia',
                turnTime: 'No data for titles',
                titleOwner: 'Lienholder',
                inspection: 'Safety Inspection (Copy)',
                details: 'Title & Registration',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://dmv.dc.gov/'
            };
            break;
        case 'FL':
            stateInfo = {
                name: 'Florida',
                turnTime: '4 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Weight Slip (Original)',
                details: 'Commercial vehicles',
                inspectionTwo: 'VIN (Copy)',
                detailsTwo: 'Title & Registration',
                inspectionThree: 'FL2290 (Original)',
                detailsThree: 'Commercial vehicles',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.flhsmv.gov/'
            };
            break;
        case 'GA':
            stateInfo = {
                name: 'Georgia',
                turnTime: '2-4 weeks (County Dependent)',
                titleOwner: 'Lienholder',
                inspection: 'Emission (Copy)',
                details: 'Title & Registration',
                inspectionTwo: 'Business License (Copy)',
                detailsTwo: 'Business owner',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://dor.georgia.gov/motor-vehicles'
            };
            break;
        case 'HI':
            stateInfo = {
                name: 'Hawaii',
                turnTime: '4 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Weight Slip (Copy)',
                details: '',
                inspectionTwo: 'Safety Inspection (Original)',
                detailsTwo: 'Title & Registration',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.honolulu.gov/csd/mvinformation.html'
            };
            break;
        case 'ID':
            stateInfo = {
                name: 'Idaho',
                turnTime: '3 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Emission (Copy)',
                details: 'Title & Registration',
                inspectionTwo: 'VIN (Original)',
                detailsTwo: 'Title & Registration',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://itd.idaho.gov/itddmv/'
            };
            break;
        case 'IL':
            stateInfo = {
                name: 'Illinois',
                turnTime: '3 weeks',
                titleOwner: 'Lienholder',
                inspection: 'None',
                details: '',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.ilsos.gov/departments/vehicles/home.html'
            };
            break;
        case 'IN':
            stateInfo = {
                name: 'Indiana',
                turnTime: '4 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Emission (Copy)',
                details: 'Title & Registration',
                inspectionTwo: 'VIN (Copy)',
                detailsTwo: 'Title & Registration',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.in.gov/bmv/'
            };
            break;
        case 'IA':
            stateInfo = {
                name: 'Iowa',
                turnTime: '3-4 weeks (County Dependent)',
                titleOwner: 'Lienholder',
                inspection: 'None',
                details: '',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://iowadot.gov/mvd'
            };
            break;
        case 'KS':
            stateInfo = {
                name: 'Kansas',
                turnTime: '3-4 weeks (County Dependent)',
                titleOwner: 'Lienholder',
                inspection: 'VIN-NVE-1 (Original)',
                details: 'Title & Registration',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.ksrevenue.gov/dovindex.html'
            };
            break;
        case 'KY':
            stateInfo = {
                name: 'Kentucky',
                turnTime: '3-4 weeks (County Dependent)',
                titleOwner: 'Owner',
                inspection: 'VIN (Original)',
                details: 'Title & Registration',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://drive.ky.gov/Vehicles/Pages/default.aspx'
            };
            break;
        case 'LA':
            stateInfo = {
                name: 'Louisiana',
                turnTime: '3 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Safety Inspection (Copy)',
                details: 'Title & Registration',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.expresslane.org/'
            };
            break;
        case 'ME':
            stateInfo = {
                name: 'Maine',
                turnTime: '3 weeks',
                titleOwner: 'Lienholder',
                inspection: 'None',
                details: '',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.maine.gov/sos/bmv/'
            };
            break;
        case 'MD':
            stateInfo = {
                name: 'Maryland',
                turnTime: '4 weeks',
                titleOwner: 'Owner',
                inspection: 'Safety Inspection (Original)',
                details: 'Title & Registration',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://mva.maryland.gov/vehicles/Pages/default.aspx'
            };
            break;
        case 'MA':
            stateInfo = {
                name: 'Massachusetts',
                turnTime: '4 weeks',
                titleOwner: 'Lienholder',
                inspection: 'None',
                details: '',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.mass.gov/orgs/massachusetts-registry-of-motor-vehicles'
            };
            break;
        case 'MI':
            stateInfo = {
                name: 'Michigan',
                turnTime: '3 weeks',
                titleOwner: 'Owner',
                inspection: 'None',
                details: '',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.michigan.gov/sos/vehicle'
            };
            break;
        case 'MN':
            stateInfo = {
                name: 'Minnesota',
                turnTime: 'No data for titles',
                titleOwner: 'Owner',
                inspection: 'None',
                details: '',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://dps.mn.gov/divisions/dvs/Pages/dvs-services.aspx'
            };
            break;
        case 'MS':
            stateInfo = {
                name: 'Mississippi',
                turnTime: '2-4 weeks (County Dependent)',
                titleOwner: 'Lienholder',
                inspection: 'None',
                details: '',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.dor.ms.gov/contact/vehicle-title-and-registration'
            };
            break;
        case 'MO':
            stateInfo = {
                name: 'Missouri',
                turnTime: '4 weeks',
                titleOwner: 'Owner',
                inspection: 'Emission (Copy)',
                details: 'Title & Registration',
                inspectionTwo: 'Safety Inspection (Original)',
                detailsTwo: 'Title & Registration',
                inspectionThree: 'ID/OD/VIN (Copy)',
                detailsThree: 'Title & Registration',
                inspectionFour: 'Tax Waivers (Copy)',
                detailsFour: 'Tax Exempt/Credit',
                stateLink: 'https://dor.mo.gov/motor-vehicle/'
            };
            break;
        case 'MT':
            stateInfo = {
                name: 'Montana',
                turnTime: '2-4 weeks (County Dependent)',
                titleOwner: 'Owner',
                inspection: 'None',
                details: '',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://dojmt.gov/driving/'
            };
            break;
        case 'NE':
            stateInfo = {
                name: 'Nebraska',
                turnTime: '2-4 weeks (County Dependent)',
                titleOwner: 'Lienholder',
                inspection: 'VIN (Original)',
                details: 'Title & Registration',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://dmv.nebraska.gov/services'
            };
            break;
        case 'NV':
            stateInfo = {
                name: 'Nevada',
                turnTime: '4 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Emission (Copy)',
                details: 'Title & Registration',
                inspectionTwo: 'VIN (Original)',
                detailsTwo: 'Title & Registration',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://dmv.nv.gov/'
            };
            break;
        case 'NH':
            stateInfo = {
                name: 'New Hampshire',
                turnTime: '2-4 weeks (County Dependent)',
                titleOwner: 'Lienholder',
                inspection: 'None',
                details: '',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.dmv.nh.gov/'
            };
            break;
        case 'NJ':
            stateInfo = {
                name: 'New Jersey',
                turnTime: '4 weeks',
                titleOwner: 'Lienholder',
                inspection: 'State Annual Inspection (Copy)',
                details: 'Title & Registration',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.nj.gov/mvc/'
            };
            break;
        case 'NM':
            stateInfo = {
                name: 'New Mexico',
                turnTime: '4 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Emission (Copy)',
                details: 'Title & Registration',
                inspectionTwo: 'VIN (Original)',
                detailsTwo: 'Title & Registration',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.mvd.newmexico.gov/'
            };
            break;
        case 'NY':
            stateInfo = {
                name: 'New York',
                turnTime: '3 weeks',
                titleOwner: 'Owner',
                inspection: 'Safety Inspection (Copy)',
                details: 'Title & Registration',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://dmv.ny.gov/'
            };
            break;
        case 'NC':
            stateInfo = {
                name: 'North Carolina',
                turnTime: '4 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Safety Inspection (Copy)',
                details: 'Title & Registration',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.ncdot.gov/dmv/offices-services/online/Pages/default.aspx'
            };
            break;
        case 'ND':
            stateInfo = {
                name: 'North Dakota',
                turnTime: '2-4 weeks (County Dependent)',
                titleOwner: 'Lienholder',
                inspection: 'None',
                details: '',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.dot.nd.gov/divisions/mv/vehicle.htm'
            };
            break;
        case 'OH':
            stateInfo = {
                name: 'Ohio',
                turnTime: '3 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Emission (Copy)',
                details: 'Title & Registration',
                inspectionTwo: 'Out of State Inspection  (Original)',
                detailsTwo: 'State Transfer',
                inspectionThree: 'Bus Inspection (Copy)',
                detailsThree: 'Bus',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.bmv.ohio.gov/'
            };
            break;
        case 'OK':
            stateInfo = {
                name: 'Oklahoma',
                turnTime: '3 weeks',
                titleOwner: 'Owner',
                inspection: 'VIN (Original)',
                details: 'Title & Registration',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://okcars.service.ok.gov/_/'
            };
            break;
        case 'OR':
            stateInfo = {
                name: 'Oregon',
                turnTime: '3 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Emission (Copy)',
                details: 'Title & Registration',
                inspectionTwo: 'VIN (Original)',
                detailsTwo: 'Title & Registration',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.oregon.gov/odot/dmv/pages/index.aspx'
            };
            break;
        case 'PA':
            stateInfo = {
                name: 'Pennsylvania',
                turnTime: '3 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Emission (Copy)',
                details: 'Title & Registration',
                inspectionTwo: 'VIN (Original)',
                detailsTwo: 'Title & Registration',
                inspectionThree: 'Safety Inspection (Copy)',
                detailsThree: 'Title & Registration',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.dmv.pa.gov/VEHICLE-SERVICES/pages/vehicle-services.aspx'
            };
            break;
        case 'RI':
            stateInfo = {
                name: 'Rhode Island',
                turnTime: '3 weeks',
                titleOwner: 'Lienholder',
                inspection: 'VIN (Original)',
                details: 'Title & Registration',
                inspectionTwo: 'Safety Inspection (Copy)',
                detailsTwo: 'Title & Registration',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://dmv.ri.gov/'
            };
            break;
        case 'SC':
            stateInfo = {
                name: 'South Carolina',
                turnTime: '3 weeks',
                titleOwner: 'Lienholder',
                inspection: 'None',
                details: '',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://scdmvonline.com/'
            };
            break;
        case 'SD':
            stateInfo = {
                name: 'South Dakota',
                turnTime: '2-4 weeks (County Dependent)',
                titleOwner: 'Lienholder',
                inspection: 'None',
                details: '',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://dor.sd.gov/individuals/motor-vehicle/'
            };
            break;
        case 'TN':
            stateInfo = {
                name: 'Tennessee',
                turnTime: '2-3 weeks (County Dependent)',
                titleOwner: 'Lienholder',
                inspection: 'Emission (Original)',
                details: 'Title & Registration',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.tn.gov/driver-services.html'
            };
            break;
        case 'TX':
            stateInfo = {
                name: 'Texas',
                turnTime: '2-3 weeks (County Dependent)',
                titleOwner: 'Lienholder',
                inspection: 'Weight Slip (Original)',
                details: 'Commercial vehicles',
                inspectionTwo: 'VIR (Original)',
                detailsTwo: 'Title & Registration',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.txdmv.gov/'
            };
            break;
        case 'UT':
            stateInfo = {
                name: 'Utah',
                turnTime: '3 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Emission (Copy)',
                details: 'Title & Registration',
                inspectionTwo: 'VIN (Copy)',
                detailsTwo: 'Title & Registration',
                inspectionThree: 'Safety Inspection (Copy)',
                detailsThree: 'Title & Registration',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://dmv.utah.gov/'
            };
            break;
        case 'VT':
            stateInfo = {
                name: 'Vermont',
                turnTime: '4 weeks',
                titleOwner: 'Lienholder',
                inspection: 'VIN (Original)',
                details: 'Title & Registration',
                inspectionTwo: 'Safety Inspection (Copy)',
                detailsTwo: 'Title & Registration',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://dmv.vermont.gov/'
            };
            break;
        case 'VA':
            stateInfo = {
                name: 'Virginia',
                turnTime: '3 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Emission (Copy)',
                details: 'Title & Registration',
                inspectionTwo: 'Safety Inspection (Copy)',
                detailsTwo: 'Title & Registration',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.dmv.virginia.gov/'
            };
            break;
        case 'WA':
            stateInfo = {
                name: 'Washington',
                turnTime: '4 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Weight Slip (Original)',
                details: 'Title & Registration',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://dol.wa.gov/'
            };
            break;
        case 'WV':
            stateInfo = {
                name: 'West Virginia',
                turnTime: '4 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Safety Inspection (Copy)',
                details: 'Title & Registration',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://transportation.wv.gov/DMV/Pages/default.aspx'
            };
            break;
        case 'WI':
            stateInfo = {
                name: 'Wisconsin',
                turnTime: '3 weeks',
                titleOwner: 'Lienholder',
                inspection: 'Emission (Copy)',
                details: 'Title & Registration',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://wisconsindot.gov/pages/online-srvcs/external/dmv.aspx'
            };
            break;
        case 'WY':
            stateInfo = {
                name: 'Wyoming',
                turnTime: '2-4 weeks (County Dependent)',
                titleOwner: 'Owner',
                inspection: 'VIN (Original)',
                details: 'Title & Registration',
                inspectionTwo: '',
                detailsTwo: '',
                inspectionThree: '',
                detailsThree: '',
                inspectionFour: '',
                detailsFour: '',
                stateLink: 'https://www.dot.state.wy.us/driverservices'
            };
            break;
        default:
            stateInfo = {
                name: 'TBD'
            };
            break;
    }
    return stateInfo;
};