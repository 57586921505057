import React, { useState } from "react";
import { Box, Typography, IconButton, Divider, Tooltip, Zoom, Snackbar, Alert } from "@mui/material";
import ReportAcertus from "./ReportAcertus";
import ModalAcertus from "../ModalAcertus";
import ReportDetails from "./ReportDetails";
import DownloadReport from "./DownloadReport";
import EventIcon from '@mui/icons-material/Event';
import { ToastProps } from "../../Types/common";

const PlusIconAcertus = () => (
    <img src="../../React/svgs/PlusIconAcertus.svg" alt="Plus Icon" />
);
interface ReportsAcertusProps {
    selections: { label: string, choice: string | boolean, id?: number }[];
    setSelections: React.Dispatch<React.SetStateAction<{ label: string, choice: string | boolean, id?: number }[]>>;
    setBalancesOnlyChecked?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReportsAcertus: React.FC<ReportsAcertusProps> = ({ selections, setSelections, setBalancesOnlyChecked }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<number>(0);
    const [reportId, setReportId] = useState<number>(0);
    const [toast, setToast] = useState<ToastProps>({ show: false, message: "", severity: 'info' });
    const [reportTitle, setReportTitle] = useState<string>("Scheduled Reports");
    const [reportPage, setReportPage] = useState<number>(0);

    const handleDownload = async (reportId: number) => {
        setDownloading(reportId);
        await DownloadReport(reportId, setToast);
        setDownloading(0);
    };

    const handleClose = async () => {
        setOpen(false);
        setReportPage(0);
        setReportTitle("Scheduled Reports");
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                minHeight: '420px',
                width: '320px',
                border: 1,
                borderColor: '#DFE1E3',
                borderRadius: '8px',
                gap: '20px',
            }}
        >
            <Snackbar open={toast.show} autoHideDuration={8000} onClose={() => setToast({ ...toast, show: false })} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity={toast.severity} sx={{ fontSize: '12px', width: '300px' }}>{toast.message}</Alert>
            </Snackbar>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    minHeight: '47px',
                    width: '320px',
                    borderBottom: 1,
                    borderColor: "#DFE1E3",
                }}
            >
                <Typography color="black" fontWeight="300" sx={{ marginLeft: "16px", fontSize:'14px' }}>
                    Reports
                </Typography>
                <Tooltip TransitionComponent={Zoom} enterDelay={200} leaveDelay={500} title={<span style={{ fontSize: '12px' }}>View, Add, or Remove your scheduled reports</span>} placement="top" arrow>
                    <IconButton onClick={() => setOpen(true)} sx={{ marginRight: "16px" }}>
                        <EventIcon sx={{ color: '#ED7300', width: '24px', height: '24px' }} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    minHeight: '47px',
                    width: '320px',
                    gap: '8px',
                }}
            >
                <ReportAcertus
                    onClickDownload={() => handleDownload(1)}
                    headerText="Pending Orders"
                    bodyText="Orders being held for additional items"
                    selections={selections}
                    setSelections={setSelections}
                    downloading={downloading}
                    reportId={1}
                />
                <ReportAcertus
                    onClickDownload={() => handleDownload(2)}
                    headerText="In-Process Orders"
                    bodyText="Orders Sent to State for processing with ETA"
                    selections={selections}
                    setSelections={setSelections}
                    downloading={downloading}
                    reportId={2}
                />
                <ReportAcertus
                    onClickDownload={() => handleDownload(4)}
                    headerText="All Orders by Status"
                    bodyText="All orders, including completed, with balance"
                    selections={selections}
                    setSelections={setSelections}
                    setBalancesOnlyChecked={setBalancesOnlyChecked}
                    downloading={downloading}
                    reportId={4}
                />
                <ReportAcertus
                    onClickDownload={() => handleDownload(5)}
                    headerText="Orders Waiting on Paperwork"
                    bodyText="Orders with Acknowledge status"
                    selections={selections}
                    setSelections={setSelections}
                    downloading={downloading}
                    reportId={5}
                />
            </Box>

            <ModalAcertus open={open} onClose={() => handleClose()} title={reportTitle} paperSx={{ height: reportPage != 0 ? "600px" : "300px", transition: "min-height 1s ease-in-out", }}>
                <ReportDetails setToast={setToast} setOpen={setOpen} setReportTitle={setReportTitle} reportPage={reportPage} setReportPage={setReportPage} />
            </ModalAcertus>
        </Box>
    );
};

export default ReportsAcertus;
