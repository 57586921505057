import React  from 'react';
import { Button, styled } from '@mui/material';

const AcertusButton = styled(Button)({
    height: "36px",
    color: "#FFFFFF",
    backgroundColor: "#ED7300",
    borderRadius: "5px",
    padding: "10px 20px 10px 20px",
    margin: "0px",
    fontFamily: "Noto Sans, Arial, sans-serif",
    fontSize: "12px",
    border: "solid transparent",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    '&:hover': {
        backgroundColor: '#ED7300',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        color: '#FFFFFF',
    },
});

const OrangeHeaderButton= ({ label, onClick, variant, disabled, sx }) => {
    return (
        <AcertusButton
            onClick={onClick}
            variant={variant}
            disabled={disabled}
            sx={sx}
        >
            {label}
        </AcertusButton>
    );
};

export default OrangeHeaderButton;