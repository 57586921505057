import React, { useState, useEffect } from "react";
import { Box, FormControlLabel, Checkbox } from "@mui/material";
import SearchBarAcertus from "../Components/SearchBarAcertus";
import AutocompleteAcertus, { AutocompleteAcertusItem } from "../Components/AutocompleteAcertus";
import DatePickerAcertus from "../Components/DatePickerAcertus";
import CheckboxAcertus from "../Components/CheckboxAcertus";
import { Title } from '../Themes/OneAcertus';
import CancelIcon from '@mui/icons-material/Cancel';
import dayjs, { Dayjs } from 'dayjs';
import OrangeHeaderButton from "../Components/OrangeHeaderButton";
import WhiteHeaderButton from "../Components/WhiteHeaderButton";
import WhiteEndIconButton from "../Components/WhiteEndIconButton";
import SaveIcon from '@mui/icons-material/Save';
interface OrdersHeaderProps {
    selections: { label: string, choice: string | boolean, id?: number }[];
    setSelections: React.Dispatch<React.SetStateAction<{ label: string, choice: string | boolean, id?: number }[]>>;
    loading: boolean;
    handleApplyFilters: () => void;
    changeBalancesOnlyChecked: () => void;
    balancesOnlyChecked: boolean;
    setDefaultCustomer: React.Dispatch<React.SetStateAction<AutocompleteAcertusItem | null>>;
    defaultCustomer: AutocompleteAcertusItem | null;
    showApplyButton: boolean;
    setShowApplyButton: React.Dispatch<React.SetStateAction<boolean>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setShowClearAll: React.Dispatch<React.SetStateAction<boolean>>;
    showClearAll: boolean;
    handleClearAll: () => void;
    startDate: Dayjs | null;
    endDate: Dayjs | null;
    setStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
    setEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

const OrdersHeader: React.FC<OrdersHeaderProps> = ({ selections, setSelections, loading, handleApplyFilters, changeBalancesOnlyChecked, balancesOnlyChecked, setDefaultCustomer, defaultCustomer, showApplyButton, setShowApplyButton, setOpen, setShowClearAll, showClearAll, handleClearAll, startDate, endDate, setStartDate, setEndDate, setSearchTerm }) => {
    const [customers, setCustomers] = useState<AutocompleteAcertusItem[]>([]);
    const [workTypes, setWorkTypes] = useState<AutocompleteAcertusItem[]>([]);
    const [states, setStates] = useState<AutocompleteAcertusItem[]>([]);
    const [statuses, setStatuses] = useState<AutocompleteAcertusItem[]>([]);
    const [etaRange, setETARange] = useState<AutocompleteAcertusItem[]>([]);
    const [isStartDateCleared, setIsStartDateCleared] = useState<boolean>(false);
    const [isEndDateCleared, setIsEndDateCleared] = useState<boolean>(false);
    const [users, setUsers] = useState<AutocompleteAcertusItem[]>([]);

    const onSearchSubmit = async (searchValue: string) => {
        await setSelections(prevSelections => {
            const searchSelectionIndex = prevSelections.findIndex(selection => selection.label === 'Search');

            if (searchSelectionIndex !== -1) {
                const newSelections = [...prevSelections];
                newSelections[searchSelectionIndex] = { ...newSelections[searchSelectionIndex], choice: searchValue };
                return newSelections;
            }
            return [...prevSelections, { label: 'Search', choice: searchValue }];
        });
        await handleApplyFilters();
    };

    const handleChange = (label: string, value: AutocompleteAcertusItem[] | undefined) => {
        if (value) {
            value.forEach(item => {
                if (!selections.some(selection => selection.label === label && selection.id === item.id)) {
                    setSelections(prevSelections => [
                        ...prevSelections,
                        { label: label, choice: item.label, id: item.id }
                    ]);
                }
            });
        }
    };

    const handleDateChange = (label: string, date: Dayjs | null) => {
        if (!date) return;

        const formattedDate = date.format('MM/DD/YYYY');

        if (label === "Start Date") {
            if (endDate && date.isAfter(endDate)) {
                setStartDate(endDate);
                const formattedEndDate = endDate.format('MM/DD/YYYY');
                updateSelection("Start Date", formattedEndDate);
                sessionStorage.setItem("Start Date", JSON.stringify(formattedEndDate));
            } else {
                setStartDate(date);
                updateSelection("Start Date", formattedDate);
                sessionStorage.setItem("Start Date", JSON.stringify(formattedDate));
            }
        }

        if (label === "End Date") {
            if (startDate && date.isBefore(startDate)) {
                setEndDate(startDate);
                const formattedStartDate = startDate.format('MM/DD/YYYY');
                updateSelection("End Date", formattedStartDate);
                sessionStorage.setItem("Start Date", JSON.stringify(formattedStartDate));
            } else {
                setEndDate(date);
                updateSelection("End Date", formattedDate);
                sessionStorage.setItem("Start Date", JSON.stringify(formattedDate));
            }
        }
        setShowApplyButton(true);
    };
    
    const handleETAChange = (label: string, value: AutocompleteAcertusItem[] | undefined) => {
        if (!value || value.length === 0) return;

        const choice = value[0].label;

        if (label === "ETA Range") {
            updateETASelection("ETA Range", choice);
        }
    };

    const updateETASelection = (label: string, choice: string | boolean) => {
        const etaIndex = selections.findIndex(selection => selection.label === label);
        if (etaIndex !== -1) {
            setSelections(prevSelections => {
                const newSelections = [...prevSelections];
                newSelections[etaIndex] = { ...newSelections[etaIndex], choice: choice };
                return newSelections;
            });
        } else {
            setSelections(prevSelections => [...prevSelections, { label: label, choice: choice }]);
        }
    };

    const updateSelection = (label: string, formattedDate: string) => {
        const dateSelectionIndex = selections.findIndex(selection => selection.label === label);
        if (dateSelectionIndex !== -1) {
            setSelections(prevSelections => {
                const newSelections = [...prevSelections];
                newSelections[dateSelectionIndex] = { ...newSelections[dateSelectionIndex], choice: formattedDate };
                return newSelections;
            });
        } else {
            setSelections(prevSelections => [...prevSelections, { label: label, choice: formattedDate }]);
        }
    };

    const handleRemove = (label: string, choice: string | boolean) => {
        setSelections(prevSelections => {
            return prevSelections.filter(selection => {
                return !(selection.label === label && selection.choice === choice);
            });
        });

        if (label === 'Start Date') {
            setStartDate(null);
            setIsStartDateCleared(true);
            setShowApplyButton(true);
        }
        if (label === 'End Date') {
            setEndDate(null);
            setIsEndDateCleared(true);
            setShowApplyButton(true);
        }
    };

    const isCategorySelected = (label: string) => {
        return selections.some(selection => selection.label === label);
    };

    const fetchDropDownDataAsync = async (requestMethod: string, param: number[] | null = null) => {
        let requestOptions;
        let url = window.location.origin + window.location.pathname + requestMethod;

        if (requestMethod === '/GetUsersDropDownAsync' && param !== null) {
            requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(param),
            };
        } else {
            requestOptions = {
                method: "GET",
            };
        }

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Failed to fetch data", error);
            return [];
        }
    };

    const updateDataAsync = async (requestMethod: string, setter: React.Dispatch<React.SetStateAction<AutocompleteAcertusItem[]>>, param: number[] | null = null) => {
        const rawData = await fetchDropDownDataAsync(requestMethod, param);
        const transformedData = rawData.map((item: { Id: number, Label: string }) => ({
            id: item.Id,
            label: item.Label
        }));
        setter(transformedData);

        sessionStorage.setItem(requestMethod, JSON.stringify(transformedData));

        const getDefaultCustomer = async () => {

            if (requestMethod === "/GetCustomersDropDownAsync" && rawData.length > 0) {
                const defaultCustomer = rawData[0];
                setDefaultCustomer({ id: defaultCustomer.Id, label: defaultCustomer.Label });
            }
        }

        await getDefaultCustomer();
    };

    useEffect(() => {
        const operations = [
            { method: "/GetCustomersDropDownAsync", setter: setCustomers },
            { method: "/GetWorkTypesDropDownAsync", setter: setWorkTypes },
            { method: "/GetStatesDropDownAsync", setter: setStates },
            { method: "/GetStatusesDropDownAsync", setter: setStatuses },
            { method: "/GetETAsDropDownAsync", setter: setETARange },
        ];

        operations.forEach(operation => {
            updateDataAsync(operation.method, operation.setter);
        });

        
    }, []);

    useEffect(() => {
        const customerSelections = selections
            .filter(selection => selection.label === 'Customer' && selection.id != null)
            .map(selection => selection.id as number);

        if (customerSelections.length > 0) {
            updateDataAsync("/GetUsersDropDownAsync", setUsers, customerSelections);
        } else {
            setUsers([]);
        }
        const filteredSelections = selections.filter(
            (selection) => selection.label !== "Page" && selection.label !== "Page Size"
        );
        setShowClearAll(filteredSelections.length > 0);
    }, [selections]);

    useEffect(() => {
        if (startDate && endDate && startDate.isAfter(endDate)) {
            setEndDate(startDate);
            updateSelection("End Date", startDate.format('MM/DD/YYYY'));
        }
    }, [startDate]);

    useEffect(() => {
        if (startDate && endDate && endDate.isBefore(startDate)) {
            setStartDate(endDate);
            updateSelection("Start Date", endDate.format('MM/DD/YYYY'));
        }
    }, [endDate]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: "wrap", justifyContent: 'flex-start', alignItems: 'flex-start', minHeight: "220px", backgroundColor: "#F5F5F5", padding: "30px", marginTop: "10px", borderRadius: "20px", gap: "30px" }}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "40px", width: "100%", }}>
                <Box sx={{ display: "inline-flex", alignItems: "center", gap: "30px", flexWrap:'wrap' }}>
                    <Title>Orders</Title>
                    <SearchBarAcertus
                        inputLabel="Invoice #, Registrant, VIN, or Unit #"
                        onSearch={(searchValue: string) => onSearchSubmit(searchValue)}
                        width="518px"
                        disabled={loading}
                        setSelections={setSelections}
                        selections={selections}
                        setSearchTerm={setSearchTerm}
                    />
                    <FormControlLabel
                        control={
                            <CheckboxAcertus
                                isChecked={balancesOnlyChecked}
                                onChange={() => changeBalancesOnlyChecked()}
                                disabled={loading}
                            />
                        }
                        label="Orders with balances only"
                        disabled={loading}
                        sx={{
                            margin: "0px",
                            "& .MuiFormControlLabel-label": {
                                fontSize: "12px",
                            },
                        }}
                    />
                </Box>
                {showClearAll && (
                    <WhiteEndIconButton
                        label="Save"
                        onClick={() => setOpen?.(true)}
                        variant="contained"
                        disabled={loading}
                        endIcon={<SaveIcon sx={{ color: '#ED7300'}} />}
                        sx={{ width: "79px", border: '1px solid #c4c4c4', height: '36px', fontSize:'12px', color: '#6D767F', fontFamily: '"Noto Sans", Arial, sans-serif' }}
                    />
                )} 
            </Box>
            <Box sx={{ display: "inline-flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start", alignItems: "center", minHeight: "40px", gap: "12px" }}>
                <AutocompleteAcertus items={customers} value={undefined} onChange={(value) => handleChange("Customer", value)} label="Customer" disabled={customers.length === 0 || loading} />
                <AutocompleteAcertus items={workTypes} value={undefined} onChange={(value) => handleChange("Task", value)} label="Task" disabled={workTypes.length === 0 || loading} />
                <AutocompleteAcertus items={states} value={undefined} onChange={(value) => handleChange("State", value)} label="State" disabled={states.length === 0 || loading} />
                <AutocompleteAcertus items={statuses} value={undefined} onChange={(value) => handleChange("Status", value)} label="Status" disabled={statuses.length === 0 || loading} />
                <AutocompleteAcertus items={etaRange} value={undefined} onChange={(value) => handleETAChange("ETA Range", value)} label="ETA Range" disabled={etaRange.length === 0 || loading} />
                <DatePickerAcertus label="Start Date" value={startDate} onChange={(date) => handleDateChange("Start Date", date)} disabled={loading} clearable={true} minDate={dayjs().subtract(1, 'year')} maxDate={endDate ? dayjs(endDate) : dayjs()} onClear={() => setIsStartDateCleared(true)} />
                <DatePickerAcertus label="End Date" value={endDate} onChange={(date) => handleDateChange("End Date", date)} disabled={loading} clearable={true} minDate={startDate ? dayjs(startDate) : dayjs().subtract(1, 'year')} maxDate={dayjs()} onClear={() => setIsEndDateCleared(true)} />
                {isCategorySelected("Customer") && (
                    <AutocompleteAcertus
                        items={users}
                        value={undefined}
                        onChange={(value) => handleChange("Users", value)}
                        label="Users"
                        disabled={users.length === 0 || loading}
                    />
                )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "40px", width: "100%", }}>
                <Box sx={{ display: "inline-flex", alignItems: "center", gap: "10px", flexWrap: 'wrap', marginRight:'10px' }}>
                    {selections
                        .filter(
                            (selection) =>
                                selection.label !== "Balances Only" &&
                                selection.label !== "Search" &&
                                selection.label !== "Page" &&
                                selection.label !== "Page Size"
                        )
                        .map((selection, index) => (
                            <FormControlLabel
                                key={index}
                                labelPlacement="start"
                                disabled={loading}
                                control={
                                    <Checkbox
                                        icon={<CancelIcon sx={{ color: "#FFFFFF" }} />}
                                        checkedIcon={<CancelIcon sx={{ color: "#FFFFFF" }} />}
                                        checked={true}
                                        onChange={() => handleRemove(selection.label, selection.choice)}
                                    />
                                }
                                label={`${selection.label}: ${selection.choice}`}
                                sx={{ height: "36px", color: "#FFFFFF", backgroundColor: "#6D767F", borderRadius: "5px", padding: "10px 20px", margin: "0px", "& .MuiFormControlLabel-label": { fontSize: "12px" }, "& .MuiTouchRipple-root": { color: "#ED7300" } }}
                            />
                        ))}
                    {showApplyButton && (
                        <OrangeHeaderButton label="Apply Changes" onClick={handleApplyFilters} variant="contained" disabled={null} sx={{}} />
                    )}
                </Box>
                {showClearAll && (
                    <WhiteHeaderButton label="Clear All" onClick={handleClearAll} variant="contained" disabled={loading} sx={{}} />
                )}
            </Box>
        </Box>
    );
};

export default OrdersHeader;
