import React, { useState, useEffect, useRef } from 'react';
import { Tab, Typography, NoSsr, styled, IconButton, Tooltip, Button, Box, FormControl, Autocomplete, createFilterOptions, TextField, Grid, Divider, Dialog, Zoom, Grow } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DescriptionIcon from '@mui/icons-material/Description';
import { VisuallyHiddenInput, OrangeButton, SelectFileButton, TemplateButton,ResetGrid, WhiteGoBackButton, SubmitButton } from '../../Shared/Renewals';
import { State } from '../../Types/common';

interface ReqBy extends Object {
    Name: string;
    CustomerId: number;
    ContactId: number;
}
interface GetDropDowns {
    states: State[];
    reqBy: ReqBy[];
}
interface IndividualModal {
    selectedReqBy: ReqBy | null;
    selectedCustState: State | null;
}

export const RenewalOrder = ({ handleNext, handleMultipleOrder, formData, reqBy}) => {

    const [disableButton, setDisableButton] = React.useState<boolean>(true);
    const [fileError, setFileError] = React.useState<boolean>(false);
    const [foundAddress, setFoundAddress] = React.useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [states, setStates] = useState<State[]>([]);
    const [contact, setContact] = useState<number | null>(null);
    const [individualModal, setIndividualModal] = useState<IndividualModal>({ selectedReqBy: reqBy[1], selectedCustState: states[1] });
    const [custName, setCustName] = useState<string | null>(null);
    const [custAddress1, setCustAddress1] = useState<string | null>(null);
    const [custAddress2, setCustAddress2] = useState<string | null>(null);
    const [custCity, setCustCity] = useState<string | null>(null);
    const [custZip, setCustZip] = useState<string | null>(null);
    const [custPhone, setCustPhone] = useState<string | null>(null);
    const [custId, setCustId] = useState<number | null>(null);
    const [contactName, setContactName] = useState<string | null>(null);
    const [fileName, setFileName] = useState<string | null>(null);
    const [file, setFile] = useState<File | null>(null);
    
    const handleFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];
        if (selectedFile) {
            const fileExtension = selectedFile.name.split('.').pop();
            if (fileExtension == "xlsx") {
                setFile(selectedFile);
                setFileName(selectedFile.name);
                await handleFile(selectedFile);
            }
            else {
                setFileError(true);
            }
        }
    };
    const handleFileError = async () => {
        setFileError(false);
    }
    const handleFile = async (file) => {
        const excelData = new FormData();
        if (file instanceof File) {
            excelData.append("file", file, file.name);
        }
        const formData = await (async () => {
            return {
                upload: { fileName, excelData, contact, custId }
            };
        })();
        await handleMultipleOrder(formData);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getCustomerAddress(formData.reqBy.contact);
                await handleAccount(formData.reqBy);
                if (formData.reqBy != undefined) {
                    setDisableButton(false);
                }
            } catch (error) {
                console.error("An error occurred during fetch data:", error);
            }
        };

        fetchData();
        return () => { };
    }, [formData]);

    const handleSelectFileButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleModalWidth = async () => {
        const modalContent = document.getElementById("modalContent");
        if (modalContent) {
            modalContent.style.width = "900px";
        }
    }
    const getCustomerAddress = async (contact) => {

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ contactId: contact }),
        };
        const url = `${window.location.origin}/CreateOrders/GetCustomerAddress`;

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                const errorMessage = `Failed to get customer address. Status: ${response.status} ${response.statusText}`;
                const responseBody = await response.text();
                console.error(errorMessage, responseBody);
                console.error("Request URL:", url);
                console.error("Request Options:", requestOptions);
            } else {
                const data = await response.json();
                setFoundAddress(true);
                setCustId(data.customerAddress.ShipCustomerId);
                setCustName(data.customerAddress.ShipCustomer);
                setContactName(data.customerAddress.ShipName);
                setCustAddress1(data.customerAddress.ShipAddr1);
                setCustAddress2(data.customerAddress.ShipAddr2);
                setCustCity(data.customerAddress.ShipCity);
                setIndividualModal((prevModal) => ({
                    ...prevModal,
                    selectedCustState: { StateName: data.customerAddress.ShipStateName, StateId: data.customerAddress.ShipStateId, StateAbbreviation: data.customerAddress.ShipStateAbrv, CountryId: null },
                }));
                setCustZip(data.customerAddress.ShipZip);
                setCustPhone(data.customerAddress.ShipPhone);
            }
        } catch (error) {
            console.error('An error occurred during getting cust address:', error);
            return false;
        }
    }
    const handleAccount = async (contact) => {
        setIndividualModal((prevModal) => ({ ...prevModal, selectedReqBy: contact }));
        const selected = contact.contact;
        if (contact != undefined) {
            await setContact(selected);
        }
    }
    const handleReqByNext = async () => {
        if (reqBy) {
            const formData = {
                reqBy: { reqBy, contact, custName, custAddress1, custAddress2, custCity, custZip, custPhone, contactName, individualModal },
            };
            handleNext(formData);
        }
        handleModalWidth();
     }
   
    return (
        <NoSsr>
            <Zoom in={foundAddress}>
            <div>
                <div className="col-md-12" style={{ display: 'grid', justifyContent: 'center', alignContent: 'center', marginTop:'10px' }} >
                    <br />
                        <Tooltip TransitionComponent={Zoom} enterDelay={1000} leaveDelay={200} title={<span style={{ fontSize: '12px' }}>Submit an Individual Order</span>} placement="right">
                        <div>
                            <OrangeButton onClick={handleReqByNext} disabled={disableButton} startIcon={<DescriptionIcon sx={{ color: 'white' }} />}>Individual Order</OrangeButton>
                        </div>
                        </Tooltip>
                    <br />
                    <Typography sx={{ fontSize: '18px', display: 'grid', justifyItems: 'center', verticalAlign: 'center', fontFamily: 'Interstate' }} >or</Typography>
                    <br />
                        <><Tooltip TransitionComponent={Zoom} enterDelay={1000} leaveDelay={200} title={<span style={{ fontSize: '12px' }}>Submit Multiple Orders</span>} placement="right">
                        <div>
                            <SelectFileButton onClick={handleSelectFileButtonClick} disabled={disableButton} startIcon={<><DescriptionIcon sx={{ color: 'white' }} /><DescriptionIcon sx={{ color: 'white', fontSize: '20px' }} /></>}>Multiple Order</SelectFileButton>
                        </div>
                        </Tooltip>
                        <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileInputChange} />
                    </>
                    <br />
                        <Tooltip TransitionComponent={Zoom} enterDelay={1000} leaveDelay={200} title={<span style={{ fontSize: '12px' }}>Template for Multiple Order submissions</span>} placement="right">
                            <TemplateButton variant="text" href="/Content/Files/Multiple_Order_Template.xlsx" startIcon={<DownloadIcon sx={{ color: '#ED7300' }} />}>Download Template</TemplateButton>
                        </Tooltip>
                </div>
                <Dialog open={fileError}>
                    <Zoom in={fileError}>
                        <div style={{ height: '150px', width: '900px', display: 'grid', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}>
                            <Grid container style={{ height: '100%', justifyContent: 'center' }}>
                                <ResetGrid>
                                    <Grid sx={{ gridArea: 'question', marginBottom: '20px' }}>
                                        <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}>Invalid file type. Please download and use the order template to submit your order.</Typography>
                                    </Grid>
                                    <Grid sx={{ gridArea: 'yesButton' }}>
                                        <SubmitButton onClick={handleFileError}>Ok</SubmitButton>
                                    </Grid>
                                </ResetGrid>
                            </Grid>
                        </div>
                    </Zoom>
                </Dialog>
                </div>
            </Zoom>
        </NoSsr>
    );
}