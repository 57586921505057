import React from "react";
import { Box, Typography, IconButton, Zoom, Tooltip, CircularProgress } from "@mui/material";

const DownloadIconAcertus = () => (
    <img src="../../React/svgs/DownloadIconAcertus.svg" alt="Download Icon" />
);

interface ReportAcertusProps {
    onClickDownload?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    headerText: string;
    bodyText: string;
    selections: { label: string, choice: string | boolean, id?: number }[];
    setSelections: React.Dispatch<React.SetStateAction<{ label: string, choice: string | boolean, id?: number }[]>>;
    setBalancesOnlyChecked?: React.Dispatch<React.SetStateAction<boolean>>;
    downloading: number;
    reportId: number;
};

const ReportAcertus: React.FC<ReportAcertusProps> = ({ onClickDownload, headerText, bodyText, setSelections, selections, setBalancesOnlyChecked, downloading, reportId }) => {

    const handleReportClick = () => {
        if (window.location.pathname === "/PendingOrders") {
            return;
        }
        switch (headerText) {
            case "Pending Orders":
                setSelections(prevSelections => {
                    const filteredSelections = prevSelections.filter(
                        selection => selection.label === "Start Date" || selection.label === "End Date" || selection.label === "Page" || selection.label === "Page Size"
                    );

                    return [
                        ...filteredSelections,
                        { label: "Status", choice: "PENDING" }
                    ];
                });
                break;
            case "In-Process Orders":
                setSelections(prevSelections => {
                    const filteredSelections = prevSelections.filter(
                        selection => selection.label === "Start Date" || selection.label === "End Date" || selection.label === "Page" || selection.label === "Page Size"
                    );

                    return [
                        ...filteredSelections,
                        { label: "Status", choice: "IN-PROCESS" }
                    ];
                });
                break;
            case "All Orders by Status":
                setSelections(prevSelections => {
                    const filteredSelections = prevSelections.filter(
                        selection => selection.label === "Start Date" || selection.label === "End Date" || selection.label === "Page" || selection.label === "Page Size"
                    );

                    return [
                        ...filteredSelections,
                        { label: "Balances Only", choice: true }
                    ];
                });
                if (setBalancesOnlyChecked) {
                    setBalancesOnlyChecked(true);
                }
                break;
            case "Orders Waiting on Paperwork":
                setSelections(prevSelections => {
                    const filteredSelections = prevSelections.filter(
                        selection => selection.label === "Start Date" || selection.label === "End Date" || selection.label === "Page" || selection.label === "Page Size"
                    );

                    return [
                        ...filteredSelections,
                        { label: "Status", choice: "WAITING FOR PAPERWORK" }
                    ];
                });
                break;
            default:
                break;
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: "wrap", justifyContent: 'flex-start', alignItems: 'center', height: "77px", width: "288px", border: 1, borderColor: "#DFE1E3", borderRadius: "8px", gap: "20px" }}>
            <Tooltip TransitionComponent={Zoom} enterDelay={1000} leaveDelay={200} title={<span style={{ fontSize: '12px' }}>Download {headerText} report</span>} placement="top" arrow>
                <IconButton onClick={onClickDownload}>
                    {downloading === reportId ? <CircularProgress sx={{ color: '#ED7300' }} size={24} /> : <DownloadIconAcertus />}
                </IconButton>
            </Tooltip>
           <Box onClick={handleReportClick} sx={{ display: 'flex', flexDirection: 'column', flexWrap: "nowrap", justifyContent: 'space-between', alignItems: 'flex-start', height: "45px", width: "222px", cursor:'pointer' }}>
                <Typography color="#5D6771" sx={{fontSize:'12px'}} fontWeight="700">{headerText}</Typography>
                <Typography color="#5D6771" sx={{ fontSize: '10px' }} fontWeight="300">{bodyText}</Typography>
           </Box>
        </Box>
    )
};

export default ReportAcertus;