import React from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, TextareaAutosize, Button, Autocomplete } from '@mui/material';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import OrangeEndIconButton from '../Components/OrangeEndIconButton';

const handleKeyDown = (event) => {
    // Prevent minus sign and other invalid characters like "e", "+", etc.
    if (event.key === '-' || event.key === 'e' || event.key === '+') {
        event.preventDefault();
    }
};
export const LookupValueInput = ({ onChange, options, sx, getOptionLabel, renderInput, renderOption, value, isOptionEqualToValue }) => {
    return (
        <FormControl required>
            <Autocomplete
                options={options}
                sx={sx}
                value={value}
                getOptionLabel={getOptionLabel}
                renderInput={renderInput}
                onChange={onChange}
                renderOption={renderOption}
                isOptionEqualToValue={isOptionEqualToValue}
            />
        </FormControl>
    );
};

export const TextInput = ({ onChange, sx, value }) => {
    return (
        <TextField value={value} variant="outlined" onChange={onChange} sx={sx} />
    );
};

export const YesNoInput = ({ onChange }) => {
    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel id="yes-no-label">Select</InputLabel>
            <Select labelId="yes-no-label" onChange={onChange} label="Select">
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
            </Select>
        </FormControl>
    );
};

export const CurrencyInput = ({ onChange, sx, value }) => {
    return (
        <TextField value={value} type="number" variant="outlined" onChange={onChange} sx={sx} inputProps={{ min: 0 }} onKeyDown={handleKeyDown} />
    );
};

export const NotesInput = ({ onChange, value }) => {
    return (
        <TextareaAutosize value={value} minRows={3} style={{ width: '100%' }} onChange={onChange} />
    );
};

export const SSNInput = ({ onChange, sx, value }) => {
    return (
        <TextField value={value} variant="outlined" onChange={onChange} sx={sx} />
    );
};

export const PhoneInput = ({ onChange, sx, value }) => {
    return (
        <TextField value={value} type="tel" variant="outlined" onChange={onChange} sx={sx}/>
    );
};

export const NumberInput = ({ onChange, sx, value }) => {
    return (
        <TextField value={value} type="number" variant="outlined" onChange={onChange} sx={sx} inputProps={{ min: 0 }} onKeyDown={handleKeyDown} />
    );
};

export const DatePicker = ({ onChange, sx, value }) => {
    return (
        <TextField value={value} type="date" variant="outlined" onChange={onChange} sx={sx} />
    );
};

export const AddressButton = ({ onClick, sx }) => {
    return (
        <OrangeEndIconButton
            label="Enter Address"
            onClick={onClick}
            variant="contained"
            endIcon={<AddLocationAltIcon sx={{ color: 'white' }} />}
            disabled={false}
            sx={sx}
        />
    );
};

export const FileUpload = ({ onChange, sx }) => {
    return (
        <TextField type="file" variant="outlined" onChange={onChange} sx={sx} fullWidth />
    );
};
