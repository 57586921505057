import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Form, Modal, ModalProps, ThemeProvider } from "react-bootstrap";
import { textChangeRangeNewSpan } from "typescript";
import { ITransactionState, TransactionRequirement, DropdownOption } from "../Types/common";
import { RequirementLookups } from "./InvoiceDetailPage";
import { Alert, Box, createTheme, Grid, NoSsr, Snackbar, Button, Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { Stack } from "@mui/system";
import { DateUtil } from "../Helpers/DateUtil";
import { StyledTab } from "../Shared/Tab";
import SaveIcon from '@mui/icons-material/Save';

export interface PendingRequirementsProps {
    TransactionRequirements: TransactionRequirement[] | [];
    TransactionState: ITransactionState;
    UpdateTransactionState(): any;
    States: DropdownOption[];
    RequirementLookups: RequirementLookups;
};

export interface PendingRequirmentsState {
    TransactionRequirements: TransactionRequirement[] | [];
    TransactionState: ITransactionState;
};

export interface RequirementModalProps extends Object {
    props: ModalProps,
    title: string,
}

export interface FilterRequirementsState extends Object {
    unmetReqs: TransactionRequirement[],
    resolvedReqs: TransactionRequirement[]
}

export interface ToastProps {
    show: boolean,
    message: string,
    severity: 'error' | 'warning' | 'info' | 'success'
}

interface FormErrors {
    attention: string,
    streetaddress: string,
    city: string,
    state: string,
    zipcode: string,
    file: string,
    data: string
}

export const PendingRequirements: React.FC<PendingRequirementsProps> = ({ TransactionRequirements = [], TransactionState = {} as ITransactionState, UpdateTransactionState, States = [] as DropdownOption[], RequirementLookups = {} as RequirementLookups }) => {
    PendingRequirements.displayName = "PendingRequirements";
    const [modalProps, setModalProps] = useState<RequirementModalProps>({ props: { show: false } } as RequirementModalProps)
    const [state, setState] = useState<ITransactionState>({} as ITransactionState)
    const [selectedReq, setSelectedReq] = useState<TransactionRequirement>({} as TransactionRequirement);
    const [formErrors, setFormErrors] = useState<FormErrors>({} as FormErrors);
    const [inputValue, setInputValue] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<File>();
    const [tabValue, setTabValue] = useState<string>("0");
    const [filteredReqState, setFilteredReqState] = useState<FilterRequirementsState>({ unmetReqs: [], resolvedReqs: [] });
    const [toast, setToast] = useState<ToastProps>({ show: false, message: "", severity: 'info' })
    const [saving, setSaving] = useState<boolean>(false)

    useEffect(() => {
        if (TransactionRequirements.length > 0) {
            let filteredTransReqs: TransactionRequirement[] = TransactionRequirements.filter(x => x.DateReceived === null)
            let resolvedReqs: TransactionRequirement[] = TransactionRequirements.filter(x => !filteredTransReqs.includes(x))

            setFilteredReqState({
                unmetReqs: filteredTransReqs,
                resolvedReqs: resolvedReqs
            })
        }
        return () => {

        }
    }, [TransactionRequirements])

    useEffect(() => {
        if (TransactionState != undefined) {
            setState(TransactionState)
        }
    }, [TransactionState])

    const handleOnClick = (event: React.MouseEvent, req: TransactionRequirement): void => {
        setSelectedReq({ ...req });
        setFormErrors({} as FormErrors);
        setModalProps({ props: { show: true }, title: req.RequirementName })
    }

    const selectFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const target = event?.target as HTMLInputElement;
        const files = target?.files as FileList;
        if (files && files[0]) {
            selectedReq.Document = files[0] as Blob;
        }
    }

    const validateForm = (): boolean => {
        let valid = true;
        const errors = {} as FormErrors;
        switch (selectedReq.RequirementFieldTypeId) {
            case 1:
            case 2:
                if (!selectedReq.Data) {
                    errors['data'] = 'Please provide a value.';
                    valid = false;
                } else if (isNaN(parseInt(selectedReq.Data,10))) {
                    errors['data'] = 'Please provide a numeric value.';
                    valid = false;
                }
                break;
            case 5:
                if (!selectedReq.Data) {
                    errors['data'] = 'Please provide a value.';
                    valid = false;
                } else if (selectedReq.IsDocument && selectedReq.Data === 'YES' && !selectedReq.Document) {
                    errors['file'] = 'Please provide a file.';
                    valid = false;
                } else if (selectedReq.IsDocument && selectedReq.Document?.size > 6291456) {
                    errors['file'] = 'Please provide a file no larger than 6MB.';
                    valid = false;
                }
                break;
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
                if (!validString(selectedReq.Attention)) {
                    errors['attention'] = 'Please provide a value for Attention.';
                    valid = false;
                }
                if (!selectedReq.StreetAddress && !validString(selectedReq.StreetAddress)) {
                    errors['address'] = 'Please provide a value for Street Address.';
                    valid = false;
                }
                if (!validString(selectedReq.City)) {
                    errors['city'] = 'Please provide a value for City.';
                    valid = false;
                }
                if (!selectedReq.StateId) {
                    errors['state'] = 'Please provide a value for State.';
                    valid = false;
                }
                if (!validString(selectedReq.ZipCode)) {
                    errors['zipcode'] = 'Please provide a value for Zip Code.';
                    valid = false;
                }
                break;
            default:
                if (!validString(selectedReq.Data)) {
                    errors['data'] = 'Please provide a valid value.';
                    valid = false;
                }
                break;
        }
        setFormErrors(errors);
        return valid;
    }
    const validString = (value: string): boolean => {
        return value ? /([A-Za-z0-9\-&\s'])$/.test(value) : false;
    }

    const getInputHTML = (): JSX.Element[] => {
        let element: JSX.Element[] = [];
        if (selectedReq.HasLookupValue && RequirementLookups) {
            const dropdownOptions = RequirementLookups[selectedReq.RequirementId]
            if (dropdownOptions) {
                element.push((
                    <>
                        <Form.Group controlId="data">
                            <Form.Select onChange={handleLookupSelectData} className="form-control">
                                <option value="">Please select a value.</option>
                                {dropdownOptions.map(o => (
                                    <option value={o.Id}>{o.Value}</option>
                                ))}
                            </Form.Select>
                            <br />
                            <span style={{ color: "red" }}>{formErrors["data"]}</span>
                        </Form.Group>
                    </>
                ));
            }
        } else {
            switch (selectedReq.RequirementFieldTypeId) {

                case 3:
                    element.push((
                        <>
                            <Form.Group controlId="data">
                                <Form.Control type="date" className="datepicker" onChange={handleInputDate}></Form.Control>
                                <span style={{ color: "red" }}>{formErrors["data"]}</span>
                            </Form.Group>
                        </>
                    ));
                    break;
                case 5:
                    element.push((
                        <>
                            <Form.Group controlId="data">
                                <Form.Select onChange={handleSelectData} className="form-control">
                                    <option value="">Please select a value.</option>
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>
                                </Form.Select>
                                <br />
                                <span style={{ color: "red" }}>{formErrors["data"]}</span>
                            </Form.Group>
                        </>
                    ));
                    break;
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                    element.push((
                        <>
                            <Form.Group controlId="attention">
                                <Form.Label>Attention</Form.Label>
                                <Form.Control type="text" onChange={handleInputAddress('Attention')} ></Form.Control>
                                <span style={{ color: "red" }}>{formErrors["attention"]}</span>
                            </Form.Group>
                            <Form.Group controlId="address">
                                <Form.Label>Address</Form.Label>
                                <Form.Control type="text" onChange={handleInputAddress('StreetAddress')}></Form.Control>
                                <span style={{ color: "red" }}>{formErrors["address"]}</span>
                            </Form.Group>
                            <Form.Group controlId="city">
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text" onChange={handleInputAddress('City')}></Form.Control>
                                <span style={{ color: "red" }}>{formErrors["city"]}</span>
                            </Form.Group>
                            <Form.Group controlId="state">
                                <Form.Label>State</Form.Label>
                                <br />
                                <Form.Select onChange={handleInputAddressState} className="form-control">
                                    <option value="">Please select a state.</option>
                                    {States.map(o => (
                                        <option value={o.Id}>{o.Value}</option>
                                    ))}
                                </Form.Select>
                                <br />
                                <span style={{ color: "red" }}>{formErrors["state"]}</span>
                            </Form.Group>
                            <Form.Group controlId="zipcode">
                                <Form.Label>Zip Code</Form.Label>
                                <Form.Control type="text" onChange={handleInputAddress('ZipCode')}></Form.Control>
                                <span style={{ color: "red" }}>{formErrors["zipcode"]}</span>
                            </Form.Group>
                        </>
                    ));
                    break;
                case 0:
                case 6:
                default:
                    element.push((
                        <>
                            <Form.Group controlId="data">
                                <Form.Control type="text" onChange={handleInputData} placeholder="Enter Here"></Form.Control>
                                <span style={{ color: "red" }}>{formErrors["data"]}</span>
                            </Form.Group>
                        </>
                    ));
                    break;
            }
        }
        if (selectedReq.IsDocument) {
            element.push((
                <>
                    <Form.Group controlId="file">
                        <br />
                        <Form.Text style={{ fontFamily: '"Noto Sans", Arial, sans-serif' }}>Valid File Types: jpg | jpeg | png | pdf | tiff</Form.Text>
                        <input type="file" accept=".PDF,image/*" onChange={selectFile} style={{ fontFamily: '"Noto Sans", Arial, sans-serif' }} />
                        <span style={{ color: "red" }}>{formErrors["file"]}</span>
                    </Form.Group>
                </>
            ))
        }
        return element
    }

    const handleOnClose = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setSelectedReq({} as TransactionRequirement);
        setFormErrors({} as FormErrors);
        setModalProps(prevProps => ({
            ...prevProps,
            props: { ...prevProps.props, show: false }
        }));
    };

    const handleOnSave = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        const valid = validateForm();
        if (valid) {

            const formData = new FormData();
            Object.keys(selectedReq).forEach((key) => {
                formData.append(key, selectedReq[key])
            });

            var url = window.location.origin + "/Portal/EditTransactionRequirement";
            var options: RequestInit = {
                method: "POST",
                body: formData
            }
        
        setSaving(true)

        fetch(url, options)
            .then((res) => res.json())
            .then((response: TransactionRequirement) => {
                let filteredReqs: TransactionRequirement[] = []
                let resolvedReqs: TransactionRequirement[] = []
                if (!response.RequirementName.includes("NOTE")) {
                    filteredReqs = filteredReqState.unmetReqs.filter(x => x.TransactionRequirementId != response.TransactionRequirementId)
                    resolvedReqs = [...filteredReqState.resolvedReqs, response]
                }
                else {
                    filteredReqs = filteredReqState.unmetReqs.filter(x => x.TransactionRequirementId != response.TransactionRequirementId)
                    filteredReqs = [...filteredReqs, response]
                    resolvedReqs = filteredReqState.resolvedReqs;
                }

                setFilteredReqState({
                    unmetReqs: filteredReqs,
                    resolvedReqs: resolvedReqs
                })

                setModalProps(prevProps => {
                    return {
                        ...prevProps,
                        props: {
                            show: false
                        }
                    }
                })

                if (filteredReqs.length == 0) {
                    UpdateTransactionState()
                }
        

                setToast({
                    show: true,
                    message: "Requirement updated!",
                    severity: 'success'
                })

            setSaving(false);
            })
            .catch(e => {
                setToast({
                    show: true,
                    message: "Error saving data",
                    severity: 'error'
                })

                setSaving(false)
            })
        }
    }

    const handleSelectData = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault()
        selectedReq.Data = e.target.value;
    }

    const handleLookupSelectData = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault()
        selectedReq.Data = e.target[e.target.selectedIndex].label;
        selectedReq.RequirementLookupId = parseInt(e.target.value, 10);
    }
    const handleInputDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const date = e.target.value.split('-');
        selectedReq.Data = `${date[1]}/${date[2]}/${date[0]}`;
    }
    const handleInputData = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        selectedReq.Data = e.target.value.toUpperCase();
    }
    const handleInputAddress = (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        selectedReq[key] = e.target.value.toUpperCase();
    }
    const handleInputAddressState = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault()
        selectedReq['StateId'] = e.target.value ? parseInt(e.target.value, 10) : 0;
    }

    const getRequirements = (): JSX.Element[] => {

        if (filteredReqState.unmetReqs.length === 0) {
            return [
                <div className="col-sm-12 margin-left-10" key="all-resolved">
                    <span style={{color:'#555'}}>No unmet requirements at this time</span>
                </div>
            ];
        }

        if (state.isCompleted || state.isCancelled) {
            return []
        }
        
        return filteredReqState.unmetReqs.map(req => {
            if (req.RequirementName.includes("NOTE")) {
                return (
                    <div className="col-sm-12 margin-left-10" key={"tran-req-id_" + req.TransactionRequirementId}>
                        <span className="mmt-button" onClick={(e) => handleOnClick(e, req)}>NOTE: {req.Data}</span>
                    </div>
                )
            }
            else {
                return (
                    <div className="col-sm-12 margin-left-10" key={"tran-req-id_" + req.TransactionRequirementId}>
                        <span className="mmt-button" onClick={(e) => handleOnClick(e, req)}>{req.RequirementName}</span>
                    </div>
                )
            }
        })
    }

    return (
        <div className="mmt-card row card">
            <NoSsr defer>
                <Snackbar open={toast.show} autoHideDuration={8000} onClose={() => setToast({ ...toast, show: false })} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert severity={toast.severity} sx={{ fontSize: '12px', width: '300px'}}>{ toast.message}</Alert>
                </Snackbar>
                <Dialog open={!!modalProps.props.show} onClose={handleOnClose} aria-labelledby="pending-requirements-dialog">
                    <DialogTitle id="pending-requirements-dialog">{modalProps.title}</DialogTitle>
                    <DialogContent>{getInputHTML()}</DialogContent>
                    <DialogActions>
                        <LoadingButton loading={saving} loadingPosition="start" startIcon={<SaveIcon fontSize="medium" />} variant="contained" color="success" size="medium" sx={{ fontSize: "12px" }} onClick={handleOnSave}>
                            Save
                        </LoadingButton>
                        <Button variant="contained" color="error" size="medium" sx={{ fontSize: "12px" }} onClick={handleOnClose}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                    <div className="card-header">
                        <div className="col-sm-12">
                            <h3 style={{ fontFamily: '"Noto Sans", Arial, sans-serif' }}>Pending Requirements</h3>
                        </div>
                    </div>
                <div className="card-body">
                        <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={(e, newValue) => setTabValue(newValue)} aria-label="lab API tabs example" TabIndicatorProps={{ sx: {borderBottom: "2px solid #ed7800"} }}>
                                    <StyledTab label="UNMET" value="0"/>
                                    <StyledTab label="RESOLVED" value="1" />
                                </TabList>
                            </Box>
                            <TabPanel value="0">{getRequirements()}</TabPanel>
                            <TabPanel value="1">
                                <Stack direction="column" spacing="2">
                                {
                                    filteredReqState.resolvedReqs.map(req => {
                                        return (
                                            <Grid container spacing="1" key={"res_tran_id_" + req.TransactionRequirementId }>
                                                <Grid item xs={10}>
                                                    { req.RequirementName }
                                                </Grid>
                                                <Grid item xs={2}>
                                                    { DateUtil.convertJsonDateToShortDate(req.DateReceived) }
                                                </Grid>
                                                <Grid item xs={12} marginLeft={1}>
                                                    { req.Data }
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                                </Stack>
                            </TabPanel>
                        </TabContext>
                    </div>
            </NoSsr>
        </div>
)
}