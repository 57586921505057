import React, { useEffect, useState } from "react";
import { JsxElement } from "typescript";
import { DateUtil } from "../Helpers/DateUtil";
import { ETAChange, ITransactionState, ShippingResult, TransactionProcedure, TransactionRequirement } from "../Types/common";
import { InvoiceDetailPageModal } from "./InvoiceDetailPage";

interface OrderHistoryProps extends Object {
    TransactionState: ITransactionState,
    Modal: InvoiceDetailPageModal
}

export const OrderHistory: React.FC<OrderHistoryProps> = ({ TransactionState = {} as ITransactionState, Modal = {} as InvoiceDetailPageModal }) => {
    OrderHistory.displayName = "OrderHistory"

    const [state, setState] = useState<ITransactionState>({} as ITransactionState);
    const [modal, setModal] = useState<InvoiceDetailPageModal>({} as InvoiceDetailPageModal);

    useEffect(() => {
        if (TransactionState != undefined) {
            setState(TransactionState)
        }
    }, [TransactionState])

    useEffect(() => {
        if (Modal != undefined) {
            setModal(Modal)
        }
    }, [Modal])

    const getResolvedHTML = (state: ITransactionState, modal: InvoiceDetailPageModal): JSX.Element => {
        if (state.isResolved) {
            return (
                <div key="order_history_resolved">
                    <div>
                        <i className="fa fa-circle mmt-circle"></i>
                        <span>Requirements Resolved</span>
                    </div>
                    <div className="mmt-orders done">
                        <div className="row">
                            <div className="col-lg-2">
                                <strong>{ requirementsResolvedDate(modal.TransactionRequirements)}</strong>

                            </div>
                            <div className="col-9">
                                <strong>Requirements Resolved</strong>
                            </div>
                        </div>
                    </div>
                </div>
                )
        }
        else {
            return (
                <div key="order_history_resolved">
                    <div>
                        <i className="fa fa-circle-o mmt-circle"></i>
                        <span>Requirements Resolved</span>
                    </div>
                    <div className="mmt-orders">
                    </div>
                </div>
                )
        }
    }

    const getReceivedHTML = (state: ITransactionState, modal: InvoiceDetailPageModal): JSX.Element => {
        let createdDateString: string = ""

        if (modal.Transaction != undefined) {
            createdDateString = DateUtil.convertJsonDateToShortDate(modal.Transaction.CreateDate)
        }

        return (
            <div key="order_history_received">
                {getReceivedStatus()}
                <div className="mmt-orders done">
                    <div className="row">
                        <div className="col-lg-2">
                            <strong>{ createdDateString }</strong>
                        </div>
                        <div className="col-9">
                            <strong>Created</strong>
                        </div>
                    </div>
                </div>
            </div>
            )
    }

    const getSentToStateHTML = (state: ITransactionState, modal: InvoiceDetailPageModal): JSX.Element => {
        if (state.isSentToState) {
            return (
                <div key="order_history_senttostate">
                    <div>
                        <i className="fa fa-circle mmt-circle"></i>
                        <span>Sent to State</span>
                    </div>
                    { getTransactionProcedures()}
                </div>
                )
        }
        else {
            return (
                <div key="order_history_senttostate">
                    <div>
                        <i className="fa fa-circle-o mmt-circle"></i>
                        <span className="mmt-order-title">Sent to State</span>
                    </div>
                    <div className="mmt-orders">
                    </div>
                </div>
                )
        }
    }
    const getReceivedStatus = (): JSX.Element => {
        return (
            <div>
                <i className="fa fa-circle mmt-circle"></i>
                <span>{state.isWaitingForPaperwork ? "Waiting for Paperwork" : "Received"}</span>
            </div>
        )
    }
    const getTransactionProcedures = (): JSX.Element => {
        return (
            <div className="mmt-orders done">
                { getAllTransactionProcedures() }
            </div>
            )
    }
    const getAllTransactionProcedures = ():JSX.Element[] => {
        var result: JSX.Element[] = []

        modal.TransactionProcedures.forEach((proc, index) => {
            var tranProcHtml = getTransactionProcedureHTML(proc)
            tranProcHtml.forEach((procHtml, procIndex) => {
                result.push(
                    React.cloneElement(procHtml, {
                        key: `order_history_proc_${index}_${procIndex}`
                    })
                );
            });
        });

        return result;
    }
    const getTransactionProcedureHTML = (tranProc: TransactionProcedure): JSX.Element[] => {
        let transProcHTML: JSX.Element = (<div></div>);
        let transProcRejectHTML: JSX.Element = (<div></div>);
        let transShippingHTML: JSX.Element = (<div></div>)
        let transShipping = modal.TranShipping.find(x => x.TranProcId == tranProc.TransactionProcedureId)
        if (tranProc.DateOut != null) {
            transProcHTML = (
                <div className="row" key={"order_history_tranProc_" + tranProc.TransactionProcedureId}>
                    <div className="col-lg-2">
                        <strong>{DateUtil.convertJsonDateToShortDate(tranProc.DateOut)}</strong>
                    </div>
                    <div className="col-9">
                        <strong>Sent to State</strong>
                    </div>
                </div>
            )
        }

        if (tranProc.RejectReason != null) {
            transProcRejectHTML = (
                <div className="row" key={"order_history_tranProcReject_" + tranProc.TransactionProcedureId}>
                    <div className="col-lg-2">
                        <strong>{DateUtil.convertJsonDateToShortDate(tranProc.DateIn)}</strong>
                    </div>
                    <div className="col-9">
                        <strong>Rejected</strong>
                    </div>
                    <div className="col-lg-2">
                    </div>
                    <div className="col-9">
                        <em>{ tranProc.RejectReason }</em>
                    </div>
                </div>
            )
        }

        if (transShipping != undefined && modal.DriverAction) {
            transShippingHTML = (
                <div className="row" key={"tran_shipping_" + transShipping.TranShippingId}>
                    <div className="col-lg-2">
                    </div>
                    <div className="col-10">
                        <em>ETA: {DateUtil.convertJsonDateToShortDate(tranProc.ETA)}, Tracking #{transShipping.OutgoingTicketNum != "" ? transShipping.OutgoingTicketNum : "courier"}</em><br />
                        <span>DMV is waiting for a driver signature, please notify your driver immediately</span>
                    </div>
                </div>
            )
        }
        else if(transShipping != undefined) {
            transShippingHTML = (
                <div className="row" key={"tran_shipping_" + transShipping.TranShippingId}>
                    <div className="col-lg-2">
                    </div>
                    <div className="col-10">
                        <em>ETA: {DateUtil.convertJsonDateToShortDate(tranProc.ETA)}, Tracking #{transShipping.OutgoingTicketNum != "" ? transShipping.OutgoingTicketNum : "courier"}</em>
                    </div>
                </div>
            )
        }

        return (
            [transProcHTML, transShippingHTML, transProcRejectHTML]
            )
    }

    const getETAChangeHTML = (state: ITransactionState, modal: InvoiceDetailPageModal): JSX.Element => {
        if (state.hasETAChange) {
            return (
                <div key="order_history_etachange">
                    <div>
                        <i className="fa fa-circle mmt-circle"></i>
                        <span>ETA Change</span>
                    </div>
                    <div className="mmt-orders done">
                        <div className="row">
                            <div className="col-lg-2">
                                <strong>{DateUtil.convertJsonDateToShortDate(modal.ETAChange.OperationDate)}</strong>

                            </div>
                            <div className="col-9">
                                <em>ETA change from: {DateUtil.convertJsonDateToShortDate(modal.ETAChange.OldETA)} to {DateUtil.convertJsonDateToShortDate(modal.ETAChange.NewETA)} due to {modal.ETAChange.ETAChangeReasonMetaData.ChangeReason}</em>
                            </div>
                        </div>
                    </div> 
                </div>
            )
        }
        else {
            return (
                <div key="order_history_etachange"></div>
            )
        }
    }
    const getCompletedByDMVHTML = (state: ITransactionState, modal: InvoiceDetailPageModal): JSX.Element => {
        if (state.isCompletedByDMV) {
            return (
                <div key="order_history_completedByDMV">
                    <div>
                        <i className="fa fa-circle mmt-circle"></i>
                        <span>Completed by DMV</span>
                    </div>
                    <div className="mmt-orders done">
                        <div className="row">
                            <div className="col-lg-2">
                                <strong>{DateUtil.convertJsonDateToShortDate(modal.CompletedByDMV.DateCreated)}</strong>

                            </div>
                            <div className="col-9">
                                <em>Transaction is in house and will be shipped shortly</em>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div key="order_history_completedByDMV"></div>
            )
        }
    }
    const getCompletedHTML = (state: ITransactionState, modal: InvoiceDetailPageModal): JSX.Element => {

        if (state.isCompleted) {
            let completedDate: string = ""
            let completedText: string = "Completed"

            if (modal.Transaction != undefined) {
                completedDate = DateUtil.convertJsonDateToShortDate(modal.Transaction.DateComplete)
            }

            if (state.isCancelled) {
                completedText = "Cancelled"
            }


            return (
                <div key="order_history_completed">
                    <div>
                        <i className="fa fa-circle mmt-circle"></i>
                        <span>{ completedText }</span>
                    </div>
                    <div key="order_history_completed_details" className="mmt-orders" style={{borderLeft: "none"}}>
                        <div className="row">
                            <div className="col-lg-2">
                                <strong>{completedDate}</strong>

                            </div>
                            <div className="col-9">
                                <strong>{completedText}</strong>
                            </div>
                        </div>
                        {!state.isCancelled && getShippingResultsHTML(modal.ShippingResults)}
                        {!state.isCancelled && getTransactionProcedureResultsHTML(modal.TransactionProcedures)}
                    </div>
                </div>)
        } else {
            return (
                <div key="order_history_completed">
                    <i className="fa fa-circle-o mmt-circle"></i>
                    <span>Complete</span>
                </div>
                )
        }
    }

    const getShippingResultsHTML = (res: ShippingResult[]): JSX.Element[] => {
        let results: JSX.Element[] = [];
        let hasTrackingNumber: boolean = res.some(x => x.TrackingNumber != null)

        if (hasTrackingNumber) {
            res.filter(x => x.TrackingNumber != null).forEach(x => {
                results.push((
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-9">
                            <span>Tracking #{x.TrackingNumber.toString()}</span>
                        </div>
                    </div>
                ))
                results.push((
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-9">
                            <span>
                                Shipping Address: {x.ShipName } {x.ShipAddress1}, {x.ShipCity}, {x.ShipState} {x.ShipZip}
                            </span>
                        </div>
                    </div>
                ))
            })
        } else {
            results.push((
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-9">
                        <span>Tracking # - No incoming tracking number found.</span>
                    </div>
                </div>
                ))
        }
        

        return results;
    }

    const requirementsResolvedDate = (reqs: TransactionRequirement[]): string => {
        let dates: Date[] = [];

        reqs.forEach(req => {
            if (req.DateReceived != null) {
                dates.push(DateUtil.parseJsonDate(req.DateReceived.toString()));
            }
        });

        if (dates.length === 0) {
            return "No date available";
        }

        const maxDate = dates.reduce((a, b) => (a > b ? a : b));
        return maxDate.toLocaleDateString('en-US');
    }

    const getTransactionProcedureResultsHTML = (procs: TransactionProcedure[]): JSX.Element[] => {
        var result: JSX.Element[] = [];

        procs.filter(x => x.Result != null).forEach(proc => {
            result.push((
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-9">
                        <span className="mmt-primary">License Plate #{proc.Result} - Exp: { DateUtil.convertJsonDateToShortDate(proc.ResultDate)}</span>
                    </div>
                </div>
                ))
        })

        return result;
    }

    const getDisplayHTML = (): JSX.Element[] => {
        return [
            getReceivedHTML(state, modal),
            getResolvedHTML(state, modal),
            getSentToStateHTML(state, modal),
            getETAChangeHTML(state, modal),
            getCompletedByDMVHTML(state, modal),
            getCompletedHTML(state, modal)
        ]
    }

        return (
            <div className="mmt-card row card">
                <div className="card-header">
                    <div className="col-sm-12 d-flex justify-content-between align-items-center">
                        <h3 style={{ fontFamily: '"Noto Sans", Arial, sans-serif' }}>Order History</h3>
                    </div>
                </div>
                <div className="card-body">
                    <div className="mmt-progress">
                        { getDisplayHTML() }
                    </div>
                </div>
            </div>)
}