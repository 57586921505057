import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, FormControlLabel, Grid, styled, TextField, Tooltip, Typography, Zoom } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Requirement, ReqValueOverride } from '../Common';
import { AddressButton, CurrencyInput, DatePicker, FileUpload, LookupValueInput, NumberInput, PhoneInput, SSNInput, TextInput } from '../../Components/Input';
import { Lookup, TranReq, RequirementId as RequirementIdEnum } from '../../Types/common';
import CircleCheckboxAcertus from '../../Components/CircleCheckboxAcertus';
import { GreyEdit } from '../../Shared/Renewals';
import ShipAddressEntry from '../../Components/ShipAddressEntry';
import GeneralAddressEntry from '../../Components/GeneralAddressEntry';
const ReqListGrid = styled(Grid)({
    display: 'grid',
    gridTemplateColumns: '3fr .5fr 1fr ',
    gap: 2,
    margin: '10px 0px',
    alignItems: 'center'
})
const RequirementNameTitle = styled(Typography)({
    color: 'black',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    fontFamily: 'InterstateLight',
});
interface RequirementListProps {
    requirements: Requirement[],
    checkboxStates: {},
    requirementValues: TranReq[],
    reqValueOverrides: ReqValueOverride[],
    hasNoLienholder: boolean,
    formData: any,
    onCheckboxChange: Function,
    onInputChange: Function,
}
const RequirementList: React.FC<RequirementListProps> = ({ requirements, checkboxStates, requirementValues, formData, hasNoLienholder, onCheckboxChange, onInputChange }) => {
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [addressRequirementId, setAddressRequirementId] = useState<number | undefined>();
    const [showAddGenAddressDialog, setShowAddGenAddressDialog] = React.useState<boolean>(false);
    const [showAddAddressDialog, setShowAddAddressDialog] = React.useState<boolean>(false);
    const formatShippingAddress = (address) => {
        const formattedAddress = (
            <>
                {address.Attention}
                <br />
                {address.Data}
                <br />
                {address.StreetAddress}{address.AddressLine2 !== null && `, ${address.AddressLine2}`}
                <br />
                {address.City}, {address.StateAbrv} {address.ZipCode}
                <br />
                {address.Phone}
            </>
        );
        return formattedAddress;
    };
    const formatGeneralAddress = (address) => {
        const formattedAddress = (
            <>
                {address.Data}
                {address.StreetAddress && (
                     <>
                        <br />
                        {address.StreetAddress}{address.AddressLine2 !== null && `, ${address.AddressLine2}`}
                    </>
                )}
                {(address.City || address.StateAbrv || address.ZipCode) && (
                    <>
                        <br />
                        {address.City}, {address.StateAbrv} {address.ZipCode}
                    </>
                )}
                {address.Phone && (
                    <>
                        <br />
                        {address.Phone}
                    </>
                )} 
            </>
        );
        return formattedAddress;
    };

    const handleEditAddress = async (requirementId) => {
        await setIsEditing(true);
        await handleOpenAddressDialog(requirementId);
    }
    const handleEditGenAddress = async (requirementId) => {
        await setIsEditing(true);
        await handleOpenGenAddressDialog(requirementId);
    }
    const handleOpenAddressDialog = async (requirementId) => {
        await setAddressRequirementId(requirementId);
        await setShowAddAddressDialog(true);
    }
    const handleOpenGenAddressDialog = async (requirementId) => {
        await setAddressRequirementId(requirementId);
        await setShowAddGenAddressDialog(true);
    }

    const handleCloseAddressDialog = async () => {
        await setShowAddAddressDialog(false);
        await setShowAddGenAddressDialog(false);
    }
    const handleAddressChange = async (address) => {
        setShowAddAddressDialog(false);
        setShowAddGenAddressDialog(false);
        await onInputChange(address.requirementId, null, address);
    };
    const filterOutLienhholder = (requirementId) => {
        return requirementId === RequirementIdEnum.Lienholder && hasNoLienholder;
    }
    const getInputComponent = (requirement, onChange) => {
        const { RequirementId, FieldTypeId, HasLookupValue, LookupValues } = requirement;
        const address = requirementValues[RequirementId];
        const existingValue = formData?.reqs?.requirementValues ? formData?.reqs?.requirementValues?.[RequirementId]?.Data : requirementValues?.[RequirementId]?.Data;
        if (HasLookupValue) {
            const existingLookupValue = LookupValues.find(
                (option) => option.LookupValue === existingValue
            );
            return (
                <LookupValueInput
                    options={LookupValues}
                    value={existingLookupValue || null}
                    sx={{ width: '250px', height: '55px', cursor: 'pointer' }}
                    getOptionLabel={(option: Lookup) => option.LookupValue || ''}
                    isOptionEqualToValue={(option, value) => option.LookupValue === value.LookupValue}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            placeholder="Select"
                            onKeyDown={(e) => {
                                const inputValue = (e.target as HTMLInputElement).value;
                                const filteredOptions = LookupValues.filter((option) =>
                                    option.LookupValue.toLowerCase().includes(inputValue.toLowerCase())
                                );
                                if (e.key === 'Tab' && filteredOptions.length === 1) {
                                    onChange(RequirementId, filteredOptions[0].LookupValue);
                                }
                            }}
                        />
                    )}
                    onChange={(e, newValue) => {
                        if (newValue) {
                            onChange(RequirementId, newValue.LookupValue);
                        }
                    }}
                    renderOption={(props, option) => (
                        <li {...props} key={option.Id} style={{ fontFamily: 'InterstateLight', fontSize: '10px' }}>
                            {option.LookupValue}
                        </li>
                    )}
                />
            );
        }

        switch (FieldTypeId) {
            case 0: // Alphanumeric
                return <TextInput value={existingValue} onChange={(e) => onChange(RequirementId, e.target.value)} sx={{ width: '250px', height: '36px', marginBottom: '20px' }} />
            case 1: // Numeric
                return <NumberInput value={existingValue} onChange={(e) => onChange(RequirementId, e.target.value)} sx={{ width: '250px', height: '36px', marginBottom: '20px' }} />;
            case 2: // Currency
                return <CurrencyInput value={existingValue} onChange={(e) => onChange(RequirementId, e.target.value)} sx={{ width: '250px', height: '36px', marginBottom: '20px' }} />;
            case 3: // DateText
                return <DatePicker value={existingValue} onChange={(e) => onChange(RequirementId, e.target.value)} sx={{ width: '250px', height: '36px', marginBottom: '20px' }} />;
            case 5: // YesNo
                const requirement = formData.reqs?.requirements?.find(req => req.RequirementId === RequirementId) ?? requirements?.find(req => req.RequirementId === RequirementId);
                const yesChecked = (
                    requirement?.Value === 'YES'
                        ? true
                        : formData.reqs?.checkboxStates?.[RequirementId]?.YES
                        ?? checkboxStates[RequirementId]?.YES
                ) || false;

                const noChecked = (
                    requirement?.Value === 'NO'
                        ? true
                        : formData.reqs?.checkboxStates?.[RequirementId]?.NO
                        ?? checkboxStates[RequirementId]?.NO
                ) || false;
                return (
                    <Grid sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridTemplateAreas: "'yes no'" }} >
                        <Grid sx={{ gridArea: 'yes', margin: '0px 0px 0px 0px' }}>
                            <FormControlLabel
                                control={
                                    <CircleCheckboxAcertus
                                        checked={yesChecked}
                                        onChange={(e) => onCheckboxChange(RequirementId, 'YES', e.target.checked)}
                                    />
                                }
                                label="Yes"
                            />
                        </Grid>
                        <Grid sx={{ gridArea: 'no', margin: '0px 0px 0px 0px' }}>
                            <FormControlLabel
                                control={
                                    <CircleCheckboxAcertus
                                        checked={noChecked}
                                        onChange={(e) => onCheckboxChange(RequirementId, 'NO', e.target.checked)}
                                    />
                                }
                                label="No"
                            />
                        </Grid>
                    </Grid>
                );
            case 6: // Notes
                return <TextInput value={existingValue} onChange={(e) => onChange(RequirementId, e.target.value)} sx={{ width: '250px', height: '36px', marginBottom: '20px' }} />;
            case 7: // GeneralAddress
                // if reqid address exists, displays address w/ edit button
                return address ? (
                    <div style={{ width: '250px', height: '60px' }}>
                        <p style={{ float: 'left', fontFamily: 'InterstateLight' }}>{formatGeneralAddress(address)}</p>
                        <Tooltip TransitionComponent={Zoom} enterDelay={500} leaveDelay={500} title={<span style={{ fontSize: '12px' }}>Click to edit address</span>} placement="right">
                            <GreyEdit sx={{ float: 'right', marginTop: '30px' }} onClick={() => handleEditGenAddress(RequirementId)} />
                        </Tooltip>
                    </div>
                ) : (
                    <AddressButton onClick={() => handleOpenGenAddressDialog(RequirementId)} sx={{ width: '250px', height: '36px' }} />
                );
            case 8: // LesseeAddress
            case 9: // DebtorAddress
            case 10: // SendTitleAddress
            case 11: // SendTagAddress
            case 12: // VehicleAddress
            case 13: // LienAddress
                // if reqid address exists, displays address w/ edit button
                return address ? (
                    <div style={{ width: '250px', height: '60px' }}>
                        <p style={{ float: 'left', fontFamily: 'InterstateLight' }}>{formatShippingAddress(address)}</p>
                        <Tooltip TransitionComponent={Zoom} enterDelay={500} leaveDelay={500} title={<span style={{ fontSize: '12px' }}>Click to edit address</span>} placement="right">
                            <GreyEdit sx={{ float: 'right', marginTop: '30px' }} onClick={() => handleEditAddress(RequirementId)} />
                        </Tooltip>
                    </div>
                ) : (
                    <AddressButton onClick={() => handleOpenAddressDialog(RequirementId)} sx={{ width: '250px', height: '36px' }} />
                );
            case 14: // SocialSecurityNumber
                return <SSNInput value={existingValue} onChange={(e) => onChange(RequirementId, e.target.value)} sx={{ width: '250px', height: '36px', marginBottom: '20px' }} />;
            case 15: // FEIN
                return <TextInput value={existingValue} onChange={(e) => onChange(RequirementId, e.target.value)} sx={{ width: '250px', height: '36px', marginBottom: '20px' }} />;
            case 16: // USPhoneNumber
                return <PhoneInput value={existingValue} onChange={(e) => onChange(RequirementId, e.target.value)} sx={{ width: '250px', height: '36px', marginBottom: '20px' }} />;
            case 17: // OriginalDocument
            case 18: // CopyDocument
                return <FileUpload onChange={onChange} sx={null} />;
            default:
                return null;
        }
    };
    return (
        <React.Fragment>
            <ReqListGrid>
                {Array.isArray(requirements) && requirements.filter(requirement => !requirement.IsDocument && requirement.IsExternal && !filterOutLienhholder(requirement.RequirementId)).map(requirement => (
                    <React.Fragment key={requirement.RequirementId}>
                        <Grid item sx={{ margin: '10px 0px 0px 0px', display: 'flex' }}>
                            <RequirementNameTitle>{requirement.Name}</RequirementNameTitle>
                        </Grid>
                        <Grid item sx={{ margin: '13px 0px 0px 8px' }}>
                            {requirement.ExternalDescription && (
                                <Tooltip TransitionComponent={Zoom} enterDelay={500} leaveDelay={100} title={<span style={{ fontSize: '12px' }}>{requirement.ExternalDescription}</span>} placement="right" arrow>
                                    <HelpOutlineIcon sx={{ cursor: 'pointer', color: '#ED7300', height: '15px', width: '15px' }} />
                                </Tooltip>
                            )}
                        </Grid>
                        <Grid item sx={{ margin: '10px 0px 0px 0px' }}>
                            {getInputComponent(requirement, (requirementId, selectedValue) => onInputChange(requirementId, selectedValue))}
                        </Grid>
                    </React.Fragment>
                ))}
            </ReqListGrid>
            {showAddAddressDialog && (
                <Dialog open={showAddAddressDialog}>
                    <ShipAddressEntry
                        handleGoBack={handleCloseAddressDialog}
                        onSubmit={handleAddressChange}
                        RequirementId={addressRequirementId}
                        Editing={isEditing}
                        InputValues={requirementValues}
                        RegAddress={{
                            Data: '',
                            Attention: formData?.reg?.regName || '',
                            Phone: '',
                            StreetAddress: formData?.reg?.regAddress1 || '',
                            AddressLine2: formData?.reg?.regAddress2 || '',
                            City: formData?.reg?.regCity || '',
                            CountyId: formData?.reg?.regCounty || '',
                            State: formData?.reg?.selection?.selectedState || '',
                            StateId: formData?.reg?.selection?.selectedState.StateId || '',
                            StateName: formData?.reg?.selection?.selectedState.StateName || '',
                            StateAbrv: formData?.reg?.selection?.selectedState.StateAbbreviation || '',
                            ZipCode: formData?.reg?.regZip || '',
                            RequirementId: null,
                            DocumentName: '',
                            DocumentUploaded: false,
                            IsExternal: false,
                        }}
                        CustomerAddress={{
                            Data: '',
                            Attention: formData?.reqBy?.contactName || '',
                            Phone: '',
                            StreetAddress: formData?.reqBy?.custAddress1 || '',
                            AddressLine2: formData?.reqBy?.custddress2 || '',
                            City: formData?.reqBy?.custCity || '',
                            CountyId: formData?.reqBy?.custCounty || '',
                            State: formData?.reqBy?.individualModal?.selectedCustState || '',
                            StateId: formData?.reqBy?.individualModal?.selectedCustState.StateId || '',
                            StateName: formData?.reqBy?.individualModal?.selectedCustState.StateName || '',
                            StateAbrv: formData?.reqBy?.individualModal?.selectedCustState.StateAbbreviation || '',
                            ZipCode: formData?.reqBy?.custZip || '',
                            RequirementId: null,
                            DocumentName: '',
                            DocumentUploaded: false,
                            IsExternal: false,
                        }}
                    />
                </Dialog>
            )}
            {showAddGenAddressDialog && (
                <Dialog open={showAddGenAddressDialog}>
                    <GeneralAddressEntry
                        handleGoBack={handleCloseAddressDialog}
                        onSubmit={handleAddressChange}
                        RequirementId={addressRequirementId}
                        Editing={isEditing}
                        InputValues={requirementValues}
                        RegAddress={{
                            Data: formData?.reg?.regName || '',
                            Attention: '',
                            Phone: '',
                            StreetAddress: formData?.reg?.regAddress1 || '',
                            AddressLine2: formData?.reg?.regAddress2 || '',
                            City: formData?.reg?.regCity || '',
                            CountyId: formData?.reg?.regCounty || '',
                            State: formData?.reg?.selection?.selectedState || '',
                            StateId: formData?.reg?.selection?.selectedState.StateId || '',
                            StateName: formData?.reg?.selection?.selectedState.StateName || '',
                            StateAbrv: formData?.reg?.selection?.selectedState.StateAbbreviation || '',
                            ZipCode: formData?.reg?.regZip || '',
                            RequirementId: null,
                            DocumentName: '',
                            DocumentUploaded: false,
                            IsExternal: false,
                        }}
                        CustomerAddress={{
                            Data: formData?.reqBy?.contactName || '',
                            Attention: '',
                            Phone: '',
                            StreetAddress: formData?.reqBy?.custAddress1 || '',
                            AddressLine2: formData?.reqBy?.custddress2 || '',
                            City: formData?.reqBy?.custCity || '',
                            CountyId: formData?.reqBy?.custCounty || '',
                            State: formData?.reqBy?.individualModal?.selectedCustState || '',
                            StateId: formData?.reqBy?.individualModal?.selectedCustState.StateId || '',
                            StateName: formData?.reqBy?.individualModal?.selectedCustState.StateName || '',
                            StateAbrv: formData?.reqBy?.individualModal?.selectedCustState.StateAbbreviation || '',
                            ZipCode: formData?.reqBy?.custZip || '',
                            RequirementId: null,
                            DocumentName: '',
                            DocumentUploaded: false,
                            IsExternal: false,
                        }}
                    />
                </Dialog>
            )}
        </React.Fragment>
    );
};
export default RequirementList;