import { ToastProps } from "../../Types/common";

const DownloadReport = async (reportId: number, setToast: React.Dispatch<React.SetStateAction<ToastProps>>) => {
    try {
        const response = await fetch(window.location.origin + `/Reports/Download?reportId=${reportId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            setToast({
                show: true,
                message: "Failed to download report. Please try again or refresh the page.",
                severity: "error",
            });
            return;
        }

        const contentDisposition = response.headers.get("Content-Disposition");
        let fileName = "report.csv"; 
        if (contentDisposition && contentDisposition.indexOf("filename=") !== -1) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch && fileNameMatch.length > 1) {
                fileName = fileNameMatch[1];
            }
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
    } catch (error) {
        setToast({
            show: true,
            message: "An error occurred while downloading the report. Please try again or refresh the page.",
            severity: "error",
        });
        return;
    }
};

export default DownloadReport;
