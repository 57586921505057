import React, { useState, useEffect, useRef } from 'react';
import { Typography, NoSsr, styled, Tooltip, TextField, Grid, FormControlLabel, Dialog, Zoom, CircularProgress } from '@mui/material';
import { RenewalTitle, GreyEdit, SubmitButton, ResetGrid } from '../../Shared/Renewals';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import OrangeEndIconButton from '../../Components/OrangeEndIconButton';
import CircleCheckboxAcertus from '../../Components/CircleCheckboxAcertus';
import BackButton from '../../Components/BackButton';
import ShipAddressEntry from '../../Components/ShipAddressEntry';
import GeneralAddressEntry from '../../Components/GeneralAddressEntry';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import { NameId, Lookup, TranReq, RequirementId as RequirementIdEnum, RequirementId } from '../../Types/common';
import { LoadingDialog, OkButtonDialog } from '../../Components/Dialog';
import { AskIfAnswers, AskIfQuestions, ReqValueOverride, Requirement, Transaction, OrderFieldTitle } from '../Common';
import QuestionList from './QuestionList';
import RequirementList from './RequirementList';

const CircleLoading = styled(CircularProgress)({
    color: '#ED7300',
});
export const ReqGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'title title title''q1 leaseCheckboxY leaseCheckboxN''q2 bosCheckboxY bosCheckboxN'",
    gridTemplateColumns: '4fr 2fr 2fr',
    alignItems: 'center'
})
export const ButtonGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'backButton nextButton'",
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center'
})


interface RequirementsProps {
    handleNext: any;
    editing: any;
    handleGoBack: any;
    formData: any;
    rerun: any;
    setRerun: any;
    reqValueOverrides: ReqValueOverride[];
    setReqValueOverrides: Function;
    hasNoLienholder: boolean;
    setHasNoLienholder: Function;
    lienholderQuestionId: number | undefined;
    setLienholderQuestionId: Function;
}
export const Requirements: React.FC<RequirementsProps> = ({ handleNext, formData, handleGoBack, editing, rerun, setRerun, reqValueOverrides, setReqValueOverrides, hasNoLienholder, setHasNoLienholder, lienholderQuestionId, setLienholderQuestionId }) => {
    const [disableButton, setDisableButton] = React.useState<boolean>(true);
    const [foundAddress, setFoundAddress] = React.useState<boolean>(true);
    const [requirements, setRequirements] = React.useState<Requirement[]>(formData?.reqs?.requirements || []);
    const [transaction, setTransaction] = React.useState<Transaction[]>(formData?.reqs?.transaction || []);
    const [leaseCheckedYes, setLeaseCheckedYes] = React.useState<boolean>(formData?.reqs?.leaseCheckedYes || false);
    const [leaseCheckedNo, setLeaseCheckedNo] = React.useState<boolean>(formData?.reqs?.leaseCheckedNo || false);
    const [showButtonGrid, setShowButtonGrid] = React.useState<boolean>(false);
    const [loadingRequirements, setLoadingRequirements] = React.useState<boolean>(false);
    const [loadingQuestions, setLoadingQuestions] = React.useState<boolean>(false);
    const [completedQuestions, setCompletedQuestions] = React.useState<boolean>(formData?.reqs?.completedQuestions || false);
    const [showAddedReqDialog, setShowAddedReqDialog] = React.useState<boolean>(false);
    const [loadingEvaluate, setLoadingEvaluate] = React.useState<boolean>(false);
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [errorDialog, setErrorDialog] = React.useState<boolean>(false);
    const [error, setError] = useState<string | null>();
    const [typeElement, setTypeElement] = React.useState<NameId | null>(formData?.reqs?.typeElement || null)
    const [requirementValues, setRequirementValues] = React.useState<TranReq[]>(formData?.reqs?.requirementValues || []);
    const [questionAnswers, setQuestionAnswers] = React.useState<AskIfAnswers[]>(formData?.reqs?.questionAnswers || []);
    const [macroQuestions, setMacroQuestions] = React.useState<AskIfQuestions[]>(formData?.reqs?.macroQuestions || []);
    const [currentState, setCurrentState] = React.useState<string | null>(formData?.reg?.selection.selectedState)
    const [currentStateId, setCurrentStateID] = React.useState<number | null>(formData?.reg?.selection.selectedState.StateId)
    const [customerId, setCustomerId] = React.useState<number | null>(formData?.reqBy?.custId)
    const [currentCustomer, setCurrentCustomer] = React.useState<string | null>(formData?.reqBy?.custName)
    const [workType, setWorkType] = React.useState<NameId | null>(formData?.reqs?.workType || []);
    const [checkboxStates, setCheckboxStates] = useState({});

    const handleAddedAck = async () => {
        await setShowAddedReqDialog(false);
    }
    const handleError = () => {
        setErrorDialog(true);
    }
    const handleErrorAck = () => {
        setErrorDialog(false);
    }
    const handleEvaluateCompleted = async (transactioninfo, updatedReqs) => {
        const requirementData = {
            reqs: {
                requirements: updatedReqs, requirementValues, leaseCheckedNo, leaseCheckedYes, typeElement, currentState, currentCustomer, checkboxStates, workType, macroQuestions, completedQuestions, questionAnswers,
                transaction: transactioninfo
            },
        };
        await handleNext(requirementData);
    }
    const handleReqNext = async () => {
        setLoadingEvaluate(true);
        await evaluateMacros()
    }

    const handleLeaseChecked = async (e, label) => {
        if (label == 'Yes') {
            await setTypeElement({ Name: 'LEASE REG', Id: 32 });
            await setLeaseCheckedYes(true)
            await setLeaseCheckedNo(false)
            setCompletedQuestions(false);
            setShowButtonGrid(false);
        }
        else {
            await setTypeElement({ Name: 'REG', Id: 13 });
            await setLeaseCheckedNo(true)
            await setLeaseCheckedYes(false)
            setCompletedQuestions(false);
            setShowButtonGrid(false);
        }
    }

    const handleCheckboxChange = async (requirementId, selection, checked) => {
        setCheckboxStates(prevState => ({
            ...prevState,
            [requirementId]: {
                YES: selection === 'YES' ? checked : false,
                NO: selection === 'NO' ? checked : false,
            }
        }));

        if (formData.reqs) {
            formData.reqs.checkboxStates[requirementId] = {
                YES: selection === 'YES' ? checked : false,
                NO: selection === 'NO' ? checked : false,
            };
        }
        await handleInputChange(requirementId, selection, null);
    };

    const handleInputChange = async (requirementId, data, address) => {
        if (address) {
            setRequirementValues(prevValues => ({
                ...prevValues,
                [address.requirementId]: {
                    ...prevValues[address.requirementId],
                    Data: address.recipient,
                    Attention: address.attentionTo,
                    StreetAddress: address.address1,
                    AddressLine2: address.address2,
                    City: address.city,
                    CityId: null,
                    StateId: address.stateId,
                    StateAbrv: address.stateAbrv,
                    StateName: address.stateName,
                    ZipCode: address.zipCode,
                    Phone: address.phoneNumber,
                    CountyId: address.countyId,
                    CountryId: 1,
                    ShipViaId: null,
                    AirbillNumber: null
                }
            }));
            setRequirements(prevRequirements =>
                prevRequirements.map(req => {
                    if (req.RequirementId == address.requirementId) {
                        return {
                            ...req,
                            Address: {
                                ...req[address.requirementId],
                                Name1: address.recipient,
                                Attention: address.attentionTo,
                                Address1: address.address1,
                                Address2: address.address2,
                                City: address.city,
                                CityId: null,
                                State: {
                                    StateId: address.stateId,
                                    StateAbrv: address.stateAbrv,
                                    StateName: address.stateName,
                                },
                                ZipCode: address.zipCode,
                                Phone: address.phoneNumber,
                                CountyId: address.countyId,
                                CountryId: 1,
                                ShipViaId: null,
                                AirbillNumber: null
                            }
                        };
                    }
                    return req;
                })
            );
        } else {
            const Data = data != null ? data.toUpperCase() : null;

            setRequirementValues(prevValues => ({
                ...prevValues,
                [requirementId]: {
                    ...prevValues[requirementId],
                    Data,
                }
            }));
            setRequirements(prevRequirements =>
                prevRequirements.map(req => {
                    if (req.RequirementId == requirementId) {
                        return {
                            ...req,
                            Value: Data
                        };
                    }
                    return req;
                })
            );
            if (formData?.reqs?.requirementValues && formData.reqs.requirementValues[requirementId]) {
                formData.reqs.requirementValues[requirementId] = {
                    ...formData.reqs.requirementValues[requirementId],
                    Data,
                };
            }
            if (formData?.reqs?.requirements && formData.reqs.requirements[requirementId]) {
                formData.reqs.requirements[requirementId] = {
                    ...formData.reqs.requirements[requirementId],
                    Value: Data
                };
            }
        }
    };



    useEffect(() => {
        macroQuestions.forEach(q => {
            if (q.Question.includes("IS THERE A LIEN")) {
                setLienholderQuestionId(q.AskIfId);
            }
        });
    }, [macroQuestions]);

    //gets requirements and macros after lease/BOS checkboxes are checked
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (rerun) {
                    getMacroQuestions(typeElement?.Id, formData.reg.selection.selectedState.StateId, formData.reqBy.custId)
                    setLoadingQuestions(true)
                    setLoadingRequirements(false)
                }
                else {
                    if ((leaseCheckedNo != false || leaseCheckedYes != false)) {
                        if (formData?.reqs?.requirements != null &&
                            currentState == formData?.reqs?.currentState &&
                            currentCustomer == formData?.reqBy?.custName &&
                            typeElement?.Id == formData?.reqs?.typeElement.Id) {
                            setLoadingQuestions(false)
                            setLoadingRequirements(false)
                            setCompletedQuestions(true)
                        }
                        else {
                            getMacroQuestions(typeElement?.Id, formData.reg.selection.selectedState.StateId, formData.reqBy.custId)
                            setLoadingQuestions(true)
                            setLoadingRequirements(false)
                        }
                    }
                }
            } catch (error) {
                console.error("An error occurred during fetch data:", error);
            }
        };

        fetchData();
        return () => { };
    }, [leaseCheckedNo, leaseCheckedYes, typeElement]);

    const getMacroQuestions = async (typeElement, state, customerId) => {
        const requestOptions = {
            method: 'GET',
        };

        const url = `${window.location.origin}/CreateOrders/GetMacroQuestions?stateId=${state}&typeElementId=${typeElement}&customerId=${customerId}`;

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                console.error("getMacroQuestions response failure:", response.status, response.statusText);
                console.error("Request URL:", response.url);

                response.headers.forEach((value, key) => {
                    console.error(`${key}: ${value}`);
                });

                const errorDetails = await response.text();
                console.error("Response Body:", errorDetails);

                return false;
            } else {
                const data = await response.json();
                if (data.success) {
                    setMacroQuestions(data.askIfQuestions);
                    setLoadingQuestions(false);
                    setRerun(false);
                }
            }
        } catch (error: any) {
            console.error('An error occurred during getMacroQuestions', error);
            console.error('Error message:', error.message);
            console.error('Stack trace:', error.stack);
            return false;
        }
    };
    const getRequirements = async () => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                typeElementId: typeElement?.Id,
                stateId: currentStateId,
                customerId: customerId,
                askIfAnswers: Object.keys(questionAnswers).map(key => ({
                    id: parseInt(key),
                    answer: questionAnswers[key].Answer
                }))
            }),
        };
        const url = `${window.location.origin}/CreateOrders/GetRequirements`;

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                console.error("get req and field types response failure:", response.status, response.statusText);
                console.error("Request URL:", response.url);
                console.error("Response Headers:", response.headers);
            } else {
                const data = await response.json();
                setRequirements(data.requirementsWithLookups);
                updateRequirementsWithOverride(data.requirementsWithLookups);
                setWorkType(data.workType);
                setShowButtonGrid(true);
                setLoadingRequirements(false)
            }
        } catch (error: any) {
            console.error('An error occurred during req and field types', error);
            console.error('Error message:', error.message);
            console.error('Stack trace:', error.stack);
            return false;
        }
    };
    const evaluateMacros = async () => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                vin: formData.vehicle.vin,
                vehicleYear: formData.vehicle.year,
                vehicleMake: formData.vehicle.make,
                vehicleModel: formData.vehicle.model,
                contact: {
                    Id: formData.reqBy.contact,
                    Name: formData.reqBy.contactName
                },
                customer: {
                    Id: formData.reqBy.custId,
                    Name: formData.reqBy.custName
                },
                state: {
                    Id: formData.reg.selection?.selectedState?.StateId,
                    Name: formData.reg.selection?.selectedState?.StateName
                },
                workTypeId: workType?.Id,
                typeElement: {
                    Id: typeElement?.Id,
                    Name: typeElement?.Name
                },
                garagedAddress: {
                    Attention: null,
                    Name1: formData.reg?.regName,
                    Phone: null,
                    Address1: formData.reg?.regAddress1,
                    Address2: formData.reg?.regAddress2,
                    City: formData.reg?.regCity,
                    County: formData.reg?.regCountyName,
                    State: formData.reg?.selection.selectedState.StateName,
                    StateAbbreviation: formData.reg?.selection?.selectedState?.StateAbbreviation,
                    Zip: formData.reg?.regZip,
                    CityId: null,
                    CountyId: formData.reg?.regCounty,
                    StateId: formData.reg?.selection.selectedState.StateId,
                    CountryId: 1,
                    ShipViaId: null,
                    AirbillNumber: null
                },
                requirements: formData.reqs?.requirements ?? requirements

            }),
        };
        const url = `${window.location.origin}/CreateOrders/EvaluateMacros`;

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                console.error("evaluate macros response failure:", response.status, response.statusText);
                console.error("Request URL:", response.url);

                response.headers.forEach((value, key) => {
                    console.error(`${key}: ${value}`);
                });

                const errorDetails = await response.text();
                console.error("Response Body:", errorDetails);

                return false;
            } else {
                const data = await response.json();
                if (data.success) {
                    if (data.NoChange) {
                        let updatedRequirements = requirements;
                        if (data.transaction?.NewRequirements) {
                            updatedRequirements = [...requirements, ...data.transaction.NewRequirements];
                            await setRequirements(updatedRequirements);
                            data.transaction.NewRequirements.forEach(req => {
                                if (req.Value != "" || req.Value != null) {
                                    handleInputChange(req.RequirementId, req.Value, null);
                                }
                            });
                            updateRequirementsWithOverride(data.transaction?.NewRequirements);
                        }
                        await setTransaction(data.transaction);
                        await setLoadingEvaluate(false);
                        await handleEvaluateCompleted(data.transaction, updatedRequirements);
                    }
                    else {
                        if (data.transaction?.NewRequirements) {
                            const updatedRequirements = [...requirements, ...data.transaction.NewRequirements];
                            await setRequirements(updatedRequirements);
                        }
                        data.transaction.NewRequirements.forEach(req => {
                            if (req.Value != "" || req.Value != null) {
                                handleInputChange(req.RequirementId, req.Value, null);
                            }
                        });
                        updateRequirementsWithOverride(data.transaction?.NewRequirements);
                        await setLoadingEvaluate(false);
                        await setShowAddedReqDialog(true);
                    }
                }
                else {
                    console.error('An error occurred during evaluate macros - data failure', error);
                    await setError("Error occurred. Please try again.");
                    await handleError();
                    await setLoadingEvaluate(false);
                }
            }
        } catch (error: any) {
            console.error('An error occurred during evaluate macros', error);
            console.error('Error message:', error.message);
            console.error('Stack trace:', error.stack);
            await setError("Error occurred. Please try again.");
            await handleError();
            await setLoadingEvaluate(false);
        }
    };

    const updateRequirementsWithOverride = (requirements: Requirement[]) => {
        requirements.forEach(r => {
            const valueOverride = reqValueOverrides.find(o => o.RequirementId === r.RequirementId)?.RequirementValue || undefined;
            if (valueOverride) {
                handleInputChange(r.RequirementId, valueOverride, null);
            }
        });
    }
    const handleCompletedQuestions = async () => {
        if (formData.reqs?.requirements) {
            setCompletedQuestions(true);
            setLoadingRequirements(false)
            setShowButtonGrid(true);
        }
        else {
            setCompletedQuestions(true);
            setLoadingRequirements(true)
            await getRequirements()
        }
    }

    const handleQuestionAnswered = (questionId, answer) => {
        setQuestionAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: {
                Id: questionId,
                Answer: answer
            }
        }));
        if (formData?.reqs?.answerValues && formData.reqs.answerValues[questionId]) {
            formData.reqs.answerValues[questionId] = {
                ...formData.reqs.answerValues[questionId],
                Id: questionId,
                Answer: answer
            };
        }
        if (questionId === lienholderQuestionId && !answer) {
            setHasNoLienholder(true);
            setReqValueOverrides([...reqValueOverrides,
            { RequirementId: RequirementId.Lienholder, RequirementValue: 'None' }
            ]);
        }
    }
    useEffect(() => {
        const checkForFilledOutRequirements = async () => {
            const requiredReqs = requirements.filter(requirement => !requirement.IsDocument && requirement.IsExternal && !(hasNoLienholder && requirement.RequirementId == RequirementId.Lienholder));
            const allRequirementsFulfilled = requiredReqs.every(requirement => {
                const { RequirementId } = requirement;
                const inputValue = requirementValues[RequirementId]

                return inputValue && inputValue.Data !== '';
            });

            if (allRequirementsFulfilled) {
                setDisableButton(false);
            } else {
                setDisableButton(true);
            }
        }
        checkForFilledOutRequirements();
        return () => { };
    }, [requirements, requirementValues]);

    return (
        <NoSsr>
            <Zoom in={foundAddress}>
                <div style={{ width: '502px' }}>
                    <ReqGrid>
                        <Grid sx={{ gridArea: 'title', margin: '20px 0px 0px 0px' }} >
                            <RenewalTitle>Requirements</RenewalTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'q1', margin: '20px 0px 0px 0px' }} >
                            <OrderFieldTitle>IS THIS A LEASE?</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'leaseCheckboxY', margin: '20px 0px 0px 0px' }} >
                            <FormControlLabel
                                control={
                                    <CircleCheckboxAcertus
                                        checked={leaseCheckedYes}
                                        onChange={(e) => handleLeaseChecked(e, 'Yes')}
                                    />
                                }
                                label="Yes"
                            />
                        </Grid>
                        <Grid sx={{ gridArea: 'leaseCheckboxN', margin: '20px 0px 0px 0px' }} >
                            <FormControlLabel
                                control={
                                    <CircleCheckboxAcertus
                                        checked={leaseCheckedNo}
                                        onChange={(e) => handleLeaseChecked(e, 'No')}
                                    />
                                }
                                label="No"
                            />
                        </Grid>
                    </ReqGrid>
                    {!loadingQuestions ? (
                        <QuestionList macroQuestions={macroQuestions} questionAnswers={questionAnswers} onQuestionAnswered={handleQuestionAnswered} onAllQuestionsAnswered={handleCompletedQuestions} />
                    ) : (
                        <Zoom in={loadingQuestions}>
                            <div style={{ height: '150px', width: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <CircleLoading sx={{ color: '#ED7300' }} />
                                <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', marginLeft: '20px' }}>Preparing Questions</Typography>
                            </div>
                        </Zoom>
                    )}
                    {completedQuestions && !loadingRequirements ? (
                        <RequirementList requirements={requirements} checkboxStates={checkboxStates} requirementValues={requirementValues} reqValueOverrides={reqValueOverrides} hasNoLienholder={hasNoLienholder} formData={formData} onCheckboxChange={handleCheckboxChange} onInputChange={handleInputChange} />

                    ) : (
                        <Zoom in={loadingRequirements}>
                            <div style={{ height: '150px', width: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <CircleLoading sx={{ color: '#ED7300' }} />
                                <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', marginLeft: '20px' }}>Generating Requirements</Typography>
                            </div>
                        </Zoom>
                    )}
                    {showButtonGrid && !loadingQuestions && (
                        <ButtonGrid>
                            <Grid sx={{ gridArea: 'backButton', marginTop: '30px' }}>
                                <BackButton
                                    label="Back"
                                    onClick={handleGoBack}
                                    variant="text"
                                    startIcon={<ArrowBackIcon sx={{ width: '18px', height: '18px', color: '#ED7300' }} />}
                                    disabled={editing}
                                    sx={{ marginBottom: '20px' }}
                                />
                            </Grid>
                            <Grid sx={{ gridArea: 'nextButton', display: 'grid', justifyContent: 'end', marginTop: '30px' }}>
                                <OrangeEndIconButton
                                    label={editing ? "Save" : "Submissions"}
                                    onClick={handleReqNext}
                                    variant="contained"
                                    endIcon={editing ? <SaveIcon sx={{ color: 'white' }} /> : <ArrowForwardIcon sx={{ color: 'white' }} />}
                                    disabled={disableButton}
                                    sx={{ width: '168px', height: '36px', marginBottom: '20px' }}
                                />
                            </Grid>
                        </ButtonGrid>
                    )}

                    {loadingEvaluate && (
                        <LoadingDialog
                            open={loadingEvaluate}
                            style={{ height: '150px', width: '450px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            sx={{ fontSize: '22px', fontFamily: 'Interstate', marginLeft: '20px' }}
                            message="Evaluating Answers"
                        />
                    )}
                    {showAddedReqDialog && (
                        <OkButtonDialog
                            open={showAddedReqDialog}
                            style={{ height: '150px', width: '580px', textWrap: 'pretty', display: 'grid', margin: '10px', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}
                            sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}
                            message="New requirements were added based on your answers. Please check for new Requirements."
                            close={handleAddedAck}
                        />
                    )}
                    <Dialog open={errorDialog}>
                        <Zoom in={errorDialog}>
                            <div style={{ height: '150px', width: '600px', display: 'grid', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}>
                                <Grid container style={{ height: '100%', justifyContent: 'center' }}>
                                    <ResetGrid>
                                        <Grid sx={{ gridArea: 'question', marginBottom: '20px' }}>
                                            <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}>{error}</Typography>
                                        </Grid>
                                        <Grid sx={{ gridArea: 'yesButton' }}>
                                            <SubmitButton onClick={handleErrorAck}>Ok</SubmitButton>
                                        </Grid>
                                    </ResetGrid>
                                </Grid>
                            </div>
                        </Zoom>
                    </Dialog>
                </div>
            </Zoom>
        </NoSsr>
    );
}