import React, { useState, useEffect, useRef } from 'react';
import { Tab, Typography, NoSsr, styled, IconButton, Tooltip, Button, Box, FormControl, Autocomplete, createFilterOptions, TextField, Grid, Divider, Dialog, FormControlLabel, Zoom, CircularProgress, Checkbox } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getStates } from './IndividualReg';
import { SubmitButton, GoBackButton, FieldLabel, RenewalTitle, ShipCityGrid, SummaryGrid, ShippingTitleGrid, ShippingGrid, Numbers } from '../../Shared/Renewals';
import { State } from '../../Types/common';

export interface IndividualModal {
    selectedShipState: State | null;
}


export const IndividualShipping = ({ handleGoBack, handleNext, formData, editing }) => {

    const [states, setStates] = useState<State[]>([]);
    const [individualModal, setIndividualModal] = useState<IndividualModal>({ selectedShipState: formData?.shipping?.individualModal.selectedShipState ?? states[1], });
    const [shipName, setShipName] = useState(formData?.shipping?.shipName ?? null);
    const [shipAddress1, setShipAddress1] = useState(formData?.shipping?.shipAddress1 ?? null);
    const [shipAddress2, setShipAddress2] = useState(formData?.shipping?.shipAddress2 ?? null);
    const [shipCity, setShipCity] = useState(formData?.shipping?.shipCity ?? null);
    const [shipZip, setShipZip] = useState(formData?.shipping?.shipZip ?? null);
    const [shipPhone, setShipPhone] = useState(formData?.shipping?.shipPhone ?? null);
    const [shipAttention, setShipAttention] = useState(formData?.shipping?.shipAttention ?? null);
    const [regChecked, setRegChecked] = React.useState<boolean>(formData?.shipping?.regChecked ?? false);
    const [myChecked, setMyChecked] = React.useState<boolean>(formData?.shipping?.myChecked ?? false);
    const [showVerifyDialog, setShowVerifyDialog] = React.useState<boolean>(false);
    const [disableButton, setDisableButton] = React.useState<boolean>(true); 
    const [addressError, setAddressError] = React.useState<boolean>(false);

    const { reg, reqBy } = formData;

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option: State) => option.StateName,
        limit: 80
    });
    const handleShippingNext = async () => {
        await setShowVerifyDialog(true);
        await verifyShippingAddress();
    }

    const handleFormSubmitWithChanges = async (formData) => {
        await handleNext(formData);
    }
    const handleErrorClose = async () => {
        setAddressError(false);
    }
    const handleFormSubmit = async (countyId) => {
        const formData = await (async () => {
            return {
                shipping: { shipAttention, shipName, shipAddress1, shipAddress2, shipCity, shipZip, shipCounty:countyId, shipPhone, individualModal, myChecked, regChecked },
            };
        })();
        await handleNext(formData);
    };

    const handleRegCheck = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setMyChecked(false);
            setRegChecked(true);
        }
        else {
            setRegChecked(false);
        }
        setShipAttention(isChecked ? reg.regName : null);
        setShipName(isChecked ? null : '');
        setShipAddress1(isChecked ? reg.regAddress1 : null);
        setShipAddress2(isChecked ? reg.regAddress2 : null);
        setShipCity(isChecked ? reg.regCity : null);
        setIndividualModal((prevModal) => ({
            ...prevModal,
            selectedShipState: isChecked ? reg.individualModal.selectedState : null,
        }));
        setShipZip(isChecked ? reg.regZip : null);
        setShipPhone(isChecked ? null: '');
    }

    const handleMyCheck = async (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setMyChecked(true);
            setRegChecked(false);
        }
        else {
            setMyChecked(false);
        }
        
        setShipAttention(isChecked ? reqBy.contactName : null);
        setShipName(isChecked ? reqBy.custName : null);
        setShipAddress1(isChecked ? reqBy.custAddress1 : null);
        setShipAddress2(isChecked ? reqBy.custAddress2 : null);
        setShipCity(isChecked ? reqBy.custCity : null);
        setIndividualModal((prevModal) => ({
            ...prevModal,
            selectedShipState: isChecked ? reqBy.individualModal.selectedCustState : null,
        }));
        setShipZip(isChecked ? reqBy.custZip : null);
        setShipPhone(isChecked ? reqBy.custPhone : null);
    }

    const formatShippingAddress = () => {
        if (shipAttention && shipName && shipAddress1 && shipCity && individualModal.selectedShipState && shipZip && shipPhone) {
            const formattedAddress = (
                <React.Fragment>
                    {shipAttention}
                    <br />
                    {shipName}
                    <br />
                    {shipAddress1}{shipAddress2 !== null && `, ${shipAddress2}`}
                    <br />
                    {shipCity}, {individualModal.selectedShipState.StateAbbreviation} {shipZip}
                    <br />
                    {shipPhone}
                </React.Fragment>
            );
            return formattedAddress;
        }
        return ''; 
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const statesData = await getStates();
                setStates(statesData);
            } catch (error) {
                console.error("An error occurred during data fetching:", error);
            }
        };

        fetchData();
        return () => { };
    }, []);

    useEffect(() => {
        if (shipAttention != null && shipName != null && shipAddress1 != null && shipCity != null && shipZip != null && shipPhone != null && individualModal.selectedShipState != undefined) {
            if (shipAttention.trim() != '' && shipName.trim() != '' && shipAddress1.trim() != '' && shipCity.trim() != '' && shipZip.trim() != '' && shipPhone.trim() != '') {
                setDisableButton(false);
            }
            else {
                setDisableButton(true);
            }
        }
        else {
            setDisableButton(true);
        }
    }, [shipAttention, shipName, shipAddress1, shipCity, shipZip, shipPhone, individualModal.selectedShipState]);

    const verifyShippingAddress = async () => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                address1: shipAddress1,
                city: shipCity,
                state: individualModal.selectedShipState?.StateAbbreviation,
                zipCode: shipZip
            }),
        };
        const url = `${window.location.origin}/CreateOrders/VerifyAddress`;

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                console.error("Verify address response failure:", response.status, response.statusText);
                console.error("Request URL:", response.url);
                console.error("Response Headers:", response.headers);
            } else {
                const data = await response.json();
                if (data.changesMade) {
                    const formData = await (async () => {
                        return {
                            shipping: {
                                individualModal: {
                                    selectedShipState: {
                                        StateId: data.stateId,
                                        StateName: data.stateName,
                                        StateAbbreviation: data.stateAbbreviation,
                                        CountryId: 1,
                                    },
                                },
                                shipAttention,
                                shipName,
                                shipAddress1: data.address1,
                                shipAddress2,
                                shipCity: data.city,
                                shipZip: data.zipCode,
                                shipCounty: data.county,
                                shipPhone,
                                myChecked,
                                regChecked
                            }
                        };
                    })();
                    await setShowVerifyDialog(false);
                    await handleFormSubmitWithChanges(formData);
                }
                else {
                    if (data.verifyFailed) {
                        console.error("verifyFailed");
                        setAddressError(true);
                        await setShowVerifyDialog(false);
                    }
                    else {
                        await setShowVerifyDialog(false);
                        await handleFormSubmit(data.county);
                    }
                }
                await setShowVerifyDialog(false);
            }
        } catch (error: any) {
            console.error('An error occurred during shipping address verification tsx:', error);
            console.error('Error message:', error.message);
            console.error('Stack trace:', error.stack);
            return false;
        }
    };
    return (
        <NoSsr>
            <div className="container height-550">
                <div>
                    <Grid container style={{ height: '100%', justifyContent: 'center' }}>
                        <ShippingTitleGrid item xs={12}>
                            <br />
                            <Grid sx={{ gridArea: 'shippingTitle', margin: '30px 0px' }}>
                                <RenewalTitle>Shipping Information</RenewalTitle>
                            </Grid>
                            <Grid sx={{ gridArea: 'circles', margin: '30px 0px' }} >
                                <div className="mmt-progress">
                                    <div>
                                        <Numbers src="../Content/assets/img/icons/Grey_One.png" /><Numbers src="../Content/assets/img/icons/Grey_Two.png" /><Numbers src="../Content/assets/img/icons/Orange_Three.png" />
                                    </div>
                                </div>
                            </Grid>
                        </ShippingTitleGrid>
                        <ShippingGrid item xs={12}>
                            <Grid sx={{ gridArea: 'shipAttention' }}>
                                <FieldLabel>Attention To</FieldLabel>
                                <FormControl required>
                                    <TextField
                                        placeholder="First and Last Name"
                                        value={shipAttention || ''}
                                        name="name"
                                        required
                                        sx={{ marginRight: '23px', width: '371px' }}
                                        onChange={(e) => {
                                            const newAttention = e.target.value;
                                            if (newAttention.length <= 80) { // limit attn to 80 digits
                                                setShipAttention(newAttention);
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid sx={{ gridArea: 'shipName' }}>
                                <FieldLabel>Recipient Name</FieldLabel>
                                <FormControl required>
                                    <TextField
                                        placeholder="Company Name"
                                        value={shipName || ''}
                                        name="name"
                                        required
                                        sx={{ marginRight: '23px', width: '371px' }}
                                        onChange={(e) => {
                                            const newShipName = e.target.value;
                                            if (newShipName.length <= 80) { // limit name to 80 digits
                                                setShipName(newShipName);
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid sx={{ gridArea: 'regCheckBox2', display: 'grid', alignContent: 'center', justifyItems:'end' }}>
                                <FormControlLabel control={<Checkbox checked={myChecked} onClick={handleMyCheck} />} label={<Typography sx={{ fontFamily: 'Interstate', fontSize: '14px' }} >Use My Address</Typography>} />
                            </Grid>
                            <Grid sx={{ gridArea: 'regCheckBox', display: 'grid', alignContent: 'center' }}>
                                <FormControlLabel control={<Checkbox checked={regChecked} onClick={handleRegCheck} />} label={<Typography sx={{ fontFamily: 'Interstate', fontSize: '14px' }} >Use Registrant Address</Typography>} />
                            </Grid>
                            <Grid sx={{ gridArea: 'shipAddress' }}>
                                <FieldLabel>Recipient Address</FieldLabel>
                                <FormControl required>
                                    <TextField
                                        placeholder="1000 Street Rd"
                                        value={shipAddress1 || ''}
                                        name="address"
                                        required
                                        sx={{ marginRight: '23px', width: '565px' }}
                                        onChange={(e) => {
                                            const newAddress1 = e.target.value;
                                            if (newAddress1.length <= 80) { // limit addr1 to 80 digits
                                                setShipAddress1(newAddress1);
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid sx={{ gridArea: 'shipAddress2' }}>
                                <FieldLabel>Suite (if applicable)</FieldLabel>
                                <FormControl required>
                                    <TextField
                                        placeholder="Apt 10"
                                        value={shipAddress2 || ''}
                                        name="address2"
                                        required
                                        sx={{ marginRight: '23px', width: '170px' }}
                                        onChange={(e) => {
                                            const newAddress2 = e.target.value;
                                            if (newAddress2.length <= 80) { // limit addr2 to 80 digits
                                                setShipAddress2(newAddress2);
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            </ShippingGrid>
                            <ShipCityGrid item xs={12}> 
                            <Grid sx={{ gridArea: 'shipCity' }}>
                                <FieldLabel>City</FieldLabel>
                                <FormControl required>
                                    <TextField
                                        placeholder="St Town"
                                        value={shipCity || ''}
                                        name="city"
                                        required
                                        sx={{ marginRight: '23px', width: '283px' }}
                                        onChange={(e) => {
                                            const newCity = e.target.value;
                                            if (newCity.length <= 30) { // limit city to 30 digits
                                                setShipCity(newCity);
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid sx={{ gridArea: 'shipState' }}>
                                <FieldLabel>State</FieldLabel>
                                <FormControl required>
                                    <Autocomplete
                                        options={states}
                                        id="state"
                                        value={individualModal.selectedShipState || null}
                                        filterOptions={filterOptions}
                                        sx={{ width: "68px", cursor: 'pointer', fontFamily: 'InterstateLight' }}
                                        getOptionLabel={(option: State) => option.StateAbbreviation}
                                        renderInput={(params) => <TextField {...params} required />}
                                        isOptionEqualToValue={(option, value) => option.StateId === value?.StateId}
                                        onChange={(e, newValue) => {
                                            if (newValue) {
                                                setIndividualModal({ ...individualModal, selectedShipState: newValue });
                                            }
                                        }}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.StateId} style={{ fontFamily: 'InterstateLight', fontSize: '10px' }} >
                                                    {option.StateAbbreviation}
                                                </li>
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid sx={{ gridArea: 'shipZip' }}>
                                <FieldLabel>Zip</FieldLabel>
                                <FormControl required>
                                    <TextField
                                        placeholder="12345"
                                        value={shipZip || ''}
                                        name="zip"
                                        required
                                        sx={{ marginRight: '23px', width: '165px' }}
                                        onChange={(e) => {
                                            const newZip = e.target.value;
                                            if (newZip.length <= 5) { // limit zip to 5 digits
                                                setShipZip(newZip);
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid sx={{ gridArea: 'shipPhone' }}>
                                <FieldLabel>Phone Number</FieldLabel>
                                <FormControl required>
                                    <TextField
                                        placeholder="913-236-9923"
                                        value={shipPhone || ''}
                                        name="phone"
                                        required
                                        sx={{ marginRight: '23px', width: '165px' }}
                                        onChange={(e) => {
                                            const newPhone = e.target.value;
                                            if (newPhone.length <= 26) { // limit phone to 26 digits
                                                setShipPhone(newPhone);
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </ShipCityGrid>
                            <SummaryGrid item xs={12}>
                                <Grid sx={{ gridArea: 'shipTo' }}>
                                    <Typography sx={{ fontFamily: 'Interstate', fontSize: '13px' }} >
                                        Ship To:
                                    </Typography>
                                </Grid>
                                <Grid sx={{ gridArea: 'addressSummary' }}>
                                    <Typography sx={{ fontFamily: 'InterstateLight', fontSize: '13px' }} >
                                        {formatShippingAddress()}
                                    </Typography>
                                </Grid>
                                <Grid sx={{ gridArea: 'backButton', marginTop: '30px' }}>
                                    <GoBackButton onClick={handleGoBack} variant="text"><ArrowBackIcon sx={{ color: '#ED7300' }} />Back</GoBackButton>
                                </Grid>
                                <Grid sx={{ gridArea: 'nextButton', display: 'grid', justifyContent: 'end', marginTop: '30px' }}>
                                    <SubmitButton sx={{ width: '191px' }} disabled={disableButton} onClick={handleShippingNext} >{editing ? "Save" : "Next"}</SubmitButton>
                                </Grid>
                            </SummaryGrid>
                    </Grid>
                    <Dialog open={showVerifyDialog}>
                        <Zoom in={showVerifyDialog}>
                            <div style={{ height: '150px', width: '450px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <CircularProgress sx={{ color: '#ED7300' }} /><Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', marginLeft: '20px' }}>Verifying Shipping Address</Typography>
                            </div>
                        </Zoom>
                    </Dialog>
                    <Dialog open={addressError}>
                        <Zoom in={addressError}>
                            <div style={{ height: '150px', width: '700px', display: 'grid', alignItems: 'center', justifyContent: 'center', justifyItems:'right' }}>
                                <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}>Unable to verify address. Please check spelling and try again.</Typography>
                                <SubmitButton onClick={handleErrorClose}>OK</SubmitButton>
                            </div>
                        </Zoom>
                    </Dialog>
                </div>
            </div>
        </NoSsr>
    );
}
