import React from "react";
import { DataGrid, GridColDef, GridFilterModel, GridRowId, GridRowModesModel } from "@mui/x-data-grid";
import { styled } from '@mui/material/styles';
import { Button, CircularProgress } from '@mui/material/';
import OrangeHeaderButton from "../Components/OrangeHeaderButton";

interface DataGridAcertusProps {
    width?: string;
    maxHeight?: string;
    minHeight?: string;
    columns: GridColDef[];
    rows: Object[];
    loading: boolean;
    pageSizeOptions: number[];
    page: number;
    pageSize: number;
    rowCount: number;
    onPageChange: (newPage: number) => void;
    onPageSizeChange: (newPageSize: number) => void;
    hideFooterSelectedRowCount?: boolean;
    checkboxSelection?: boolean;
    onRowSelectionModelChange?: (selectionModel: GridRowId[]) => void;
    initialFilterModel?: GridFilterModel;
    initialRowModesModel?: GridRowModesModel;
    processRowUpdate?;
    onRowClick?;
    handleCancelLoading?: () => void; 
};

const StyledDataGrid = styled(DataGrid)(({ }) => ({
    '& .MuiDataGrid-row:nth-of-type(even)': {
        backgroundColor: '#EFF0F1',
    },
    '& .MuiDataGrid-row:nth-of-type(odd)': {
        backgroundColor: '#FFFFFF',
    },
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: '#EFF0F1', fontWeight: '700' , 
    },
    '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
        fontSize: '11px', 
    },
    "& .MuiDataGrid-row:hover": {
        cursor: "pointer",
        color: '#ED7300'
    },
}));

const DataGridAcertus: React.FC<DataGridAcertusProps> = ({
    width = "1314px",
    maxHeight = "264px",
    minHeight = "264px",
    columns,
    rows,
    loading = false,
    pageSizeOptions = [15, 30, 50, 100],
    page,
    pageSize,
    rowCount,
    onPageChange,
    onPageSizeChange,
    hideFooterSelectedRowCount = true,
    checkboxSelection = false,
    onRowSelectionModelChange,
    initialRowModesModel,
    processRowUpdate,
    onRowClick,
    handleCancelLoading,
}) => {
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [paginationModel, setPaginationModel] = React.useState({ page: page - 1, pageSize }); 

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };


    React.useEffect(() => {
        setRowModesModel(initialRowModesModel || {});
    }, [initialRowModesModel]);

    React.useEffect(() => {
        setPaginationModel({ page: page - 1, pageSize });
    }, [page, pageSize]);

    const handlePaginationModelChange = (model) => {
        onPageChange(model.page + 1);
        onPageSizeChange(model.pageSize);
    };

    return (
        <StyledDataGrid
            sx={{
                width: width,
                maxHeight: maxHeight,
                minHeight: minHeight,
            }}
            columns={columns.map((column) => ({
                ...column,
                filterable: false, 
            }))}
            rows={rows}
            rowHeight={37}
            columnHeaderHeight={47}
            loading={loading}
            pagination
            paginationMode="server"
            paginationModel={paginationModel} 
            onPaginationModelChange={handlePaginationModelChange} 
            rowCount={rowCount}
            hideFooterSelectedRowCount={hideFooterSelectedRowCount}
            pageSizeOptions={pageSizeOptions}
            checkboxSelection={checkboxSelection}
            onRowSelectionModelChange={onRowSelectionModelChange}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            processRowUpdate={processRowUpdate}
            onRowClick={onRowClick}
            components={{
                NoRowsOverlay: () => (
                    <div style={{ paddingTop: '15%', color: '#5D6771', textAlign: 'center', fontSize: '12px' }}>
                        No records found
                    </div>
                ),
                LoadingOverlay: () => (
                    <div style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', padding: '20px', flexDirection: 'column', backdropFilter: 'blur(1px)', backgroundColor: 'rgba(255, 255, 255, 0.6)', height: '100%' }}>
                        <CircularProgress sx={{ color: '#ED7300' }} />
                        <OrangeHeaderButton label="Cancel" onClick={handleCancelLoading} variant="contained" disabled={null} sx={{ width: '47px', height:'24px', fontSize:'11px'}} />
                    </div>
                ),
            }}
        />
    );
};

export default DataGridAcertus;
