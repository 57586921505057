import { createTheme } from '@mui/material/styles';

export interface Transaction extends Object {
    InvoiceNumber: number | 0;
    DateIn: Date;
    DateComplete: Date | null;
    Balance: number | null;
    ContactId: number;
    StatusId: string;
    ClientUnit: string;
    VIN: string;
    VINLast6: string;
    VehicleYear: string;
    Make: string;
    Model: string;
    WorkTypeId: number | null;
    Name1: string;
    Name2: string;
    Address: string;
    AddressLine2: string;
    PONumber: string;
    CityName: string;
    CountyId: number | null;
    StateId: number | null;
    ZipCode: string;
    ProjectId: number | null;
    Emailed: boolean | null;
    CreatedBy: string;
    CreateDate: Date | null;
    ModifiedBy: string;
    ModifiedDate: Date | null;
    CompletedBy: string;
}

export interface WorkType extends Object {
    WorkTypeId: number;
    StateId: State[];
}

export interface TransactionRequirement extends Object {
    TransactionRequirementId: number;
    DateReceived: Date | null;
    OrderSequence: number | null;
    Data: string;
    RequirementLookupId: number | null;
    Attention: string;
    Phone: string;
    StreetAddress: string;
    AddressLine2: string;
    City: string;
    StateId: number | null;
    ZipCode: string;
    ShipViaId: number | null;
    Account: string;
    ServiceTypeId: number | null;
    PackageTypeId: number | null;
    BillingOptionId: number | null;
    RequirementName: string;
    RequirementId: number;
    RequirementFieldTypeId: number;
    IsDocument: boolean;
    HasLookupValue: boolean;
    Document: Blob;
}

export interface TransactionProcedure extends Object {
    TransactionProcedureId: number;
    TransactionId: number;
    ProcedureId: number;
    WorkTypeId: number | null;
    StateId: number | null;
    CheckOutId: number | null;
    DestinationId: number | null;
    Seq: number;
    DateIn: Date | null;
    DateOut: Date | null;
    InViaId: number | null;
    OutViaId: number | null;
    ETA: Date | null;
    Result: string;
    ResultDate: Date | null;
    RejectedBy: string;
    RejectReason: string;
    SetByMacro: boolean | null;
    IsCurrent: boolean;
    RejectionReasonId: number | null;
    ViolationTypeId: number | null;
    DMVId: number | null;
    IsCurrentAfterReject: boolean | null;
    OutboundBy: string;
    InboundBy: string;
}

export interface ITransactionState extends Object {
    isReceived: boolean | true;
    isResolved: boolean | false;
    isSentToState: boolean | false;
    hasETAChange: boolean | false;
    isCompleted: boolean | false;
    isCancelled: boolean | false;
    isCompletedByDMV: boolean | false;
    isWaitingForPaperwork: boolean | false;
}

export interface Customer extends Object {
    CustomerTypeId: number | null;
    RecordType: string;
    Name: string;
    CheckName: string;
    POAddr1: string;
    POAddr2: string;
    POCity: string;
    POState: string;
    POZip: string;
    ShipAddr1: string;
    ShipAddr2: string;
    ShipCity: string;
    ShipState: string;
    ShipStateId: number | null;
    ShipZip: string;
    TermId: number | null;
    AccountNumber: string;
    PrefDispId: number | null;
    DealerNumber: string;
    ClientUnit: string;
    FEIN: string;
    FaxSchedule: string;
    COLeinHolder: boolean | null;
    RushPref: boolean;
    OnHold: boolean;
    Scanned_POA: boolean;
    Lease: boolean;
    Note: string;
    Unit_Req: boolean;
    LeaseName: string;
    LeaseAddr1: string;
    LeaseAddr2: string;
    LeaseCity: string;
    LeaseState: string;
    LeaseZip: string;
    ShipCounty: string;
    AddDate: string | null;
    RepId: number | null;
    DMVCheckId: number | null;
    NoBlankChecks: boolean | null;
    OneCheckPerInvoice: boolean | null;
    NeedsMoneyOrder: boolean;
    SalesRepDate: string | null;
    SendTagToReqBy: boolean;
    SeparateShippingByContact: boolean;
    ShippingNotes: string;
    Email_Billing: boolean;
    Suppress_Document_Print: boolean | null;
    MaxInvoicesPerCheck: number;
    BillingEmail: string;
    ParentId: number | null;
    NoOvernightForProjects: boolean | null;
    LogoURL: string;
    PrefShipServiceId: number | null;
    IsActive: boolean;
    IsFmc: boolean;
    MetroLocationId: number;
    CustomerCategoryId: number | null;
    MyRenewalsAccess: boolean | null;
    CreatedBy: number | null;
    PortalAccess: boolean | null;
    IsParent: boolean;
    CountyId: number | null;
    PaidViaACH: boolean;
}

export interface TypeElement extends Object {
    TypeElementId: number;
    Description: string;
    AccountGroupId: number | null;
    IsActive: boolean;
}

export interface State extends Object {
    StateId: number;
    StateAbbreviation: string;
    StateName: string;
    CountryId: number | null;
}
export interface ReqAddress extends Object {
    Attention: string | null;
    Name1: string | null;
    Phone: string | null;
    Address1: string | null;
    Address2: string | null;
    City: string | null;
    CityId: number | null;
    State: State[];
    Zip: string | null;
    CountyId: number | null;
    CountryId: 1,
    ShipViaId: number | null,
    AirbillNumber: string | null
}
export interface TransactionFee extends Object {
    TransactionFeeId: number;
    TransactionId: number;
    FeeId: number;
    WorkTypeId: number | null;
    CheckOutId: number | null;
    OrderSequence: number | null;
    Fee: number;
    EstimatedFee: number | null;
    Cost: number;
    AddUserId: number;
    AdjustedFee: number | null;
    NSItemID: number | null;
    NSVendorBillID: number | null;
    NSVendorPaymentID: number | null;
    DateCreated: string | null;
    NSWipID: number | null;
    NSADJID: number | null;
    CreatedBy: string;
    IsLocked: boolean;
    LastModifiedBy: string;
    LastModifiedDate: string | null;
    FeeMetaData: Fee

}

export interface Fee extends Object {
    FeeId: number;
    FeeDescription: string;
    Cost: number | null;
    FeeAmount: number | 0;
    CategoryId: number;
    Credit: boolean;
    AllowZeroCost: boolean;
    IsActive: boolean;
    Description: string;
    Fee1: number | 0;
}

export interface Payment extends Object {
    CheckDate: string | null;
    CheckNumber: string;
    CheckAmount: number;
}

export interface ScannedDocument extends Object {
    Id: number;
    InvoiceNumber: number;
    Label: string;
    Filepath: string;
    Path: string;
    Filename: string;
    AWSFilepath: string;
}

export interface SendTagResult extends Object {
    transactionId: number;
    requirementId: number;
    requirementName: string;
    data: string;
    streetAddress: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    county: string;
    customerId: number | null;
    recordType: string;
    account: string;
    attention: string;
    fieldType: string;
    dispositionId: number;
    disposition: string;
    outgoingTicketNum: string;
}

export interface ShippingResult extends Object {
    ResultDescription: string;
    ResultDate: string | null;
    DateIn: string | null;
    ShipVia: string;
    TrackingNumber: string;
    ServiceTypeName: string;
    Destination: string;
    ShipAddress1: string;
    ShipAddressLine2: string;
    ShipCity: string;
    ShipState: string;
    ShipZip: string;
    ShipName: string;
}

export interface TranShipping extends Object {
    TranShippingId: number;
    TranId: number;
    OutgoingTicketNum: string;
    IncomingTicketNum: string;
    TranProcId: number | null;
    TranReqId: number | null;
    ShipperId: number;
}

export interface TranNotes extends Object{
    TranNoteId: number;
    TranId: number;
    TranNoteDescription: string;
    CreatedBy: string;
    CreatedTime: Date | null;
    ModifiedDate: Date | null;
    ModifiedBy: string;
    TranNoteCategoryId: number | null;
}

export enum StateType {
    NA = 0,
    AL = 1,
    AK = 2,
    AZ = 3,
    AR = 4,
    CA = 5,
    CO = 6,
    CT = 7,
    DE = 8,
    FL = 9,
    GA = 10,
    HI = 11,
    ID = 12,
    IL = 13,
    IN = 14,
    IA = 15,
    KS = 16,
    KY = 17,
    LA = 18,
    ME = 19,
    MD = 20,
    MA = 21,
    MI = 22,
    MN = 23,
    MS = 24,
    MO = 25,
    MT = 26,
    NE = 27,
    NV = 28,
    NH = 29,
    NJ = 30,
    NM = 31,
    NY = 32,
    NC = 33,
    ND = 34,
    OH = 35,
    OK = 36,
    OR = 37,
    PA = 38,
    RI = 39,
    SC = 40,
    SD = 41,
    TN = 42,
    TX = 43,
    UT = 44,
    VT = 45,
    VA = 46,
    WA = 47,
    WV = 48,
    WI = 49,
    WY = 50,
    DC = 51,
    PR = 52,
    AB = 53,
    BC = 54,
    MB = 55,
    NB = 56,
    NL = 57,
    NS = 58,
    NT = 59,
    NU = 60,
    ON = 61,
    PE = 62,
    QC = 63,
    SK = 64,
    YT = 65,
    VI = 66,
    GU = 67,
    PQ = 68
}

export interface DropdownOption extends Object {
    Id: string;
    Value: string;
}

export interface Address extends Object {
    Attention: string;
    Phone: string;
    StreetAddress: string;
    AddressLine2: string;
    City: string;
    StateId: number | null;
    ZipCode: string;
    ShipViaId: number | null;
}

export interface ETAChange extends Object {
    ETAChangeId: number;
    OperationDate: Date | null;
    OldETA: Date | null;
    NewETA: Date | null;
    ETAChangeReasonMetaData: ETAChangeReason;
}

export interface ETAChangeReason extends Object {
    ETAChangeReasonId: number;
    ChangeReason: string;
}
export interface CompletedByDMV extends Object {
    CompletedByDMV: boolean;
    DateCreated: Date | null;
}
export interface ReqBy extends Object {
    Name: string;
    CustomerId: number;
    ContactId: number;
}
export interface StyleCode extends Object {
    Description: string;
    StateStyleCodeId: number;
}
export interface NameId extends Object {
    Name: string;
    Id: number;
}
export interface Lookup extends Object {
    LookupValue: string;
    Id: number;
}
export interface TranReq extends Object {
    RequirementId: number | null;
    Data: string;
    Attention: string | null;
    Phone: string | null;
    StreetAddress: string | null;
    AddressLine2: string | null;
    City: string | null;
    CountyId: number | null;
    State: State | null;
    StateId: number | null;
    StateName: string | null;
    StateAbrv: string | null;
    ZipCode: string | null;
    DocumentName: string | null;
    DocumentUploaded: boolean;
    IsExternal: boolean;
}
export enum RequirementId {
    EmptyWeight = 12,
    GVWR = 74,
    FuelType = 152,
    Lienholder = 43,
    SellerAddress = 107,
    MailToAddrOnApp = 171,
}

export interface ToastProps {
    show: boolean,
    message: string,
    severity: 'error' | 'warning' | 'info' | 'success'
}

export const theme = createTheme({
    typography: {
        fontFamily: "Noto Sans, Arial, sans-serif",
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    fontSize: '12px',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#ED7300',
                    },
                },
            },
        },
    },
});