import { StyledComponent } from "@emotion/styled";
import { TabList, TabListProps } from "@mui/lab";
import { styled, Tab, TabProps, Grid, Button, Typography , TextField, LinearProgress} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

export const SubmitButton = styled(Button)({
    border: '0',
    width: '145px',
    height: '36px',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '8px',
    textTransform: 'none',
    backgroundColor: '#ED7300',
    borderRadius: '8px',
    color: '#FFFFFF',
    cursor: 'pointer',
    fontFamily: 'InterstateLight',
    fontWeight: '700',
    fontSize: '14px',
    float: 'right',
    lineHeight: '18px',
    '&:hover': {
        backgroundColor: '#ED7300',
        borderColor: '#0062cc',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        color: '#FFFFFF',
    },
    '&.Mui-disabled': {
        backgroundColor: '#F8C799',
        color: 'white',
        cursor: 'not-allowed',
    },
})
export const GoBackButton = styled(Button)({
    color: '#ED7300',
    fontSize: '13px',
    fontFamily: 'Interstate',
})
export const FieldLabel = styled(Typography)({
    color: '#333E48',
    fontSize: '12px',
    fontFamily: 'Interstate',
})
export const RenewalTitle = styled(Typography)({
    color: '#333E48',
    fontSize: '22px',
    fontFamily: 'Interstate',
})
export const OrderSelectionGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'title''accountDD''orderDD'",
    gridTemplateColumns: '3fr',
})
export const TitleGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'vehicleTitle vehicleTitle . circles circles'",
    gridTemplateColumns: '3fr 3fr 3fr 3fr',
})
export const VINGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'vehicleTitle vehicleTitle . . cirlces circles''vin vin . . . .'",
    gridTemplateColumns: '5fr 4fr 4fr 4fr 4fr 4fr',
    marginBottom: '20px',
    borderBottom: '1px lightgrey solid',
})
export const InfoGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'start',
    gridTemplateAreas: "'disabledVIN year make model color type' 'backButton . . . nextButton nextButton'",
    gridTemplateColumns: '4fr 2fr 2fr 2fr 2fr 3fr',
})
export const Numbers = styled('img')({
    width: '31px',
    height: '31px',
    marginRight: '24px',
})
export const PromptButton = styled(Button)({
    border: '0',
    width: '160px',
    height: '36px',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '8px',
    textTransform: 'none',
    backgroundColor: '#ED7300',
    borderRadius: '8px',
    color: '#FFFFFF',
    cursor: 'pointer',
    fontFamily: 'InterstateLight',
    fontWeight: '700',
    fontSize: '14px',
    float: 'right',
    lineHeight: '18px',
    '&:hover': {
        backgroundColor: '#ED7300',
        borderColor: '#0062cc',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        color: '#FFFFFF',
    },
})
export const ResultText = styled(Typography)({
    color: '#333E48',
    fontSize: '14px',
    fontFamily: 'InterstateLight',
    //margin: '14px 0px 20px 0px',
})
export const ExtraGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'fleet unit poNumber . . edit'",
    gridTemplateColumns: '4fr 4fr 5fr 3fr 5fr 1fr',
    marginTop: '20px',
    borderBottom: '1px lightgrey solid',
})
export const TopGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'confirmationTitle confirmationTitle . account account account''renewalState regName regAddress plate plateExp edit'",
    gridTemplateColumns: '4fr 4fr 5fr 3fr 5fr 1fr',
    borderBottom: '1px lightgrey solid',
})
export const MiddleGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'vin vehicleYear vehicleMake vehicleModel vehicleColor vehicleBody edit'",
    gridTemplateColumns: '5fr 4fr 4fr 4fr 3fr 5fr 1fr',
    marginTop: '20px',
    borderBottom: '1px lightgrey solid',
})
export const BottomGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'shipTo addressSummary edit .''backButton . submitButton submitButton'",
    gridTemplateColumns: '0fr 2fr 2fr 3fr',
    marginTop: '20px',
})
export const OrderGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'logo logo logo' 'pizza pizza pizza'",
    gridTemplateColumns: '1fr 5fr 1fr',
})
export const SuccessGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'pizza pizza pizza''success success success''newOrder . viewOrder'",
    gridTemplateColumns: '1fr 5fr 1fr',
})
export const Edit = styled(EditIcon)({
    cursor: 'pointer',
    color: '#ED7300',
    width: '17px',
    height: '17px',
})
export const GreyEdit = styled(EditIcon)({
    cursor: 'pointer',
    color: '#BEC2C6',
    width: '20px',
    height: '20px',
})
export const FormTextField = styled(TextField)({
    '&.Mui:focus': {
        borderColor: '#ED7300',
    },
    '&.MuiInputBase-root': {
        '&:hover fieldset': {
            borderColor: "#ED7300",
        },
        '&.Mui-focused fieldset': {
            borderColor: "#ED7300",
        },
    },
})
export const RegTitleGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'renewalTitle renewalTitle . circles circles'",
    gridTemplateColumns: '3fr 3fr 3fr 3fr',
    /*    marginBottom: '20px',*/
})
export const RenewalStateGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'renewalState . . .'",
    gridTemplateColumns: '3fr 2fr 2fr 2fr',
    marginBottom: '20px',
    borderBottom: '1px lightgrey solid',
})
export const RegGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'start',
    gridTemplateAreas: "'regName regName plate plateExp plateExp' 'regAddress regAddress . regAddress2 .' 'regCity regState regZip poNumber poNumber' 'backButton . . nextButton nextButton'",
    gridTemplateColumns: '3fr 4fr 3fr 3fr 2fr',
})
export const ExtraRegGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'start',
    gridTemplateAreas: "'fleet unit . . .'",
    gridTemplateColumns: '3fr 3fr 4fr 3fr 3fr',
})
export const ShippingTitleGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'shippingTitle shippingTitle . circles circles'",
    gridTemplateColumns: '3fr 3fr 3fr 3fr',
    /*    marginBottom: '20px',*/
})
export const ShippingGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'shippingTitle shippingTitle circles circles''shipAttention regCheckBox2 regCheckBox regCheckBox''shipName . . . ''shipAddress shipAddress shipAddress2 shipAddress2'",
    gridTemplateColumns: '5fr 4fr 4.35fr .5fr',
})
export const ShipCityGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'shipCity shipState shipZip shipPhone'",
    gridTemplateColumns: '4.7fr 2fr 4fr 5fr',
    marginBottom: '20px',
    borderBottom: '1px lightgrey solid',
})
export const SummaryGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'shipTo addressSummary addressSummary .' 'backButton . nextButton nextButton'",
    gridTemplateColumns: '0fr 2fr 2fr 3fr ',
})
export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
export const OrangeButton = styled(Button)({
    border: '0',
    width: '250px',
    height: '36px',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '8px',
    textTransform: 'none',
    backgroundColor: '#ED7300',
    borderRadius: '8px',
    color: '#FFFFFF',
    cursor: 'pointer',
    fontFamily: 'InterstateLight',
    fontWeight: '700',
    fontSize: '14px',
    float: 'right',
    lineHeight: '18px',
    '&:hover': {
        backgroundColor: '#ED7300',
        borderColor: '#0062cc',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        color: '#FFFFFF',
    },
    '&.Mui-disabled': {
        backgroundColor: '#F8C799',
        color: 'white',
        cursor: 'not-allowed',
    },
})
export const SelectFileButton = styled(Button)({
    border: '0',
    width: '250px',
    height: '36px',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '8px',
    textTransform: 'none',
    backgroundColor: '#ED7300',
    borderRadius: '8px',
    color: '#FFFFFF',
    cursor: 'pointer',
    fontFamily: 'InterstateLight',
    fontWeight: '700',
    fontSize: '14px',
    float: 'right',
    lineHeight: '18px',
    '&:hover': {
        backgroundColor: '#ED7300',
        borderColor: '#0062cc',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        color: '#FFFFFF',
    },
    '&.Mui-disabled': {
        backgroundColor: '#F8C799',
        color: 'white',
        cursor: 'not-allowed',
    },
})
export const TemplateButton = styled(Button)({
    //border: '1px solid #ED7300',
    //borderColor: '#ED7300',
    width: '250px',
    height: '36px',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '8px',
    textTransform: 'none',
    backgroundColor: 'white',
    borderRadius: '8px',
    color: '#ED7300',
    cursor: 'pointer',
    fontFamily: 'Interstate',
    fontWeight: '700',
    fontSize: '14px',
    float: 'right',
    lineHeight: '18px',
    '&:hover': {
        textDecoration: 'underline',
        backgroundColor: 'white',
        color: '#ED7300 !important',
    },
})
export const TabStyle = styled(Tab)({
    fontFamily: 'InterstateLight',
    color: '#333E48',
    padding: '5px 15px',
    fontSize: '13px',
    marginRight: '2px',
    lineHeight: '1.42857143',
    borderRadius: '10px 10px 0px 0px',
    minHeight: '30px',
    textTransform: 'none',
    '&.Mui-selected': {
        color: 'white',
        backgroundColor: '#ED7800',
        fontFamily: 'Interstate',
    },
});
export const ResetGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'question question question''noButton . yesButton'",
    gridTemplateColumns: '1fr 4fr 1fr',
    margin: '20px',
    justifyItems: 'center'
})
export const WhiteGoBackButton = styled(Button)({
    border: '1px solid #ED7300',
    borderColor: '#ED7300',
    width: '162px',
    height: '36px',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '8px',
    textTransform: 'none',
    backgroundColor: 'white',
    borderRadius: '8px',
    color: '#ED7300',
    cursor: 'pointer',
    fontFamily: 'Interstate',
    fontWeight: '700',
    fontSize: '14px',
    float: 'right',
    lineHeight: '18px',
    '&:hover': {
        backgroundColor: 'white',
        borderColor: '#ED7300',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        color: '#FED7300',
    },
})
export const UploadGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'title . .' 'success . .''fileName fileName replaceButton'",
    gridTemplateColumns: '3fr 3fr 3fr',
    marginBottom: '14px',
    borderBottom: '1px lightgrey solid',
})
export const PizzaGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'pizza pizza pizza'",
    gridTemplateColumns: '3fr 3fr 3fr',
    marginBottom: '11px',
    borderBottom: '1px lightgrey solid',
})
export const ButtonGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'backButton . nextButton'",
    gridTemplateColumns: '3fr 3fr 3fr',
})
export const SubTitle = styled(Grid)({
    color: '#333E48',
    fontSize: '18px',
    fontFamily: 'Interstate',
})
export const CloseButton = styled(Button)({
    border: '0',
    width: '85px',
    height: '34px',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '8px',
    textTransform: 'none',
    backgroundColor: '#ED7300',
    borderRadius: '8px',
    color: '#FFFFFF',
    cursor: 'pointer',
    fontFamily: 'InterstateLight',
    fontWeight: '700',
    fontSize: '14px',
    float: 'right',
    lineHeight: '18px',
    '&:hover': {
        backgroundColor: '#ED7300',
        borderColor: '#0062cc',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        color: '#FFFFFF',
    },
    '&.Mui-disabled': {
        backgroundColor: '#F8C799',
        color: 'white',
        cursor: 'not-allowed',
    },
})
export const ReplaceButton = styled(Button)({
    border: '1px solid #ED7300',
    borderColor: '#ED7300',
    width: '162px',
    height: '36px',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '8px',
    textTransform: 'none',
    backgroundColor: 'white',
    borderRadius: '8px',
    color: '#ED7300',
    cursor: 'pointer',
    fontFamily: 'Interstate',
    fontWeight: '700',
    fontSize: '14px',
    float: 'right',
    lineHeight: '18px',
    '&:hover': {
        backgroundColor: 'white',
        borderColor: '#ED7300',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        color: '#FED7300',
    },
})
export const MiddleConfirmGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'invoices duplicates incomplete addressError total'",
    gridTemplateColumns: '3fr 3fr 3fr 3fr 3fr',
    marginBottom: '11px',
    borderBottom: '1px lightgrey solid',
})


export const SummaryText = styled(Typography)({
    color: '#333E48',
    fontSize: '16px',
    fontFamily: 'Interstate',
})
export const OrderFieldTitle = styled(Typography)({
    color: '#333E48',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    fontFamily: 'InterstateLight',
});
export const VehicleGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'title title''vinTitle .''vin vin''yearTitle makeTitle''year make''modelTitle .''model model''colorTitle colorTitle''color color''divider divider'",
    gridTemplateColumns: '1.8fr 4fr',
})
export const RegistrationGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'regiTitle regiTitle regiTitle''stateTitle . .''state state state''regNameTitle regNameTitle regNameTitle''regName regName regName''regTitle regTitle regTitle2''reg reg reg2''cityTitle stateAbrvTitle zipTitle''city stateAbrv zip''reqButton reqButton reqButton'",
    gridTemplateColumns: '4fr 1.8fr 1.8fr',
})
export const OrangeLinearProgress = styled(LinearProgress)({
    '&.MuiLinearProgress-root': {
        backgroundColor: '#F8C799',
    },
    '& .MuiLinearProgress-bar1Indeterminate': {
        backgroundColor: '#F29D4D',
    },
    '& .MuiLinearProgress-bar2Indeterminate': {
        backgroundColor: '#ED7300',
    },
});