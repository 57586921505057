import React, { useState, useEffect } from "react";
import { Box, Typography, Alert, FormControlLabel, Checkbox } from "@mui/material";
import AutocompleteAcertus, { AutocompleteAcertusItem } from "../../Components/AutocompleteAcertus";
import CancelIcon from '@mui/icons-material/Cancel';
import WhiteHeaderButton from "../../Components/WhiteHeaderButton";
import { FormTextField, WhiteGoBackButton, SubmitButton } from '../../Shared/Renewals'

export interface ToastProps {
    show: boolean,
    message: string,
    severity: 'error' | 'warning' | 'info' | 'success'
}
interface PendingFilterDetailsProps {
    onClickSave: () => void;
    setPendingOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setFilterSelections: React.Dispatch<React.SetStateAction<{ label: string, choice: string | boolean, id?: number }[]>>;
    filterSelections: { label: string, choice: string | boolean, id?: number }[];
    setFilterName: React.Dispatch<React.SetStateAction<string | null>>;
    filterName: string | null;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    updateUserList: (param: number[] | null) => Promise<AutocompleteAcertusItem[]>;
    handleChange: (label: string, value: AutocompleteAcertusItem[] | undefined) => void;
    getDropdownSessionData: (requestMethod: string, setter: React.Dispatch<React.SetStateAction<AutocompleteAcertusItem[]>>, param?: number[] | null) => void;
}

const PendingFilterDetails: React.FC<PendingFilterDetailsProps> = ({ onClickSave, setPendingOpen, setFilterSelections, filterSelections, setFilterName, filterName, setPageSize, updateUserList, handleChange, getDropdownSessionData }) => {
    const [alertMessage, setAlertMessage] = useState<string | null>(null); 
    const [alertSeverity, setAlertSeverity] = useState<"success" | "error" | undefined>(undefined); 
    const [customers, setCustomers] = useState<AutocompleteAcertusItem[]>([]);
    const [workTypes, setWorkTypes] = useState<AutocompleteAcertusItem[]>([]);
    const [users, setUsers] = useState<AutocompleteAcertusItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [showClearAll, setShowClearAll] = useState<boolean>(false);
    
    
    useEffect(() => {
        const dropdowns = [
            { method: "/GetCustomersDropDownAsync", setter: setCustomers },
            { method: "/GetWorkTypesDropDownAsync", setter: setWorkTypes },
            { method: "/GetUsersDropDownAsync", setter: setUsers },
        ];

        dropdowns.forEach(drop => {
            getDropdownSessionData(drop.method, drop.setter);
        });

        const getSearchSelections = sessionStorage.getItem('pendingSelections');
        const getPageSize = sessionStorage.getItem('pageSize');
        const parsedSelections = getSearchSelections ? JSON.parse(getSearchSelections) : [];
        const pageSizeNumber = Number(getPageSize);
        const filteredSelections = parsedSelections.filter(
            (selection) => selection.label !== 'Start Date' && selection.label !== 'End Date' && selection.label !== "Page" && selection.label !== "Page Size"
        );
        setPageSize(pageSizeNumber);
        setFilterSelections(filteredSelections);
        setShowClearAll(filteredSelections.length > 0);
    }, []);

    useEffect(() => {
        const filteredSelections = filterSelections.filter(
            (selection) => selection.label !== 'Start Date' && selection.label !== 'End Date' && selection.label !== "Page" && selection.label !== "Page Size"
        );
        setShowClearAll(filteredSelections.length > 0);

        const customerSelections = filterSelections
            .filter(selection => selection.label === 'Customer' && selection.id != null)
            .map(selection => selection.id as number);

        if (customerSelections.length > 0) {
            (async () => {
                const rawData = await updateUserList(customerSelections);
                setUsers(rawData);
            })();
        }

    }, [filterSelections]);

    const isCategorySelected = (label: string) => {
        return filterSelections.some(filterSelection => filterSelection.label === label);
    };

    const handleRemove = (label: string, choice: string | boolean) => {
        setFilterSelections(prevFilterSelections => {
            return prevFilterSelections.filter(filterSelection => {
                return !(filterSelection.label === label && filterSelection.choice === choice);
            });
        });
    };

    

    const handleClearAll = async () => {
        setFilterSelections([]);
        setShowClearAll(false);
    };

    const handleSearchName = (name) => {
        setFilterName(name);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "flex-start",
                minHeight: "500px",
            }}
        >
            {alertMessage && (
                <Alert severity={alertSeverity} sx={{ width: "100%", marginBottom: "20px" }}>
                    {alertMessage}
                </Alert>
            )}
            <Typography color="#5D6771" sx={{ fontSize: '12px' }} fontWeight="700">Filter Name</Typography>
            <FormTextField value={filterName || ""} sx={{ fontSize: '12px', width: '442px', marginBottom: '20px' }} InputProps={{ sx: { fontSize: '12px' } }} onChange={(e) => handleSearchName(e.target.value)} />
            <Typography color="#5D6771" sx={{ fontSize: '12px' }} fontWeight="700">Filters</Typography>
            <Box sx={{ display: "inline-flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start", alignItems: "center", minHeight: "40px", gap: "6px", marginBottom: '20px' }}>
                <AutocompleteAcertus items={customers} value={undefined} onChange={(value) => handleChange("Customer", value)} label="Customer" disabled={customers.length === 0 || loading} />
                <AutocompleteAcertus items={workTypes} value={undefined} onChange={(value) => handleChange("Task", value)} label="Task" disabled={workTypes.length === 0 || loading} />
                {isCategorySelected("Customer") && (
                    <AutocompleteAcertus
                        items={users}
                        value={undefined}
                        onChange={(value) => handleChange("Users", value)}
                        label="Users"
                        disabled={users.length === 0 || loading}
                    />
                )}
            </Box>
            <Box sx={{ display: "inline-flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start", alignItems: "center", minHeight: "40px", gap: "10px", marginBottom: '20px' }}>
                {filterSelections
                    .filter(filterSelection => filterSelection.label !== "Page" && filterSelection.label !== "Page Size")
                    .map((filterSelection, index) => (
                        <FormControlLabel
                            key={index}
                            labelPlacement="start"
                            disabled={loading}
                            control={
                                <Checkbox
                                    icon={<CancelIcon sx={{ color: "#FFFFFF" }} />}
                                    checkedIcon={<CancelIcon sx={{ color: "#FFFFFF" }} />}
                                    checked={true}
                                    onChange={() => handleRemove(filterSelection.label, filterSelection.choice)}
                                />}
                            label={`${filterSelection.label}: ${filterSelection.choice}`}
                            sx={{
                                height: "36px", color: "#FFFFFF", backgroundColor: "#6D767F", borderRadius: "5px", padding: "10px 20px 10px 20px", margin: "0px",
                                "& .MuiFormControlLabel-label": {
                                    fontSize: "12px",
                                },
                                '& .MuiTouchRipple-root': {
                                    color: '#ED7300',
                                },
                            }}
                        />
                    ))
                }
                {showClearAll && (
                    <WhiteHeaderButton
                        label="Clear All"
                        onClick={handleClearAll}
                        variant="contained"
                        disabled={null}
                        sx={{}}
                    />
                )}
            </Box>
            <Box sx={{ display: "inline-flex", justifyContent: "space-between", minHeight: "40px", gap: "10px", width: '100%', alignItems: 'flex-start' }}>
                <WhiteGoBackButton onClick={() => setPendingOpen(false)} sx={{ marginBottom: '20px' }} variant="text">Cancel</WhiteGoBackButton>
                <SubmitButton onClick={onClickSave} disabled={!showClearAll || filterName == null} sx={{ fontFamily: '"Noto Sans", Arial, sans-serif' }}>Save</SubmitButton>
            </Box>
        </Box>
    );
};

export default PendingFilterDetails;
