import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, Divider, Tooltip, Zoom, CircularProgress, Dialog, Grid, Snackbar, Alert } from "@mui/material";
import FilterBox from "./FilterBox";
import ModalAcertus from "../ModalAcertus";
import FilterDetails from "../Filters/FilterDetails";
import PendingFilterDetails from "../Filters/PendingFilterDetails";
import DeleteSavedSearch from "./DeleteSavedSearch";
import { TabStyle, ResetGrid, WhiteGoBackButton, SubmitButton, RenewalTitle, OrderSelectionGrid } from '../../Shared/Renewals'
import dayjs, { Dayjs } from 'dayjs';
import SaveSearch from "./SaveSearch";
import { AutocompleteAcertusItem } from "../../Components/AutocompleteAcertus";

const PlusIconAcertus = () => (
    <img src="../../React/svgs/PlusIconAcertus.svg" alt="Plus Icon" />
);
interface SavedSearch {
    Id: number;
    SearchName: string;
    CreateDate: string;
    Selections: string;
}
interface FiltersAcertusProps {
    selections: { label: string, choice: string | boolean, id?: number }[];
    setSelections: React.Dispatch<React.SetStateAction<{ label: string, choice: string | boolean, id?: number }[]>>;
    setBalancesOnlyChecked?: React.Dispatch<React.SetStateAction<boolean>>;
    setPendingOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    pendingOpen?: boolean;
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    open?: boolean;
}
export interface ToastProps {
    show: boolean,
    message: string,
    severity: 'error' | 'warning' | 'info' | 'success'
}

const FiltersAcertus: React.FC<FiltersAcertusProps> = ({ selections, setSelections, setBalancesOnlyChecked, setPendingOpen, pendingOpen, setOpen, open }) => {
    const [savedSearches, setSavedSearches] = useState<SavedSearch[]>([]);
    const [pending, setPending] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [deleteFilterId, setDeleteFilterId] = useState<number | null>(null)
    const [deleteFilterName, setDeleteFilterName] = useState<string | null>(null)
    const [toast, setToast] = useState<ToastProps>({ show: false, message: "", severity: 'info' })
    const [filterName, setFilterName] = useState<string | null>(null)
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [filterSelections, setFilterSelections] = useState<{ label: string, choice: string | boolean, id?: number }[]>([
        { label: 'Start Date', choice: dayjs().subtract(6, 'month').format('MM/DD/YYYY') },
        { label: 'End Date', choice: dayjs().format('MM/DD/YYYY') },
        { label: 'Page', choice: String(page) },
        { label: 'Page Size', choice: String(pageSize) },
    ]);

    const handleChange = async (label: string, value: AutocompleteAcertusItem[] | undefined) => {
        if (value) {
            value.forEach(item => {
                if (!filterSelections.some(selection => selection.label === label && selection.id === item.id)) {
                    setFilterSelections(prevSelections => [
                        ...prevSelections,
                        { label: label, choice: item.label, id: item.id }
                    ]);
                }
            });
        }
    };

    const getDropdownSessionData = async (requestMethod: string, setter: React.Dispatch<React.SetStateAction<AutocompleteAcertusItem[]>>, param: number[] | null = null) => {
        const getSaved = sessionStorage.getItem(`${requestMethod}`);
        const parsedSaved = getSaved ? JSON.parse(getSaved) : [];

        setter(parsedSaved);
    };

    useEffect(() => {
        const initialize = async () => {
            const isPendingOrders = window.location.pathname === "/PendingOrders";
            setPending(isPendingOrders);
            fetchSavedSearches(isPendingOrders);
        };

        initialize();
    }, []);

    const updateUserList = async (param: number[] | null = null): Promise<AutocompleteAcertusItem[]> => {
        let requestOptions: RequestInit | undefined;
        let url = window.location.origin + window.location.pathname + '/GetUsersDropDownAsync';

        requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(param),
        };

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }
            const data = await response.json();
            const transformedData = data.map((item: { Id: number, Label: string }) => ({
                id: item.Id,
                label: item.Label
            }));

            return transformedData;
        } catch (error) {
            console.error("Failed to fetch data", error);
            return [];
        }
    }

    const fetchSavedSearches = async (pending) => {

        setIsLoading(true);
        try {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            };
            const url = `${window.location.origin}/SavedSearch/GetSavedSearch?pending=${pending}`;
            const res = await fetch(url, requestOptions);

            if (!res.ok) {
                const errorText = await res.text();
                console.error(`!res.ok status: ${res.status}`, errorText);
                throw new Error("Failed to fetch saved searches");
            }

            const data = await res.json();
            if (data.success) {
                setSavedSearches(data.data);
            } else {
                console.error("Failed to fetch saved searches:", data.message);
            }
        } catch (error) {
            console.error("error attempting to get saved search:", error);

        } finally {
            setIsLoading(false);
        }
        
    };

    const formatDotNetDate = (dotNetDateString) => {

        const timestamp = parseInt(dotNetDateString.replace(/\/Date\((\d+)\)\//, "$1"), 10);
        const date = new Date(timestamp);

        // Format as MM/DD/YYYY
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    };

    const handleDeleteFilter = async (id, name) => {
        setDeleteFilterName(name);
        setDeleteFilterId(id);
        setShowDeleteConfirmation(true);
    }
    const handleDeleteNo = async () => {
        setDeleteFilterName(null);
        setDeleteFilterId(null);
        setShowDeleteConfirmation(false);
    }
    const handleDeleteYes = async (id, deleteFunction) => {
        try {
            
            const success = await deleteFunction(id);

            if (success) {
                setDeleteFilterName(null);
                setDeleteFilterId(null);
                setShowDeleteConfirmation(false);
                setToast({
                    show: true,
                    message: "Filter Deleted!",
                    severity: 'success'
                });
            } else {
                setToast({
                    show: true,
                    message: "Error Occurred. Filter Not Deleted!",
                    severity: 'error'
                });
            }
        }
        catch {
            console.error("Filter not Deleted. handle yes deleteFunction catch");
        }
        finally {
            await fetchSavedSearches(pending);
            setIsLoading(false);
        }
    };

    const handleSaveFilter = async (saveFunction) => {
        try {
            const success = await saveFunction(filterSelections, filterName, pending);  

            if (success.success) {
                setToast({
                    show: true,
                    message: "Filter Saved!",
                    severity: 'success'
                });
                setOpen?.(false);
                setPendingOpen?.(false);
            } else {
                const message = success.message
                setToast({
                    show: true,
                    message: `Filter did not save! ${message}`,
                    severity: 'error'
                });
            }
        }
        catch {
            console.error("Filter not saved.");
        }
        finally {
            await fetchSavedSearches(pending);
            setFilterName(null)
            setIsLoading(false);
        }
    };
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                minHeight: '420px',
                width: '320px',
                border: 1,
                borderColor: '#DFE1E3',
                borderRadius: '8px',
                gap: '20px',
            }}
        >
            <Snackbar open={toast.show} autoHideDuration={8000} onClose={() => setToast({ ...toast, show: false })} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity={toast.severity} sx={{ fontSize: '12px', width: '300px' }}>{toast.message}</Alert>
            </Snackbar>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    minHeight: '47px',
                    width: '320px',
                    borderBottom: 1,
                    borderColor: "#DFE1E3",
                }}
            >
                <Typography color="black" fontWeight="300" sx={{ marginLeft: "16px", fontSize:'14px' }}>
                    Filters
                </Typography>
                <Tooltip
                    TransitionComponent={Zoom}
                    enterDelay={200}
                    leaveDelay={500}
                    title={<span style={{ fontSize: '12px' }}>Add a saved filter</span>}
                    placement="top"
                    arrow
                >
                    <span>
                        <IconButton
                            onClick={() => {
                                if (pending) {
                                    setPendingOpen?.(true);
                                } else {
                                    setOpen?.(true);
                                }
                            }}
                            disabled={isLoading}
                            sx={{ marginRight: "16px" }}
                        >
                            <PlusIconAcertus />
                        </IconButton>
                    </span>
                </Tooltip>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    minHeight: '47px',
                    width: '320px',
                    gap: '8px',
                }}
            >
                {isLoading ? (
                    <CircularProgress sx={{ color: '#ED7300' }} />
                ) : savedSearches.length === 0 ? (
                    <Typography color="#5D6771" sx={{ fontSize: '12px' }} fontWeight="700">No Saved Searches</Typography>
                ) : savedSearches.map((search) => {
                    let parsedSelections = [];
                    try {
                        parsedSelections =
                            typeof search.Selections === 'string'
                                ? JSON.parse(search.Selections)
                                : search.Selections || [];
                    } catch (error) {
                        console.error('Error parsing selections:', error);
                    }

                    if (!Array.isArray(parsedSelections)) {
                        console.error('parsedSelections is not an array');
                        parsedSelections = [];
                    }

                    const normalizedSelections = parsedSelections.map((item: any) => ({
                        label: item.Label,
                        choice: item.Choice,
                        id: item.Id,
                    }));

                    return (
                        <FilterBox
                            key={search.Id}
                            onClickDelete={() => handleDeleteFilter(search.Id, search.SearchName)}
                            headerText={search.SearchName}
                            bodyText={`Created on ${formatDotNetDate(search.CreateDate)}`}
                            selections={normalizedSelections} 
                            setSelections={(newSelections) => {
                                setSelections(newSelections);
                            }}
                        />
                    );
                })
                }
            </Box>
            <ModalAcertus open={open ?? false} onClose={() => setOpen?.(false)} title="Save Filters" paperSx={{ width: 500 }}>
                {open && setOpen && (
                    <FilterDetails
                        onClickSave={() => handleSaveFilter(SaveSearch)}
                        setFilterSelections={setFilterSelections}
                        filterSelections={filterSelections}
                        setFilterName={setFilterName}
                        filterName={filterName}
                        setPageSize={setPageSize}
                        setOpen={setOpen}
                        updateUserList={updateUserList}
                        handleChange={handleChange}
                        getDropdownSessionData={getDropdownSessionData}
                    />
                )}
            </ModalAcertus>
            <ModalAcertus open={pendingOpen ?? false} onClose={() => setPendingOpen?.(false)} title="Save Pending Filters" paperSx={{ width: 500 }}>
                {pendingOpen && setPendingOpen && (
                    <PendingFilterDetails
                        onClickSave={() => handleSaveFilter(SaveSearch)}
                        setFilterSelections={setFilterSelections}
                        filterSelections={filterSelections}
                        setFilterName={setFilterName}
                        filterName={filterName}
                        setPageSize={setPageSize}
                        setPendingOpen={setPendingOpen}
                        updateUserList={updateUserList}
                        handleChange={handleChange}
                        getDropdownSessionData={getDropdownSessionData}
                    />
                )}
            </ModalAcertus>
            <Dialog open={showDeleteConfirmation}>
                <Zoom in={showDeleteConfirmation}>
                    <div style={{ height: '200px', width: '400px', display: 'grid', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}>
                        <Grid container style={{ height: '100%', justifyContent: 'center', justifyItems: 'center' }}>
                            <ResetGrid>
                                <Grid sx={{ gridArea: 'question', marginBottom: '20px', display: 'grid', justifyContent: 'center' }}>
                                    <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}>Are you sure you want to delete {deleteFilterName}?</Typography>
                                </Grid>
                                <Grid sx={{ gridArea: 'noButton' }}>
                                    <WhiteGoBackButton onClick={handleDeleteNo} variant="text">Cancel</WhiteGoBackButton>
                                </Grid>
                                <Grid sx={{ gridArea: 'yesButton' }}>
                                    <SubmitButton onClick={() => handleDeleteYes(deleteFilterId, DeleteSavedSearch)}>Delete</SubmitButton>
                                </Grid>
                            </ResetGrid>
                        </Grid>
                    </div>
                </Zoom>
            </Dialog>
        </Box>
    );
};

export default FiltersAcertus;
