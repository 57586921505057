import React from 'react';
import Checkbox from '@mui/material/Checkbox';

interface CheckboxAcertusProps {
    isChecked: boolean;
    onChange: () => void;
    disabled?: boolean;
};

const CheckboxAcertus: React.FC<CheckboxAcertusProps> = ({ isChecked, onChange, disabled }) => {
    return (
        <Checkbox
            checked={isChecked}
            onChange={onChange}
            disabled={disabled}
            sx={{
                color: '#ED7300',
                '&.Mui-checked': {
                    color: '#ED7300',
                },
            }}
        />
    );
};

export default CheckboxAcertus;
