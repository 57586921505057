import React, { useEffect, useState } from "react";
import { DateUtil } from "../Helpers/DateUtil";
import { Customer, DropdownOption, ETAChange, ETAChangeReason, ITransactionState, Payment, ScannedDocument, SendTagResult, ShippingResult, State, TranNotes, Transaction, TransactionFee, TransactionProcedure, TransactionRequirement, TranShipping, TypeElement, CompletedByDMV, WorkType } from "../Types/common";
import { Documents } from "./Documents";
import { EmailHistory } from "./EmailHistory";
import { OrderBalance } from "./OrderBalance";
import { OrderHistory } from "./OrderHistory";
import { OrderInformation } from "./OrderInformation";
import { PendingRequirements } from "./PendingRequirements";
import { PizzaTracker } from "./PizzaTracker";
import { LinearProgress, Dialog, Typography, Portal, Zoom, Snackbar, Alert } from "@mui/material";
import { OrangeLinearProgress } from "../Shared/Renewals";
import OrangeStartIconButton from "../Components/OrangeStartIconButton";

export interface AddressRenewalDeltasPageProps extends Object {
    InvoiceNumber: number;
};

export interface InvoiceDetailPageState extends Object {
    invoice: InvoiceDetailPageModal,
    isLoaded: boolean,
    transactionState: ITransactionState
}

export interface RequirementLookups {
    [requirementId: number] : DropdownOption[]
}

export interface InvoiceDetailPageModal extends Object {
    Transaction: Transaction;
    TransactionRequirements: TransactionRequirement[] | [];
    TransactionProcedures: TransactionProcedure[] | [];
    Customer: Customer;
    WorkTypes: WorkType;
    State: State;
    TypeElement: TypeElement;
    TransactionFees: TransactionFee[] | [];
    Payments: Payment[] | [];
    ScannedDocuments: ScannedDocument[] | [];
    SendTagResults: SendTagResult[] | [];
    ShippingResults: ShippingResult[] | [];
    TranShipping: TranShipping[] | [];
    TranNotes: TranNotes[] | [];
    RequirementLookups: RequirementLookups;
    States: DropdownOption[];
    ETAChange: ETAChange;
    ETAChangeReason: ETAChangeReason;
    DriverAction: boolean;
    CompletedByDMV: CompletedByDMV;
};

interface ToastProps {
    show: boolean,
    message: string,
    severity: 'error' | 'warning' | 'info' | 'success'
}

export const InvoiceDetailPage: React.FC<AddressRenewalDeltasPageProps> = ({ InvoiceNumber }) => {
    const [state, setState] = useState<InvoiceDetailPageState>({ invoice: {} as InvoiceDetailPageModal, isLoaded: false, transactionState: {} as ITransactionState });
    const [hasError, setHasError] = useState<boolean>(false);
    const [waitingForPaperWorkRequirement] = useState(3264);
    const [toast, setToast] = useState<ToastProps>({ show: false, message: "", severity: 'info' })

    InvoiceDetailPage.displayName = "InvoiceDetailPage";

    const DownloadIconAcertus = () => (
        <img src="/React/svgs/DownloadIconAcertusWhite.svg" alt="Download Icon" />
    );

    useEffect(() => {
        
        const url = window.location.origin + "/InvoiceDetail/GetInvoiceDetails";
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ invoiceNumber: InvoiceNumber }),
        };


        fetch(url, requestOptions)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`Request failed with status ${res.status}: ${res.statusText}`);
                }
                return res.json();
            })
            .then((result: InvoiceDetailPageModal) => {
                setState({
                    invoice: result,
                    isLoaded: true,
                    transactionState: getTransactionState(result)
                })
            })
            .catch((error) => {
                setHasError(true);
                console.error("Error fetching invoice details:", error);
            });
    }, []);

    const handleDownloadInvoice = (): void => {
        const url = window.location.origin + "/InvoiceDetail/InvoiceReportDownload";
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ invoice: InvoiceNumber, format: "PDF" }),
        };

        setToast({
            show: true,
            message: "Downloading invoice. Please wait...",
            severity: 'info'
        })
        fetch(url, requestOptions)
            .then(res => res.blob())
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = "Invoice_" + InvoiceNumber + ".pdf";
                a.click();
                a.remove();
                setToast({
                    show: true,
                    message: "Invoice downloaded successfully!",
                    severity: "success",
                });
            })
            .catch((error) => {
                setToast({
                    show: true,
                    message: "Sorry, there was an error downloading the invoice.",
                    severity: 'error'
                })
                console.error(error);
            });
    }

    const getTransactionState = (model: InvoiceDetailPageModal): ITransactionState => {
        var result: ITransactionState = {} as ITransactionState
        result.isReceived = true;

        if (model.TransactionRequirements.every(x => x.DateReceived !== null)) {
            result.isResolved = true;
        }
        if (model.TransactionRequirements.some(x => x.RequirementId === waitingForPaperWorkRequirement && x.Data == "YES")) {
            result.isWaitingForPaperwork = true;
        }

        if (model.TransactionProcedures.some(x => x.DateOut !== null)) {
            result.isResolved = true;
            result.isSentToState = true;
        }

        if (model.ETAChange !== null) {
            result.hasETAChange = true;
        }

        if (model.CompletedByDMV !== null) {
            result.isCompletedByDMV = true;
        }

        if (model.Transaction.DateComplete !== null) {
            result.isResolved = true;
            result.isSentToState = true;
            result.isCompleted = true;
        }

        if (model.Transaction.StatusId === "X") {
            result.isResolved = true;
            result.isSentToState = true;
            result.isCompleted = true;
            result.isCancelled = true;
        }

        return result;
    }

    //const getModalHTML = (): JSX.Element => {
    //    if (hasError) {
    //        return (
    //            <Portal>
    //                <div>
    //                    <h2 style={{ textAlign: "center"}}> Sorry, there was an issue fetching the data for this invoice.</h2>
    //                </div>
    //            </Portal>
    //        )
    //    } else {
    //        return (
    //            <Portal>
    //                <div>
    //                    <h2 style={{ textAlign: "center"}}>Loading</h2>
    //                    <LinearProgress />
    //                </div>
    //            </Portal>
    //        )
    //    }
    //}

    const updateTransactionStateToResolved = () => {
        setState(prevState => {
            return {
                ...prevState,
                transactionState: {
                    isReceived: true,
                    isResolved: true,
                    isSentToState: false,
                    hasETAChange: false,
                    isCompleted: false,
                    isCancelled: false,
                    isCompletedByDMV: false,
                    isWaitingForPaperwork: false,
                }
            }
        })
    }

    const showDownloadInvoice = (): boolean => {
        let result: boolean = false;
        let dateComplete: Date = new Date()

        try {
            var date: Date = DateUtil.parseJsonDate(state.invoice.Transaction.DateComplete?.toString() || new Date().toString())
            dateComplete = date;
            dateComplete.setDate(date.getDate() + 1)
            if (state.invoice.Transaction.StatusId === "C" && dateComplete < new Date()) {
                return true
            }
        }
        catch (ex) {

        }
        return result;
    }

    const parseAndFormatDate = (dateString): string => {
        if (!dateString) {
            return ''; 
        }

        const timestampMatch = dateString.match(/\d+/);
        if (timestampMatch && timestampMatch[0]) {
            const timestamp = timestampMatch[0];
            const date = new Date(parseInt(timestamp, 10));
            return date.toLocaleDateString("en-US", { month: '2-digit', day: '2-digit', year: 'numeric' });
        } else {
            return 'Invalid Date';
        }
    }
    if (!state.isLoaded) {
        return (
            <div className="container">
                <div className="row">
                    <div style={{ textAlign: "center" }}>
                        {hasError ? (
                            <Typography variant="h3" color="error">
                                Sorry, there was an issue fetching the data for this invoice.
                            </Typography>
                        ) : (
                            <>
                                <Typography variant="h3">Loading...</Typography>
                                <OrangeLinearProgress style={{ marginTop: "20px" }} />
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <Zoom in={state.isLoaded}>
                <div className="container" style={{ fontFamily: '"Noto Sans", Arial, sans-serif' }}>
                    <Snackbar open={toast.show} autoHideDuration={3000} onClose={() => setToast({ ...toast, show: false })} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity={toast.severity} sx={{ fontSize: '12px', width: '300px' }}>{toast.message}</Alert>
                    </Snackbar>
                    <div className="row">
                        <div className="col-md-10" style={{marginTop:'10px'}}>
                            <h2 style={{ fontFamily: '"Noto Sans", Arial, sans-serif' }}>Invoice # {InvoiceNumber}</h2>
                            <PizzaTracker
                                TransactionState={state.transactionState}
                                eta={state.invoice.TransactionProcedures && state.invoice.TransactionProcedures.length > 0
                                    ? parseAndFormatDate(state.invoice.TransactionProcedures[0].ETA)
                                    : ''}
                            />
                        </div>
                        <div className="col-md-2">
                            {showDownloadInvoice() &&
                                <OrangeStartIconButton
                                    label="Download"
                                    onClick={handleDownloadInvoice}
                                    variant="contained"
                                    startIcon={<DownloadIconAcertus />}
                                    disabled={null}
                                    sx={{ fontFamily:'"Noto Sans", Arial, sans-serif'}}
                                />
                            }
                        </div>
                    </div>
                    <div className="flex-row row">
                        <div className="col-md-6 p-3">
                            <div className="row p-3">
                                <OrderInformation {...state.invoice} />
                            </div>
                            <div className="row p-3">
                                <PendingRequirements TransactionRequirements={state.invoice.TransactionRequirements} TransactionState={state.transactionState} UpdateTransactionState={updateTransactionStateToResolved} States={state.invoice.States} RequirementLookups={state.invoice.RequirementLookups} />
                            </div>
                            <div className="row p-3">
                                <OrderHistory TransactionState={state.transactionState} Modal={ state.invoice } />
                            </div>
                        </div>
                        <div className="col-md-6 p-3">
                            <div className="row p-3">
                                <OrderBalance TransactionFees={ state.invoice.TransactionFees } Payments={ state.invoice.Payments } />
                            </div>
                            <div className="row p-3">
                                <Documents ScannedDocuments={ state.invoice.ScannedDocuments } />
                            </div>
                            <div className="row p-3">
                                <EmailHistory TranNotes={ state.invoice.TranNotes } />
                            </div>
                        </div>
                    </div>
                </div>
            </Zoom>
        );
    }
}
