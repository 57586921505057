import React, { useState, useEffect } from "react";
import { Box, Typography, Button, CircularProgress, Alert, FormControlLabel, Checkbox } from "@mui/material";
import { ToastProps } from "../../Types/common";
import BackButton from '../BackButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CancelIcon from '@mui/icons-material/Cancel';

const days: Record<number, string> = {
    1: "Sun",
    2: "Mon",
    3: "Tue",
    4: "Wed",
    5: "Thu",
    6: "Fri",
    7: "Sat",
};
const reports: Record<number, string> = {
    1: "Pending Orders",
    2: "In-Process Orders",
    4: "All Orders by Status",
    5: "Orders Waiting on Paperwork",
};
interface ScheduledReport {
    ReportId: number;
    DayOfWeekIds: number[];
}
interface RemoveReportsProps {
    setToast: React.Dispatch<React.SetStateAction<ToastProps>>;
    setReportTitle: React.Dispatch<React.SetStateAction<string>>;
    setReportPage: React.Dispatch<React.SetStateAction<number>>;
    refreshReports: () => void;
    scheduledReports: ScheduledReport[];
    setScheduledReports: React.Dispatch<React.SetStateAction<ScheduledReport[]>>;
}
export const RemoveReports: React.FC<RemoveReportsProps> = ({ setToast, setReportTitle, setReportPage, scheduledReports, setScheduledReports, refreshReports }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [removedDays, setRemovedDays] = useState<{ reportId: number; dayOfWeekId: number, reportName: string }[]>([]);

    useEffect(() => {
        setReportTitle("Remove Scheduled Report");
    }, []);

    const handleBack = async () => {
        setReportTitle("Scheduled Reports")
        setReportPage(0)
    }

    const handleRemoveScheduledDay = (reportId: number, dayOfWeekId: number, reportName: string) => {
        setRemovedDays((prev) => [...prev, { reportId, dayOfWeekId, reportName }]);

        setScheduledReports((prevReports) =>
            prevReports
                .map((report) =>
                    report.ReportId === reportId
                        ? { ...report, DayOfWeekIds: report.DayOfWeekIds.filter((d) => d !== dayOfWeekId) }
                        : report
                )
                .filter((report) => report.DayOfWeekIds.length > 0)
        );
    }

    const handleSubmitRemovedDays = async () => {
        if (removedDays.length === 0) return;
        setIsLoading(true);
        try {
            const response = await fetch(window.location.origin + "/Reports/RemoveScheduledReportsAsync", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(removedDays),
            });
            const result = await response.json();
            setToast({
                show: true,
                message: result.message,
                severity: result.severity,
            });
            setRemovedDays([]);
            refreshReports();
            setReportPage(0);
        } catch (error) {
            console.error("Error removing scheduled days:", error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const reportsArray = scheduledReports
        .slice() 
        .sort((a, b) => a.ReportId - b.ReportId) 
        .map((report) => ({
            reportId: report.ReportId,
            days: report.DayOfWeekIds,
        }));


    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "flex-start",
                minHeight: "500px",
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {reportsArray.map(({ reportId, days: dayIds }) => (
                    <div key={reportId} style={{ marginBottom: "20px" }}>
                        <Typography sx={{ color: '#333E48', fontSize: '14px' }}>{reports[reportId]}:</Typography>
                        {Array.isArray(dayIds) && dayIds.length > 0 ? (
                            dayIds.map((dayOfWeekId, index) => {
                                const dayLabel = days[Number(dayOfWeekId)]; 
                                return (
                                    <FormControlLabel
                                        key={index}
                                        labelPlacement="start"
                                        disabled={false}
                                        control={
                                            <Checkbox
                                                icon={<CancelIcon sx={{ color: "#FFFFFF" }} />}
                                                checkedIcon={<CancelIcon sx={{ color: "#FFFFFF" }} />}
                                                checked={true}
                                                onChange={() => handleRemoveScheduledDay(reportId, dayOfWeekId, reports[reportId])}
                                            />
                                        }
                                        label={dayLabel}
                                        sx={{
                                            height: "30px",
                                            color: "#FFFFFF",
                                            backgroundColor: "#6D767F",
                                            borderRadius: "5px",
                                            padding: "5px 15px",
                                            margin: "5px",
                                            "& .MuiFormControlLabel-label": { fontSize: "12px" },
                                            "& .MuiTouchRipple-root": { color: "#ED7300" },
                                        }}
                                    />
                                );
                            })
                        ) : (
                            <div>No days available</div>
                        )}
                    </div>
                ))}
            </Box>
            <Box sx={{ display: 'flex' }}>
            <BackButton startIcon={<ArrowBackIosNewIcon />} label="Back" variant="text" onClick={() => handleBack()} disabled={false} sx={{ width: '170px' }} />
            <Button
                sx={{
                    borderRadius: "8px",
                    width: "170px",
                    backgroundColor: "#ED7300",
                    textTransform: "none",
                    "&:hover": {
                        backgroundColor: "#ED7300",
                    },
                }}
                variant="contained"
                disabled={removedDays.length === 0 || isLoading}
                onClick={() => handleSubmitRemovedDays()}
            >
                {isLoading ? (
                    <CircularProgress size={20} sx={{ color: "#FFFFFF" }} />
                ) : (
                    <Typography fontWeight="400" sx={{ color: "#FFFFFF", fontSize: '14px' }}>
                        Save
                    </Typography>
                )}
                </Button>
            </Box>
        </Box>
    );
};

export default RemoveReports;
