import React, { useState, useEffect, useRef } from 'react';
import { Typography, NoSsr, styled, FormControl, Autocomplete, createFilterOptions, TextField, Grid, Divider, Zoom } from '@mui/material';
import { RenewalTitle, OrderFieldTitle, VehicleGrid, RegistrationGrid } from '../../Shared/Renewals';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import OrangeEndIconButton from '../../Components/OrangeEndIconButton';
import { LoadingDialog, OkButtonDialog } from '../../Components/Dialog';
import SaveIcon from '@mui/icons-material/Save';
import { State, ReqBy, StyleCode, RequirementId } from '../../Types/common';
import { ReqValueOverride } from '../Common';

interface GetDropDowns {
    states: State[];
    reqBy: ReqBy[];
    stateStyleCode: StyleCode[]
}
interface Selection {
    selectedState: State | null;
}
interface LTOrderProps {
    handleNext: any;
    editing: any;
    formData: any;
    handleNextRerun: any;
    reqValueOverrides: ReqValueOverride[];
    setReqValueOverrides: Function;
}
export const LTOrder: React.FC<LTOrderProps> = ({ handleNext, formData, editing, handleNextRerun, reqValueOverrides, setReqValueOverrides }) => {
    const [disableButton, setDisableButton] = React.useState<boolean>(true);
    const [foundAddress, setFoundAddress] = React.useState<boolean>(false);
    const [vin, setVIN] = useState(formData?.vehicle?.vin ?? null);
    const [year, setYear] = useState(formData?.vehicle?.year ?? null);
    const [model, setModel] = useState(formData?.vehicle?.model ?? null);
    const [make, setMake] = useState(formData?.vehicle?.make ?? null);
    const [states, setStates] = useState<State[]>([]);
    //const [bodyType, setBodyType] = useState<StyleCode[]>([]);
    const [selection, setSelection] = useState<Selection>({
        selectedState: formData?.reg?.selection.selectedState ?? states[1],
    });
    const [regName, setRegName] = useState(formData?.reg?.regName ?? null);
    const [regAddress1, setRegAddress1] = useState(formData?.reg?.regAddress1 ?? null);
    const [regAddress2, setRegAddress2] = useState(formData?.reg?.regAddress2 ?? null);
    const [regCity, setRegCity] = useState(formData?.reg?.regCity ?? null);
    const [regZip, setRegZip] = useState(formData?.reg?.regZip ?? null);
    const [addressError, setAddressError] = React.useState<boolean>(false);
    const [showVerifyDialog, setShowVerifyDialog] = React.useState<boolean>(false);

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option: State) => option.StateName,
        limit: 80
    });
    const handleErrorClose = async () => {
        setAddressError(false);
    }
    const handleRegNext = async () => {
        await setShowVerifyDialog(true);
        await verifyAddress();
    }
    const handleFormSubmitWithChanges = async (regData) => {
        if (formData?.reqs?.requirements != null && (selection.selectedState != formData?.reqs?.currentState || formData?.reqs?.currentCustomer != regData?.reqBy?.custName)) {
            await handleNextRerun(regData)
        }
        await handleNext(regData);
    };
    const handleFormSubmit = async (countyId, countyName) => {
        const regData = await (async () => {
            return {
                reg: { selection, regName, regAddress1, regAddress2, regCity, regZip, regCounty: countyId, regCountyName: countyName },
                vehicle: { year, make, model, vin, selection }
            };
        })();
        if (formData?.reqs?.requirements != null && (selection.selectedState != formData?.reqs?.currentState || formData?.reqs?.currentCustomer != formData?.reqBy?.custName)) {
            await handleNextRerun(regData)
        }
        else {
            await handleNext(regData);
        }
    };

    const getVehicleDetails = (newVIN): void => {
        const requestOptions = {
            method: "GET",
        };
        const url = `${window.location.origin}/CreateOrders/GetVehicleDetails?vin=${newVIN}`;

        fetch(url, requestOptions)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`vehicle details response error: ${res.status}`);
                }
                return res.json();
            })
            .then((data) => {
                const decoded = data.vinDecode.Result.Data;
                setYear(decoded.Year ?? null);
                setModel(decoded.Model ?? null);
                setMake(decoded.Make ?? null);
                setReqValueOverrides([...reqValueOverrides,
                { RequirementId: RequirementId.EmptyWeight, RequirementValue: decoded.EmptyWeight ?? null },
                { RequirementId: RequirementId.GVWR, RequirementValue: decoded.Gvwr ?? null },
                { RequirementId: RequirementId.FuelType, RequirementValue: decoded.FuelType ?? null }
                ]);
            })
            .catch((error) => {
                console.error("error vin decoder vehicle details");
                console.error("error:", error);
            });
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                await getStates().then((result) => setStates(result));
                if (formData?.reqBy?.custName != null) {
                    setFoundAddress(true);
                }
            }
            catch (error) {
                console.error("An error occurred during fetch states/cust address data:", error);
            }
        };

        fetchData();
        return () => { };
    }, [formData]);

    useEffect(() => {
        if (regName != null && regAddress1 != null && regCity != null && regZip != null && selection.selectedState != undefined && vin != null && year != null && make != null && model != null) {
            if (regName.trim() != '' && regAddress1.trim() != '' && regCity.trim() != '' && regZip.trim() != '' && vin.trim() != '' && make.trim() != '' && model.trim() != '') {
                setDisableButton(false);
            }
            else {
                setDisableButton(true);
            }
        }
        else {
            setDisableButton(true);
        }
    }, [regAddress1, regName, regCity, regZip, vin, year, make, model, selection.selectedState]);

    const getStates = (): Promise<State[]> => {
        const requestOptions = {
            method: "GET",
        };

        const url = window.location.origin + "/CreateOrders/GetStates";

        return fetch(url, requestOptions)
            .then((res) => res.json())
            .then((result: GetDropDowns) => result.states)
            .catch((error) => {
                console.error("error get states dropdown");
                console.error("error:", error);
                return [];
            });
    };

    const verifyAddress = async () => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                address1: regAddress1,
                city: regCity,
                state: selection.selectedState?.StateAbbreviation,
                zipCode: regZip
            }),
        };
        const url = `${window.location.origin}/CreateOrders/VerifyAddress`;

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                console.error("Verify address response failure:", response.status, response.statusText);
                console.error("Request URL:", response.url);
                console.error("Response Headers:", response.headers);
            } else {
                const data = await response.json();
                if (data.changesMade) {
                    const formData = await (async () => {
                        return {
                            reg: {
                                selection: {
                                    selectedState: {
                                        StateId: data.stateId,
                                        StateName: data.stateName,
                                        StateAbbreviation: data.stateAbbreviation
                                    },
                                },
                                regName,
                                regAddress1: data.address1,
                                regAddress2,
                                regCity: data.city,
                                regZip: data.zipCode,
                                regCounty: data.county,
                                regCountyName: data.countyName
                            },
                            vehicle: { year, make, model, vin, selection },
                        };
                    })();
                    await setShowVerifyDialog(false);
                    await handleFormSubmitWithChanges(formData);
                }
                else {
                    if (data.verifyFailed) {
                        setShowVerifyDialog(false);
                        setAddressError(true);
                    }
                    else {
                        await setShowVerifyDialog(false);
                        await handleFormSubmit(data.county, data.countyName);
                    }
                }
                //await setShowVerifyDialog(false);
            }
        } catch (error: any) {
            console.error('An error occurred during address verification tsx:', error);
            console.error('Error message:', error.message);
            console.error('Stack trace:', error.stack);
            return false;
        }
    };
    //const handleAccount = async (contact) => {
    //    setIndividualModal((prevModal) => ({ ...prevModal, selectedReqBy: contact }));
    //    const selected = contact.contact;
    //    if (contact != undefined) {
    //        await setContact(selected);
    //    }
    //}

    //useEffect(() => {
    //    if (vin && vin.length === 17) {
    //        setShowVehicleInfo(true);
    //    }
    //}, [vin]);
    const handleVin = async (newVIN) => {
        await getVehicleDetails(newVIN);
    };
    return (
        <NoSsr>
            <Zoom in={foundAddress}>
                <div style={{ width: '502px' }}>
                    <VehicleGrid>
                        <Grid sx={{ gridArea: 'title', margin: '20px 0px 0px 0px' }} >
                            <RenewalTitle>Vehicle Information</RenewalTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'vinTitle', margin: '20px 0px 0px 0px' }} >
                            <OrderFieldTitle>VIN</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'vin', margin: '10px 0px 0px 0px' }} >
                            <FormControl required>
                                <TextField
                                    placeholder="VIN Lookup"
                                    name="vin"
                                    value={vin || ''}
                                    required
                                    sx={{ width: "504px", cursor: 'pointer', fontFamily: 'Interstate' }}
                                    onChange={(e) => {
                                        const newVIN = e.target.value;
                                        if (newVIN.length <= 17) { // limit vin to 17 digits
                                            setVIN(newVIN);
                                        }
                                        if (newVIN.length === 17) {
                                            handleVin(newVIN);
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ gridArea: 'yearTitle', margin: '0px 0px 0px 0px' }} >
                            <OrderFieldTitle>Year</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'year', margin: '10px 0px 0px 0px' }} >
                            <FormControl required>
                                <TextField
                                    placeholder="Year"
                                    value={year || ''}
                                    name="year"
                                    required
                                    sx={{ width: "146px", cursor: 'pointer', fontFamily: 'Interstate', marginRight: '23px' }}
                                    onChange={(e) => {
                                        const newYear = e.target.value;
                                        if (newYear.length <= 4) { // limit year to 4 digits
                                            setYear(newYear);
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ gridArea: 'makeTitle', margin: '0px 0px 0px 0px' }} >
                            <OrderFieldTitle>Make</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'make', margin: '10px 0px 0px 0px' }} >
                            <FormControl required>
                                <TextField
                                    placeholder="Make"
                                    value={make || ''}
                                    name="make"
                                    required
                                    sx={{ width: "335px", cursor: 'pointer', fontFamily: 'Interstate' }}
                                    onChange={(e) => {
                                        const newMake = e.target.value;
                                        if (newMake.length <= 20) { // limit make to 20 digits
                                            setMake(newMake);
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ gridArea: 'modelTitle', margin: '0px 0px 0px 0px' }} >
                            <OrderFieldTitle>Model</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'model', margin: '10px 0px 0px 0px' }} >
                            <FormControl required>
                                <TextField
                                    placeholder="Model"
                                    value={model || ''}
                                    name="model"
                                    required
                                    sx={{ width: "504px", cursor: 'pointer', fontFamily: 'Interstate' }}
                                    onChange={(e) => {
                                        const newModel = e.target.value;
                                        if (newModel.length <= 30) { // limit model to 30 digits
                                            setModel(newModel);
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ gridArea: 'divider', margin: '0px 0px 0px 0px' }}>
                            <Divider sx={{ border: '1px solid #ED7300', width: '502px' }} />
                        </Grid>
                    </VehicleGrid>
                    <RegistrationGrid>
                        <Grid sx={{ gridArea: 'regiTitle', margin: '20px 0px 0px 0px' }} >
                            <RenewalTitle>Registration</RenewalTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'stateTitle', margin: '20px 0px 0px 0px' }} >
                            <OrderFieldTitle>Select State</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'state', margin: '10px 0px 0px 0px' }} >
                            <FormControl required>
                                <Autocomplete
                                    options={states}
                                    id="state"
                                    value={selection.selectedState || null}
                                    filterOptions={filterOptions}
                                    sx={{ width: "504px", cursor: 'pointer', fontFamily: 'Interstate' }}
                                    getOptionLabel={(option: State) => option.StateName}
                                    isOptionEqualToValue={(option, value) => option.StateName === value?.StateName}
                                    renderInput={(params) => <TextField {...params} required />}
                                    onChange={(e, newValue) => {
                                        if (newValue) {
                                            setSelection({ ...selection, selectedState: newValue });
                                        }
                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.StateId} style={{ fontFamily: 'InterstateLight', fontSize: '10px' }} >
                                                {option.StateName}
                                            </li>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ gridArea: 'regNameTitle', margin: '0px 0px 0px 0px' }} >
                            <OrderFieldTitle>Registration Name</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'regName', margin: '10px 0px 0px 0px' }} >
                            <FormControl required>
                                <TextField
                                    placeholder="Name"
                                    value={regName || ''}
                                    name="regName"
                                    required
                                    sx={{ width: "504px", cursor: 'pointer', fontFamily: 'Interstate' }}
                                    onChange={(e) => {
                                        const newName = e.target.value;
                                        if (newName.length <= 100) { // limit make to 100 digits
                                            setRegName(newName);
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ gridArea: 'regTitle', margin: '0px 0px 0px 0px' }} >
                            <OrderFieldTitle>Registration Address</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'regTitle2', margin: '0px 0px 0px 30px' }} >
                            <OrderFieldTitle>Suite (if applicable)</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'reg', margin: '10px 0px 0px 0px' }} >
                            <FormControl required>
                                <TextField
                                    placeholder="1000 Street Rd"
                                    value={regAddress1 || ''}
                                    sx={{ width: "346px", cursor: 'pointer', fontFamily: 'Interstate' }}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue.length <= 80) { // limit addr1 to 80 digits
                                            setRegAddress1(inputValue);
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ gridArea: 'reg2', margin: '10px 0px 0px 0px', display: 'grid', justifyItems: 'end' }} >
                            <FormControl required>
                                <TextField
                                    placeholder="Apt 100"
                                    value={regAddress2 || ''}
                                    sx={{ width: "135px", cursor: 'pointer', fontFamily: 'Interstate' }}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue.length <= 80) { // limit addr2 to 80 digits
                                            setRegAddress2(inputValue);
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ gridArea: 'cityTitle', margin: '0px 0px 0px 0px' }}>
                            <OrderFieldTitle>City</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'stateAbrvTitle', margin: '0px 0px 0px 0px' }}>
                            <OrderFieldTitle>State</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'zipTitle', margin: '0px 0px 0px 0px' }}>
                            <OrderFieldTitle>Zip Code</OrderFieldTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'city', margin: '10px 0px 0px 0px' }}>
                            <TextField
                                placeholder="New Town"
                                name="city"
                                value={regCity || ''}
                                required
                                sx={{ width: '223px', marginRight: '23px' }}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (inputValue.length <= 30) { // limit city to 30 digits
                                        setRegCity(inputValue);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid sx={{ gridArea: 'stateAbrv', margin: '10px 0px 0px 0px' }}>
                            <FormControl required>
                                <TextField
                                    placeholder="KS"
                                    name="stateAbrv"
                                    value={selection.selectedState?.StateAbbreviation || ''}
                                    disabled
                                    sx={{ width: '68px', marginRight: '23px' }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ gridArea: 'zip', margin: '10px 0px 0px 0px' }}>
                            <FormControl required>
                                <TextField
                                    placeholder="12345"
                                    name="zip"
                                    value={regZip || ''}
                                    required
                                    sx={{ width: '166px' }}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue.length <= 5) { // limit zip to 5 digits
                                            setRegZip(inputValue);
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ gridArea: 'reqButton', margin: '10px 0px 0px 0px' }} >
                            <OrangeEndIconButton
                                label={editing ? "Save" : "Requirements"}
                                onClick={handleRegNext}
                                variant="contained"
                                endIcon={editing ? <SaveIcon sx={{ color: 'white' }} /> : <ArrowForwardIcon sx={{ color: 'white' }} />}
                                disabled={disableButton}
                                sx={{ width: '505px', marginBottom: '20px' }}
                            />
                        </Grid>
                    </RegistrationGrid>
                    <LoadingDialog
                        open={showVerifyDialog}
                        style={{ height: '150px', width: '450px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        sx={{ fontSize: '22px', fontFamily: 'Interstate', marginLeft: '20px' }}
                        message="Verifying Address"
                    />
                    <OkButtonDialog
                        open={addressError}
                        style={{ height: '150px', width: '580px', textWrap: 'pretty', display: 'grid', margin: '10px', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}
                        sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}
                        message="Unable to verify address. Please check spelling and try again."
                        close={handleErrorClose}
                    />
                </div>
            </Zoom>
        </NoSsr>
    );
}