import React, { useState, useEffect, useRef } from 'react';
import { Tab, Typography, NoSsr, styled, IconButton, Tooltip, Button, Box, FormControl, Autocomplete, createFilterOptions, TextField, Grid, Divider, Dialog } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SubmitButton, GoBackButton, FieldLabel, RenewalTitle, TitleGrid, VINGrid, InfoGrid, Numbers } from '../Shared/Renewals';
interface StyleCode extends Object {
    Description: string;
    StateStyleCodeId: number;
}
interface GetDropDowns {
    stateStyleCode: StyleCode[] 
}
interface IndividualModal {
    selectedBodyType: StyleCode | null;
}

export const IndividualVehicle = ({ handleGoBack, handleNext, formData, editing}) => {
    
    const [showVehicleInfo, setShowVehicleInfo] = React.useState<boolean>(false);
    const [disableButton, setDisableButton] = React.useState<boolean>(true);
    const [vin, setVIN] = useState(formData?.vehicle?.vin ?? null);
    const [year, setYear] = useState(formData?.vehicle?.year ?? null);
    const [model, setModel] = useState(formData?.vehicle?.model ?? null);
    const [make, setMake] = useState(formData?.vehicle?.make ?? null);
    const [color, setColor] = useState(formData?.vehicle?.color ?? null);
    const [bodyType, setBodyType] = useState<StyleCode[]>([]);
    const [individualModal, setIndividualModal] = useState<IndividualModal>({ selectedBodyType: formData?.vehicle?.individualModal.selectedBodyType ?? bodyType[1]});
    const { reg } = formData;

    const handleVin = async (newVIN) => {
        await getVehicleDetails(newVIN);
    };

    const handleVehicleNext = () => {
        if (vin != null) {
            const newData = {
                
                vehicle: { vin, make, model, year, color, bodyType, individualModal },
            };
            handleNext(newData);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getStateStyleCodes();
            } catch (error) {
                console.error("An error occurred during data fetching:", error);
            }
        };

        fetchData();
        return () => { };
    }, []);
    useEffect(() => {
        if ( vin != null && year != null && model != null && make != null && color != null && individualModal.selectedBodyType != undefined) {
            if (vin != '' && year != '' && model != '' && make != '' && color != '') {
                setDisableButton(false);
            }
            else {
                setDisableButton(true);
            }
        }
        else {
            setDisableButton(true);
        }
    }, [vin, year, model, make, color, individualModal.selectedBodyType]);

    useEffect(() => {
        if (vin && vin.length === 17) {
            setShowVehicleInfo(true);
        }
    }, [vin]);
    const getStateStyleCodes = (): void => {
        const requestOptions = {
            method: "GET",
        };
        const url = `${window.location.origin}/Portal/GetStateStyleCodes?stateId=${reg.individualModal.selectedState.Id}`;
        fetch(url, requestOptions)
            .then((res) => res.json())
            .then(async (result: GetDropDowns) => setBodyType(result.stateStyleCode))
            .catch((error) => {
                console.log("error get body types dropdown");
                console.log("error:", error);
            });
    }

    const getVehicleDetails = (newVIN): void => {
        const requestOptions = {
            method: "GET",
        };
        const url = `${window.location.origin}/Portal/GetVehicleDetails?vin=${newVIN}`;

        fetch(url, requestOptions)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`vehicle details response error: ${res.status}`);
                }
                return res.json();
                })
            .then((data) => {
                const decoded = data.vinDecode.Result.Data;
                setYear(decoded.Year ?? null);
                setModel(decoded.Model ?? null);
                setMake(decoded.Make ?? null);
                setColor(data.color ?? null);
                const selectedBodyType = bodyType.find(bodyType => bodyType.StateStyleCodeId === data.bodyType?.StateStyleCodeId);
                setIndividualModal({
                    ...individualModal,
                    selectedBodyType: selectedBodyType ? { Description: selectedBodyType.Description, StateStyleCodeId: selectedBodyType.StateStyleCodeId } : null
                });
            })
            .catch((error) => {
                console.log("error vin decoder vehcile details tsx");
                console.log("error:", error);
            });
    }
    return (
        <NoSsr>
            <div className="container height-350">
                <div>
                    <Grid container style={{ height: '100%', justifyContent: 'center' }}>
                        <TitleGrid item xs={12}>
                            <br />
                            <Grid sx={{ gridArea: 'vehicleTitle', margin: '30px 0px' }}>
                                <RenewalTitle>Vehicle Information</RenewalTitle>
                            </Grid>
                            <Grid sx={{ gridArea: 'circles', margin: '30px 0px' }} >
                                <div className="mmt-progress">
                                    <div>
                                        <Numbers src="../Content/assets/img/icons/Grey_One.png" /><Numbers src="../Content/assets/img/icons/Orange_Two.png" /><Numbers src="../Content/assets/img/icons/Grey_Three.png" />
                                    </div>
                                </div>
                            </Grid>
                        </TitleGrid>
                        <VINGrid item xs={12}>
                            <Grid sx={{ gridArea: 'vin' }}>
                                <FieldLabel>Enter the VIN</FieldLabel>
                                <FormControl required>
                                    <TextField
                                        placeholder="VIN Lookup"
                                        name="vin"
                                        value={vin || ''}
                                        required
                                        sx={{ width:'264px'}}
                                        onChange={(e) => {
                                            const newVIN = e.target.value;
                                            if (newVIN.length <= 17) { // limit vin to 17 digits
                                                setVIN(newVIN);
                                            }
                                            if (newVIN.length === 17) {
                                                handleVin(newVIN); 
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </VINGrid>
                        {showVehicleInfo && (
                            <InfoGrid item xs={12}>
                                <Grid sx={{ gridArea: 'disabledVIN' }}>
                                    <FieldLabel>VIN</FieldLabel>
                                    <FormControl required>
                                        <TextField
                                            placeholder="A1B2C3D7E8F9G0H1I"
                                            name="disabledVIN"
                                            required
                                            disabled
                                            value={vin || ''}
                                            sx={{ marginRight: '23px', width:'158px' }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid sx={{ gridArea: 'year' }}>
                                    <FieldLabel>Year</FieldLabel>
                                    <FormControl required>
                                        <TextField
                                            placeholder="Year"
                                            value={year || ''}
                                            name="make"
                                            required
                                            sx={{ marginRight: '23px', width:'84px' }}
                                            onChange={(e) => {
                                                const newYear = e.target.value;
                                                if (newYear.length <= 4) { // limit year to 4 digits
                                                    setYear(newYear);
                                                }
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid sx={{ gridArea: 'make' }}>
                                    <FieldLabel>Make</FieldLabel>
                                    <FormControl required>
                                        <TextField
                                            placeholder="Make"
                                            value={make || ''}
                                            name="make"
                                            required
                                            sx={{ marginRight: '23px', width:'102px' }}
                                            onChange={(e) => {
                                                const newMake = e.target.value;
                                                if (newMake.length <= 20) { // limit make to 20 digits
                                                    setMake(newMake);
                                                }
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid sx={{ gridArea: 'model' }}>
                                    <FieldLabel>Model</FieldLabel>
                                    <FormControl required>
                                        <TextField
                                            placeholder="Model"
                                            value={model || ''}
                                            name="model"
                                            required
                                            sx={{ marginRight: '23px', width: '102px' }}
                                            onChange={(e) => {
                                                const newModel = e.target.value;
                                                if (newModel.length <= 30) { // limit model to 30 digits
                                                    setModel(newModel);
                                                }
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid sx={{ gridArea: 'color' }}>
                                    <FieldLabel>Color</FieldLabel>
                                    <FormControl required>
                                        <TextField
                                            placeholder="Color"
                                            value={color || ''}
                                            name="color"
                                            required
                                            sx={{ marginRight: '23px', width: '102px' }}
                                            onChange={(e) => {
                                                const newColor = e.target.value;
                                                if (newColor.length <= 100) { // limit model to 100 digits
                                                    setColor(newColor);
                                                }
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid sx={{ gridArea: 'type' }}>
                                    <FieldLabel>Body Type</FieldLabel>
                                    <FormControl required>
                                        <Autocomplete
                                            options={bodyType}
                                            id="bodyType"
                                            value={individualModal.selectedBodyType || null}
                                            sx={{ width: '165px', cursor: 'pointer', fontFamily: 'InterstateLight' }}
                                            getOptionLabel={(option: StyleCode) => option.Description}
                                            renderInput={(params) => <TextField {...params} required placeholder="Select Body Type" />}
                                            isOptionEqualToValue={(option, value) => option.StateStyleCodeId === value?.StateStyleCodeId}
                                            onChange={(e, newValue) => {
                                                if (newValue) {
                                                    setIndividualModal({ ...individualModal, selectedBodyType: newValue });
                                                }
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.StateStyleCodeId} style={{ fontFamily: 'InterstateLight', fontSize: '10px' }} >
                                                        {option.Description}
                                                    </li>
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid sx={{ gridArea: 'backButton', marginTop: '30px' }}>
                                    <GoBackButton onClick={handleGoBack} variant="text"><ArrowBackIcon sx={{ color: '#ED7300' }} />Back</GoBackButton>
                                </Grid>
                                <Grid sx={{ gridArea: 'nextButton', display: 'grid', justifyContent: 'end', marginTop: '30px' }}>
                                    <SubmitButton sx={{ width: '191px' }} disabled={disableButton} onClick={handleVehicleNext} >{editing ? "Save" : "Next"}</SubmitButton>
                                </Grid>
                            </InfoGrid>
                            )}    
                    </Grid>
                </div>
            </div>
        </NoSsr>
    );
}
