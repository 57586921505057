import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';

interface DatePickerAcertusProps {
    label?: string;
    value?: Dayjs | null;
    onChange?: (date: Dayjs | null) => void;
    disabled?: boolean;
    clearable?: boolean;
    minDate?: Dayjs;
    maxDate?: Dayjs;
    onClear?: () => void;
};

const DatePickerAcertus: React.FC<DatePickerAcertusProps> = ({ label, value, onChange, disabled, clearable, minDate, maxDate, onClear }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={label}
                value={value}
                onChange={onChange}
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
                openTo="day"
                slotProps={{
                    field: { clearable: clearable, onClear: onClear },
                    textField: { size: 'small' },
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        width: "215px",
                        fontSize: "12px",
                        borderRadius: "4px",
                        backgroundColor: "#FFFFFF",
                        '&:hover fieldset': {
                            borderColor: "#ED7300",
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: "#ED7300",
                        },
                    },
                    '& .MuiInputLabel-root': {
                        fontSize: "12px",
                        color: '#5D6771',
                        "&.Mui-focused": {
                            color: "#5D6771",
                        },
                    },
                    '& .MuiInputBase-input': {
                        fontSize: "12px",
                    },
                }}
            />
        </LocalizationProvider>
    );
};

export default DatePickerAcertus;
