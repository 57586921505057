import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, NoSsr, styled, Typography, Dialog, Zoom, Button, Grid, FormControl, Autocomplete, TextField, Divider, Grow, GlobalStyles } from '@mui/material';
import { RenewalOrder } from './Renewals/RenewalOrder';
import { IndividualReg } from './Renewals/IndividualReg';
import { IndividualVehicle } from './Renewals/IndividualVehicle';
import { IndividualShipping } from './Renewals/IndividualShipping';
import { IndividualConfirmation } from './Renewals/IndividualConfirmation';
import { MultipleUpload } from './Renewals/MultipleUpload';
import { LTOrder } from './LicenseAndTitle/LTOrder';
import { Requirements } from './LicenseAndTitle/Requirements';
import { Submissions } from './LicenseAndTitle/Submissions'
import { Summary } from './LicenseAndTitle/Summary'
import { TabStyle, ResetGrid, WhiteGoBackButton, SubmitButton, RenewalTitle, OrderSelectionGrid } from '../Shared/Renewals'
import { LoadingDialog, OkButtonDialog } from '../Components/Dialog';
import { State, ReqBy } from '../Types/common';
import { ReqValueOverride } from './Common';

export const OrdersGridWithScroll = styled(Grid)({
    height: '100%',
    justifyContent: 'center',
    maxHeight: '715px',
    overflow: 'auto',
    marginTop: '20px',
    '&::-webkit-scrollbar': {
        width: '13px',
    },
    '&::-webkit-scrollbar-track': {
        background: '#ffffff',
        borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D9D9D9',
        borderRadius: '10px',
        border: '3px solid #ffffff',
    },
});

interface FormData {
    confirm?: {
        invoiceNumber?: string;
    };
}
interface OrderType extends Object {
    Name: string;
    Id: number;
}
interface GetDropDowns {
    reqBy: ReqBy[];
    orderType: OrderType[];
}
interface IndividualModal {
    selectedReqBy: ReqBy | null;
    selectedCustState: State | null;
    selectedOrderType: OrderType | null;
}
export const CreateOrdersPage = ({ }) => {
    const [reqBy, setReqBy] = useState<ReqBy[]>([]);
    const [orderType, setOrderType] = useState<OrderType[]>([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState<FormData>({});
    const [rerun, setRerun] = React.useState<boolean>(false);
    const [showRerunDialog, setShowRerunDialog] = React.useState<boolean>(false);
    const [showResetDialog, setShowResetDialog] = React.useState<boolean>(false);
    const [showCloseDialog, setShowCloseDialog] = React.useState<boolean>(false);
    const [multipleOrder, setMultipleOrder] = React.useState<boolean>(false);
    const [editing, setEditing] = React.useState<boolean>(false);
    const [custName, setCustName] = useState<string | null>(null);
    const [custAddress1, setCustAddress1] = useState<string | null>(null);
    const [custAddress2, setCustAddress2] = useState<string | null>(null);
    const [custCity, setCustCity] = useState<string | null>(null);
    const [custState, setCustState] = useState<string | null>(null);
    const [custStateAbrv, setCustStateAbrv] = useState<string | null>(null);
    const [custZip, setCustZip] = useState<string | null>(null);
    const [custPhone, setCustPhone] = useState<string | null>(null);
    const [custId, setCustId] = useState<number | null>(null);
    const [contact, setContact] = useState<number | null>(null);
    const [contactName, setContactName] = useState<string | null>(null);
    const [states, setStates] = useState<State[]>([]);
    const [disableButton, setDisableButton] = React.useState<boolean>(true)
    const [individualModal, setIndividualModal] = useState<IndividualModal>({ selectedReqBy: reqBy[1], selectedCustState: states[1], selectedOrderType: orderType[1] });
    const [reqValueOverrides, setReqValueOverrides] = useState<ReqValueOverride[]>([]);
    const [hasNoLienholder, setHasNoLienholder] = React.useState<boolean>(false);
    const [lienholderQuestionId, setLienholderQuestionId] = useState<number | undefined>();
    const updateFormData = (newData: FormData) => {
        setFormData({ ...formData, ...newData });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                window.startCreateOrder = async () => {
                    await getReqInfo();
                }
            } catch (error) {
                console.error("An error occurred during data fetching in useeffect:", error);
            }
        };
        window.resetCurrentStep = async () => {
            setShowCloseDialog(true);
        }
        fetchData();
        return () => {
            if (window.startCreateOrder) {
                delete window.startCreateOrder;
            }
            if (window.resetCurrentStep) {
                delete window.resetCurrentStep;
            }
        };
    }, []);

    const handleAccount = async (contact) => {
        getCustomerAddress(contact.ContactId);
        setIndividualModal(prevState => ({ ...prevState, selectedReqBy: contact }));
        const selected = contact.ContactId;
        if (contact != undefined) {
            setDisableButton(false);
            setContact(selected);
        }
    }
    const getCustomerAddress = async (contact) => {

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ contactId: contact }),
        };
        const url = `${window.location.origin}/CreateOrders/GetCustomerAddress`;

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                const errorMessage = `Failed to get customer address. Status: ${response.status} ${response.statusText}`;
                const responseBody = await response.text();
                console.error(errorMessage, responseBody);
                console.error("Request URL:", url);
                console.error("Request Options:", requestOptions);
            } else {
                const data = await response.json();
                if (data != null) {
                    setCustId(data.customerAddress.ShipCustomerId);
                    setCustName(data.customerAddress.ShipCustomer);
                    setContactName(data.customerAddress.ShipName);
                    setCustAddress1(data.customerAddress.ShipAddr1);
                    setCustAddress2(data.customerAddress.ShipAddr2);
                    setCustCity(data.customerAddress.ShipCity);
                    setCustState(data.customerAddress.ShipStateName);
                    setCustStateAbrv(data.customerAddress.ShipStateAbrv);
                    setIndividualModal((prevModal) => ({
                        ...prevModal,
                        selectedCustState: { StateName: data.customerAddress.ShipStateName, StateId: data.customerAddress.ShipStateId, StateAbbreviation: data.customerAddress.ShipStateAbrv, CountryId: null },
                        selectedReqBy: { Name: data.customerAddress.ShipCustomer, CustomerId: data.customerAddress.ShipCustomerId, ContactId: contact },
                    }));
                    setCustZip(data.customerAddress.ShipZip);
                    setCustPhone(data.customerAddress.ShipPhone);
                    setContact(contact);
                    const formData = {
                        reqBy: {
                            reqBy,
                            contactId: contact,
                            custId: data.customerAddress.ShipCustomerId,
                            custName: data.customerAddress.ShipCustomer,
                            custAddress1: data.customerAddress.ShipAddr1,
                            custAddress2: data.customerAddress.ShipAddr2,
                            custCity: data.customerAddress.ShipCity,
                            custState: data.customerAddress.ShipStateName,
                            custStateAbrv: data.customerAddress.ShipStateAbrv,
                            custZip: data.customerAddress.ShipZip,
                            custPhone: data.customerAddress.ShipPhone,
                            contactName: data.customerAddress.ShipName,
                            individualModal: {
                                ...individualModal,
                                selectedCustState: {
                                    StateName: data.customerAddress.ShipStateName,
                                    StateId: data.customerAddress.ShipStateId,
                                    StateAbbreviation: data.customerAddress.ShipStateAbrv
                                },
                                selectedReqBy: {
                                    Name: data.customerAddress.ShipCustomer,
                                    CustomerId: data.customerAddress.ShipCustomerId,
                                    ContactId: contact
                                },
                            },
                        }
                    };
                    await updateForm(formData);
                }
            }
        } catch (error) {
            console.error('An error occurred during getting cust address:', error);
            return false;
        }
    }
    const getReqInfo = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };
        const url = window.location.origin + "/CreateOrders/GetReqInfo";
        try {
            const res = await fetch(url, requestOptions);
            if (!res.ok) {
                console.error(`HTTP error! status: ${res.status}`);
                const errorText = await res.text();
                console.error("Response text:", errorText);
                throw new Error("Network response was not ok");
            }
            const result: GetDropDowns = await res.json();
            setReqBy(result.reqBy);
            if (result.reqBy.length === 1) {
                const singleReqBy = result.reqBy[0];
                setDisableButton(false);
                await handleAccount(singleReqBy);
            }
        } catch (error) {
            console.error("error GetReqInfo dropdown");
            console.error("error:", error);
        }
    }
    const handleEdit = async (step) => {
        setEditing(true);
        setCurrentStep(step);
    }
    const handleNewOrder = () => {
        setShowResetDialog(false);
        setShowCloseDialog(false);
        setEditing(false);
        setCurrentStep(0);
        setIndividualModal({ ...individualModal, selectedOrderType: null, selectedReqBy: reqBy.length != 1 ? null : reqBy[0] });
        setFormData({});
        handleModalWidth();
    };
    const handleOrderChangeReset = () => {
        setShowResetDialog(false);
        setShowCloseDialog(false);
        setEditing(false);
        setCurrentStep(0);
        setFormData({});
        handleModalWidth();
    };
    const handleClose = () => {
        setShowResetDialog(false);
        setShowCloseDialog(false);
        setEditing(false);
        setCurrentStep(0);
        setIndividualModal({ ...individualModal, selectedOrderType: null, selectedReqBy: reqBy.length != 1 ? null : reqBy[0] });
        setFormData({});
        handleModalWidth();
        if (window.closeCreateOrder) {
            window.closeCreateOrder();
        }
    };
    const handleRerun = () => {
        setRerun(true);
        setShowRerunDialog(false)
        setCurrentStep(8);
        setEditing(false);
    }
    const handleResetNo = () => {
        setShowRerunDialog(false);
        setShowResetDialog(false);
        setShowCloseDialog(false);
    };
    const handleModalWidth = async () => {
        const modalContent = document.getElementById("modalContent");
        if (modalContent) {
            modalContent.style.width = "600px";
        }
    }
    const handleMultipleOrderModalWidth = async () => {
        const modalContent = document.getElementById("modalContent");
        if (modalContent) {
            modalContent.style.width = "900px";
        }
    }
    const handleGoBack = async () => {
        if (currentStep == 2 || currentStep == 6) {
            setShowResetDialog(true)
        }
        else {
            setCurrentStep(currentStep - 1);
        }

    };
    const updateForm = async (newData) => {
        updateFormData(newData);
    };
    const handleNext = async (newData) => {
        updateFormData(newData);
        if (editing) {
            if (currentStep >= 7) {
                setCurrentStep(10);
            }
            else {
                setCurrentStep(5);
            }
        } else {
            setCurrentStep(currentStep + 1);
        }
    };
    const handleNextRerun = async (newData) => {
        updateFormData(newData);
        if (editing) {
            setRerun(true);
            setShowRerunDialog(true);
            setCurrentStep(7);
        }
        else {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleMultipleOrder = (newData) => {
        setFormData({});
        updateFormData(newData);
        setCurrentStep(6);
        handleMultipleOrderModalWidth();
    };

    const handleAccountChange = async (newValue) => {
        const selectedContact = newValue.ContactId;
        await setIndividualModal(prevState => ({ ...prevState, selectedReqBy: newValue }))
        if (selectedContact != undefined) {
            await setDisableButton(false);
            await setContact(selectedContact);
        }
        await getCustomerAddress(selectedContact);
        if (individualModal.selectedOrderType) {
            if (individualModal.selectedOrderType.Id == 1) {
                await setCurrentStep(1)
            }
            if (individualModal.selectedOrderType.Id == 2) {
                await setCurrentStep(7)
            }
        }
    }

    const orderTypeOptions = [
        { Name: 'Renewal', Id: 1 },
        { Name: 'License & Title Order', Id: 2 }
    ];

    const handleOrderTypeChange = async (orderType) => {
        const selected = orderType.Id;
        if (selected) {
            await setOrderType(orderType);
            await setIndividualModal(prevState => ({
                ...prevState,
                selectedOrderType: orderType,
            }));
            const formData = await (async () => {
                return {
                    reqBy: { reqBy, individualModal, contact, custId, custName, custAddress1, custAddress2, custCity, custZip, custPhone, contactName },
                    orderType: { selected, orderType }
                };
            })();
            await updateForm(formData);
            if (individualModal.selectedReqBy) {
                if (selected == 1) {
                    setCurrentStep(1)
                }
                if (selected == 2) {
                    setCurrentStep(7)
                }
            }
        }
    };
    return (
        <NoSsr>
            <div>
                <OrdersGridWithScroll container>
                    {(currentStep <= 1 || currentStep === 7) && (
                    <div>
                    <OrderSelectionGrid>
                        <Grid sx={{ gridArea: 'title' }}>
                            <RenewalTitle>New Order</RenewalTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'accountDD', margin: '20px 0px 0px 0px' }}>
                            <FormControl required>
                                <Autocomplete
                                    options={reqBy}
                                    id="reqBy"
                                    value={reqBy.length === 1 ? reqBy[0] : individualModal.selectedReqBy || null}
                                    sx={{ width: "504px", cursor: 'pointer', fontFamily: 'Interstate' }}
                                    getOptionLabel={(option: ReqBy) => option.Name}
                                    isOptionEqualToValue={(option, value) => option.CustomerId === value.CustomerId && option.ContactId === value.ContactId}
                                    renderInput={(params) => <TextField {...params} required placeholder="Select Account" />}
                                    onChange={(e, newValue) => {
                                        if (newValue) {
                                            setIndividualModal({ ...individualModal, selectedReqBy: newValue });
                                            handleAccountChange(newValue);
                                        }
                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.CustomerId} style={{ fontFamily: 'InterstateLight', fontSize: '10px' }} >
                                                {option.Name}
                                            </li>
                                        );
                                    }}
                                    loading={reqBy.length === 0}  
                                    loadingText="Loading..."  
                                    noOptionsText={reqBy.length === 0 ? "Loading..." : "No options"} 
                                />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ gridArea: 'orderDD', margin: '0px 0px' }}>
                            <FormControl required>
                                <Autocomplete
                                    options={orderTypeOptions}
                                    id="orderType"
                                    value={individualModal.selectedOrderType || null}
                                    sx={{ width: "504px", cursor: 'pointer', fontFamily: 'Interstate' }}
                                    isOptionEqualToValue={(option, value) => option.Id === value.Id}
                                    getOptionLabel={(option) => option.Name}
                                    renderInput={(params) => <TextField {...params} required placeholder="Select Order Type" />}
                                    onChange={(e, newValue) => {
                                        if (newValue) {
                                            handleOrderChangeReset()
                                            handleOrderTypeChange(newValue);
                                        }
                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.Id} style={{ fontFamily: 'InterstateLight', fontSize: '10px' }} >
                                                {option.Name}
                                            </li>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </OrderSelectionGrid>
                    <Divider sx={{ border: '1px solid #ED7300', width: '502px' }} />
            </div>
                    )}
                    {currentStep === 1 && (
                        <RenewalOrder handleNext={handleNext} handleMultipleOrder={handleMultipleOrder} formData={formData} reqBy={reqBy} />
                    )}
                    {currentStep === 2 && (
                        <IndividualReg handleGoBack={handleGoBack} handleNext={handleNext} formData={formData} editing={editing} />
                    )}
                    {currentStep === 3 && (
                        <IndividualVehicle handleGoBack={handleGoBack} handleNext={handleNext} formData={formData} editing={editing} />
                    )}
                    {currentStep === 4 && (
                        <IndividualShipping handleGoBack={handleGoBack} handleNext={handleNext} formData={formData} editing={editing} />
                    )}
                    {currentStep === 5 && (
                        <IndividualConfirmation handleGoBack={handleGoBack} handleNext={handleNext} formData={formData} handleEdit={handleEdit} handleNewOrder={handleNewOrder} handleClose={handleClose} />
                    )}
                    {currentStep === 6 && (
                        <MultipleUpload handleGoBack={handleGoBack} formData={formData} handleNewOrder={handleNewOrder} />
                    )}
                    {currentStep === 7 && (
                        <LTOrder handleNext={handleNext} formData={formData} editing={editing} handleNextRerun={handleNextRerun} reqValueOverrides={reqValueOverrides} setReqValueOverrides={setReqValueOverrides} />
                    )}
                    {currentStep === 8 && (
                        <Requirements handleNext={handleNext} formData={formData} handleGoBack={handleGoBack} rerun={rerun} setRerun={setRerun} editing={editing} reqValueOverrides={reqValueOverrides} setReqValueOverrides={setReqValueOverrides} hasNoLienholder={hasNoLienholder} setHasNoLienholder={setHasNoLienholder} lienholderQuestionId={lienholderQuestionId} setLienholderQuestionId={setLienholderQuestionId} />
                    )}
                    {currentStep === 9 && (
                        <Submissions handleNext={handleNext} formData={formData} handleGoBack={handleGoBack} editing={editing} />
                    )}
                    {currentStep === 10 && (
                        <Summary handleNext={handleNext} formData={formData} handleGoBack={handleGoBack} handleEdit={handleEdit} handleNewOrder={handleNewOrder} handleClose={handleClose} />
                    )}
                    <Dialog open={showResetDialog}>
                        <Zoom in={showResetDialog}>
                            <div style={{ height: '150px', width: '600px', display: 'grid', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}>
                                <Grid container style={{ height: '100%', justifyContent: 'center' }}>
                                    <ResetGrid>
                                        <Grid sx={{ gridArea: 'question', marginBottom: '20px' }}>
                                            <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}>Are you sure you want to start over?</Typography>
                                        </Grid>
                                        <Grid sx={{ gridArea: 'noButton' }}>
                                            <WhiteGoBackButton onClick={handleResetNo} variant="text">No</WhiteGoBackButton>
                                        </Grid>
                                        <Grid sx={{ gridArea: 'yesButton' }}>
                                            <SubmitButton onClick={handleNewOrder}>Yes</SubmitButton>
                                        </Grid>
                                    </ResetGrid>
                                </Grid>
                            </div>
                        </Zoom>
                    </Dialog>
                    <Dialog open={showCloseDialog}>
                        <Zoom in={showCloseDialog}>
                            <div style={{ height: '227px', width: '600px', display: 'grid', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}>
                                <Grid container style={{ height: '100%', justifyContent: 'center', justifyItems: 'center' }}>
                                    <ResetGrid>
                                        <Grid sx={{ gridArea: 'question', marginBottom: '20px', display: 'grid', justifyContent: 'center' }}>
                                            <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}>Are you sure you want to close?</Typography>
                                            <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 20px 20px' }}>Your progress will be reset.</Typography>
                                        </Grid>
                                        <Grid sx={{ gridArea: 'noButton' }}>
                                            <WhiteGoBackButton onClick={handleResetNo} variant="text">No</WhiteGoBackButton>
                                        </Grid>
                                        <Grid sx={{ gridArea: 'yesButton' }}>
                                            <SubmitButton onClick={handleClose}>Yes</SubmitButton>
                                        </Grid>
                                    </ResetGrid>
                                </Grid>
                            </div>
                        </Zoom>
                    </Dialog>
                    <OkButtonDialog
                        open={showRerunDialog}
                        style={{ height: '150px', width: '580px', textWrap: 'pretty', display: 'grid', margin: '10px', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}
                        sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}
                        message="Valuable information changed. Please check for new Requirements."
                        close={handleRerun}
                    />
                </OrdersGridWithScroll>
            </div>
        </NoSsr>
    );
}
