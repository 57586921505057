import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, NoSsr, styled, Typography, Dialog, Zoom, Button, Grid, FormControl, Autocomplete, TextField, Divider, Grow, GlobalStyles } from '@mui/material';
import { RenewalOrder } from './RenewalOrder';
import { IndividualReg } from './IndividualReg';
import { IndividualVehicle } from './IndividualVehicle';
import { IndividualShipping } from './IndividualShipping';
import { IndividualConfirmation } from './IndividualConfirmation';
import { MultipleUpload } from './MultipleUpload';
import { TabStyle, ResetGrid, WhiteGoBackButton, SubmitButton, RenewalTitle, OrderSelectionGrid } from '../Shared/Renewals'

interface FormData {
    confirm?: {
        invoiceNumber?: string;
    };
}
interface States extends Object {
    Name: string;
    Id: number;
    Abbreviation: string;
}
interface ReqBy extends Object {
    Name: string;
    CustomerId: number;
    ContactId: number;
}
interface OrderType extends Object {
    Name: string;
    Id: number;
}
interface GetDropDowns {
    reqBy: ReqBy[];
    orderType: OrderType[];
}
interface IndividualModal {
    selectedReqBy: ReqBy | null;
    selectedCustState: States | null;
    selectedOrderType: OrderType | null;
}
export const RenewalsPage = ({ }) => {
    const [reqBy, setReqBy] = useState<ReqBy[]>([]);
    const [orderType, setOrderType] = useState<OrderType[]>([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState<FormData>({});
    const [showResetDialog, setShowResetDialog] = React.useState<boolean>(false); 
    const [showCloseDialog, setShowCloseDialog] = React.useState<boolean>(false); 
    const [multipleOrder, setMultipleOrder] = React.useState<boolean>(false); 
    const [editing, setEditing] = React.useState<boolean>(false);
    const [custName, setCustName] = useState<string | null>(null);
    const [custAddress1, setCustAddress1] = useState<string | null>(null);
    const [custAddress2, setCustAddress2] = useState<string | null>(null);
    const [custCity, setCustCity] = useState<string | null>(null);
    const [custZip, setCustZip] = useState<string | null>(null);
    const [custPhone, setCustPhone] = useState<string | null>(null);
    const [custId, setCustId] = useState<number | null>(null);
    const [contact, setContact] = useState<number | null>(null);
    const [contactName, setContactName] = useState<string | null>(null);
    const [states, setStates] = useState<States[]>([]);
    const [disableButton, setDisableButton] = React.useState<boolean>(true);
    const [individualModal, setIndividualModal] = useState<IndividualModal>({ selectedReqBy: reqBy[1], selectedCustState: states[1], selectedOrderType: orderType[1] });
    const updateFormData = (newData: FormData) => {
        setFormData({ ...formData, ...newData });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getReqInfo();
            } catch (error) {
                console.error("An error occurred during data fetching in useeffect:", error);
            }
        };
        window.resetCurrentStep = async () => {
            setShowCloseDialog(true);
        }
        fetchData();
        return () => {
            if (window.resetCurrentStep) {
                delete window.resetCurrentStep;
            }
        };
    }, []);

    const handleAccount = async (contact) => {
        setIndividualModal(prevState => ({ ...prevState, selectedReqBy: contact }));
        const selected = contact.ContactId;
        if (contact != undefined) {
            setDisableButton(false);
            setContact(selected);
        }
    }
    const getReqInfo = async () => {
        const requestOptions = {
            method: "GET",
        };

        const url = window.location.origin + "/Portal/GetReqInfo";
        fetch(url, requestOptions)
            .then((res) => res.json())
            .then(async (result: GetDropDowns) => {
                setReqBy(result.reqBy);
                if (result.reqBy.length === 1) {
                    const singleReqBy = result.reqBy[0];
                    setDisableButton(false);
                    await handleAccount(singleReqBy);
                }
            })
            .catch((error) => {
                console.error("error GetReqInfo dropdown");
                console.error("error:", error);
            });
    }
    
    const handleEditReg = () => {
        setEditing(true);
        setCurrentStep(2);
    };
    const handleEditVehicle = () => {
        setEditing(true);
        setCurrentStep(3);
    };
    const handleEditShip = () => {
        setEditing(true);
        setCurrentStep(4);
    };
    const handleNewOrder = () => {
        setShowResetDialog(false);
        setShowCloseDialog(false);
        setEditing(false);
        setCurrentStep(0);
        setIndividualModal({ ...individualModal, selectedOrderType: null, selectedReqBy: reqBy.length != 1 ? null : reqBy[0] });
        setFormData({});
        handleModalWidth();
    };
    const handleClose = () => {
        setShowResetDialog(false);
        setShowCloseDialog(false);
        setEditing(false);
        setCurrentStep(0);
        setIndividualModal({ ...individualModal, selectedOrderType: null, selectedReqBy: reqBy.length != 1 ? null : reqBy[0] });
        setFormData({});
        handleModalWidth();
        //if (window.closeModal) {
        //    window.closeModal();
        //}
    };
    const handleResetNo = () => {
        setShowResetDialog(false);
        setShowCloseDialog(false);
    };
    const handleModalWidth = async () => {
        const modalContent = document.getElementById("modalContent");
        if (modalContent) {
            modalContent.style.width = "600px";
        }
    }
    const handleMultipleOrderModalWidth = async () => {
        const modalContent = document.getElementById("modalContent");
        if (modalContent) {
            modalContent.style.width = "900px";
        }
    }
    const handleGoBack = async () => {
        if (currentStep == 2 || currentStep == 6) {
            setShowResetDialog(true)
        }
        else {
            setCurrentStep(currentStep - 1);
        }

    };

    const handleNext = async (newData) => {
        const orderTypeId = newData.orderType.Id;
        updateFormData(newData);
        if (orderTypeId == 2) {
            setCurrentStep(7)
        }
        if (orderTypeId == 1) {
            if (editing) {
                setCurrentStep(5)
            }
            else {
                setCurrentStep(currentStep + 1);
            }
        }
    };

    const handleMultipleOrder = (newData) => {
        setFormData({});
        updateFormData(newData);
        setCurrentStep(6);
        handleMultipleOrderModalWidth();
    };

    const handleAccountChange = async (newValue) => {
        const selected = newValue.ContactId;
        if (selected != undefined) {
            setIndividualModal(prevState => ({...prevState, selectedReqBy: newValue }));
            setDisableButton(false);
            setContact(selected);
        }
        if (individualModal.selectedOrderType) {
            const formData = {
                reqBy: { reqBy, individualModal, contact, custName, custAddress1, custAddress2, custCity, custZip, custPhone, contactName },
                orderType: { orderType }
            };
            handleNext(formData)
        }
    }
   
    const orderTypeOptions = [
        { Name: 'Renewal', Id: 1 },
        { Name: 'License & Title Order', Id: 2 }
    ];

    const handleOrderTypeChange = async (newValue) => {
        const selected = newValue.Id;
        if (selected) {
            setIndividualModal(prevState => ({
                ...prevState,
                selectedOrderType: newValue,
            }));
            setOrderType(selected);
            if (individualModal.selectedReqBy) {
                const formData = {
                    reqBy: { reqBy, individualModal, contact, custName, custAddress1, custAddress2, custCity, custZip, custPhone, contactName },
                    orderType: { orderType }
                };
                handleNext(formData)
            }
        }
    };
    return (
        <NoSsr>
            <div>
                <Grid container style={{ height: '100%', justifyContent: 'center' }}>
                    {currentStep <= 1 || currentStep == 7 && (
                        <div>
                    <OrderSelectionGrid>
                        <Grid sx={{ gridArea: 'title' }}>
                            <RenewalTitle>New Order</RenewalTitle>
                        </Grid>
                        <Grid sx={{ gridArea: 'accountDD', margin: '20px 0px 0px 0px' }}>
                            <FormControl required>
                                <Autocomplete
                                    options={reqBy}
                                    id="reqBy"
                                    value={reqBy.length === 1 ? reqBy[0] : individualModal.selectedReqBy || null}
                                    sx={{ width: "504px", cursor: 'pointer', fontFamily: 'Interstate' }}
                                    getOptionLabel={(option: ReqBy) => option.Name}
                                    renderInput={(params) => <TextField {...params} required placeholder="Select Account" />}
                                    onChange={(e, newValue) => {
                                        if (newValue) {
                                            setIndividualModal({ ...individualModal, selectedReqBy: newValue });
                                            handleAccountChange(newValue);
                                        }
                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.CustomerId} style={{ fontFamily: 'InterstateLight', fontSize: '10px' }} >
                                                {option.Name}
                                            </li>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ gridArea: 'orderDD', margin: '0px 0px' }}>
                            <FormControl required>
                                <Autocomplete
                                    options={orderTypeOptions}
                                    id="orderType"
                                    value={individualModal.selectedOrderType || null}
                                    sx={{ width: "504px", cursor: 'pointer', fontFamily: 'Interstate' }}
                                    isOptionEqualToValue={(option, value) => option.Id === value.Id}
                                    getOptionLabel={(option) => option.Name}
                                    renderInput={(params) => <TextField {...params} required placeholder="Select Order Type" />}
                                    onChange={(e, newValue) => {
                                        if (newValue) {
                                            handleOrderTypeChange(newValue);
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </OrderSelectionGrid>
                            <Divider sx={{border:'1px solid #ED7300'}} />
                        </div>
                    )}
                    {currentStep === 1 && (
                        <RenewalOrder handleNext={handleNext} handleMultipleOrder={handleMultipleOrder} formData={formData} reqBy={reqBy} />
                    )}
                    {currentStep === 2 && (
                        <IndividualReg handleGoBack={handleGoBack} handleNext={handleNext} formData={formData} editing={editing} />
                    )}
                    {currentStep === 3 && (
                        <IndividualVehicle handleGoBack={handleGoBack} handleNext={handleNext} formData={formData} editing={editing} />
                    )}
                    {currentStep === 4 && (
                        <IndividualShipping handleGoBack={handleGoBack} handleNext={handleNext} formData={formData} editing={editing} />
                    )}
                    {currentStep === 5 && (
                        <IndividualConfirmation handleGoBack={handleGoBack} handleNext={handleNext} formData={formData} handleEditReg={handleEditReg} handleEditVehicle={handleEditVehicle} handleEditShip={handleEditShip} handleNewOrder={handleNewOrder} />
                    )}
                    {currentStep === 6 && (
                        <MultipleUpload handleGoBack={handleGoBack} formData={formData} handleNewOrder={handleNewOrder} />
                    )}
                    <Dialog open={showResetDialog}>
                        <Zoom in={showResetDialog}>
                            <div style={{ height: '150px', width: '600px', display: 'grid', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}>
                                <Grid container style={{ height: '100%', justifyContent: 'center' }}>
                                    <ResetGrid>
                                        <Grid sx={{ gridArea: 'question', marginBottom: '20px' }}>
                                            <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}>Are you sure you want to start over?</Typography>
                                        </Grid>
                                        <Grid sx={{ gridArea: 'noButton' }}>
                                            <WhiteGoBackButton onClick={handleResetNo} variant="text">No</WhiteGoBackButton>
                                        </Grid>
                                        <Grid sx={{ gridArea: 'yesButton' }}>
                                            <SubmitButton onClick={handleNewOrder}>Yes</SubmitButton>
                                        </Grid>
                                    </ResetGrid>
                                </Grid>
                            </div>
                        </Zoom>
                    </Dialog>
                    <Dialog open={showCloseDialog}>
                        <Zoom in={showCloseDialog}>
                            <div style={{ height: '227px', width: '600px', display: 'grid', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}>
                                <Grid container style={{ height: '100%', justifyContent: 'center', justifyItems:'center' }}>
                                    <ResetGrid>
                                        <Grid sx={{ gridArea: 'question', marginBottom: '20px', display: 'grid', justifyContent: 'center' }}>
                                            <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}>Are you sure you want to close?</Typography>
                                            <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 20px 20px' }}>Your progress will be reset.</Typography>
                                        </Grid>
                                        <Grid sx={{ gridArea: 'noButton' }}>
                                            <WhiteGoBackButton onClick={handleResetNo} variant="text">No</WhiteGoBackButton>
                                        </Grid>
                                        <Grid sx={{ gridArea: 'yesButton' }}>
                                            <SubmitButton onClick={handleClose}>Yes</SubmitButton>
                                        </Grid>
                                    </ResetGrid>
                                </Grid>
                            </div>
                        </Zoom>
                    </Dialog>
                </Grid>
            </div>
        </NoSsr>
    );
}
