export class DateUtil {
    static convertJsonDateToShortDate(jsonDate: Date | null): string {
        let result: string = ""

        if (jsonDate != null) {
            let parsedDate = DateUtil.parseJsonDate(jsonDate.toString())
            result = parsedDate.toLocaleDateString('en-us')
        }

        return result;
    }

    static parseJsonDate(jsonDateString: string): Date {
        return new Date(parseInt(jsonDateString.replace('/Date(', '')));
    }
}