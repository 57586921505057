import React, { useState, useEffect, useRef } from 'react';
import { Tab, Typography, NoSsr, styled, IconButton, Tooltip, Button, Box, FormControl, Autocomplete, createFilterOptions, TextField, Grid, Divider, Dialog,Zoom, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SubmitButton, FormTextField, GoBackButton, FieldLabel, RenewalTitle, RegTitleGrid, RenewalStateGrid, RegGrid, Numbers, ExtraRegGrid } from '../../Shared/Renewals';
import { State } from '../../Types/common';
interface Destination extends Object {
    Name: string;
    Id: number;
}
interface GetDropDowns {
    states: State[];
    destination: Destination[];
}
export interface RegFields {
    defaultDestination: Destination | null;
    cityDestination: Destination | null;
    countyDestination: Destination | null;
    Name: string | null;
    Address: string | null;
    City: string | null;
    Zip: string | null;
    selectedRegState: State | null;
    selectedRenewalState: State | null;
}
export interface IndividualModal {
    selectedState: State | null;
    selectedRenewalState: State | null;
}

export const getStates = (): Promise<State[]> => {
    const requestOptions = {
        method: "GET",
    };

    const url = window.location.origin + "/CreateOrders/GetStates";

    return fetch(url, requestOptions)
        .then((res) => res.json())
        .then((result: GetDropDowns) => result.states)
        .catch((error) => {
            console.error("error get states dropdown");
            console.error("error:", error);
            return [];
        });
};
export const IndividualReg = ({ handleGoBack, handleNext, formData, editing }) => {
    
    const [showRegSection, setShowRegSection] = React.useState<boolean>(false);
    const [showVerifyDialog, setShowVerifyDialog] = React.useState<boolean>(false);
    const [states, setStates] = useState<State[]>([]);
    const [destination, setDestination] = useState<Destination[]>([]);
    const [individualModal, setIndividualModal] = useState<IndividualModal>({
        selectedRenewalState: formData?.reg?.individualModal.selectedRenewalState ?? states[1], 
        selectedState: formData?.reg?.individualModal.selectedState ?? states[1], 
    });
    const [regName, setRegName] = useState(formData?.reg?.regName ?? null);
    const [regAddress1, setRegAddress1] = useState(formData?.reg?.regAddress1 ?? null);
    const [regAddress2, setRegAddress2] = useState(formData?.reg?.regAddress2 ?? null);
    const [regCity, setRegCity] = useState(formData?.reg?.regCity ?? null);
    const [plate, setPlate] = useState(formData?.reg?.plate ?? null);
    const [regZip, setRegZip] = useState(formData?.reg?.regZip ?? null);
    const [regCounty, setRegCounty] = useState(formData?.reg?.regCounty ?? null);
    const [fleet, setFleet] = useState(formData?.reg?.fleet ?? null);
    const [unit, setUnit] = useState(formData?.reg?.unit ?? null);
    const [poNumber, setPoNumber] = useState(formData?.reg?.poNumber ?? null);
    const [plateExp, setPlateExp] = useState(formData?.reg?.plateExp ?? null);
    const [disableButton, setDisableButton] = React.useState<boolean>(true);
    const [addressError, setAddressError] = React.useState<boolean>(false);
    
    const handleRenewalStateSelection = async (newValue) => {
        await getReqInfo(newValue.Id)
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option: State) => option.StateName,
        limit: 80
    });

    const handleErrorClose = async () => {
        setAddressError(false);
    }

    const handleRegNext = async () => {
        await setShowVerifyDialog(true);
        await verifyAddress();

    }

    const handleFormSubmitWithChanges = async (formData) => {
        await handleNext(formData);
        await handleModalWidth();
    };
    const handleModalWidth = async () => {
        const modalContent = document.getElementById("modalContent");
        if (modalContent) {
            modalContent.style.width = "880px";
        }
    }
    const handleFormSubmit = async (countyId) => {
        const formData = await (async () => {
            return {
                reg: { individualModal, regName, plate, plateExp, regAddress1, regAddress2, regCity, regZip, states, regCounty: countyId, fleet, unit, poNumber }
            };
        })();
        await handleNext(formData);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                await getStates().then((result) => setStates(result));
            } catch (error) {
                console.error("An error occurred during data fetching:", error);
            }
        };

        fetchData();
        return () => { };
    }, []);

    useEffect(() => {
        if (individualModal.selectedRenewalState != undefined) {
            setShowRegSection(true);
        }
    }, [individualModal.selectedRenewalState]);

    useEffect(() => {
        if (individualModal.selectedRenewalState != undefined && regName != null && regAddress1 != null && regCity != null && individualModal.selectedState != undefined && regZip != null && plate != null && plateExp != null) {
            if (regName.trim() != '' && regAddress1.trim() != '' && regCity.trim() != '' && regZip.trim() != '' && plate.trim() != '' && plateExp.trim() != '') {
                setDisableButton(false);
            }
            else {
                setDisableButton(true);
            }
        }
        else {
            setDisableButton(true);
        }
    }, [individualModal.selectedRenewalState, regName, regAddress1, regCity, individualModal.selectedState, regZip, plate, plateExp]);

    const getReqInfo = (renewalStateId): void => {
        const url = `${window.location.origin}/CreateOrders/GetReqInfo?state=${renewalStateId}`;
        const requestOptions = {
            method: "GET",
        };

        fetch(url, requestOptions)
            .then((res) => res.json())
            .then((result: RegFields) => {
                if(result.defaultDestination != undefined)
                {
                    setDestination([result.defaultDestination]);
                }  
            })
            .catch((error) => {
                console.error("error get customer info dropdown");
                console.error("error:", error);
            });
    }
    
    const verifyAddress = async () => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                address1: regAddress1,
                city: regCity,
                state: individualModal.selectedState?.StateAbbreviation,
                zipCode: regZip
            }),
        };
        const url = `${window.location.origin}/CreateOrders/VerifyAddress`;

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                console.error("Verify address response failure:", response.status, response.statusText);
                console.error("Request URL:", response.url);
                console.error("Response Headers:", response.headers);
            } else {
                const data = await response.json();
                if (data.changesMade) {
                    const formData = await (async () => {
                        return {
                            reg: {
                                individualModal: {
                                    selectedState: {
                                        StateId: data.stateId,
                                        StateName: data.stateName,
                                        StateAbbreviation: data.stateAbbreviation
                                    },
                                    selectedRenewalState: individualModal.selectedRenewalState
                                },
                                regName,
                                plate,
                                plateExp,
                                regAddress1: data.address1,
                                regAddress2,
                                regCity: data.city,
                                regZip: data.zipCode,
                                regCounty: data.county,
                                fleet,
                                unit,
                                poNumber
                            }
                        };
                    })();
                    await setShowVerifyDialog(false);
                    await handleFormSubmitWithChanges(formData);
                }
                else {
                    if (data.verifyFailed) {
                        console.error("verifyFailed");
                        setAddressError(true);
                        setShowVerifyDialog(false);
                    }
                    else {
                        await setShowVerifyDialog(false);
                        await handleFormSubmit(data.county);
                    }
                }
                await setShowVerifyDialog(false);
            } 
        } catch (error: any) {
            console.error('An error occurred during address verification tsx:', error);
            console.error('Error message:', error.message);
            console.error('Stack trace:', error.stack);
            return false;
        }
    };
    
    return (
        <NoSsr>
            <div className="container height-550">
                <div>
                    <Grid container style={{ height: '100%' }}>
                        <RegTitleGrid item xs={12}>
                            <br />
                            <Grid sx={{ gridArea: 'renewalTitle', margin:'30px 0px' }}>
                                <RenewalTitle>Renewal Information</RenewalTitle>
                            </Grid>
                            <Grid sx={{ gridArea: 'circles', margin: '30px 0px' }} >
                                <Numbers src="../Content/assets/img/icons/Orange_One.png" /><Numbers src="../Content/assets/img/icons/Grey_Two.png" /><Numbers src="../Content/assets/img/icons/Grey_Three.png" />
                            </Grid>
                        </RegTitleGrid>
                        <RenewalStateGrid item xs={12}>
                            <Grid sx={{ gridArea: 'renewalState', width: '264px', marginRight: '23px' }}>
                                <FieldLabel>Select a Renewal State</FieldLabel>
                                <FormControl required>
                                    <Autocomplete
                                        fullWidth
                                        options={states}
                                        id="renewalState"
                                        value={individualModal.selectedRenewalState || null}
                                        filterOptions={filterOptions}
                                        sx={{ width: "273px", cursor: 'pointer', fontFamily: 'InterstateLight' }}
                                        getOptionLabel={(option: State) => option.StateName}
                                        renderInput={(params) => <TextField {...params} required autoComplete="off" />}
                                        isOptionEqualToValue={(option, value) => option.StateId === value?.StateId}
                                        onChange={(e, newValue) => {
                                            if (newValue) {
                                                setIndividualModal({ ...individualModal, selectedRenewalState: newValue, selectedState: newValue });
                                                handleRenewalStateSelection(newValue);
                                            }
                                        }}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.StateId} style={{ fontFamily: 'InterstateLight', fontSize: '10px' }} >
                                                    {option.StateName}
                                                </li>
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </RenewalStateGrid>
                        {showRegSection && (
                            <div style={{display:'grid', justifyItems:'start', alignItems:'center', alignContent:'center'}}>
                                <ExtraRegGrid item xs={12}>
                                    <Grid sx={{ gridArea: 'fleet' }}>
                                        <FieldLabel>Fleet</FieldLabel>
                                        <FormControl>
                                            <FormTextField
                                                name="fleet"
                                                placeholder="1234"
                                                value={fleet || ''}
                                                fullWidth
                                                sx={{ width: '154px', marginRight: '23px' }}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue.length <= 12) { // limit to 12
                                                        setFleet(inputValue);
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid sx={{ gridArea: 'unit' }}>
                                        <FieldLabel>Unit</FieldLabel>
                                        <FormControl>
                                            <FormTextField
                                                name="unit"
                                                placeholder="ABCD"
                                                value={unit || ''}
                                                fullWidth
                                                sx={{ width: '154px', marginRight: '23px' }}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue.length <= 12) { // limit to 12
                                                        setUnit(inputValue);
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </ExtraRegGrid>
                                <RegGrid item xs={12}>
                                    <Grid sx={{ gridArea: 'regName' }}>
                                        <FieldLabel>Registrant Name*</FieldLabel>
                                        <FormControl required>
                                            <FormTextField
                                                name="regName"
                                                placeholder="First and Last Name"
                                                value={regName || ''}
                                                required
                                                fullWidth
                                                sx={{ width: '374px', marginRight: '23px' }}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue.length <= 100) { // limit name to 100 digits
                                                        setRegName(inputValue);
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid sx={{ gridArea: 'plate' }}>
                                        <FieldLabel>Plate Number*</FieldLabel>
                                        <FormControl required>
                                            <FormTextField
                                                placeholder="ABC123"
                                                name="plate"
                                                value={plate || ''}
                                                required
                                                fullWidth
                                                sx={{ width: '154px', marginRight: '23px' }}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue.length <= 40) { // limit plate to 40 digits
                                                        setPlate(inputValue);
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid sx={{ gridArea: 'plateExp' }}>
                                        <FieldLabel>Plate Expiration*</FieldLabel>
                                        <FormControl required>
                                            <FormTextField
                                                type="date"
                                                name="expiration"
                                                value={plateExp || ''}
                                                required
                                                fullWidth
                                                sx={{ width: '174px', marginRight: '23px' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    min: new Date().toISOString().split('T')[0],
                                                    placeholder: new Date().toISOString().split('T')[0],
                                                }}
                                                onChange={(e) => setPlateExp(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid sx={{ gridArea: 'regAddress' }}>
                                        <FieldLabel>Registrant Address*</FieldLabel>
                                        <FormControl required>
                                            <TextField
                                                placeholder="1000 Street Rd"
                                                name="address"
                                                value={regAddress1 || ''}
                                                required
                                                sx={{ width: '551px', marginRight: '23px' }}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue.length <= 80) { // limit addr1 to 80 digits
                                                        setRegAddress1(inputValue);
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid sx={{ gridArea: 'regAddress2' }}>
                                        <FieldLabel>Suite (if applicable)</FieldLabel>
                                        <FormControl required>
                                            <TextField
                                                placeholder="Apt 100"
                                                name="address"
                                                value={regAddress2 || ''}
                                                required
                                                sx={{ width: '174px', marginRight: '23px' }}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue.length <= 80) { // limit addr2 to 80 digits
                                                        setRegAddress2(inputValue);
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid sx={{ gridArea: 'regCity' }}>
                                        <FieldLabel>City*</FieldLabel>
                                        <FormControl required>
                                            <TextField
                                                placeholder="New Town"
                                                name="city"
                                                value={regCity || ''}
                                                required
                                                sx={{ width: '283px', marginRight: '23px' }}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue.length <= 30) { // limit city to 30 digits
                                                        setRegCity(inputValue);
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid sx={{ gridArea: 'regState' }}>
                                        <FieldLabel>State*</FieldLabel>
                                        <FormControl required>
                                            <Autocomplete
                                                options={states}
                                                id="state"
                                                value={individualModal.selectedState || null}
                                                filterOptions={filterOptions}
                                                sx={{ width: "68px", marginRight: '23px', cursor: 'pointer', fontFamily: 'InterstateLight' }}
                                                getOptionLabel={(option: State) => option.StateAbbreviation}
                                                isOptionEqualToValue={(option, value) => option.StateAbbreviation === value?.StateAbbreviation}
                                                renderInput={(params) => <TextField {...params} required />}
                                                disabled
                                                onChange={(e, newValue) => {
                                                    if (newValue) {
                                                        setIndividualModal({ ...individualModal, selectedState: newValue });
                                                    }
                                                }}
                                                renderOption={(props, option) => {
                                                    return (
                                                        <li {...props} key={option.StateId} style={{ fontFamily: 'InterstateLight', fontSize: '10px' }} >
                                                            {option.StateAbbreviation}
                                                        </li>
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid sx={{ gridArea: 'regZip' }}>
                                        <FieldLabel>Zip*</FieldLabel>
                                        <FormControl required>
                                            <TextField
                                                placeholder="12345"
                                                name="zip"
                                                value={regZip || ''}
                                                required
                                                sx={{ width: '154px', marginRight: '23px' }}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue.length <= 5) { // limit zip to 5 digits
                                                        setRegZip(inputValue);
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid sx={{ gridArea: 'poNumber' }}>
                                        <FieldLabel>PO Number</FieldLabel>
                                        <FormControl>
                                            <FormTextField
                                                name="poNumber"
                                                placeholder="1234567"
                                                value={poNumber || ''}
                                                fullWidth
                                                sx={{ width: '174px' }}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue.length <= 100) { // limit to 100
                                                        setPoNumber(inputValue);
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid sx={{ gridArea: 'backButton', marginTop: '30px' }}>
                                        <GoBackButton onClick={handleGoBack} variant="text"><ArrowBackIcon sx={{ color: '#ED7300' }} />Start Over</GoBackButton>
                                    </Grid>
                                    <Grid sx={{ gridArea: 'nextButton', display: 'grid', justifyContent: 'start', marginTop: '30px' }}>
                                        <SubmitButton sx={{ width: '191px' }} type="submit" disabled={disableButton} onClick={handleRegNext} >{editing ? "Save" : "Next"}</SubmitButton>
                                    </Grid>
                                </RegGrid>
                            </div>
                        )}
                    </Grid>
                    <Dialog open={showVerifyDialog}>
                        <Zoom in={showVerifyDialog}>
                            <div style={{ height: '150px', width: '400px', display:'flex', alignItems:'center', justifyContent:'center' }}>
                                <CircularProgress sx={{color:'#ED7300'}} /><Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', marginLeft:'20px' }}>Verifying Address</Typography>
                            </div>
                        </Zoom>
                    </Dialog>
                    <Dialog open={addressError}>
                        <Zoom in={addressError}>
                            <div style={{ height: '150px', width: '700px', display: 'grid', alignItems: 'center', justifyContent: 'center', justifyItems: 'right' }}>
                                <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate', margin: '20px 0px 0px 20px' }}>Unable to verify address. Please check spelling and try again.</Typography>
                                <SubmitButton onClick={handleErrorClose}>OK</SubmitButton>
                            </div>
                        </Zoom>
                    </Dialog>
                </div>
            </div>
        </NoSsr>
    );
}
