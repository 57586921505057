import React  from 'react';
import { Card, styled, CardContent } from '@mui/material';

const GreyContainer = styled(Card)({
    backgroundColor: '#EFF0F1',
    padding: '15px 30px 15px 30px',
    borderRadius: '20px',
    boxShadow: 'none',
});

const GreyCard= ({ children }) => {
    return (
        <GreyContainer>
            <CardContent>
            {children}
            </CardContent>
        </GreyContainer>
    );
};

export default GreyCard;