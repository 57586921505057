import React, { useEffect, useState } from "react";
import { ITransactionState } from "../Types/common";

export interface PizzaTrackerStyling extends Object {
    completedStyling: string | "domino-inactive";
    sentToStateStyling: string | "domino-inactive";
    resolvedStyling: string | "domino-inactive";
    receivedStyling: string | "domino-active";
};

export interface PizzaTrackerProps {
    TransactionState: ITransactionState;
    eta: string;
};

export interface PizzaTrackerState extends Object {
    Style: PizzaTrackerStyling;
}

export const PizzaTracker: React.FC<PizzaTrackerProps> = ({ TransactionState = {} as ITransactionState, eta = "" }) => {
    PizzaTracker.displayName = "PizzaTracker"
    const complete = "domino-completed";
    const active = "domino-active";
    const inactive = "domino-inactive";

    const [state, setState] = useState<PizzaTrackerState>({ Style: { receivedStyling: complete, resolvedStyling: inactive, sentToStateStyling: inactive, completedStyling: inactive } } as PizzaTrackerState)

    useEffect(() => {
        if (TransactionState != undefined) {
            setState({
                Style: getStyling(TransactionState)
            })
        }
    }, [TransactionState])

    const displayCompleted = () => {
        if (TransactionState.isCancelled) {
            return ( <p>Cancelled</p>)
        }
        else if (TransactionState.isCompleted) {
            return ( <p>Completed</p> )
        }
        else {
            if (eta != "") {
                return (<p>ETA: {eta}</p>)
            }
            else {
                return (<p>ETA: TBD</p>)
            }
        }
            
    }
    const displayReceivedStatus = () => {
        if (TransactionState.isWaitingForPaperwork) {
            return <p>Waiting for Paperwork</p>;
        }
        return <p>Received</p>;
    };
    const getStyling = (state: ITransactionState): PizzaTrackerStyling => {
        let result = {} as PizzaTrackerStyling;
            result = {
                receivedStyling: active,
                resolvedStyling: inactive,
                sentToStateStyling: inactive,
                completedStyling: inactive,
            }

        if (state.isResolved) {
            result = {
                receivedStyling: complete,
                resolvedStyling: active,
                sentToStateStyling: inactive,
                completedStyling: inactive,
            }
        }

        if (state.isSentToState) {
            result = {
                receivedStyling: complete,
                resolvedStyling: complete,
                sentToStateStyling: active,
                completedStyling: inactive,
            }
        }

        if (state.isCompleted) {
            result = {
                receivedStyling: complete,
                resolvedStyling: complete,
                sentToStateStyling: complete,
                completedStyling: active,
            }
        }

        return result;
    }
        return (
            <div className="row d-flex flex-row">
                <div className="d-flex flex-column justify-content-center align-items-center domino-container " style={{ marginRight: 5 }}>
                    <div className={"domino domino-left " + state.Style.receivedStyling}></div>
                    {displayReceivedStatus()}
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center domino-container" style={{ marginRight: 5}}>
                    <div className={"domino " + state.Style.resolvedStyling}></div>
                   <p>Resolved</p>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center domino-container" style={{marginRight: 5}}>
                    <div className={"domino " + state.Style.sentToStateStyling}></div>
                    <p>Sent to State</p>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center domino-container">
                    <div className={"domino domino-right " + state.Style.completedStyling}></div>
                    { displayCompleted() }
                </div>
            </div>
        )
}