import TextEncodingPolyfill from "text-encoding";
import React from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";

//Import all the dynamically generated global state from all pages.
import './index';

//Needed since Microsoft.V8 engine is missing these functions.
globalThis.TextEncoder = TextEncodingPolyfill.TextEncoder;
globalThis.TextDecoder = TextEncodingPolyfill.TextDecoder;
//
globalThis.React = React;
globalThis.ReactDOM = ReactDOM;
globalThis.ReactDOMServer = ReactDOMServer;
declare global {
    interface Window {
        resetCurrentStep?: () => void;
        closeCreateOrder?: () => void;
        startCreateOrder?: () => void;
    }
}