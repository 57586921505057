import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Box, FormControlLabel, Checkbox } from "@mui/material";
import AutocompleteAcertus, { AutocompleteAcertusItem } from "../Components/AutocompleteAcertus";
import CancelIcon from '@mui/icons-material/Cancel';
import { Title } from "../Themes/OneAcertus";
import OrangeHeaderButton from "../Components/OrangeHeaderButton";
import WhiteEndIconButton from "../Components/WhiteEndIconButton";
import SaveIcon from '@mui/icons-material/Save';
import WhiteHeaderButton from "../Components/WhiteHeaderButton";

interface PendingOrdersHeaderProps {
    selections: { label: string, choice: string | boolean, id?: number }[];
    setSelections: React.Dispatch<React.SetStateAction<{ label: string, choice: string | boolean, id?: number }[]>>;
    loading: boolean;
    useDefaultCustomer: boolean;
    showApplyButton: boolean;
    setShowApplyButton: React.Dispatch<React.SetStateAction<boolean>>;
    handleApplyFilters: () => void;
    handleClearAll: () => void;
    setDefaultCustomer: React.Dispatch<React.SetStateAction<AutocompleteAcertusItem | null>>;
    defaultCustomer: AutocompleteAcertusItem | null;
    setPendingOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    setShowClearAll: React.Dispatch<React.SetStateAction<boolean>>;
    showClearAll: boolean;
}

const PendingOrdersHeader: React.FC<PendingOrdersHeaderProps> = ({ selections, setSelections, loading, useDefaultCustomer, showApplyButton, setShowApplyButton, handleClearAll, handleApplyFilters, setDefaultCustomer, defaultCustomer, setPendingOpen, setShowClearAll, showClearAll }) => {
    const [customers, setCustomers] = useState<AutocompleteAcertusItem[]>([]);
    const [workTypes, setWorkTypes] = useState<AutocompleteAcertusItem[]>([]);
    const [users, setUsers] = useState<AutocompleteAcertusItem[]>([]);   
    

    useEffect(() => {
        const operations = [
            { method: "/GetCustomersDropDownAsync", setter: setCustomers },
            { method: "/GetWorkTypesDropDownAsync", setter: setWorkTypes },
        ];

        operations.forEach(operation => {
            updateDataAsync(operation.method, operation.setter);
        });
    }, []);

    useEffect(() => {
        const customerSelections = selections
            .filter(selection => selection.label === 'Customer' && selection.id != null)
            .map(selection => selection.id as number);

        if (customerSelections.length > 0) {
            updateDataAsync("/GetUsersDropDownAsync", setUsers, customerSelections);
        } else {
            setUsers([]);
        }

        const filteredSelections = selections.filter(
            (selection) => selection.label !== "Page" && selection.label !== "Page Size"
        );
        setShowClearAll(filteredSelections.length > 0);
    }, [selections]);

    const handleChange = (label: string, value: AutocompleteAcertusItem[] | undefined) => {
        if (value) {
            value.forEach(item => {
                if (!selections.some(selection => selection.label === label && selection.id === item.id)) {
                    setSelections(prevSelections => [
                        ...prevSelections,
                        { label: label, choice: item.label, id: item.id }
                    ]);
                }
            });
        }
    };

    const handleRemove = (label: string, choice: string | boolean) => {
        setSelections(prevSelections => {
            return prevSelections.filter(selection => {
                return !(selection.label === label && selection.choice === choice);
            });
        });
    };

    const isCategorySelected = (label: string) => {
        return selections.some(selection => selection.label === label);
    };

    const fetchDropDownDataAsync = async (requestMethod: string, param: number[] | null = null) => {
        let requestOptions: RequestInit | undefined;
        let url = window.location.origin + window.location.pathname + requestMethod;

        if (requestMethod === '/GetUsersDropDownAsync' && param !== null) {
            requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(param),
            };
        } else {
            requestOptions = {
                method: "GET",
            };
        }

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                console.error("Bad Response dropdown pending:", {
                    status: response.status,
                    statusText: response.statusText,
                    url: response.url,
                    headers: Array.from(response.headers.entries()),
                });
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Failed to fetch dropdown pending", error);
            return [];
        }
    };

    const updateDataAsync = async (requestMethod: string, setter: React.Dispatch<React.SetStateAction<AutocompleteAcertusItem[]>>, param: number[] | null = null) => {
        const rawData = await fetchDropDownDataAsync(requestMethod, param);
        const transformedData = rawData.map((item: { Id: number, Label: string }) => ({
            id: item.Id,
            label: item.Label
        }));
        setter(transformedData);

        sessionStorage.setItem(requestMethod, JSON.stringify(transformedData));
        
        const getDefaultCustomer = async () => {

            if (requestMethod === "/GetCustomersDropDownAsync" && rawData.length > 0) {
                const defaultCustomer = rawData[0];
                setDefaultCustomer({ id: defaultCustomer.Id, label: defaultCustomer.Label });
            }
        }
        
        await getDefaultCustomer();
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: "wrap", justifyContent: 'flex-start', alignItems: 'flex-start', minHeight: "220px", backgroundColor: "#F5F5F5", padding: "30px", marginTop: "10px", borderRadius: "20px", gap: "30px" }}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "40px", width: "100%", }}>
                <Box sx={{ display: "inline-flex", alignItems: "center", gap: "30px", flexWrap: 'wrap' }}>
                    <Title>Pending Orders</Title>
                </Box>
                {showClearAll && (
                    <WhiteEndIconButton
                        label="Save"
                        onClick={() => setPendingOpen?.(true)}
                        variant="contained"
                        disabled={loading}
                        endIcon={<SaveIcon sx={{ color: '#ED7300' }} />}
                        sx={{ width: "79px", border: '1px solid #c4c4c4', height: '36px', fontSize: '12px', color: '#6D767F', fontFamily: '"Noto Sans", Arial, sans-serif' }}
                    />
                )} 
            </Box>
            <Box sx={{ display: "inline-flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start", alignItems: "center", minHeight: "40px", gap: "12px" }}>
                <AutocompleteAcertus
                    items={customers}
                    value={undefined}
                    onChange={(value) => handleChange("Customer", value)}
                    label="Customer"
                    disabled={customers.length === 0 || loading}
                />
                <AutocompleteAcertus
                    items={workTypes}
                    value={undefined}
                    onChange={(value) => handleChange("Task", value)}
                    label="Task"
                    disabled={workTypes.length === 0 || loading}
                />
                {isCategorySelected("Customer") && (
                    <AutocompleteAcertus
                        items={users}
                        value={undefined}
                        onChange={(value) => handleChange("Users", value)}
                        label="Users"
                        disabled={users.length === 0 || loading}
                    />
                )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", height: "40px", width: "100%", }}>
                <Box sx={{ display: "inline-flex", alignItems: "center", gap: "10px", flexWrap: 'wrap', marginRight: '10px' }}>
                    {selections
                        .filter(selection => selection.label !== "Search" && selection.label !== "Page" && selection.label !== "Page Size")
                        .map((selection, index) => (
                            <FormControlLabel
                                key={index}
                                labelPlacement="start"
                                disabled={loading}
                                control={
                                    <Checkbox
                                        icon={<CancelIcon sx={{ color: "#FFFFFF" }} />}
                                        checkedIcon={<CancelIcon sx={{ color: "#FFFFFF" }} />}
                                        checked={true}
                                        onChange={() => handleRemove(selection.label, selection.choice)}
                                    />}
                                label={`${selection.label}: ${selection.choice}`}
                                sx={{
                                    height: "36px", color: "#FFFFFF", backgroundColor: "#6D767F", borderRadius: "5px", padding: "10px 20px 10px 20px", margin: "0px",
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "12px",
                                    },
                                }}
                            />
                        ))
                        }
                
                    {showApplyButton && (
                        <OrangeHeaderButton
                            label="Apply Changes"
                            onClick={handleApplyFilters}
                            variant="contained"
                            disabled={null}
                            sx={{}}
                        />
                        )}
                </Box>
                {showClearAll && (
                    <WhiteHeaderButton label="Clear All" onClick={handleClearAll} variant="contained" disabled={loading} sx={{}} />
                )}
            </Box>
        </Box>
    );
};

export default PendingOrdersHeader;
