import { styled,Typography } from "@mui/material";
import { Lookup, NameId, ReqAddress } from "../Types/common";

export interface ReqValueOverride extends Object {
    RequirementId: number;
    RequirementValue: string;
}
export interface Transaction extends Object {
    AcertusOrderId: string;
    ClientUnit: string | null;
    Contact: NameId[];
    CreatedBy: string;
    Customer: NameId[];
    Fees: Fee[];
    Forms: Form[];
    GaragedAddress: ReqAddress[],
    LicencePlate: string | null;
    Procedures: Procedure[];
    RegistrationType: NameId[];
    Requirements: Requirement[];
    SourceChannelId: number;
    State: NameId[];
    StyleCode: number | null;
    TransactionId: number | null;
    TypeElement: NameId[];
    VehicleMake: string;
    VehicleModel: string;
    VehicleYear: string;
    Vin: string;
    WorkTypeId: number;
    IntegrityHash: string | null;
}
export interface Requirement extends Object {
    Name: string;
    RequirementId: number;
    FieldTypeId: number;
    HasLookupValue: boolean;
    LookupValues: Lookup[];
    LookupIdValue: number;
    IsDocument: boolean;
    IsExternal: boolean;
    ExternalDescription: string | null;
    Address: ReqAddress[];
    Value: string | null;
}
export interface AskIfQuestions extends Object {
    Answer: string | null;
    AskIfId: number;
    AskIfParentId: number;
    ParentAnswerToDisplay: boolean;
    Question: string;
}
export interface AskIfAnswers extends Object {
    Id: number;
    Answer: boolean | null;
}
export interface Fee extends Object {
    FeeId: number;
    Description: string;
    Cost: number;
    Amount: number;
    Credit: boolean;
    ZeroCost: boolean;
    CategoryId: number;
}
export interface Form extends Object {
    FormId: number;
    Description: string;
    Filename: string;
    NumberOfCopies: number | null;
    WarnPrior: boolean;
    DefaultDestination: string;
}
export interface Procedure extends Object {
    ProcedureId: number;
    Description: string;
    TagResult: string;
    TitleResult: string;
}

export const OrderFieldTitle = styled(Typography)({
    color: 'black',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    fontFamily: 'InterstateLight',
});