import React, { useState, useEffect, useRef } from "react";
import { ThemeProvider } from '@mui/material/styles';
import OrdersHeader from "./OrdersHeader";
import OrdersBody from "./OrdersBody";
import ReportsAcertus from "../Components/Reports/ReportsAcertus";
import FiltersAcertus from "../Components/Filters/FiltersAcertus";
import { NoSsr, Box } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { AutocompleteAcertusItem } from "../Components/AutocompleteAcertus";
import GetFilter from "../Components/GetFilter";
import { theme } from '../Types/common';

export const OrdersPage: React.FC = () => {
    const [rows, setRows] = useState<[]>([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [inProcessCount, setInProcessCount] = useState<number>(0);
    const [pendingCount, setPendingCount] = useState<number>(0);
    const [waitingForPaperworkCount, setWaitingForPaperworkCount] = useState<number>(0);
    const [completeCount, setCompleteCount] = useState<number>(0);
    const [cancelledCount, setCancelledCount] = useState<number>(0);
    const [totalCount, setTotalCount] = useState(0);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [balancesOnlyChecked, setBalancesOnlyChecked] = useState<boolean>(false);
    const [lastStoredSearch, setLastStoredSearch] = useState<boolean>(false);
    const [useStoredFilter, setUseStoredFilter] = useState<boolean>(false);
    const [useDefaultCustomer, setUseDefaultCustomer] = useState<boolean>(false);
    const [showApplyButton, setShowApplyButton] = useState<boolean>(false);
    const [showClearAll, setShowClearAll] = useState<boolean>(false);
    const [defaultCustomer, setDefaultCustomer] = useState<AutocompleteAcertusItem | null>(null); 
    const [selections, setSelections] = useState<{ label: string, choice: string | boolean, id?: number }[]>([
        { label: 'Start Date', choice: dayjs().subtract(6, 'month').format('MM/DD/YYYY') },
        { label: 'End Date', choice: dayjs().format('MM/DD/YYYY') },
        { label: 'Page', choice: String(page) },
        { label: 'Page Size', choice: String(pageSize) },
    ]);
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const lastSelectionsRef = useRef(selections);
    const isFirstRender = useRef(true);
    const [searchTerm, setSearchTerm] = React.useState("");
    const abortControllerRef = React.useRef<AbortController | null>(null);

    useEffect(() => {
        const fetchStoredFilter = async () => {
            try {
                return await GetFilter(false);
            } catch (error) {
                console.error("Error fetching stored filter:", error);
                return null;
            }
        };

        const fetchStored = async (savedFilter) => {
            try {
                const getSearchSelections = sessionStorage.getItem('selections');

                if (getSearchSelections && getSearchSelections.length > 0) {
                    setLastStoredSearch(true);
                    await getLastStoredSearch();
                } else if (savedFilter && savedFilter.length > 0) {
                    setUseStoredFilter(true);
                    await handleFilterSelections(savedFilter);
                }
                else {
                    setUseDefaultCustomer(true);
                }

            } catch (error) {
                console.error("Error fetching stored -", error);
                setLoading(false);
            }

        }

        const execute = async () => {
            try {
                const savedFilter = await fetchStoredFilter();
                await fetchStored(savedFilter);
            } catch (error) {
                console.error("Error orders execute -", error);
                setLoading(false);
            }
        };

        execute();

    }, []);

    useEffect(() => {
        const filteredSelections = selections.filter(
            item => item.label !== 'Start Date' && item.label !== 'End Date' && item.label !== 'Page' && item.label !== 'Page Size'
        );

        const hasSelectionsChanged = JSON.stringify(lastSelectionsRef.current) !== JSON.stringify(filteredSelections);

        if (!filteredSelections.some(selection => selection.label === "Balances Only")) {
            setBalancesOnlyChecked(false);
        }

        if (hasSelectionsChanged && filteredSelections.length > 0) {
            if (isFirstRender.current) {
                if (lastStoredSearch) {
                    setLastStoredSearch(false);
                }
                else if (useStoredFilter) {
                    setUseStoredFilter(false);
                    handleApplyFilters();
                }
                else if (useDefaultCustomer) {
                    setUseDefaultCustomer(false);
                    handleApplyFilters();
                }

                isFirstRender.current = false;
                return;
            }

            setShowApplyButton(true);

            lastSelectionsRef.current = filteredSelections;
            setLastStoredSearch(false);
        }
    }, [selections]);

    useEffect(() => {
        if (useDefaultCustomer) {
            if (defaultCustomer) {
                const customer = selections.findIndex(selection => selection.label === "Customer");
                if (customer !== -1) {
                    setSelections(prevSelections => {
                        const newSelections = [...prevSelections];
                        newSelections[customer] = { ...newSelections[customer], choice: defaultCustomer.label, id: defaultCustomer.id };
                        return newSelections;
                    });
                } else {
                    setSelections(prevSelections => [...prevSelections, { label: "Customer", choice: defaultCustomer.label, id: defaultCustomer.id }]);
                }
            }
        }
    }, [defaultCustomer]);

    const handleApplyFilters = async () => {
        setShowApplyButton(false);
        await sendSelectionsToBackend();
    };

    const changeBalancesOnlyChecked = () => {
        const newCheckedValue = !balancesOnlyChecked;
        setBalancesOnlyChecked(newCheckedValue);

        setSelections(prevSelections => {
            const exists = prevSelections.some(selection => selection.label === 'Balances Only');
            if (!exists) {
                return [...prevSelections, { label: 'Balances Only', choice: newCheckedValue }];
            } else {
                return prevSelections.filter(selection => selection.label !== 'Balances Only');
            }
        });
    };

    const sendSelectionsToBackend = async () => {

        abortControllerRef.current = new AbortController(); 

        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(selections),
            signal: abortControllerRef.current.signal,
        };
        
        setShowApplyButton(false);
        setLoading(true);
        setLastStoredSearch(false);

        try {
            const response = await fetch('/Orders/ReceiveSelectionsAsync', requestOptions);

            if (!response.ok) {
                console.error("Response not OK:", response.status, response.statusText);
                throw new Error("Failed to send selections");
            }
            
            const responseData = await response.json();
            const data = responseData.searchResults.Data.searchResults.Results;
            setTotalCount(responseData.searchResults.Data.searchResults.Total);
            setInProcessCount(responseData.searchResults.Data.searchResults.InProcessCount);
            setPendingCount(responseData.searchResults.Data.searchResults.PendingCount);
            setCompleteCount(responseData.searchResults.Data.searchResults.CompleteCount);
            setCancelledCount(responseData.searchResults.Data.searchResults.CancelledCount);
            setWaitingForPaperworkCount(responseData.searchResults.Data.searchResults.WaitingCount);

            sessionStorage.setItem('totalCount', String(responseData.searchResults.Data.searchResults.Total));
            sessionStorage.setItem('inProcessCount', String(responseData.searchResults.Data.searchResults.InProcessCount));
            sessionStorage.setItem('pendingCount', String(responseData.searchResults.Data.searchResults.PendingCount));
            sessionStorage.setItem('completeCount', String(responseData.searchResults.Data.searchResults.CompleteCount));
            sessionStorage.setItem('cancelledCount', String(responseData.searchResults.Data.searchResults.CancelledCount));
            sessionStorage.setItem('waitingForPaperworkCount', String(responseData.searchResults.Data.searchResults.WaitingCount));
            
            const formatDate = (backendDate: string | null | undefined): Date | null => {
                if (!backendDate) {
                    return null;
                }
                const timestampMatch = backendDate.match(/\d+/);
                if (!timestampMatch) {
                    return null;
                }
                const timestamp = parseInt(timestampMatch[0], 10);
                const date = new Date(timestamp);
                return date;
            };

            const dataWithIdsAndFormattedDate = data.map((row, index) => ({
                ...row,
                id: row.InvoiceNumber || index,
                ETA: formatDate(row.ETA),
                Date: formatDate(row.Date),
            }));
            setRows(dataWithIdsAndFormattedDate);
            sessionStorage.setItem('rows', JSON.stringify(dataWithIdsAndFormattedDate));
            sessionStorage.setItem('selections', JSON.stringify(selections));
            sessionStorage.setItem('pageSize', pageSize.toString())
        } catch (error: unknown) {
            if (error instanceof Error && error.name === 'AbortError') {
                console.log('Request was aborted');
            } else {
                console.error("Failed to send selections", error);
            }
            setLoading(false);
        }
        finally {
            setLoading(false);
            setShowApplyButton(false);
        }
    };
    const handleCancelLoading = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        setLoading(false);
    };
    const getLastStoredSearch = async () => {
        const getSearchSelections = sessionStorage.getItem('selections');
        const getSearchRows = sessionStorage.getItem('rows');
        const parsedSelections = getSearchSelections ? JSON.parse(getSearchSelections) : [];
        const parsedRows = getSearchRows ? JSON.parse(getSearchRows) : [];
        const hasBalancesOnly = parsedSelections.some(selection => selection.label === "Balances Only");
        const storedPageSize = parsedSelections.find(selection => selection.label === "Page Size")?.choice ?? pageSize;
        const storedPage = parsedSelections.find(selection => selection.label === "Page")?.choice ?? page;
        const storedStartDate = parsedSelections.find(selection => selection.label === "Start Date")?.choice ?? null;
        const storedEndDate = parsedSelections.find(selection => selection.label === "End Date")?.choice ?? null;

        setSelections(parsedSelections);
        setRows(parsedRows);
        setPageSize(parseInt(storedPageSize));
        setPage(parseInt(storedPage));
        setStartDate(storedStartDate ? dayjs(storedStartDate, "MM/DD/YYYY") : null);
        setEndDate(storedEndDate ? dayjs(storedEndDate, "MM/DD/YYYY") : null);
        updateNumericState('inProcessCount', setInProcessCount);
        updateNumericState('pendingCount', setPendingCount);
        updateNumericState('waitingForPaperworkCount', setWaitingForPaperworkCount);
        updateNumericState('completeCount', setCompleteCount);
        updateNumericState('cancelledCount', setCancelledCount);
        updateNumericState('totalCount', setTotalCount);
        if (hasBalancesOnly) {
            setBalancesOnlyChecked(true);
        }
        setLoading(false);
    }

    const updateNumericState = (key: string, setState: React.Dispatch<React.SetStateAction<number>>) => {
        const value = sessionStorage.getItem(key);
        if (value) {
            const parsedValue = parseInt(value, 10);
            if (!isNaN(parsedValue)) {
                setState(parsedValue);
            } else {
                console.error(`Invalid number format for ${key} in sessionStorage`);
                setState(0);
            }
        }
        else {
            setState(0);
        }
    };

    const handleFilterSelections = async (filter) => {
        try {
            const filterSelections = JSON.parse(filter) as Array<{
                Label: string;
                Choice: string | boolean;
                Id?: number;
            }>;

            if (filterSelections.length > 0) {

                const newSelections: { label: string, choice: string | boolean, id?: number }[] = [];

                filterSelections.forEach(selection => {
                    const { Label: label, Choice: choice, Id: id } = selection;
                    if (label === "Start Date" && typeof choice === "string") {
                        const parsedStartDate = dayjs(choice, "MM/DD/YYYY");
                        if (parsedStartDate.isValid()) {
                            setStartDate(parsedStartDate);
                            newSelections.push({ label, choice, id });
                        } else {
                            console.error(`Invalid Start Date format: ${choice}`);
                        }
                    } else if (label === "End Date" && typeof choice === "string") {
                        const parsedEndDate = dayjs(choice, "MM/DD/YYYY");
                        if (parsedEndDate.isValid()) {
                            setEndDate(parsedEndDate);
                            newSelections.push({ label, choice, id });
                        } else {
                            console.error(`Invalid End Date format: ${choice}`);
                        }
                    } else {
                        newSelections.push({ label, choice, id });
                    }
                });
                
                setSelections(newSelections);

            } else {
                console.error("No filter selections to process.");
            }
        } catch (error) {
            console.error("Error parsing filterSelections:", error);
        }
    };

    const handleClearAll = async () => {
        const updatedSelections = selections.filter(
            (selection) => selection.label === "Page" || selection.label === "Page Size"
        );

        setSelections(updatedSelections);
        lastSelectionsRef.current = updatedSelections;
        setShowApplyButton(false);
        setShowClearAll(false);
        setRows([]);
        setTotalCount(0);
        setInProcessCount(0);
        setPendingCount(0);
        setWaitingForPaperworkCount(0);
        setCompleteCount(0);
        setCancelledCount(0);
        setStartDate(null);
        setEndDate(null);
        setSearchTerm("");
    };

    return (
        <ThemeProvider theme={theme}>
            <NoSsr>
                <OrdersHeader selections={selections} setSelections={setSelections} loading={loading} handleApplyFilters={handleApplyFilters}
                    changeBalancesOnlyChecked={changeBalancesOnlyChecked} balancesOnlyChecked={balancesOnlyChecked} setDefaultCustomer={setDefaultCustomer}
                    defaultCustomer={defaultCustomer} setShowApplyButton={setShowApplyButton} showApplyButton={showApplyButton}
                    setOpen={setOpen} setShowClearAll={setShowClearAll} showClearAll={showClearAll} handleClearAll={handleClearAll} setStartDate={setStartDate}
                    startDate={startDate} setEndDate={setEndDate} endDate={endDate} setSearchTerm={setSearchTerm} />
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', minHeight: "620px", marginTop: "10px", gap: "20px" }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: "nowrap", justifyContent: 'flex-start', alignItems: 'flex-start', minHeight: "620px", width: "80vw" }}>
                        <OrdersBody rows={rows} loading={loading} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} totalCount={totalCount}
                            inProcessCount={inProcessCount} pendingCount={pendingCount} completeCount={completeCount} cancelledCount={cancelledCount}
                            waitingForPaperworkCount={waitingForPaperworkCount} handleApplyFilters={handleApplyFilters} setSelections={setSelections} selections={selections} handleCancelLoading={handleCancelLoading} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: "nowrap", justifyContent: 'flex-end', alignItems: 'flex-start', minHeight: "620px", gap:'10px' }}>
                        <ReportsAcertus setSelections={setSelections} selections={selections} setBalancesOnlyChecked={setBalancesOnlyChecked} />
                        <FiltersAcertus setSelections={setSelections} selections={selections} setBalancesOnlyChecked={setBalancesOnlyChecked} setOpen={setOpen} open={open} />
                    </Box>
                </Box>
            </NoSsr>
        </ThemeProvider>
    );
};
