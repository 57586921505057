import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const CircleCheckboxAcertus = ({ checked, onChange }) => {
    return (
        <Checkbox
            checked={checked}
            onChange={onChange}
            icon={<RadioButtonUncheckedIcon /> }
            checkedIcon={<RadioButtonCheckedIcon />}
            sx={{
                color: '#ED7300',
                '&.Mui-checked': {
                    color: '#ED7300',
                },
            }}
        />
    );
};

export default CircleCheckboxAcertus;