const SaveSearch = async (filterSelections, searchName, pending) => {
    try {
        const response = await fetch(window.location.origin + "/SavedSearch/SaveSearch", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ filterSelections, searchName, pending  })
        });

        if (!response.ok) {
            throw new Error(`Response Error: ${response.statusText}`);
        }

        const result = await response.json();
        return result; 
    } catch (error) {
        console.error("Error:", error);
        console.error("error delete saved search")
    } finally {

    }
};

export default SaveSearch;
