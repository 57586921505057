import React, { useEffect, useState } from "react";
 
export interface PizzaTrackerStyling extends Object {
    completedStyling: string | "domino-inactive";
    uploadingStyling: string | "domino-inactive";
    analyzingStyling: string | "domino-inactive";
    verifyingStyling: string | "domino-inactive";
};

export interface PizzaTrackerProps {
    uploadingSuccess: boolean;
    analyzingSuccess: boolean;
    verifyingSuccess: boolean;
}

export interface PizzaTrackerState extends Object {
    Style: PizzaTrackerStyling;
}

export const MultiplePizzaTracker: React.FC<PizzaTrackerProps> = ({ uploadingSuccess, analyzingSuccess, verifyingSuccess }) => {
    const [state, setState] = useState<PizzaTrackerStyling>({
        uploadingStyling: uploadingSuccess ? "domino-completed" : "domino-active",
        analyzingStyling: analyzingSuccess ? "domino-completed" : "domino-active",
        verifyingStyling: verifyingSuccess ? "domino-completed" : "domino-active",
        completedStyling: "domino-inactive"
    });

    useEffect(() => {
        if (uploadingSuccess && analyzingSuccess && verifyingSuccess) {
            setState({
                uploadingStyling: "domino-completed",
                analyzingStyling: "domino-completed",
                verifyingStyling: "domino-completed",
                completedStyling: "domino-active"
            });
        } else if (uploadingSuccess && analyzingSuccess) {
            setState({
                uploadingStyling: "domino-completed",
                analyzingStyling: "domino-completed",
                verifyingStyling: "domino-active",
                completedStyling: "domino-inactive"
            });
        } else if (uploadingSuccess) {
            setState({
                uploadingStyling: "domino-completed",
                analyzingStyling: "domino-active",
                verifyingStyling: "domino-inactive",
                completedStyling: "domino-inactive"
            });
        } else {
            setState({
                uploadingStyling: "domino-active",
                analyzingStyling: "domino-inactive",
                verifyingStyling: "domino-inactive",
                completedStyling: "domino-inactive"
            });
        }
    }, [uploadingSuccess, analyzingSuccess, verifyingSuccess]);

    return (
        <div className="row d-flex flex-row">
            <div className="d-flex flex-column justify-content-center align-items-center domino-container">
                <div className={"domino domino-left " + state.uploadingStyling}></div>
                {!uploadingSuccess && <p>Uploading</p>}
                {uploadingSuccess && <p>Uploaded</p>}
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center domino-container">
                <div className={"domino " + state.analyzingStyling}></div>
                {!analyzingSuccess && <p>Analyzing</p>}
                {analyzingSuccess && <p>Analyzed</p>}
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center domino-container">
                <div className={"domino " + state.verifyingStyling}></div>
                {!verifyingSuccess && <p>Verifying</p>}
                {verifyingSuccess && <p>Verified</p>}
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center domino-container">
                <div className={"domino domino-right " + state.completedStyling}></div>
                {!uploadingSuccess && !analyzingSuccess && !verifyingSuccess && <p>Completing</p>}
                {uploadingSuccess && !analyzingSuccess && !verifyingSuccess && <p>Completing</p>}
                {uploadingSuccess && analyzingSuccess && !verifyingSuccess && <p>Completing</p>}
                {uploadingSuccess && analyzingSuccess && verifyingSuccess && <p>Completed</p>}
            </div>
        </div>
    );
}