import React from "react";
import { Dialog, DialogTitle, DialogContent, Box, Typography, Button } from "@mui/material";

interface CloseConfirmationModalAcertusProps {
    open: boolean;
    onCancel: () => void;
    onQuit: () => void;
}

const CloseConfirmationModalAcertus: React.FC<CloseConfirmationModalAcertusProps> = ({ open, onCancel, onQuit }) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            PaperProps={{
                sx: {
                    width: "384px",
                    height: "212px",
                },
            }}
            sx={{
                '& .MuiDialog-container': {
                    justifyContent: 'center',
                    alignItems: 'center',
                },
            }}
        >
            <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h3" align="center" color="#333E48" sx={{ fontWeight: 400 }}>
                    Are you sure you want to quit?
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ flexDirection: "column", justifyContent: "center", gap: "16px" }}>
                <Typography variant="h5" align="center" color="#333E48" sx={{ fontWeight: 300 }}>
                    All progress in this session will be lost.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '16px' }}>
                    <Button onClick={onCancel} variant="outlined" sx={{
                        width: "150px", height: "36px", color: "#ED7300", borderColor: "#ED7300", backgroundColor: "#FFFFFF", textTransform: "none",
                        "&:hover": {
                            borderColor: "#ED7300",
                            backgroundColor: "#FFFFFF"
                        }
                    }}>
                        <Typography variant="h5">
                            Cancel
                        </Typography>
                    </Button>
                    <Button onClick={onQuit} variant="contained" sx={{
                        width: "150px", height: "36px", color: "#FFFFFF", backgroundColor: "#ED7300", textTransform: "none",
                        "&:hover": {
                            color: "#FFFFFF",
                            backgroundColor: "#ED7300"
                        }
                    }}>
                        <Typography variant="h5" sx={{ color:"#FFFFFF" }}>
                            Quit
                        </Typography>
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default CloseConfirmationModalAcertus;
