import { StyledComponent } from "@emotion/styled";
import { TabList, TabListProps } from "@mui/lab";
import { styled, Tab, TabProps, Grid, Typography, Card } from "@mui/material";


export const FileGrid = styled(Grid)({
    display: 'flex',
    flexDirection:'row',
    justifyContent: 'flex-start',
    gridTemplateAreas: "'document files button'",
    gridTemplateColumns: '.3fr 3fr 6fr',
    marginTop: '30px'
})
export const MainBody = styled('div')({
    margin: '0px 30px'
});
export const Title = styled(Typography)({
    color: '#ED7300',
    fontSize: '32px',
    fontFamily: 'Interstate',
    fontWeight: '400',
    lineHeight: '40px',
    wordWrap: 'break-word'
});
export const SubTitle = styled(Typography)({
    color: '#5D6771',
    fontSize: '14px',
    fontFamily: 'InterstateLight',
    fontWeight: '700',
    lineHeight: '40px',
    wordWrap: 'break-word'
});