import React, { useState, useEffect, useRef } from 'react';
import { Tab, Typography, NoSsr, styled, IconButton, Tooltip, Button, Box, FormControl, Autocomplete, createFilterOptions, TextField, Grid, Divider, Dialog, Zoom, LinearProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { IndividualPizzaTracker } from './IndividualPizzaTracker'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { SubmitButton, PromptButton, GoBackButton, FieldLabel, RenewalTitle, ResultText, TopGrid, MiddleGrid, BottomGrid, OrderGrid, SuccessGrid, Edit, ExtraGrid  } from '../Shared/Renewals';

export const IndividualConfirmation = ({ handleGoBack, handleNext, formData, handleEditReg, handleEditVehicle, handleEditShip, handleNewOrder }) => {
    
    
    const { reg, vehicle, shipping, reqBy } = formData;
    const [invoiceNumber, setInvoiceNumber] = useState<number | null>(null);
    const [defaultSuccess, setDefaultSuccess] = React.useState<boolean>(false);
    const [createSuccess, setCreateSuccess] = React.useState<boolean>(false);
    const [showOrderBox, setShowOrderBox] = React.useState<boolean>(false); 
    const handleModalWidth = async () => {
        const modalContent = document.getElementById("modalContent");
        if (modalContent) {
            modalContent.style.width = "1000px";
        }
    }
    const handleRenewalSubmit = async () => {
        handleModalWidth();
        setShowOrderBox(true);
        try {
            await createInvoice();
        } catch (error) {
            console.error('An error occurred during renewal submission:', error);
        }
    };
    const handleViewInvoiceClick = async () => {
        console.log("view invoice: ", invoiceNumber);
        window.location.href = `/Portal/InvoiceDetails?invoice=${invoiceNumber}`;
    };

    const formatShippingAddress = () => {

        if (shipping.shipAttention && shipping.shipName && shipping.shipAddress1 && shipping.shipCity && shipping.individualModal.selectedShipState && shipping.shipZip) {
            const formattedAddress = (
                <React.Fragment>
                    {shipping.shipAttention}
                    <br />
                    {shipping.shipName}
                    <br />
                    {shipping.shipAddress1}{shipping.shipAddress2 !== null && `, ${shipping.shipAddress2}`}
                    <br />
                    {shipping.shipCity}, {shipping.individualModal.selectedShipState.Abbreviation} {shipping.shipZip}
                    <br />
                    {shipping.shipPhone}
                </React.Fragment>
            );
            return formattedAddress;
        }

        return ''; 
    };
    const formatRegAddress = () => {

        if (reg.regName && reg.regAddress1 && reg.regCity && reg.individualModal.selectedState && reg.regZip) {
            const formattedAddress = (
                <React.Fragment>
                    {reg.regAddress1}{reg.regAddress2 !== null && `, ${reg.regAddress2}`}
                    <br />
                    {reg.regCity}, {reg.individualModal.selectedState.Abbreviation} {reg.regZip}
                </React.Fragment>
            );
            return formattedAddress;
        }

        return ''; 
    };
   
    const createInvoice = async () => {

        const url = `${window.location.origin}/Portal/CreateRenewalInvoice`;
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ contactId: reqBy.contact, vin: vehicle.vin, vehicleYear: vehicle.year, vehicleMake: vehicle.make, vehicleModel: vehicle.model, regName: reg.regName, regAddress1: reg.regAddress1, regAddress2: reg.regAddress2, regCity: reg.regCity, regState: reg.individualModal.selectedState.Id, regZip: reg.regZip, regCounty: reg.regCounty, renewalState: reg.individualModal.selectedRenewalState.Id, styleCode: vehicle.individualModal.selectedBodyType.StateStyleCodeId, fleet: reg.fleet, unit: reg.unit, poNumber: reg.poNumber }),
        };
        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                const errorMessage = `Failed to create invoice. Status: ${response.status} ${response.statusText}`;
                const responseBody = await response.text(); // Read response body as text
                console.error(errorMessage, responseBody);
                console.error("Request URL:", url);
                console.error("Request Options:", requestOptions);
            } else {
                const data = await response.json();
                const tranid = data.TransactionId
                const worktype = data.Worktype
                setInvoiceNumber(tranid);
                setCreateSuccess(true);
                await getDefaults(tranid, worktype);
            }
        } catch (error) {
            console.error('An error occurred during creating invoice:', error);
            return false;
        }
    };

    const getDefaults = async (invoice, worktype) => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ invoice: invoice, renewalState: reg.individualModal.selectedRenewalState.Id, regCity: reg.regCity, regZip: reg.regZip, regCounty: reg.regCounty, workType: worktype, plate: reg.plate, plateExp: reg.plateExp, vehicleColor: vehicle.color, vehicleYear: vehicle.year, shipAttention: shipping.shipAttention, shipName: shipping.shipName, shipAddress1: shipping.shipAddress1, shipAddress2: shipping.shipAddress2, shipCity: shipping.shipCity, shipState: shipping.individualModal.selectedShipState.Id, shipZip: shipping.shipZip, shipCounty: shipping.shipCounty, shipPhone: shipping.shipPhone, contactId: reqBy.contact, fleet: reg.fleet, unit: reg.unit, poNumber: reg.poNumber }),
        };
        const url = `${window.location.origin}/Portal/GetDefaults`;
        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                const errorMessage = `Failed to create defaults. Status: ${response.status} ${response.statusText}`;
                const responseBody = await response.text(); 
                console.error(errorMessage, responseBody);
                console.error("Request URL:", url);
                console.error("Request Options:", requestOptions);
            } else {
                const data = await response.json();
                setDefaultSuccess(true);
            }
        } catch (error) {
            console.error('An error occurred during get defaults tsx:', error);
            return false;
        }
    };
    return (
        <NoSsr>
            <div className="container height-550">
                <Grid container style={{ height: '100%', justifyContent: 'center' }}>
                        <TopGrid item xs={12}>
                            <br />
                            <Grid sx={{ gridArea: 'confirmationTitle', margin: '30px 0px' }}>
                                <RenewalTitle>Confirmation</RenewalTitle>
                            </Grid>
                            <Grid sx={{ gridArea: 'account', margin: '30px 0px', display:'inline-flex', alignItems:'center' }}>
                                <FieldLabel sx={{ margin: '0px 10px 0px 0px' }} >Account: </FieldLabel><ResultText>{reqBy.custName}</ResultText>
                            </Grid>
                            <Grid sx={{ gridArea: 'renewalState' }}>
                                <FieldLabel sx={{ marginBottom: '11px'}}>Renewal State</FieldLabel>
                                <ResultText>{reg.individualModal.selectedRenewalState.Name}</ResultText>
                            </Grid>
                            <Grid sx={{ gridArea: 'regName' }}>
                                <FieldLabel sx={{ marginBottom: '11px' }}>Registrant Name</FieldLabel>
                                <ResultText>{reg.regName}</ResultText>
                            </Grid>
                            <Grid sx={{ gridArea: 'regAddress' }}>
                                <FieldLabel sx={{ marginBottom: '11px' }}>Registrant Address</FieldLabel>
                                <ResultText sx={{marginBottom:'20px'}} >{formatRegAddress()}</ResultText>
                            </Grid>
                            <Grid sx={{ gridArea: 'plate' }}>
                                <FieldLabel sx={{ marginBottom: '11px' }}>Plate Number</FieldLabel>
                                <ResultText>{reg.plate}</ResultText>
                            </Grid>
                            <Grid sx={{ gridArea: 'plateExp' }}>
                                <FieldLabel sx={{ marginBottom: '11px' }}>Plate Expiration</FieldLabel>
                                <ResultText>{new Date(reg.plateExp).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}</ResultText>
                            </Grid>
                            <Grid sx={{ gridArea: 'edit', display:'grid', alignItems:'center' }}>
                                <Edit onClick={handleEditReg} />
                            </Grid>
                        </TopGrid>
                        <ExtraGrid item xs={12}>
                            <Grid sx={{ gridArea: 'fleet' }}>
                                <FieldLabel sx={{ marginBottom: '11px' }}>Fleet</FieldLabel>
                                <ResultText>{reg.fleet ? reg.fleet : 'N/A'}</ResultText>
                            </Grid>
                            <Grid sx={{ gridArea: 'unit' }}>
                                <FieldLabel sx={{ marginBottom: '11px' }}>Unit</FieldLabel>
                                <ResultText>{reg.unit ? reg.unit : 'N/A'}</ResultText>
                            </Grid>
                            <Grid sx={{ gridArea: 'poNumber' }}>
                                <FieldLabel sx={{ marginBottom: '11px' }}>PO Number</FieldLabel>
                                <ResultText sx={{ marginBottom: '20px' }} >{reg.poNumber ? reg.poNumber : 'N/A'}</ResultText>
                            </Grid>
                            <Grid sx={{ gridArea: 'edit', display: 'grid', alignItems: 'center' }}>
                                <Edit onClick={handleEditReg} />
                            </Grid>
                        </ExtraGrid>
                        <MiddleGrid item xs={12}>
                            <Grid sx={{ gridArea: 'vin', marginBottom: '20px' }}>
                                <FieldLabel sx={{ marginBottom: '11px' }}>VIN</FieldLabel>
                                <ResultText>{vehicle.vin}</ResultText>
                            </Grid>
                            <Grid sx={{ gridArea: 'vehicleYear' }}>
                                <FieldLabel sx={{ marginBottom: '11px' }}>Year</FieldLabel>
                                <ResultText>{vehicle.year}</ResultText>
                            </Grid>
                            <Grid sx={{ gridArea: 'vehicleMake' }}>
                                <FieldLabel sx={{ marginBottom: '11px' }}>Make</FieldLabel>
                                <ResultText>{vehicle.make}</ResultText>
                            </Grid>
                            <Grid sx={{ gridArea: 'vehicleModel' }}>
                                <FieldLabel sx={{ marginBottom: '11px' }}>Model</FieldLabel>
                                <ResultText>{vehicle.model}</ResultText>
                            </Grid>  
                            <Grid sx={{ gridArea: 'vehicleColor' }}>
                                <FieldLabel sx={{ marginBottom: '11px' }}>Color</FieldLabel>
                                <ResultText>{vehicle.color}</ResultText>
                            </Grid>
                            <Grid sx={{ gridArea: 'vehicleBody', marginBottom: '20px' }}>
                                <FieldLabel sx={{ marginBottom: '11px' }}>Body Type</FieldLabel>
                                <ResultText>{vehicle.individualModal.selectedBodyType.Description}</ResultText>
                            </Grid>
                            <Grid sx={{ gridArea: 'edit', display: 'grid', alignItems: 'center' }}>
                                <Edit onClick={handleEditVehicle} />
                            </Grid>
                            </MiddleGrid>
                        <BottomGrid item xs={12}>
                            <Grid sx={{ gridArea: 'shipTo' }}>
                                <Typography sx={{ fontFamily: 'Interstate', fontSize: '13px' }} >
                                    Ship To:
                                </Typography>
                            </Grid>
                            <Grid sx={{ gridArea: 'addressSummary' }}>
                                <Typography sx={{ fontFamily: 'InterstateLight', fontSize: '13px' }} >
                                    {formatShippingAddress()}
                                </Typography>
                            </Grid>
                            <Grid sx={{ gridArea: 'edit', display: 'grid', alignItems: 'center' }}>
                                <Edit onClick={handleEditShip} />
                            </Grid>
                            <Grid sx={{ gridArea: 'backButton', marginTop: '30px' }}>
                                <GoBackButton onClick={handleGoBack} variant="text"><ArrowBackIcon sx={{ color: '#ED7300' }} />Back</GoBackButton>
                            </Grid>
                            <Grid sx={{ gridArea: 'submitButton', display: 'grid', justifyContent: 'end', marginTop: '30px' }}>
                                <SubmitButton sx={{ width: '191px' }} onClick={ handleRenewalSubmit } >Submit Renewal</SubmitButton>
                            </Grid>
                        </BottomGrid>
                </Grid>                            

                <Dialog open={showOrderBox}>
                    <Grid container style={{ height: '100%', justifyContent: 'center', width:'775px' }}>
                        <OrderGrid item xs={10} style={{fontSize: '22px', fontFamily: 'Interstate' }}>
                            <Grid sx={{ gridArea: 'logo', display:'grid', justifyItems:'center', margin:'30px 0px'}}>
                                <img src="/Content/assets/img/metro/logo/tnr4C.png" width="400px" />
                            </Grid>
                            {!createSuccess || !defaultSuccess && (
                                <Zoom in={showOrderBox}>
                                    <Grid sx={{ gridArea: 'pizza', display: 'grid', justifyItems: 'center', margin: '0px 20px', fontSize: '13px' }}>
                                        <LinearProgress />
                                    </Grid>
                                </Zoom>
                            ) }
                        </OrderGrid>
                        {createSuccess && defaultSuccess ? (
                            <SuccessGrid item xs={10} style={{ fontSize: '22px', fontFamily: 'Interstate' }}>
                                <Zoom in={createSuccess && defaultSuccess} >
                                    <Grid sx={{ gridArea: 'success', display: 'grid', justifyItems: 'center', margin: '20px 0px 49px' }}>
                                        <Typography sx={{ fontSize: '22px', fontFamily: 'Interstate' }} >Order Received Successfully</Typography>
                                        <Typography sx={{ fontSize: '21px', fontFamily: 'Interstate' }}>Invoice Number: {invoiceNumber}</Typography>
                                    </Grid>
                                </Zoom>
                                <Zoom in={createSuccess && defaultSuccess} style={{ transitionDelay: createSuccess && defaultSuccess ? '500ms' : '0ms' }}>
                                    <Grid sx={{ gridArea: 'newOrder', marginBottom: '20px' }}>
                                        <Tooltip TransitionComponent={Zoom} enterDelay={1000} leaveDelay={200} title={<span style={{ fontSize: '12px' }}>Click here to submit a new order</span>} placement="top">
                                        <PromptButton startIcon={<NavigateBeforeIcon sx={{ color: 'white' }} />} onClick={handleNewOrder}>New Order</PromptButton>
                                        </Tooltip>
                                    </Grid>
                                </Zoom>
                                <Zoom in={createSuccess && defaultSuccess} style={{ transitionDelay: createSuccess && defaultSuccess ? '500ms' : '0ms' }}>
                                    <Grid sx={{ gridArea: 'viewOrder', marginBottom: '20px' }}>
                                        <Tooltip TransitionComponent={Zoom} enterDelay={1000} leaveDelay={200} title={<span style={{ fontSize: '12px' }}>Click here to view invoice details</span>} placement="top">
                                        <PromptButton endIcon={<NavigateNextIcon sx={{ color: 'white' }} />} onClick={handleViewInvoiceClick}>View Invoice</PromptButton>
                                        </Tooltip>
                                    </Grid>
                                </Zoom>
                                </SuccessGrid>
                        ) : (
                        <SuccessGrid item xs={10} style={{ fontSize: '22px', fontFamily: 'Interstate' }}>
                            <Grid sx={{ gridArea: 'success', display: 'grid', justifyItems: 'center', margin: '20px 0px' }}>
                                <Zoom in={!createSuccess || !defaultSuccess} style={{ transitionDelay: createSuccess && defaultSuccess ? '500ms' : '0ms' }}>
                                    <Typography sx={{ fontSize: '1em', fontFamily: 'Interstate' }}>Thank you for your submission!</Typography>
                                </Zoom>
                            </Grid>
                        </SuccessGrid>
                        )}
                    </Grid>
                </Dialog>
            </div>
        </NoSsr>
    );
}