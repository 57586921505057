import { State } from '../Types/common';
import { Grid, styled } from "@mui/material";
export interface RequirementAnswer {
    TransactionRequirementId: number;
    Data: string | null;
    Address: {
        Data: string;
        Attention?: string | null;
        Phone?: string | null;
        StreetAddress: string | null;
        AddressLine2?: string | null;
        City: string | null;
        CountyId?: number | null;
        State: State | null;
        StateId: number | null;
        StateName: string | null;
        StateAbrv: string | null;
        ZipCode: string | null;
    } | null;
}
export interface Selection {
    selectedState: State | null;
}

export const UpdateGrid = styled(Grid)({
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    gridTemplateAreas: "'title title .''body body body''backButton . saveButton'",
    gridTemplateColumns: '1fr 4fr 2fr',
})

export interface RequirementAddress {
    Attention?: string | null;
    Phone?: string | null;
    StreetAddress: string | null;
    AddressLine2?: string | null;
    City: string | null;
    CountyId?: number | null;
    StateId: number | undefined;
    StateName: string | undefined;
    StateAbrv: string | undefined;
    ZipCode: string | null;
}
export interface LookupOptions {
    RequirementLookupId: number;
    Value: string;
}